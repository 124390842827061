import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { background2, blue } from '../style/colors';
import athenelogo from '../../assets/images/athenelogo.png';
import LinkExtern from '../style/LinkExtern';
import Link from '../style/Link';

const RowNoWrap = styled('div')`
    display: flex;    
    flex-flow: nowrap;
    
    ${({ center }) => center && 'justify-content: center;'}
    ${({ right }) => right && 'justify-content: flex-end;'}
    ${({ between }) => between && 'justify-content: space-between;'}
    
    align-items: ${({ hcenter }) => (hcenter ? 'center' : 'stretch')};
`;

const HeaderStyle = styled(RowNoWrap)`
    align-items: center;
	justify-content: center;
	padding-top: 10px;
	padding-bottom: 10px;
	height: 19%;
	width: 100%;
    background-color: ${background2};
    border-bottom: 1px solid rgba(32, 33, 36, 0.28);
`;

const ContentBlock = styled(RowNoWrap)`
    align-items: center;
	display: flex;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;
	width: 900px;
`;

const TitleBlock = styled('div')`
    align-items: center;
    margin-right: 20px;
`;

const ImageBlock = styled('div')`
    align-items: center;
    @media screen and (max-width:815px){
    	#athene { display:none; }
	}
`;

const Title = styled(RowNoWrap)`
	text-align: right;
	margin-left: 70px;
	width: 100%;
    font-size: 28px;
    font-weight: 300;
    color: ${blue};
    &.activeNavItem {
        border-bottom: 5px solid rgba(0, 0, 0, 0.25);
        margin-bottom: 0;
    }
`;

const Subtitle = styled('div')`
	text-align: right;
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
    color: #434343;
    &.activeNavItem {
        border-bottom: 5px solid rgba(0, 0, 0, 0.25);
        margin-bottom: 0;
    }
`;

class Header extends Component {
    render() {
        return (
			<>	                        	
				{this.props.path.split('/')[1] === 'search' || 
				this.props.path.split('/')[1] === 'edit' || 
				this.props.path.split('/')[1] === 'karte' ||
				this.props.path.split('/')[1] === 'findOrt' ||
				this.props.path.split('/')[1] === 'list' || 
				this.props.path.split('/')[1] === 'user' || 
				this.props.path.split('/')[1] === 'attributes' || 
				this.props.path.split('/')[1] === 'operator' ? (
					<></>
				) : (	
			            <HeaderStyle>
							<ContentBlock>
								<ImageBlock>
			                    	<LinkExtern href="https://www.hadw-bw.de/" target="_blank">
			                        	<img id="athene" src={athenelogo} height="100px" alt="" class="responsive" />
			                    	</LinkExtern>
								</ImageBlock>
								<Link to="/">
									<TitleBlock>
			                        	<Title>
											Theologenbriefwechsel
										</Title>
										<Subtitle>im Südwesten des Reichs in der Frühen Neuzeit (1550-1620)</Subtitle>
									</TitleBlock>
								</Link>
							</ContentBlock>
			            </HeaderStyle>
				)}
			</>
        );
    }
}

const mapStateToProps = state => ({
    path: state.router.location.pathname,
});

export default connect(mapStateToProps)(Header);
