import React from 'react';
import { get } from 'lodash';

import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';

import AllowedForUser from '../../container/AllowedForUser';
import SearchHOC from '../../container/SearchHOC';

import { ZitiernameRow as TableRow } from '../../style/SearchRow';
import Cell from '../../style/SearchCell';
import Link from '../../style/Link';
import Row from '../../style/Row';

import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import OpenSVG from '../../SVG/Open';

import { CheckBox, Input } from '../../inputs/InputsWithLabelFocus';

const SearchZitiername = ({ items, search, defaultSort, handleChangeSort, sort, asc, loading }) => {
    if (!sort.length) defaultSort('name.v');
    return (
        <>
            <>
                <TableRow>
                    <Cell block>{null}</Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Name"
                            name="name"
                            value={get(search, 'name') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('name.v')}
                            direction={'name.v' === sort ? !asc : false}
                            active={'name.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Vorname"
                            name="vorname"
                            value={get(search, 'vorname') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('vorname.v')}
                            direction={'vorname.v' === sort ? !asc : false}
                            active={'vorname.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Beiname"
                            name="beiname"
                            value={get(search, 'beiname') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('beiname.v')}
                            direction={'beiname.v' === sort ? !asc : false}
                            active={'beiname.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Titel"
                            name="titel"
                            value={get(search, 'titel') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('titel.v')}
                            direction={'titel.v' === sort ? !asc : false}
                            active={'titel.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <CheckBox label="geprüft" name="geprueft" width="100%" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('geprueft.v')}
                            direction={'geprueft.v' === sort ? !asc : false}
                            active={'geprueft.v' === sort}
                        />
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                 {/* vertikaler Platzhalter für Dropdownmenüs: */}
			 	{items.length < 1 && (
					 <>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
					 </>
				 )}
                <Loading loading={loading}>
                    {items.map((item, i) => {
                        return (
                            <TableRow key={i}>
                                <Link to={`/edit/zitiername/${item._id}`} target={false}>
                                    <OpenSVG />
                                </Link>
                                <Cell>{get(item, 'name.v')}</Cell>
                                <Cell>{get(item, 'vorname.v')}</Cell>
                                <Cell>{get(item, 'beiname.v')}</Cell>
                                <Cell>{get(item, 'titel.v')}</Cell>
                                <Cell>{get(item, 'geprueft.v') === true ? '✔' : ''}</Cell>
                                <Cell block>{null}</Cell>
                            </TableRow>
                        );
                    })}
                </Loading>
            </>
            <AllowedForUser rolle="Hiwi">
                <NewButton type="person" target="_blank" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchZitiername);
