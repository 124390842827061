import React from 'react';
import NavBar from '../style/NavBar';
import NavLink from '../style/NavLink';

export default () => (
    <NavBar>
        <NavLink to="/search/brief">Brief</NavLink>
        <NavLink to="/search/archiv">Archiv</NavLink>
        <NavLink to="/search/signatur">Signatur</NavLink>
        <NavLink to="/search/briefhandschrift">Briefhandschrift</NavLink>
        <NavLink to="/search/briefdruck">Briefdruck</NavLink>
        <NavLink to="/search/druck">Druck</NavLink>
        <NavLink to="/search/literatur">Literatur</NavLink>
        <NavLink to="/search/person">Person</NavLink>
		<NavLink to="/search/zitiername">Name</NavLink>
        <NavLink to="/search/ort">Ort</NavLink>
        <NavLink to="/search/sache">Sache</NavLink>
    </NavBar>
);
