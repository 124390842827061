export const LOAD_ITEM = 'LOAD_ITEM';
export const CHANGE_ITEM = 'CHANGE_ITEM';
export const CHANGE_ITEM_FAV = 'CHANGE_ITEM_FAV';
export const DELETE_ARRAY_ELEMENT = 'DELETE_ARRAY_ELEMENT';
export const ADD_ARRAY_ELEMENT = 'ADD_ARRAY_ELEMENT';
export const ADD_ARRAY_ELEMENT_HERE = 'ADD_ARRAY_ELEMENT_HERE';
export const MOVE_ARRAY_ELEMENT_UP = 'MOVE_ARRAY_ELEMENT_UP';
export const MOVE_ARRAY_ELEMENT_DOWN = 'MOVE_ARRAY_ELEMENT_DOWN';
export const RESET_ITEM = 'RESET_ITEM';
export const BUILD_SHORT_SACHE = 'BUILD_SHORT_SACHE';
export const LOAD_LOG = 'LOAD_LOG';
export const CREATE_ITEM = 'CREATE_ITEM';
export const SAVE_ITEM = 'SAVE_ITEM';
export const SET_SCHEMA = 'SET_SCHEMA';
export const SET_TYPELIST = 'SET_TYPELIST';
