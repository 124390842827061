import React from 'react';
import PublicViewHOC from '../../container/PublicViewHOC';
//import H1 from '../../style/H1';
import Link from '../../style/Link';
import Area from '../../style/Area';
import OpenSVG from '../../SVG/Open';
import { Row } from '../../style/Row';
import Short from '../../container/Short';
import RefsLinks from '../../container/RefsLinksView';
import LinkExtern from '../../style/LinkExtern';
import Container from '../../style/Container';
import WebGlobeSVG from '../../SVG/WebGlobe';
//import { LabelFastSearch as Search } from '../../labels/LabelWithNoFocus';
import { connect } from 'react-redux';
import Space from '../../style/Space';
import AllowedForUser from '../../container/AllowedForUser';
import { get } from '../../service/immutables';

const BriefdruckPublicUI = ({ item, VD1617DBLink, VD1617StartPosition, VD1617EndPosition }) => (
    <>
		{/*
        <Row>
            <H1>Briefdruck</H1>
        </Row>
		*/}
		<br/>
		<Row>
			<Short name="long" />
			<AllowedForUser rolle="Gast">
	            <Space />
	            <Link to={`/edit/briefdruck/${item._id}`} target="_self">
	                <OpenSVG />
	            </Link>
			</AllowedForUser>
		</Row>
		<Row>
			<Area>
				<RefsLinks label="Brief&nbsp;" api="brief" idPath="_id" query="druck.v=" long noResultMessage />
			</Area>
		</Row>
        <br />
		<Row>
			<Container>Druck:</Container>
			<Area>
				{/* <Search label="" api="Druck" name="druck" width="100%" /> */}
				{(get('druck.v.short.v', item).includes("VD16 ") || get('druck.v.short.v', item).includes("VD17 ")) ? (
					<LinkExtern
			            href={
							 VD1617DBLink + get('druck.v.short.v', item).slice(VD1617StartPosition,VD1617EndPosition).replace(/ /g, "+")
							}
			            target="blank"
			        >
						{get('druck.v.long.v', item)}
			        </LinkExtern>
				) : (
					<>{get('druck.v.long.v', item)}</>
				)
				}
	        </Area>
		</Row>
		{get('exemplar.v', item) && (
			<Row>
				<Container>Benutztes Exemplar:</Container>
				<Area>
					<>{get('exemplar.v', item)}</>
		        </Area>
			</Row>
		)}
		{(get('reproduktion[0].datei[0].v', item) && get('reproduktion[0].datei[0].v', item).includes('http')) && (
			<>
				<Row>
					<Container>Externes Digitalisat:</Container>
					<LinkExtern
			            href={get('reproduktion[0].datei[0].v', item)}
			            target="blank"
			        >
			            <WebGlobeSVG />
			        </LinkExtern>
				</Row>
			</>
		)}
		<br />
		{/*
        <Search api="Briefdruck" label="abhängig von Briefdruck" name="abhaengigVon" />
        <List label="Nachdrucke" name="nachdrucke" simple>
            {index => (
				<Search label="Nachdruck" api="Briefdruck" name={`nachdrucke[${index}]`} />
            )}
        </List>
		*/}
    </>
);

const mapStateToProps = state => ({
    item: state.item,
	VD1617DBLink: get('druck.v.short.v', state.item).includes("VD16 ") ? "http://gateway-bayern.de/" : "https://kxp.k10plus.de/DB=1.28/CMD?ACT=SRCHA&IKT=8079&TRM=%27",
	VD1617StartPosition: get('druck.v.short.v', state.item).includes("VD16 ") ? get('druck.v.short.v', state.item).search("VD16 ") : get('druck.v.short.v', state.item).search("VD17 ") + 5,
	VD1617EndPosition: get('druck.v.short.v', state.item).search(")"-1),
});

export default PublicViewHOC(connect(mapStateToProps)(BriefdruckPublicUI));
