import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from '../service/immutables';
import Arrow from '../SVG/ChevronTopDown';


class CollapseTopDown extends Component {
    constructor(props) {
        super();
        this.state = {
            show: props.show,
        };
    }

    render() {
        const { children } = this.props;
        const { show } = this.state;
        return (
            <>
                {show && children}
                <Arrow
                    direction={show}
                    onClick={() => this.setState(({ show }) => ({ show: !show }))}
                />
            </>
        );
    }
}

CollapseTopDown.propTypes = {
    show: PropTypes.bool,
    trigger: PropTypes.arrayOf(PropTypes.string),
    helperForUserInput: PropTypes.string,
};

CollapseTopDown.defaultProps = {
    show: false,
};

const mapStateToProps = (state, props) => {
    let show = false;
    for (let i = 0; i < props.trigger.length; ++i) {
        let tmp = get(props.trigger[i] + '.v', state.item);
        if (tmp !== '' && tmp !== null && tmp !== undefined) {
            show = true;
            break; // ein relevanter wert wurde gefunden
        }
    }
    return { show: !!show || !!props.helperForUserInput };
};

export default connect(mapStateToProps)(CollapseTopDown);
