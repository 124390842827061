import React from 'react';
import NavBar from '../style/NavBar';
import NavLink from '../style/NavLink';
import { connect } from 'react-redux';
import { get as getLodash } from 'lodash';

const NavUser = ({ id }) => (
    <NavBar>
        <NavLink to={'/user/favoriten/' + id}>Favoriten</NavLink>
        <NavLink to={'/user/zuletztBearbeitet/' + id}>zuletzt Bearbeitet</NavLink>
        <NavLink to={'/user/mitarbeiter/' + id}>Einstellungen</NavLink>
    </NavBar>
);

const mapStateToProps = (state, props) => ({ id: getLodash(state, 'auth.user.id') });

export default connect(mapStateToProps)(NavUser);
