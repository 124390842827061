import { LabelFocusHoc } from './LabelFocusHoc';
import CheckBoxRaw from './raw/CheckBox';
import DropdownSimpleRaw, { ChangeHookDropDownSimple as ChangeHookDropDownSimpleRaw } from './raw/DropdownSimple';
import FastSearchRaw from './raw/FastSearch';
import FileUploadRaw from './raw/FileUpload';
import InputRaw from './raw/Input';
import ZeitRaw from './raw/Zeit';

export const CheckBox = LabelFocusHoc(CheckBoxRaw);
export const DropdownSimple = LabelFocusHoc(DropdownSimpleRaw);
export const ChangeHookDropdownSimple = LabelFocusHoc(ChangeHookDropDownSimpleRaw);
export const FastSearch = LabelFocusHoc(FastSearchRaw);
export const FileUpload = LabelFocusHoc(FileUploadRaw);
export const Input = LabelFocusHoc(InputRaw);
export const Zeit = LabelFocusHoc(ZeitRaw);
