import React from 'react';
import styled from 'styled-components';
import { blue, grey } from '../style/colors';

const RoundButton = styled.div`
    width: 50px;
    height: 50px;

    position: fixed;
    bottom: 5%;
    right: 5%;
    //overflow-y: auto;
    z-index: 5;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 3px solid ${({ saved }) => (saved ? blue : grey)};
    border-radius: 50%;

    &:hover {
        box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.5);
    }
`;

const SaveButton = ({ clickHandler, saved }) => (
    <RoundButton onClick={clickHandler} saved={saved}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            stroke={saved ? blue : grey}
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
            <polyline points="17 21 17 13 7 13 7 21" />
            <polyline points="7 3 7 8 15 8" />
        </svg>
    </RoundButton>
);

export default SaveButton;
