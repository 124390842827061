import { get } from 'lodash';

const placeholder = 'x';

export const formatZeit = zeit => {
    let datum = '';
    datum += formatDate(zeit.am);
    if (zeit.between) {
        datum += ' - ';
        datum += formatDate(zeit.bis);
    }
    return datum;
};

const formatDate = date => {
    let tag = '';
    if (get(date, 't.v') !== undefined) {
        if (get(date, 't.v') !== null) {
            tag = get(date, 't.v');
        }
    }
    let monat = '';
    if (get(date, 'm.v') !== undefined) {
        if (get(date, 'm.v') !== null) {
            monat = get(date, 'm.v');
        }
    }
    let jahr = '';
    if (get(date, 'j.v') !== undefined) {
        if (get(date, 'j.v') !== null) {
            jahr = get(date, 'j.v');
        }
    }
    if (tag !== '' && monat !== '' && jahr !== '') return `${tag}.${monat}.${jahr}`;
    else if (tag === '' && monat === '' && jahr !== '') return jahr;
    else if (tag === '' && monat === '' && jahr === '') return '';
    else return `${tag || placeholder}.${monat || placeholder}.${jahr || placeholder}`;
};

export default formatZeit;

/*

const placeholder = "x";

export const formatZeit = (zeit) => {
    console.log(zeit)
    let datum = ""
    datum += formatDate(zeit.am)
    if(zeit.between) {
        datum += "-"
        datum += formatDate(zeit.bis)
    }
    return datum
}

const formatDate = (date) => {
    if(date.t.v && date.m.v && date.j.v) return `${date.t.v}.${date.m.v}.${date.j.v}`
    else if(!date.t.v && !date.m.v && date.j.v) return date.j.v
    else if (!date.t.v && !date.m.v && !date.j.v) return ""
    else return `${date.t.v || placeholder}.${date.m.v || placeholder}.${date.j.v || placeholder}`
}

export default formatZeit


 */
