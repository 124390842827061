import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const RoundButton = styled(Link)`
    width: 50px;
    height: 50px;

    position: fixed;
    bottom: 60px;
    right: 90px;
    z-index: 5;
    overflow-y: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 3px solid #387ef5;
    border-radius: 50%;

    &:hover {
        box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.5);
    }
`;

const NewButton = ({ type, target }) => (
    <RoundButton to={`/edit/${type}`} target={target ? target : '_blank'}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#387ef5"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10" />
            <line x1="12" y1="8" x2="12" y2="16" />
            <line x1="8" y1="12" x2="16" y2="12" />
        </svg>
    </RoundButton>
);

export default NewButton;
