import styled from 'styled-components';

export default styled.div`
    //braucht man nur für table
    display: ${({ block }) => (block ? 'block' : 'flex')};
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    //ende tabel
    //braucht man für tabel und grid
    white-space: normal;
    word-wrap: break-word;
	hyphens: auto;
    padding: 1px;
`;
