import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from '../../service/immutables';
import Input from './Input';
import { Row } from '../../style/Row';
import Arrow from '../../SVG/ChevronLeftRight';
import { changeItem } from '../../store/actions/item';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../style/IconButton';

class Zeit extends Component {
    setToDay = () => {
        //https://www.w3schools.com/js/js_date_methods.asp
        const { handleChange, name } = this.props;
        var date = new Date();
        handleChange(name + '.am.t.v', date.getDate());
        handleChange(name + '.am.m.v', date.getMonth() + 1);
        handleChange(name + '.am.j.v', date.getFullYear());
    };
    render() {
        const {
            name,
            handleChange,
            erschlossen,
            unsicher,
            fragwuerdig,
            ca,
            between,
            today,
            onlyYear,
        } = this.props;
        return (
            <Row hcenter>
                {erschlossen && '['}
                <>
                    {onlyYear !== true && (
                        <>
                            <Input
                                name={name + '.am.t'}
                                placeholder="T"
                                maxLength={2}
                                width="1.2em"
                                textRight
                            />
                            .
                            <Input
                                name={name + '.am.m'}
                                placeholder="M"
                                maxLength={2}
                                width="1.2em"
                                textRight
                            />
                            .
                        </>
                    )}
                    <Input
                        name={name + '.am.j'}
                        placeholder="J"
                        maxLength={4}
                        width="2.3em"
                        textRight
                    />
                </>
                {between && <span style={{ margin: '0 5px' }}> - </span> /* has to be here */}
                {between && (
                    <>
                        {onlyYear !== true && (
                            <>
                                <Input
                                    name={name + '.bis.t'}
                                    placeholder="T"
                                    maxLength={2}
                                    width="1.2em"
                                    textRight
                                />
                                .
                                <Input
                                    name={name + '.bis.m'}
                                    placeholder="M"
                                    maxLength={2}
                                    width="1.2em"
                                    textRight
                                />
                                .
                            </>
                        )}
                        <Input
                            name={name + '.bis.j'}
                            placeholder="J"
                            maxLength={4}
                            width="2.3em"
                            textRight
                        />
                    </>
                )}
                {ca && <>&nbsp;ca.</>}
                {unsicher && '?'}
                {erschlossen && ']'}
                {fragwuerdig && <>&nbsp;[?]</>}
                <div>
                    <Arrow
                        direction={!between}
                        onClick={() => handleChange(name + '.between', !between)}
                    />
                </div>
                {today && (
                    <IconButton>
                        	<FontAwesomeIcon icon={faCalendarCheck} onClick={() => this.setToDay()} />
                    </IconButton>
                )}
            </Row>
        );
    }
}

Zeit.propTypes = {
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
    between: get(props.name + '.between', state.item),
    erschlossen: get(props.name + '.m.erschlossen', state.item),
    unsicher: get(props.name + '.m.unsicher', state.item),
    ca: get(props.name + '.m.ca', state.item),
    fragwuerdig: get(props.name + '.m.fragwuerdig', state.item),
});

const mapDispatchToProps = {
    handleChange: changeItem,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Zeit);
