import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Item from '../style/Item';
import ItemContainer from '../style/ItemContainer';
import AddSVG from '../SVG/Add';
import Add2SVG from '../SVG/AddSmall';
import DeleteSVG from '../SVG/Delete';
import {
    addArrayElement,
    addArrayElementHere,
    deleteArrayElement,
    moveArrayElementDown,
    moveArrayElementUp,
} from '../store/actions/item';
import { connect } from 'react-redux';
import { get } from '../service/immutables';
import ArrowTopDown from '../SVG/ArrowTopDownSmall';
import Link from '../style/Link';
import Row from '../style/Row';
import Area from '../style/Area';

const Corner = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const Column = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-left: 0px;
`;


class List extends React.Component {
    componentDidUpdate(prevProps, state) {
        // Typical usage (don't forget to compare props):
        //console.log("updated")
        //console.log(this.state)
        //console.log(this.props.state)
    }
    onKeyPressed = ({ keyCode }) => {
        const { add, name } = this.props;
        if (keyCode === 45) {
            add(name);
        }
    };
    render() {
        const {
            children,
            items,
            label,
            column,
            disabled,
            add,
            addHere,
            del,
            name,
            moveUp,
            moveDown,
            simple,
			withArea,
            newLink,
            noAddHereButton,
        } = this.props;
        // if list is empty put a single (virtual) item on the list for visual
        if (simple && !items.length) items.push({});
        return (
            <div onKeyDown={this.onKeyPressed}>
                {!simple && (
                    <div>
                        {label}
                    </div>
                )}
                <ItemContainer column={column} listSimple={simple} >
                    {items.map((item, i) => (
                        <Row nowrap key={i}>
							{simple && withArea ? (
								<Area simple>
		                            <Item simple={simple} style={{ backgroundColor: 'transparent' }}>
		                                {children(i, item)}
		                                {(!simple || items.length > 1) && (
		                                    <Corner>
		                                        <DeleteSVG onClick={() => del(name, i)} disabled={disabled} />
		                                        <ArrowTopDown
		                                            direction={true}
		                                            onClick={!disabled ? () => moveUp(name, i) : undefined}
		                                        />
		                                        <ArrowTopDown onClick={!disabled ? () => moveDown(name, i) : undefined}/>
		                                    </Corner>
		                                )}
		                            </Item>
								</Area>
							) : (
								<Item simple={simple} style={{ backgroundColor: 'transparent' }}>
	                                {children(i, item)}
	                                {(!simple || items.length > 1) && (
	                                    <Corner>
	                                        <DeleteSVG onClick={() => del(name, i)} disabled={disabled} />
	                                        <ArrowTopDown
	                                            direction={true}
	                                            onClick={!disabled ? () => moveUp(name, i) : undefined}
	                                        />
	                                        <ArrowTopDown onClick={!disabled ? () => moveDown(name, i) : undefined}/>
	                                        {!noAddHereButton && <Add2SVG onClick={() => addHere(name, i)} disabled={disabled} />}
	                                    </Corner>
	                                )}
		                        </Item>
							)}
                            {simple && i === items.length - 1 && (
                                <Column>
                                    <AddSVG onClick={() => add(name)} disabled={disabled} />
                                </Column>
                            )}
                        </Row>
                    ))}
                </ItemContainer>
                {!simple && (
					<Row>
						<AddSVG onClick={() => add(name)} disabled={disabled} />
						{newLink && (
	                        <>
	                            &nbsp;&nbsp;
	                            <Link to={'/edit/' + newLink + '/'} target={false}>✧</Link>
	                            &nbsp;&nbsp;
	                        </>
	                    )}
					</Row>
                )}
            </div>
        );
    }
}

List.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired, // KEIN items={pfad.zum.wert || []} da bei arrays der richtige Pfad immer vorhanden ist (durchs Backend).
    // TODO benötigen Listen in Listen doch die Abfrage nach dem Vorhandensein des Arrays?
    children: PropTypes.func.isRequired,
    column: PropTypes.bool, //  vertical direction
    disabled: PropTypes.bool, // disabled user can still delete items TODO: change that
};
List.defaultProps = {
    disabled: false,
};
const mapStateToProps = (state, props) => {
    return {
        items: get(props.name, state.item) || [],
        //state: state
    };
};

const mapDispatchToProps = {
    add: addArrayElement,
    addHere: addArrayElementHere,
    del: deleteArrayElement,
    moveUp: moveArrayElementUp,
    moveDown: moveArrayElementDown,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
