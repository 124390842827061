import React from 'react';
import { get } from 'lodash';

import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';
import AllowedForUser from '../../container/AllowedForUser';
import SearchHOC from '../../container/SearchHOC';

import { ArchivRow as TableRow } from '../../style/SearchRow';
import Cell from '../../style/SearchCell';
import Link from '../../style/Link';
import LinkExtern from '../../style/LinkExtern';
import Row from '../../style/Row';

import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import DeleteSVG from '../../SVG/Delete';
import OpenSVG from '../../SVG/Open';
import WebGlobeSVG from '../../SVG/WebGlobe';

import { Input } from '../../inputs/InputsWithLabelFocus';
import { CheckBox } from '../../inputs/InputsWithLabelFocus';

const SearchArchiv = ({ items, search, defaultSort, handleChangeSort, sort, asc, loading }) => {
    if (!sort.length) defaultSort('short.v');
    return (
        <>
            <div>
                <TableRow>
                    <Cell block>{null}</Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Kürzel"
                            name="short"
                            value={get(search, 'short') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('short.v')}
                            direction={'short.v' === sort ? !asc : false}
                            active={'short.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Name"
                            name="name"
                            value={get(search, 'name') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('name.v')}
                            direction={'name.v' === sort ? !asc : false}
                            active={'name.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Onlinekatalog"
                            name="katalog"
                            value={get(search, 'katalog') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('katalog.v')}
                            direction={'katalog.v' === sort ? !asc : false}
                            active={'katalog.v' === sort}
                        />
                    </Cell>
					<Cell>
                        <CheckBox
                            noMeta
                            width="100%"
                            label="Bildliz."
                            name="reproduktionen[0].rechte.vorhanden"
                            value={get(search, 'reproduktionen[0].rechte.vorhanden') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('reproduktionen.rechte.vorhanden.v')}
                            direction={'reproduktionen.rechte.vorhanden.v' === sort ? !asc : false}
                            active={'reproduktionen.rechte.vorhanden.v' === sort}
                        />
                    </Cell>
					<Cell>
						<Input
                            noMeta
                            width="100%"
                            label="Hinweise bezüglich Veröffentlichung von Digitalisaten"
                            name="reproduktionen[0].rechte.erlaeuterung"
                            value={get(search, 'reproduktionen[0].rechte.erlaeuterung') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('reproduktionen.rechte.erlaeuterung.v')}
                            direction={'reproduktionen.rechte.erlaeuterung.v' === sort ? !asc : false}
                            active={'reproduktionen.rechte.erlaeuterung.v' === sort}
                        />
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                {/* vertikaler Platzhalter für Dropdownmenüs: */}
			 	{items.length < 1 && (
					 <>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
					 </>
				 )}
                <Loading loading={loading}>
                    {items.map((item, i) => (
                        <TableRow key={i}>
                            <Link to={`/edit/archiv/${item._id}`} target={false}>
                                <OpenSVG />
                            </Link>
                            <Cell>{item.short && item.short.v}</Cell>
                            <Cell>{item.name && item.name.v}</Cell>
                            <Cell>
                                <LinkExtern href={item.katalog && item.katalog.v} target="blank">
                                    {get(item, 'katalog.v') ? <WebGlobeSVG /> : ''}
                                </LinkExtern>
                            </Cell>
							<Cell>{item.reproduktionen && item.reproduktionen[0] && item.reproduktionen[0].rechte && item.reproduktionen[0].rechte.vorhanden.v ? '✓' : ''}</Cell>
							<Cell>{item.reproduktionen && item.reproduktionen[0] && item.reproduktionen[0].rechte && item.reproduktionen[0].rechte.erlaeuterung.v}</Cell>
                            <AllowedForUser rolle="Admin">
                                <Link to={`/ref/archiv/${item._id}`}>
                                    <DeleteSVG />
                                </Link>
                            </AllowedForUser>
                        </TableRow>
                    ))}
                </Loading>
            </div>
            <AllowedForUser rolle="Hiwi">
                <NewButton type="archiv" target="_blank" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchArchiv);
