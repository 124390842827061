import PropTypes from 'prop-types';
import React from 'react';
import Row from '../../style/Row';
import TextAreaStyle from '../../style/Textarea';
import { get } from '../../service/immutables';
import { changeItem } from '../../store/actions/item';
import { connect } from 'react-redux';
import EditSVG from '../../SVG/Edit';
import LinkExtern from '../../style/LinkExtern';

// Textarea is build with https://react-components.buildo.io/#textareaautosize
class LinkedInput extends React.Component {
    constructor(props) {
        super();
        this.state = {
            //true = edit | false = link
            edit:
                props.value === ''
                    ? true
                    : props.checkHttp
                    ? props.value.includes('http')
                        ? false
                        : true
                    : false,
        };
    }
    onClickEdit = () => this.setState({ edit: true });
    handleBlur = () => {
        let edit = this.props.checkHttp
            ? this.props.value.includes('http')
                ? false
                : true
            : false;
        this.setState({ edit: edit });
    };
    render() {
        let {
            handleChange,
            value,
            placeholder,
            erschlossen,
            unsicher,
            fragwuerdig,
            disabled,
            helperForUserInput,
            link,
            removeFromValueForLink,
        } = this.props;
        let { edit } = this.state;
        if (link === undefined) link = '';
        let valueShow = value;
        if (value.includes(removeFromValueForLink)) {
            value = value.replace(removeFromValueForLink, '');
        }
        return (
            <Row nowrap>
                {erschlossen && '['}
                {edit ? (
                    <TextAreaStyle
                        onChange={handleChange}
                        value={valueShow === '' ? helperForUserInput : valueShow}
                        placeholder={placeholder}
                        // rows={1}     // setting the min height - default: 1
                        maxRows={15} // setting max height
                        disabled={disabled}
                        onBlur={this.handleBlur}
                    />
                ) : (
                    <>
                        <LinkExtern target="_blank" href={link === 'http://gateway-bayern.de/' ? link + value.replace(/ /g, '+') : link + value}>
                            {valueShow}
                        </LinkExtern>
                        <EditSVG onClick={this.onClickEdit} />
                    </>
                )}
                {unsicher && '?'}
                {erschlossen && ']'}
                {fragwuerdig && <>&nbsp;[?]</>}
            </Row>
        );
    }
}

LinkedInput.defaultProps = {};

LinkedInput.propTypes = {
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.any, //.isRequired
};

const mapStateToProps = (state, props) => ({
    value: get(props.name + '.v', state.item) || '',
    erschlossen: get(props.name + '.m.erschlossen', state.item),
    unsicher: get(props.name + '.m.unsicher', state.item),
    ca: get(props.name + '.m.ca', state.item),
    fragwuerdig: get(props.name + '.m.fragwuerdig', state.item),
});

const mapDispatchToProps = (dispatch, props) => ({
    handleChange: e => dispatch(changeItem(props.name + '.v', e.target.value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LinkedInput);
