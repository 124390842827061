import PropTypes from 'prop-types';
import React from 'react';
import ItemContainer from '../style/ItemContainer';
import Label from '../style/Label';
import {
    addArrayElement,
    deleteArrayElement,
    moveArrayElementDown,
    moveArrayElementUp,
} from '../store/actions/item';
import { connect } from 'react-redux';
import { get } from '../service/immutables';
import TitleList from '../style/Title';
import Row from '../style/Row';

const List = props => {
    const { children, items, label, row, labelInRow, forceCombinedContentInRow, simple, name, extraSpace, connector } = props;
    // if list is empty, dont show list
    let show = true;
    if (items.length === 0) show = false;
    let tmp = false;
    for (let i = 0; i < items.length; ++i) {
        if (items[i].v !== null) {
            tmp = true;
            break;
        }
    }
    show = tmp;
    if (simple && !items.length) items.push({});
    return (
        <>	
            {show && (
				labelInRow ? (
                    <ItemContainer row={row} margin="0.4em">
						<Row>
							<>{label}&nbsp;</>
	                        {items.map((item, i) => (
                                <Row nowrap key={name + ":" + i}>
									{i > 0 && (<>{connector}</>)}
                                    <>{children(i, item)}</>
									{extraSpace && (<>&nbsp;&nbsp;&nbsp;</>)}
                                </Row>
	                        ))}
						</Row>
                    </ItemContainer>
	            ) : (
					forceCombinedContentInRow ? (
						<>
		                    {!simple && (
		                        <TitleList>
		                            <Label>{label}</Label>
		                        </TitleList>
		                    )}
		                    <ItemContainer row={row}>
		                        {items.map((item, i) => (
	                                <Row key={name + ":" + i}>
										{i > 0 && (<>{connector}</>)}
										<>{children(i, item)}</>
										{extraSpace && (<>&nbsp;&nbsp;&nbsp;</>)}
	                                </Row>
		                        ))}
		                    </ItemContainer>
		                </>
					) : (
						<>
		                    {!simple && (
		                        <TitleList>
		                            <Label>{label}</Label>
		                        </TitleList>
		                    )}
		                    <ItemContainer row={row}>
		                        {items.map((item, i) => (
	                                <>
										{i > 0 && (<>{connector}</>)}
										<>{children(i, item)}</>
										{extraSpace && (<>&nbsp;&nbsp;&nbsp;</>)}
	                                </>
		                        ))}
		                    </ItemContainer>
		                </>
					)
				)
			)
		}
        </>
    );
};

List.defaultProps = {
    disabled: false,
};

List.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired, // KEIN items={pfad.zum.wert || []} da bei arrays der richtige Pfad immer vorhanden ist (durchs Backend).
    // TODO Frage: benötigen Listen in Listen doch die Abfrage nach dem Vorhandensein des Arrays?
    children: PropTypes.func.isRequired,
    row: PropTypes.bool, // force items direction to be vertical (default = column)
    disabled: PropTypes.bool, // when disabled user can only delete items (no more adding)
};

const mapStateToProps = (state, props) => ({
    items: get(props.name, state.item) || [],
});

const mapDispatchToProps = {
    add: addArrayElement,
    del: deleteArrayElement,
    moveUp: moveArrayElementUp,
    moveDown: moveArrayElementDown,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
