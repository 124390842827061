import styled from 'styled-components';
import { grey } from './colors';

export default styled('div')`
    font-size: 1.5em !important;
    font-weight: bold;
    letter-spacing: -0.2px !important;
    padding: 0 !important;
    margin-bottom: 10px !important;
    color: ${grey} !important;
`;
