import React from 'react';
import Icon from '../style/IconButton';
import { lightGrey } from '../style/colors';

export const ChevronTopDown = ({ direction, onClick, active }) =>
    direction ? (
        <Icon onClick={onClick} active={active}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke={lightGrey}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-down"
            >
                <polyline points="18 15 12 9 6 15" />
            </svg>
        </Icon>
    ) : (
        <Icon onClick={onClick} active={active}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke={lightGrey}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-down"
            >
                <polyline points="6 9 12 15 18 9" />
            </svg>
        </Icon>
    );

export default ChevronTopDown;
