import React from 'react';
import Icon from '../style/IconButton';
import { lightGrey, yellow } from '../style/colors';

export const FavoritStar = ({ direction, onClick, active }) =>
    direction ? (
        <Icon onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path fill={yellow} d="m4.8,23.4 7.3-22.6 7.3,22.6-19.2-14.0h23.8z" />
            </svg>
        </Icon>
    ) : (
        <Icon onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path fill={lightGrey} d="m4.8,23.4 7.3-22.6 7.3,22.6-19.2-14.0h23.8z" />
            </svg>
        </Icon>
    );
export default FavoritStar;

//source: https://upload.wikimedia.org/wikipedia/commons/2/29/Gold_Star.svg
