import { combineReducers } from 'redux';
import notification from './notifications';
import item from './item';
import auth from './auth';
import meta from './meta';
import search from './search';

const rootReducer = combineReducers({
    notification,
    item,
    auth,
    meta,
    search,
});

export default rootReducer;
