import styled from 'styled-components';
import { white } from './colors';

export const Area = styled('div')`
    background-color: ${white};
    max-width: ${props => (props.width ? props.width : 'fit-content')};
	
    height: ${props => (props.height ? props.height : 'fit-content')};
    ${p =>
        !p.noStyle &&
        `    
        margin: 5px 8px 6px 1px;
        padding: 6px 8px;
    `};
    ${p =>
        p.noStyle &&
        `    
        margin-top: -5px;
        padding: 6.5px 5px;
    `};
    vertical-align: top;
    border: none;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
    }
`;

export default Area;
