import React, { Component } from 'react';
import NavBar from '../style/NavBar';
import NavLink from '../style/NavLink';
import { connect } from 'react-redux';

class NavOpSearch extends Component {
    render() {
		return (
		    <NavBar>
				{(this.props.path.split('/')[1] === 'attributes' || (this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] !== 'brief')) && (
                	<NavLink to="/attributes/brief">Briefe</NavLink>
					)}
				{(this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] === 'brief') && (
                	<NavLink to="/operator/brief">Briefe</NavLink>
				)}
				{(this.props.path.split('/')[1] === 'attributes' || (this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] !== 'briefhandschrift')) && (
                	<NavLink to="/attributes/briefhandschrift">Briefhandschrift</NavLink>
					)}
				{(this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] === 'briefhandschrift') && (
                	<NavLink to="/operator/briefhandschrift">Briefhandschrift</NavLink>
				)}
				{(this.props.path.split('/')[1] === 'attributes' || (this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] !== 'person')) && (
                	<NavLink to="/attributes/person">Personen</NavLink>
					)}
				{(this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] === 'person') && (
                	<NavLink to="/operator/person">Personen</NavLink>
				)}
				{(this.props.path.split('/')[1] === 'attributes' || (this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] !== 'ort')) && (
                	<NavLink to="/attributes/ort">Orte</NavLink>
					)}
				{(this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] === 'ort') && (
                	<NavLink to="/operator/ort">Orte</NavLink>
				)}
				{(this.props.path.split('/')[1] === 'attributes' || (this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] !== 'sache')) && (
                	<NavLink to="/attributes/sache">Sachen</NavLink>
					)}
				{(this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] === 'sache') && (
                	<NavLink to="/operator/sache">Sachen</NavLink>
				)}
				{(this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] === 'archiv') && (
                	<NavLink to="/operator/archiv">Archiv</NavLink>
				)}
				{(this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] === 'signatur') && (
                	<NavLink to="/operator/signatur">Signatur</NavLink>
				)}
				{(this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] === 'briefdruck') && (
                	<NavLink to="/operator/briefdruck">Briefdruck</NavLink>
				)}
				{(this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] === 'Druck') && (
                	<NavLink to="/operator/druck">Druck</NavLink>
				)}
				{(this.props.path.split('/')[1] === 'operator' && this.props.path.split('/')[2] === 'literatur') && (
                	<NavLink to="/operator/literatur">Literatur</NavLink>
				)}
		    </NavBar>
		);
	}
}

const mapStateToProps = state => ({
    path: state.router.location.pathname,
});

export default connect(mapStateToProps)(NavOpSearch);