import React from 'react';
import CollapseArea from '../../container/CollapseArea';
import PublicViewHOC from '../../container/PublicViewHOC';
//import H1 from '../../style/H1';
import Link from '../../style/Link';
import Area from '../../style/Area';
import OpenSVG from '../../SVG/Open';
import { Row } from '../../style/Row';
import Short from '../../container/Short';
import RefsCount from '../../container/RefsCount';
import RefsLinks from '../../container/RefsLinksView';
import LinkExtern from '../../style/LinkExtern';
import WebGlobeSVG from '../../SVG/WebGlobe';
import { connect } from 'react-redux';
import Space from '../../style/Space';
import AllowedForUser from '../../container/AllowedForUser';
import { LabelTextarea as Textarea} from '../../labels/LabelWithNoFocus';
import { get } from '../../service/immutables';

const LiteraturPublicUI = ({ item }) => (
    <>
		{/*
        <Row>
            <H1>Literatur</H1>
        </Row>
		*/}
		<br/>
		<Row>
		<Short />
		<AllowedForUser rolle="Gast">
            <Space />
            <Link to={`/edit/literatur/${item._id}`} target="_self">
                <OpenSVG />
            </Link>
		</AllowedForUser>
		</Row>
        <br />
		{(get('gesamtaufnahme.v', item) && 
		!get('gesamtaufnahme.v', item).includes(' .') &&
		!get('gesamtaufnahme.v', item).includes(', ,') && 
		!get('gesamtaufnahme.v', item).includes('  ') && 
		!get('gesamtaufnahme.v', item).includes('|') && 
		!get('gesamtaufnahme.v', item).startsWith(':'))? (
            <Textarea label="" name="gesamtaufnahme"/>
		) : (
			<Area>
				<>Bibliographische Angabe wird nachgereicht</>		
			</Area>
		)}
		{(get('exemplar[0].standort.v', item) && get('exemplar[0].standort.v', item).includes('http')) && (
			<>
				<br/>
				<Row>
					<>Digitalisat:&nbsp;</>
					<LinkExtern
			            href={get('exemplar[0].standort.v', item)}
			            target="blank"
			        >
			            <WebGlobeSVG />
			        </LinkExtern>
				</Row>
			</>
		)}
		<br />
        <RefsCount
            label="Hinweise: "
            api="brief"
            idPath="_id"
            query="literatur.literatur.v="
            anschlussText="Briefe"
        />
        <CollapseArea show={false}>
            <RefsLinks api="brief" idPath="_id" query="literatur.literatur.v=" long column />
        </CollapseArea>
        <RefsCount
            label="Editionen: "
            api="brief"
            idPath="_id"
            query="edition.literatur.v="
            anschlussText="Briefe"
        />
        <CollapseArea show={false}>
            <RefsLinks api="brief" idPath="_id" query="edition.literatur.v=" long column />
        </CollapseArea>
        <br />
    </>
);

const mapStateToProps = (state) => {
    return {
        item: state.item,
    };
};

export default PublicViewHOC(connect(mapStateToProps)(LiteraturPublicUI));
