import React from 'react';
import Schlagwort from '../../components/Schlagworte';
import Anmerkung from '../../components/Anmerkungen';
import EditHOC from '../../container/EditHOC';
import CollapseArea from '../../container/CollapseArea';
import List from '../../container/List';
import Area from '../../style/Area';
import H1 from '../../style/H1';
import H3 from '../../style/H3';
import H4 from '../../style/H4';
import { Row } from '../../style/Row';
import Title from '../../style/Title';
import RefsLinks from '../../container/RefsLinks';
import RefsCount from '../../container/RefsCount';
import Short from '../../container/Short';
import DeleteItem from '../../container/DeleteItem';
import { connect } from 'react-redux';
import { get } from '../../service/immutables';
import { get as getLodash } from 'lodash';
import {
    CheckBox,
    DropdownSimple,
    FastSearch as Search,
    Input,
    LinkedInput,
    Textarea,
    Zeit,
} from '../../inputs/InputsWithMetaLabelFocus';
import VerticalLine from '../../style/VerticalLine';
import SetFavorite from '../../container/SetFavorite';
import Space from '../../style/Space';

const Signatur = ({ type, istGueltig, archivDa, uebergeordnetDa, disableCheckbox, shortNotGiven, item }) => (
    <>
        <Row>
            <H1>Signatur</H1>
            <Space />
            <SetFavorite type={type} />
            <Space />
            <DeleteItem type={type} />
        </Row>
		<Row>
			<H3 notBold>
			{/* Das hier könnte man massiv kürzen, wenn listenform besser wäre (kein Abstand beim Strich etc., aber vielleicht brauch man das woander so)*/}
			{get('stufe.v.short.v', item) && (
				<>
					<>{get('stufe.v.short.v', item)}</>
					{(((get('entstehung.0.am.j.v', item) || get('entstehung.0.bis.j.v', item)) && get('entstehung.0.listenform.v', item)) || get('umfang.v', item)) && (
						<>&nbsp;(</>
					)}
					{get('umfang.v', item) && (
						<>
							{get('umfang.v', item)}
						</>
					)}
					{(get('entstehung.0.am.j.v', item) || get('entstehung.0.bis.j.v', item)) && get('entstehung.0.listenform.v', item) && get('umfang.v', item) && (
						<>,&nbsp;</>
					)} 
					{(get('entstehung.0.am.j.v', item) || get('entstehung.0.bis.j.v', item)) && get('entstehung.0.listenform.v', item) && (
						<>{get('entstehung.0.listenform.v', item).replace(/ /g,'')}</>
					)}
					{(((get('entstehung.0.am.j.v', item) || get('entstehung.0.bis.j.v', item)) && get('entstehung.0.listenform.v', item)) || get('umfang.v', item)) && (
						<>)</>
					)}
					<>:&nbsp;</>
				</>
			)}
			</H3>
        	<Short />
		</Row>
		<Row>
			<H4 notBold>
			{get('titel.v', item) && (
				<>
					Titel:&nbsp;{get('titel.v', item)}
				</>
			)}
			</H4>
		</Row>
        <CollapseArea label="Verzeichnung" show={shortNotGiven}>
            <Row>
                <Area>
                    <Search label="Archiv" name="archiv" api="Archiv" disabled={istGueltig} small />
                </Area>
                <Area>
                    <Row>
                        <Search
                            wrap
                            label="übergeordneter Bestand"
                            noMeta
                            small
                            api="Signatur"
                            name="uebergeordnet"
                            disabled={archivDa || istGueltig}
                            extraParam="archiv"
                            extraParamName="archiv"
                            placeholder={
                                archivDa
                                    ? '(zuerst Archiv angeben)'
                                    : 'vorh. Signatur wählen'
                            }
                        />
                        <VerticalLine />
                        <DropdownSimple
                            wrap
                            label="Anschluss"
                            name={`anschluss`}
                            items={[
                                ' ',
                                ',_',
                                ',',
                                '.',
                                ':_',
                                ':',
                                '/',
                                '-',
                                'unmittelbar',
                                'anhängen',
                                'nicht wiedergeben',
                            ]}
                            default_value=" "
                            disabled={istGueltig || uebergeordnetDa}
							noMeta
							noUnderline
                            width="135px"
                        />
                        <VerticalLine />
                        <Input
                            wrap
                            label="Zusatz"
                            placeholder="unteilbarer Signaturteil"
                            name="zusatz"
                            disabled={istGueltig}
							width="175px"
							noMeta
                        />
                    </Row>
                </Area>
                <Area>
					<CheckBox
                        label="gültig"
                        name="istGueltig"
                        disabled={disableCheckbox}
						noMeta
						noUnderline
						width="32px"
                    />
                </Area>
            </Row>
            <Row>
                <Area>
                    <Search label="Verzeichnungsstufe" api="Verzeichnungsstufe" name="stufe" />
                </Area>
                <Area>
                    <Input label="Umfang" name="umfang" small />
                </Area>
                <Area>
                    <Search
                        label="einheitl. Überlieferungsform"
                        api="UebForm"
                        name="uebForm"
                        placeholder="Überlieferungsform"
                        width="280px"
                        
                    />
                </Area>
            </Row>
            <Row>
                <List label="Laufzeit" name="entstehung" simple withArea>
                    {index => (
                            <Zeit label="Laufzeit" name={`entstehung[${index}]`} />
                    )}
                </List>
            </Row>
			<Row>
                <Area>
                    <Textarea label="Titel" name="titel" extraLarge />
                </Area>
            </Row>
            <List label="Altsignaturen und alte Titel" name="alt" simple withArea column>
                {index => (
                        <Input
                            label="Alte Signatur/alter Titel"
                            name={`alt[${index}]`}
                            extraLarge
                        />
                )}
            </List>
        </CollapseArea>
		<CollapseArea label="Verwendung im Projekt" show={true}>
            <Row>
                <Area>
                    <RefsCount
                        label="Die Signatur (ohne untergeordnete Bestände) ist in "
                        api="briefhandschrift"
                        idPath="_id"
                        query={'signatur.v='}
                        anschlussText="Handschriften verwendet"
						column
                    />
        			<CollapseArea show={false}>
	            		<RefsLinks
	                        api="briefhandschrift"
	                        idPath="_id"
	                        query={'signatur.v='}
							column
	                    />	
        			</CollapseArea>
                </Area>
            </Row>
            <Row>
                <Area>
                    <RefsCount
                        label="Davon sind "
                        api="briefhandschrift"
                        idPath="_id"
                        query={'reproduktion.datei.v=&signatur.v='}
                        anschlussText="Reproduktionen vorhanden"
						column
                    />
        			<CollapseArea show={false}>
	                    <RefsLinks
	                        api="briefhandschrift"
	                        idPath="_id"
	                        query={'reproduktion.datei.v=&signatur.v='}
							column
	                    />
        			</CollapseArea>
                </Area>
            </Row>
            <Row>
                <Area>
                    <RefsCount
                        label="Untergeordnete Bestände: "
                        api="signatur"
                        idPath="_id"
                        query={'uebergeordnet.v='}
                        anschlussText=""
						column
                    />
        			<CollapseArea show={false}>
	                    <RefsLinks
	                        label="Bestände"
	                        api="signatur"
	                        idPath="_id"
	                        query={'uebergeordnet.v='}
							column
	                    />
					</CollapseArea>
                </Area>
            </Row>
        </CollapseArea>
 <CollapseArea label="Findmittel" show={true}>
            <Area>
                <List label="gedruckte Findmittel/Literatur" name="findmittel.publizierte" column>
                    {index => (
                        <Row>
                            <Search
                                label="Findmittel/Literatur"
                                api="Literatur"
                                name={`findmittel.publizierte[${index}].literatur`}
                            />
                            <VerticalLine />
                            <Input
                                label="Seite"
                                name={`findmittel.publizierte[${index}].seite`}
                                placeholder="S."
                                helperForUserInput="S. "
                                small
                            />
                            <VerticalLine />
                            <CheckBox
                                label="ausgewertet"
                                name={`findmittel.publizierte[${index}].ausgewertet`}
                            />
                        </Row>
                    )}
                </List>
            </Area>
            <Area>
                <List label="archivalische Findmittel" name="findmittel.archivalische" column>
                    {index => (
                        <Row>
                            <Input
                                label="Findmittel"
                                name={`findmittel.archivalische[${index}].string`}
                                large
                            />
                            <VerticalLine />
                            <CheckBox
                                label="ausgewertet"
                                name={`findmittel.archivalische[${index}].ausgewertet`}
                            />
                        </Row>
                    )}
                </List>
            </Area>
            <Area>
                <List label="elektronische Findmittel" name="findmittel.url" column>
                    {index => (
                        <Row>
                            <LinkedInput
                                label="Findmittel"
                                name={`findmittel.url[${index}].string`}
                                placeholder="http://..."
                                checkHttp
                                large
                            />
                            <VerticalLine />
                            <CheckBox
                                label="ausgewertet"
                                name={`findmittel.url[${index}].ausgewertet`}
                            />
                        </Row>
                    )}
                </List>
            </Area>
        </CollapseArea>
        <CollapseArea label="Bearbeitung" show={true}>
            <Area>
                <List label="Sichtungen" name="sichtung">
                    {index => (
                        <>
                            <Row>
                                <Area>
                                    <Title>Sichtung</Title>
                                    <Input
                                        label="Gründlichkeit"
                                        name={`sichtung[${index}].gruendlich`}
                                        placeholder="gründlich?"
                                        helperForUserInput="gründlich?"
                                    />
                                    <Input
                                        label="Vollständigkeit"
                                        name={`sichtung[${index}].vollstaendig`}
                                        placeholder="vollständig?"
                                        helperForUserInput="vollständig?"
                                    />
                                    <List
                                        label="durch"
                                        name={`sichtung[${index}].durch`}
                                        simple
                                        column
                                    >
                                        {index2 => (
                                            <Search
                                                label="durch"
                                                name={`sichtung[${index}].durch[${index2}]`}
                                                api="Mitarbeiter"
                                                small
                                            />
                                        )}
                                    </List>
                                    <Zeit label="am" name={`sichtung[${index}].am`} today />
                                </Area>
                                <Area>
                                    <Title>Briefe</Title>
                                    <DropdownSimple
                                        label="enthalten?"
                                        name={`sichtung[${index}].ertrag.funde`}
                                        items={['', 'ja', 'nein', 'vielleicht']}
                                        default_value=""
                                        small
                                    />
                                    <Input
                                        label="Menge"
                                        name={`sichtung[${index}].ertrag.briefanzahl`}
                                        small
                                    />
                                    <DropdownSimple
                                        label="übernommen"
                                        name={`sichtung[${index}].ertrag.uebernommen`}
                                        items={['', 'vollständig', 'teilweise']}
                                        default_value=""
                                        small
                                    />
                                </Area>
                            </Row>
                        </>
                    )}
                </List>
            </Area>
            <Area>
                <List label="Sichtungen von Teilmengen" name="teilsichtung">
                    {index => (
                        <>
                            <Row>
                                <Area>
                                    <Title>Abschnitt</Title>
                                    <Row>
                                        <Input
                                            label="Beschreibung/Titel"
                                            name={`teilsichtung[${index}].titel`}
                                        />
                                        <VerticalLine />
                                        <Input
                                            label="fol./S."
                                            name={`teilsichtung[${index}].seite`}
                                            placeholder="fol."
                                            helperForUserInput="fol. "
                                            small
                                        />
                                    </Row>
                                </Area>
                            </Row>
                            <Row>
                                <Area>
                                    <Title>Sichtung</Title>
                                    <Input
                                        label="Gründlichkeit"
                                        name={`teilsichtung[${index}].gruendlich`}
                                        placeholder="gründlich?"
                                        helperForUserInput="gründlich?"
                                    />
                                    <Input
                                        label="Vollständigkeit"
                                        name={`teilsichtung[${index}].vollstaendig`}
                                        placeholder="vollständig?"
                                        helperForUserInput="vollständig?"
                                    />
                                    <List
                                        label="durch"
                                        name={`teilsichtung[${index}].durch`}
                                        simple
                                        column
                                    >
                                        {index2 => (
                                            <Search
                                                label="durch"
                                                name={`teilsichtung[${index}].durch[${index2}]`}
                                                api="Mitarbeiter"
                                                small
                                            />
                                        )}
                                    </List>
                                    <Zeit label="am" name={`teilsichtung[${index}].am`} today />
                                </Area>
                                <Area>
                                    <Title>Briefe</Title>
                                    <DropdownSimple
                                        label="Briefe enthalten"
                                        name={`teilsichtung[${index}].ertrag.funde`}
                                        items={['', 'ja', 'nein', 'vielleicht']}
                                        default_value=""
                                        small
                                    />
                                    <Input
                                        label="Menge"
                                        name={`teilsichtung[${index}].ertrag.briefanzahl`}
                                        small
                                    />
                                    <DropdownSimple
                                        label="Briefe übernommen"
                                        name={`teilsichtung[${index}].ertrag.uebernommen`}
                                        items={['', 'vollständig', 'teilsichtung']}
                                        default_value=""
                                        small
                                    />
                                </Area>
                            </Row>
                        </>
                    )}
                </List>
            </Area>
            <Row>
                <Area>
                    <Anmerkung />
                </Area>
            </Row>
        </CollapseArea>
        <Row>
            <Schlagwort />
        </Row>
    </>
);

const mapStateToProps = state => {
    let checkbox = true;
    let archiv = true;
    let uebergeordnet = true;
    let gültig = getLodash(state, 'item.istGueltig.v');
    if (getLodash(state, 'item.archiv.v.short.v') !== undefined) {
        archiv = false;
    }
    if (getLodash(state, 'item.uebergeordnet.v.short.v') !== undefined) {
        uebergeordnet = false;
    }
    if (get('user.role', state.auth) === 'Admin') checkbox = false;
    return {
        istGueltig: gültig,
        archivDa: archiv,
        uebergeordnetDa: uebergeordnet,
        disableCheckbox: checkbox,
        shortNotGiven: state.item.short && state.item.short.v ? false : true, // für Collapsearea)
		item: state.item,
    };
};

export default EditHOC(connect(mapStateToProps)(Signatur));
