import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from '../../service/immutables';
import { getItem } from '../../service/fetch';

import CollapseArea from '../../container/CollapseArea';
import PublicViewHOC from '../../container/PublicViewHOC';
import List from '../../container/ViewList';
import DataCombination from '../../container/DataCombination';
import IFrame from '../../container/IFrame';
import TitleMeta from '../../container/TitleMeta';
import Switch from '../../components/SwitchView';
import IIIFViewer from '../../../client/components/IIIFViewer/IIIFViewer';

import Area from '../../style/Area';
import Button from '../../style/Button';
import Container from '../../style/Container';
import H3 from '../../style/H3';
import ItemContainer from '../../style/ItemContainer';
import Row from '../../style/Row';
import Space from '../../style/Space';

import {
    LabelFastSearch as Search,
    LabelTextarea as Textarea,
	LabelInput as Input,
	LabelLinkedInput as LinkedInput,
} from '../../labels/LabelWithNoFocus';

class BriefPublicUI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            log: {},
            digitFreigeschaltet: false,
            reproduktion: [],
            handschrift_id: '',
			digitAlleine: false, 
        }
    }

    componentDidMount() {
        const { item } = this.props;
        getItem('log/brief', item._id, "onlyLastChange")
            .then(log => this.setState({log: log}))
        if (get("digitalisat.quelle.handschrift.v._id", item)) {
            getItem('briefhandschrift', item.digitalisat.quelle.handschrift.v._id)
                .then(handschrift => this.setState(prevState => ({ digitFreigeschaltet: get("reproduktion.0.status.v", handschrift) === "freigeschaltet",
                                                                    reproduktion: handschrift.reproduktion,
                                                                    handschrift_id: handschrift._id
                                                                })))
    }
}
    

    render(){
        console.log(this.state);
        const {
            item,
			absendeortErlaeuterung,
            datumErlaeuterung,
			datumText,
            praesentation,
            eingang,
            verfasserErlaeuterung,
            adressatErlaeuterung,
            ueberbringer,
            antwortAuf,
            beantwortetDurch,
            bezugAuf,
            via,
            regest,
            sprache,
            incipit,
			transkriptionVorzeigbar,
			textvorlageAngegeben,
            handschrift,
            druck,
            edition,
			url,
			band,
            literatur,
			digitQuelleAngegeben,
            //digitQuelleHandschrift,
            //digitQuelleDruck,
            token
        } = this.props;
        const { digitFreigeschaltet, log, handschrift_id, reproduktion } = this.state;
		let { digitAlleine } = this.state;

		let transkriptionZeigen = false;
		if (transkriptionVorzeigbar && textvorlageAngegeben && (!band || band === 'Württemberg I' || band === 'Kurpfalz I' || band === 'online')) transkriptionZeigen = true;
		
		if (!transkriptionZeigen) digitAlleine = true;
		
		let digitZeigen = false;
		if (digitFreigeschaltet && digitQuelleAngegeben) digitZeigen = true;
		
		let hinweiseVorhanden = false;
		if (ueberbringer || bezugAuf || antwortAuf || beantwortetDurch || via || datumErlaeuterung || datumText || praesentation || eingang || verfasserErlaeuterung || adressatErlaeuterung || absendeortErlaeuterung) hinweiseVorhanden = true;
		
        const currentDate = new Date();
        return (
            <>
                <H3>
                    <ItemContainer row margin="0">
                        <TitleMeta />
                    </ItemContainer>
                </H3>
                {hinweiseVorhanden && (
                    <CollapseArea label='Hinweise' show={true}>
                        <Area>
                            <Input label="Datierung:" name="erlaeuterung" />
							{get("datumstext.v", item) && (
								<Container smallTopBottomPadding smallLeftRightPadding>
									<>Datumstext: </><>{get("datumstext.v", item)}</>
								</Container>
							)}
							{get("eingang.schoen.v", item) && (
								<Container smallTopBottomPadding smallLeftRightPadding>
									<>Eingang in der Kanzlei: </><>{get("eingang.schoen.v", item)}</>
								</Container>
							)}
							{get("praesentation.schoen.v", item) && (
								<Container smallTopBottomPadding smallLeftRightPadding>
									<>Präsentationsdatum: </><>{get("praesentation.schoen.v", item)}</>
								</Container>
							)}
							{(get("zielort[0].v.short.v", item) !== get("empfangsort[0].v.short.v", item)) && (
								<List label="Empfangsort:" name="empfangsort" row labelInRow connector="&nbsp;→&nbsp;">
	                                {index => <Search label="" api="Ort" name={`empfangsort[${index}]`}/>}
	                            </List>
							)}
							<List label="Überbringer:" name="ueberbringer" row labelInRow connector=",&nbsp;">
                                {index => <Search label="" api="Zitiername" name={`ueberbringer[${index}]`}/>}
                            </List>		
                            <List label="via:" name="via" row labelInRow connector="&nbsp;→&nbsp;">
                                {index => <Search label="" api="Ort" name={`via[${index}]`}/>}
                            </List>
                            <Row>
                                {verfasserErlaeuterung && (
                                    <List label="" name="verfasser" column>
                                        {index => (
                                            <Input
                                                label={
                                                    'Erläuterung zum Verfasser ' + get(`verfasser[${index}].person.v.short.v`, item) + ':'
                                                }
                                                name={`verfasser[${index}].erlaeuterung`}
                                            />
                                        )}
                                    </List>
                                )}
                            </Row>
                            <Row>
                                {adressatErlaeuterung && (
                                    <List label="" name="adressat" column>
                                        {index => (
                                            <Input
                                                label={
                                                    'Erläuterung zum Adressaten ' +
                                                    get(`adressat[${index}].person.v.short.v`, item) +
                                                    ':'
                                                }
                                                name={`adressat[${index}].erlaeuterung`}
                                            />
                                        )}
                                    </List>
                                )}
                            </Row>
							<Row>
                                {absendeortErlaeuterung && (
                                    <List label="" name="absendeort" column>
                                        {index => (
                                            <Input
                                                label={
                                                    'Erläuterung zum Absendeort ' +
                                                    get(`absendeort[${index}].ort.v.short.v`, item) +
                                                    ':'
                                                }
                                                name={`absendeort[${index}].erlaeuterung`}
                                            />
                                        )}
                                    </List>
                                )}
                            </Row>
                        <Row>
                        	{antwortAuf && (
				                <ItemContainer margin="0.3em">
				                    <List label="antwortet auf Brief-ID:" name="antwortAuf" column >
				                        {index => <Search label="" api="Brief" name={`antwortAuf[${index}].briefID`} />}
				                    </List>
			                    </ItemContainer>
		                    )}
		                    {bezugAuf && (
								<>
			                    	{antwortAuf && (<Space/>)}
				                    <ItemContainer margin="0.3em">
					                    <List label="vgl. auch Brief-ID:" name="bezugAuf" column>
					                        {index => <Search label="" api="Brief" name={`bezugAuf[${index}].briefID`} />}
					                    </List>
					                </ItemContainer>
				                </>
			                )}
			                {beantwortetDurch && (
								<>
									{(antwortAuf || bezugAuf) && (<Space/>)}
					                <ItemContainer margin="0.3em">
					                    <List label="beantwortet durch Brief-ID:" name="beantwortetDurch" column>
					                        {index => (
					                            <Search label="" api="Brief" name={`beantwortetDurch[${index}].briefID`} />
					                        )}
					                    </List>
					                </ItemContainer>
					            </>
			                )}
		                </Row>
                        </Area>
                    </CollapseArea>
                )}
                {regest && (
                    <CollapseArea label='Regest' show={true}>
                    	<Textarea label="" name="regest.text" />
						{get("regest.von[0].v", item) && (
							<Container>
								<>Bearbeitung: </>
								<>{get("regest.von[0].v.long.v", item).slice(0,get("regest.von[0].v.long.v", item).search(/\(/) - 1)}</>
								{get("regest.von[1].v", item) && (
									<>, {get("regest.von[1].v.long.v", item).slice(0,get("regest.von[1].v.long.v", item).search(/\(/) - 1)}</>
								)}
							</Container>
						)}
                    </CollapseArea>
                )}
                {(sprache || incipit || digitZeigen || transkriptionZeigen) && (
                    <CollapseArea label={!digitZeigen ? 'Text' : 'Text & Bild'} show={true}>
						{(sprache || incipit) && !transkriptionZeigen && (
	                        <Area>
								{sprache && (
									<Container smallTopBottomPadding smallLeftRightPadding>
										<>Sprache: </>
										{get('sprache', item)
											.map(single => get('v.short.v', single))
											.filter(single => single)
											.join(', ')
										}
									</Container>
								)}
								{incipit && (
									<Container smallTopBottomPadding smallLeftRightPadding>
										<>Incipit: </><>{get("incipit.v", item)}</>
									</Container>
								)}
	                        </Area>
						)}
						{(digitZeigen && transkriptionZeigen) && (
							<Button onClick={() => this.setState({ digitAlleine: !digitAlleine })}>
                        			{digitAlleine ? '➔ Text mit Bild' : '➔ Bild ohne Text'}
	                        </Button>
						)}
						{!digitAlleine && transkriptionZeigen && (
							<>
								{(get(`transkription.von[0].v.short.v`, item) !== get(`regest.von[0].v.short.v`, item) || get(`transkription.von[1].v.short.v`, item)) && (
									<Container>
										<>Transkription: </>
										{get("transkription.von[0].v.long.v", item) && (
											<>{get(`transkription.von[0].v.long.v`, item).slice(0,get(`transkription.von[0].v.long.v`, item).search(/\(/) - 1)}</>
										)}
										{get("transkription.von[1].v.long.v", item) && (
											<>, {get("transkription.von[1].v.long.v", item).slice(0,get("transkription.von[1].v.long.v", item).search(/\(/) - 1)}</>
										)}
									</Container>
								)}
								{get("transkription.quelle[1]", item) ? (
									<Container>
					                	<List label={!digitZeigen ? 'Textvorlage:' : 'Quelle:'} name="transkription.quelle">
						                    {index => (
												/* Switch ist hier wichtig für die show-Bedingung, es können sonst unabsichtliche gespeicherte Daten hier auftauchen */
												<Switch name={`transkription.quelle[${index}].show`}>
						                            <Search
						                                label=""
						                                api="Briefhandschrift"
						                                key="Briefhandschrift"
						                                name={`transkription.quelle[${index}].handschrift`}
						                            />
						                            <Search
						                                label=""
						                                api="Briefdruck"
						                                key="Druck"
						                                name={`transkription.quelle[${index}].druck`}
						                            />
													<DataCombination name={`transkription.quelle[${index}].literatur.literaturAngabe`} />
						                        </Switch>
						                    )}
					                	</List>
									</Container>
								) : (
									<Container>
										<Row>
											<>Textvorlage:&nbsp;</>
											<Switch name={`transkription.quelle[0].show`}>
					                            <Search
					                                label=""
					                                api="Briefhandschrift"
					                                key="Briefhandschrift"
					                                name={`transkription.quelle[0].handschrift`}
					                            />
					                            <Search
					                                label=""
					                                api="Briefdruck"
					                                key="Druck"
					                                name={`transkription.quelle[0].druck`}
					                            />
												<DataCombination name={`transkription.quelle[0].literatur.literaturAngabe`} />
					                        </Switch>
										</Row>
									</Container>
								)}
				               	<IFrame enable="transkription.datei" />
							</>
						)}
						{digitAlleine && digitZeigen && (
							<Container>
								{reproduktion && reproduktion.length > 0 && (
									<IIIFViewer
										briefhandschrift_id={handschrift_id}
										filepaths={reproduktion[0].datei}
										userToken={token}
									/>
							  	)}
								<br/>
								<div style={{textAlign: 'right'}}>
									<Switch name={`digitalisat.quelle.show`} >
										<Search
						                    label=""
						                    api="Briefhandschrift"
						                    key="Briefhandschrift"
						                    name={`digitalisat.quelle.handschrift`}
							            />
							            <Search
							                label=""
							                api="Briefdruck"
							                key="Druck"
							                name={`digitalisat.quelle.druck`}
							            />
									</Switch>
								</div>
							</Container>
						)}
				</CollapseArea>
				)}
                {(handschrift || druck || edition || literatur || url) && (
                    <CollapseArea label='Fundorte' show={true}>
                        <Area>
                            <List label="Handschriftliche Überlieferungen:" name="handschrift" column>
                                {index => (
                                    <Search label="" api="Briefhandschrift" name={`handschrift[${index}]`} />
                                )}
                            </List>
                            <List label="Gedruckte Überlieferungen:" name="druck" column>
                                {index => <Search label="" api="Briefdruck" name={`druck[${index}]`} />}
                            </List>
                            <List label="Editionen:" name="edition" column forceCombinedContentInRow>
                                {index => <DataCombination name={`edition[${index}].literaturAngabe`}/>}
                            </List>
                            <List label="Literatur:" name="literatur" column forceCombinedContentInRow>
                                {index => <DataCombination name={`literatur[${index}].literaturAngabe`}/>}
                            </List>
                            <List label="Datenbanken:" name={`url`} column>
                                {index => (
                                    <LinkedInput
                                        label=""
                                        name={`url[${index}]`}
                                    />
                                )}
                            </List>
                        </Area>
                    </CollapseArea>
                )}
                {(get('schlagworte.orte[0].v', item) || get('schlagworte.personen[0].v', item) || get('schlagworte.sachen[0].v', item)) && (
					<CollapseArea label='Schlagworte' show={true}>
						{get('schlagworte.personen[0].v', item) && (
							<>		
					        	<br />
						        <List label="Personen:" name="schlagworte.personen" row extraSpace>
						            {index => <Search label="" api="Zitiername" name={`schlagworte.personen[${index}]`} row />}
						        </List>
							</>
						)}
						{get('schlagworte.orte[0].v', item) && (
							<>
						        <br />
						        <List label="Orte:" name="schlagworte.orte" row extraSpace>
						            {index => <Search label="" api="Ort" name={`schlagworte.orte[${index}]`} row />}
						        </List>
							</>
						)}
						{get('schlagworte.sachen[0].v', item) && (
							<>
						        <br />
						        <List label="Sachen:" name="schlagworte.sachen" row extraSpace>
						            {index => <Search label="" api="Sache" name={`schlagworte.sachen[${index}]`} row />}
						        </List>
							</>
						)}
					</CollapseArea>
				)}
                <br/>
                <H3>Zitierempfehlung:</H3>
                <>Brief-ID {get('short.v', item)}, in: Theologenbriefwechsel im Südwesten des Reichs in der Frühen Neuzeit (1550-1620).
                    Verfügbar unter: https://thbw.hadw-bw.de/brief/{get('short.v', item)}. </>
                <>Zugriff am {currentDate.getDate()}.{currentDate.getMonth() + 1}.{currentDate.getFullYear()}.</>
                <br/>

                {/* <>urn:nbn:de:#########</> */}
                <br/>
                <>Hinweis: Diese Datenbank ist 'work in progress'. Bis zur abschließenden Bearbeitung des gesamten Korpus sind Änderungen und Vervollständigungen zu erwarten. </>
                <>Dieser Brief wurde am {get('createdAt', item).substr(0,10)}</>
                {(get('angelegtVon.v.long.v', item)) && <> von {get('angelegtVon.v.long.v', item).replace(/\(.*\)/, '')}</>}
                <> angelegt und am {get('am', log) ? get('am', log).substr(0,10) : get('updatedAt', item).substr(0,10)}</>
               {(get('mitarbeiter.long.v', log)) && <> von {get('mitarbeiter.long.v', log).replace(/\(.*\)/, '')}</>}
                <> zuletzt verändert.</>
				<br/><br/>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    item: state.item,
    verfasserErlaeuterung: get("verfasser", state.item).map((item, i) => (get("verfasser", state.item)[i].erlaeuterung.v)).join(''),
	adressatErlaeuterung: get("adressat", state.item).map((item, i) => (get("adressat", state.item)[i].erlaeuterung.v)).join(''),
	absendeortErlaeuterung: get("absendeort", state.item).map((item, i) => (get("absendeort", state.item)[i].erlaeuterung.v)).join(''),
    ueberbringer: get("ueberbringer[0].v.short.v", state.item),
    antwortAuf: get("antwortAuf[0].briefID.v.short.v", state.item),
    beantwortetDurch: get("beantwortetDurch[0].briefID.v.short.v", state.item),
    bezugAuf: get("bezugAuf[0].briefID.v.short.v", state.item),
    via: get("via[0].v.short.v", state.item),
    regest: get("regest.text.v", state.item),
    sprache: get("sprache[0].v", state.item),
    incipit: get("incipit.v", state.item),
	transkriptionVorzeigbar: get("transkription.datei.v", state.item) ? get("transkription.fertig.v", state.item) : false,
	textvorlageAngegeben: get("transkription.quelle[0].handschrift.v", state.item) || get("transkription.quelle[0].druck.v", state.item) || get("transkription.quelle[0].literatur.literatur.v", state.item),
	digitQuelleAngegeben: get("digitalisat.quelle.handschrift.v.short.v", state.item) || get("digitalisat.quelle.druck.v.short.v", state.item), 
    //digitQuelleHandschrift: get("digitalisat.quelle.handschrift.v.short.v", state.item),
    //digitQuelleDruck: get("digitalisat.quelle.druck.v.short.v", state.item),
    datumErlaeuterung: get("erlaeuterung.v", state.item),
	datumText: get("datumstext.v", state.item),
    praesentation: get("praesentation.schoen.v", state.item),
    eingang: get("eingang.schoen.v", state.item),
    handschrift: get("handschrift[0].v.short.v", state.item),
    druck: get("druck[0].v.short.v", state.item),
    edition: get("edition[0].literatur.v.short.v", state.item),
	url: get("url[0].v", state.item),
	band: get("band.v.short.v", state.item),
    literatur: get("literatur[0].literatur.v.short.v", state.item),
    token: state.auth.token
});

export default PublicViewHOC(connect(mapStateToProps)(BriefPublicUI));
