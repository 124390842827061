import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadSchema } from '../../../service/fetch';
import { setSchema, startOperatorSearch } from '../../../store/actions/search';
import { changeItem, resetItem } from '../../../store/actions/item';

import Button from '../../../style/Button';
import Cell from '../../../style/SearchCell';
import Container from '../../../style/Container';
import { defaultTextColor } from '../../../style/colors';
import Link from '../../../style/Link';
import { OpSearchRow as TableRow } from '../../../style/SearchRow';
import Row from '../../../style/Row';
import Space from '../../../style/Space';
import { SearchBody, SearchContainer } from '../../../style/Search';
import OpenSVG from '../../../SVG/Open';

import List from '../../../container/OpElementList';

import Loading from '../../../components/Loading';
import OperatorElement from '../../../components/OperatorElement';
import OnEnterPress from '../../../components/OnEnterPress';
import SearchFooter from '../../../components/SearchFooter';
import TokenCheckBox from '../../../components/TokenCheckBox';
import ZeitraumInput from '../../../components/ZeitraumInput';

import SearchItems from '../adv/items/SearchItems';

class ExternOpSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.match.params.type,
            loadingItems: false,
            showItems: false,
        };
        const { setSchema, changeItem } = this.props;
        changeItem('$operator', []);
        // get the items which can be used as proposals in fastsearch / dropdown
        loadSchema(this.props.type).then(setSchema);
    }

    async startSearch() {
        this.setState({ showItems: false });
        await this.props.startOperatorSearch();
        this.setState({ showItems: true });
    }

    componentWillUnmount() {
        this.props.resetItem();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const newType = nextProps.match.params.type;
        const { changeItem, setSchema } = nextProps;
        if (newType !== prevState.type) {
            // new type given! (User may clicked on navigation link)
            // set default values
            changeItem('$operator', []);
            // get the items which can be used as proposals in fastsearch / dropdown
            loadSchema(newType).then(setSchema);
            // change state by returning new updated state
            return {
                ...prevState,
                type: newType,
            };
        }
        // no state change
        return null;
    }

    render() {
        const { loadingItems, items, count, proposalItems } = this.props;
        const { showItems, type } = this.state;
		const englroute = this.props.match.url.split('/')[this.props.match.url.split('/').length-1] === 'engl';
        let loadingSchema = !!proposalItems ? 0 : 1;
		let searching = !loadingItems && !showItems;
        return (
            <>
                <SearchContainer>
                    <SearchBody>
							<Container>
                                {showItems && (
                                    <Row nowrap>
                                        <Container>{count + ' Treffer'}</Container>
										{!englroute && (
											<Button onClick={() => this.setState({ showItems: false })}>
                                            Suche ändern
                                        	</Button>
										)}
										{englroute && (
											<Button onClick={() => this.setState({ showItems: false })}>
                                            Adjust search
                                        	</Button>
										)}
                                    </Row>
                                )}
                                <Row nowrap>
                                    <Loading loading={loadingSchema}>
                                        {searching ? (
                                            <>
                                                <Row nowrap>
                                                    <List label="" name={'$operator'}>
                                                        {index => (
                                                            <OnEnterPress
                                                                action={() =>
                                                                    this.startSearch.apply(this)
                                                                }
                                                            >
                                                                <OperatorElement index={index} />
                                                            </OnEnterPress>
                                                        )}
                                                    </List>
                                                </Row>
                                                <Row nowrap>
													<Space/>
													{type === 'brief' && (
		                                               <ZeitraumInput name="$period" />
													)}
													<Space/>
													{!englroute && (
														<Button onClick={() => this.startSearch()}>
			                                            Suchen
			                                        	</Button>
													)}
													{englroute && (
														<Button onClick={() => this.startSearch()}>
			                                            Search
			                                        	</Button>
													)}
												</Row>
                                                <Row nowrap>
													{!englroute && (
														<Container>Hier wird Groß- und Kleinschreibung unterschieden!</Container>
													)}
													{englroute && (
														<Container>This search is case sensitive!</Container>
													)}
                                                </Row>
                                                <Row>
													{!englroute && type === 'brief' && (
														<Container width="600px">Zu Verfassern und Adressaten: Alternative Namenschreibungen (z.B. Brentz, Brentius usw.) 
														und Zugehörigkeiten zu Kollektiven (z. B. Johannes Marbach als Teil der Straßburger Prediger) sind über die
														<Link to="/filter/person"> ➔ Personensuche</Link> auffindbar. In jedem Personendatensatz sind auch die zugehörigen 
														Briefe aufgelistet.</Container>
													)}
													{englroute && (
														<Container></Container>
													)}
                                                </Row>
                                                <Row nowrap>
													{!englroute && type === 'person' && (
														<Container>Hinweis: Die Suchkombination "Amt" und "Wirkungsort" funktioniert derzeit nicht.</Container>
													)}
													{englroute && (
														<Container></Container>
													)}
                                                </Row>
                                                <Row nowrap>
													{!englroute && (
														<Link to={`/filter/${type}`} target="_self" color={defaultTextColor}>
	                                                    	<Button>Zur Filtersuche</Button>
                                                    	</Link>
													)}
													{englroute && (
														<Link to={`/filter/${type}/engl`} target="_self" color={defaultTextColor}>
	                                                    	<Button>Go to filter mode</Button>
                                                    	</Link>
													)}
    												{(type === 'person' || type === 'ort' || type === 'sache') && (
														<>
														{!englroute && (
															<Link to={`/filter/${type}`} target="_self" color={defaultTextColor}>
																<Button>
				                                                    Register
				                                                </Button>
															</Link>
														)}
														{englroute && (
															<Link to={`/filter/${type}/engl`} target="_self" color={defaultTextColor}>
																<Button>
				                                                    Index
				                                                </Button>
															</Link>
														)}
														</>
													)}
													{!englroute && (
														<Link to={`/erweitert/${type}`} target="_self" color={defaultTextColor}>
	                                                    	 <Button>Suche zurücksetzen</Button>
                                                   		</Link>
													)}
													{englroute && (
														<Link to={`/erweitert/${type}/engl`} target="_self" color={defaultTextColor}>
	                                                     	<Button>Reset</Button>
                                                    	</Link>
													)}
													{type === 'brief' && (<TokenCheckBox />)}
                                                </Row>
                                            </>
                                        ) : (
                                             <SearchItems items={items} loading={loadingItems}>
                                                    {(item, i) => (
														<TableRow key={i}>
	                                                        <Link
	                                                            to={englroute ? `/ansicht/${type}/${item._id}/engl` : `/ansicht/${type}/${item._id}`}
	                                                            target="_blank"
	                                                        >
	                                                            <OpenSVG />
	                                                        </Link>
                                                        	<Cell>{type === 'brief' ? item.long.v : (
																		(type === 'person' && item.shortAlt && item.shortAlt.v) ? item.short.v : item.short.v
																	)}</Cell>
	                                                    </TableRow>
                                                    )}
                                                </SearchItems>
                                        )}
                                    </Loading>
                                </Row>
                            </Container>
                    </SearchBody>
                </SearchContainer>
                {showItems && <SearchFooter startSearch={startOperatorSearch} />}
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    proposalItems: state.search.schema,
    items: state.search.items,
    count: state.search.count,
    type: props.match.params.type,
    loadingItems: state.search.loading,
});

const mapDispatchToProps = {
    changeItem,
    resetItem,
    setSchema,
    startOperatorSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternOpSearch);
