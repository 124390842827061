import React from 'react';
import ScrollContainer from '../../style/ScrollContainer';
import Container from '../../style/Container';
import styled from 'styled-components';
import { defaultTextColor } from '../../style/colors';
import Button from '../../style/Button';
import Link from '../../style/Link';
//import LinkExtern from '../../style/LinkExtern';
import Row from '../../style/Row';
import AllowedForUser from '../../container/AllowedForUser';

const BigFontSize = styled('div')`
    font-size: 1.5em !important;
    letter-spacing: -0.2px !important;
    padding: 0 !important;
`;

class Attributes extends React.Component {
    render() {
        return (
			<AllowedForUser rolle="Mitarbeiter">
                <ScrollContainer>
					<br/>
					<Row center>
						<Container>
							<Link to={'/operator/brief'} target="_self" color={defaultTextColor}>
								<Button>
									<BigFontSize>
	                                Briefe
									</BigFontSize>
									Erweiterte Suche 
	                            </Button>
							</Link>
						</Container>
						<Container>
	                        <Link to={'/operator/briefhandschrift'} target="_self" color={defaultTextColor}>
								<Button>
									<BigFontSize>
	                                Handschriften
									</BigFontSize>
									Erweiterte Suche 
	                            </Button>
							</Link>
						</Container>
						<Container>
	                        <Link to={'/operator/person'} target="_self" color={defaultTextColor}>
								<Button>
									<BigFontSize>
	                                Person
									</BigFontSize>
									Erweiterte Suche 
	                            </Button>
							</Link>
						</Container>
					</Row>
				</ScrollContainer>
			</AllowedForUser>
        );
    }
}
export default Attributes;
