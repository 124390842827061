import React from 'react';
import PropTypes from 'prop-types';
import style from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';

import AllowedForUser from '../container/AllowedForUser';
import { get } from '../service/immutables';

import { changeItem } from '../store/actions/item';

import Row from '../style/Row';
import CheckBoxStyle from '../style/CheckBox';
import Meta from '../SVG/Meta';


const PaddingLeft = style.div`
    padding-left: 10px;
`;

const MetaLabelStyle = style.div`
	padding-left: 1px;
	padding-right: 3px;
	margin-top: -2px;
`;

const checkBoxOptions = [
    {
        tooltip: 'erschlossen',
        path: '.m.erschlossen',
        valueName: 'erschlossen',
        metaLabel: '[..]'
    },
    {
        tooltip: 'unsicher',
        path: '.m.unsicher',
        valueName: 'unsicher',
        metaLabel: '?',
    },
    {
        tooltip: 'circa',
        path: '.m.ca',
        valueName: 'ca',
        metaLabel: 'ca.',
    },
    {
        tooltip: 'fragwürdig',
        path: '.m.fragwuerdig',
        valueName: 'fragwuerdig',
        metaLabel: '[?]'
    },
]

const CheckBox = ({ handleChange, name, value }) => (
    <CheckBoxStyle
        onClick={() => handleChange(name, !value)}
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="9"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        {value && <polyline points="20 6 9 17 4 12" />}
    </CheckBoxStyle>
);

const MetaButton = connect((state, props) => ({ active: state.meta.focus === props.name }))(Meta);

const AdminWrapper = ({ condition, children }) => 
   condition ? <AllowedForUser rolle="Admin">{children}</AllowedForUser> : children;


const handleCheckboxes = props => (name, value) => {
    const { handleChange, checkBoxValues } = props;
    const {fragwuerdig, unsicher, ca, erschlossen } = checkBoxValues;

    // if erschlossen or fragwuerdig is selected change the other values
    if (props.name + '.m.erschlossen' === name) {
        if (value) {
            if (fragwuerdig) handleChange(props.name + '.m.fragwuerdig', false);
        } else if (!value) {
            if (unsicher) handleChange(props.name + '.m.unsicher', false);
            if (ca) handleChange(props.name + '.m.ca', false);
        }
    } else if (props.name + '.m.fragwuerdig' === name && value) {
        if (unsicher) handleChange(props.name + '.m.unsicher', false);
        if (ca) handleChange(props.name + '.m.ca', false);
        if (erschlossen) handleChange(props.name + '.m.erschlossen', false);
    }
    handleChange(name, value);
};


const MetaCheckboxes = props => {
    const {
        name,
        checkBoxValues,
        disableButton,
        handledComponentIsZeit,
    } = props;

    return (
        <Row hcenter>
            <PaddingLeft />
            {checkBoxOptions.map((checkBox, index) => {
                if(checkBox.valueName === 'erschlossen' || checkBox.valueName === 'fragwuerdig' || checkBoxValues.erschlossen){
                    return (
                        <React.Fragment key={index}>
                        <AdminWrapper condition={checkBox.valueName === 'fragwuerdig'}>
                        <Tooltip title={checkBox.tooltip} placement='top'>
                        <span>
                            <CheckBox
                            handleChange = {handleCheckboxes(props)}
                            name = {name + checkBox.path}
                            value = {checkBoxValues[checkBox.valueName]}
                            />
                        </span>
                    </Tooltip>
                    <MetaLabelStyle>{checkBox.metaLabel}</MetaLabelStyle>
                    </AdminWrapper>
                    </React.Fragment>
                    )
                }
                return null;
            })
            }
            {handledComponentIsZeit && (
				<>
		            {!disableButton && (
		                <MetaButton name={name} handledComponentIsZeit={handledComponentIsZeit} />
		            )}
				</>
			)}
        </Row>            
    );
};

MetaCheckboxes.propTypes = {
    handledComponentIsZeit: PropTypes.bool,
};

MetaCheckboxes.defaultProps = {
    disableButton: false,
    handledComponentIsZeit: false,
};

const mapStateToProps = (state, props) => {
    const values = {
        erschlossen: get(props.name + '.m.erschlossen', state.item),
        unsicher: get(props.name + '.m.unsicher', state.item),
        ca: get(props.name + '.m.ca', state.item),
        fragwuerdig: get(props.name + '.m.fragwuerdig', state.item),
    }
    return {checkBoxValues: values}
};

const mapDispatchToProps = {
    handleChange: changeItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MetaCheckboxes);
