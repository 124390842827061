import React, { Component } from 'react';
import { get } from '../service/immutables';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { label } from '../style/colors';

const H1 = styled('div')`
    font-size: 3rem !important;
    letter-spacing: -0.8px !important;
    color: ${label} !important;
    margin-top: 10px !important;
    margin-left: 15px;
    line-height: 3rem;
    padding: 0 !important;
`;

class H1Edit extends Component {
    render() {
        const { value } = this.props;
        let short = null;
        if (value.short && value.short.v) short = value.short.v;
        if (short === 'Brief') short = null;
        return <H1>{short !== null ? '(' + short + ')' : ''}</H1>;
    }
}

const mapStateToProps = (state, props) => ({
    value: get(props.name + '.v', state.item) || props.helperForUserInput || '',
});

const mapDispatchToProps = (dispatch, props) => ({
    //handleChange: e => dispatch(changeItem(props.name + '.v', e.target.value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(H1Edit);
