import React from 'react';
import EditHOC from '../../container/EditHOC';
import { FavoriteRow as TableRow } from '../../style/SearchRow';
import Loading from '../../components/Loading';
import { connect } from 'react-redux';
import { get } from '../../service/immutables';
import Cell from '../../style/SearchCell';
import OpenSVG from '../../SVG/Open';
import Link from '../../style/Link';
import { Textarea } from '../../inputs/InputsWithLabelFocus';
import { getItem, removeFavorite } from '../../service/fetch';
import { changeItemFAV, loadItem } from '../../store/actions/item';
import DeleteSVG from '../../SVG/Delete';

const getLong = async (type, id) => {
    console.log('hier getLong');
    console.log(type, id);
    const result = await getItem(type, id);
    return {
        short: { v: result.short.v },
        long: { v: result.long.v },
        _id: id,
        v: id,
    };
};

class Favoriten extends React.Component {
    handleMount = async () => {
        let { items, user } = this.props;
        items.map(async (_, i) => {
            try {
                const result = await getLong(_.kind.v, _.objekt.v);
                this.props.handleChange(`favorit.${i}.objekt`, result);
                // result into state
            } catch (e) {
                if (e.message.includes(_.objekt.v) && _.objekt.v !== undefined) {
                    await removeFavorite(user, _.objekt.v);
                }
            }
        });
    };
    componentDidMount = async () => {
        await this.handleMount();
    };

    del = async index => {
        const { items, user } = this.props;
        if (items[index].objekt._id !== undefined) {
            const mitarbeiter = await removeFavorite(user, items[index].objekt._id);
            this.props.loadItem(mitarbeiter);
            await this.handleMount();
        }
    };

    render() {
        const { loading, items } = this.props;

        return (
            <>
                <Loading loading={loading}>
                    {items.map((item, i) => (
                        <TableRow key={i}>
                            <Link to={`/edit/${item.kind.v}/${item.objekt._id}`} target={false}>
                                <OpenSVG />
                            </Link>
                            <Cell>{item.kind.v}</Cell>
                            <Cell>{item.objekt.long && item.objekt.long.v}</Cell>
                            <Cell>
                                <Textarea
                                    label="Notiz"
                                    name={`favorit[${i}].notiz`}
                                    width={'100%'}
                                />
                            </Cell>
                            <Cell block>
                                <DeleteSVG onClick={() => this.del(i)} />
                            </Cell>
                        </TableRow>
                    ))}
                </Loading>
            </>
        );
    }
}

const mapStateToProps = state => ({
    items: get('item.favorit', state) || [],
    user: get('item._id', state) || '',
});

const mapDispatchToProps = {
    handleChange: changeItemFAV,
    loadItem,
};

export default EditHOC(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Favoriten)
);
