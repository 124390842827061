import React from 'react';
import H2 from '../../style/H2';
import LogHOC from '../../container/LogHOC';
import Cell from '../../style/SearchCell';
import { LogRow } from '../../style/SearchRow';
import Link from '../../style/Link';
import Space from '../../style/Space';
import { get } from 'lodash';
//replace(/\{/g, "").
//.replace(/\:/g, ": ")
const LogView = ({ logs, item, type, id }) => (
    <>
        <H2>
            Logs von
            <Space />
            <Link to={`/edit/${type}/${id}`}>{type + ' ' + get(item, 'short.v')}</Link>
        </H2>
        <div>
            {
                <LogRow>
                    <Cell block>Mitarbeiter</Cell>
                    <Cell block>Datum</Cell>
                    <Cell block>Veränderungen</Cell>
                </LogRow>
            }
            {logs.map((log, i) => {
                if (JSON.stringify(log.changes) !== '{}') {
                    return (
                        <LogRow key={i}>
                            <Cell block>{get(log, 'mitarbeiter.short.v')}</Cell>
                            <Cell block>{new Date(log.am).toLocaleString()}</Cell>
                            <Cell block>{JSON.stringify(log.changes).replace(/\{/, "").replace(/":/g, ": ").replace(/"/g, " ").replace(/\{/g, "_").replace(/\}/g, "_")}</Cell>
                        </LogRow>
                    );
                }
                return null;
            })}
        </div>
    </>
);

export default LogHOC(LogView);
