import { get } from 'lodash';
import React from 'react';
import ownSlice from '../../service/sliceSearch';

import SearchHOC from '../../container/SearchHOC';
import Loading from '../../components/Loading';
import { CheckBox, FastSearch as Search, Input } from '../../inputs/InputsWithLabelFocus';

import Link from '../../style/Link';
import { EditionsBriefRow as TableRow } from '../../style/SearchRow';
import Cell from '../../style/SearchCell';
import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import Row from '../../style/Row';


const SearchStrassburgI = ({ items, handleChangeSort, defaultSort, sort, asc, loading }) => {
    if (!sort.length) defaultSort('datierung.shortZeit.v');
    return (
        <>
            <>
                <TableRow>
					<Cell>
                        <Input width="100%" noMeta label="" name="aufnehmen" placeholder="ja?"/>
                        <ArrowTopDown
                            onClick={() => handleChangeSort('aufnehmen.v')}
                            direction={'aufnehmen.v' === sort ? !asc : false}
                            active={'aufnehmen.v' === sort}
                        />
                    </Cell>
					<Cell>
						<div>Nr.&nbsp;</div>
					</Cell>
					<Cell></Cell>
                    <Cell>
                        <Input width="100%" noMeta placeholder="Titel" label="" name="long" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('short.v')}
                            direction={'short.v' === sort ? !asc : false}
                            active={'short.v' === sort}
                        />
                    </Cell>
					<Cell>
                        <div>Datum</div>
                        <ArrowTopDown
                            onClick={() => handleChangeSort('datierung.shortZeit.v')}
                            direction={'datierung.shortZeit.v' === sort ? !asc : false}
                            active={'datierung.shortZeit.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <CheckBox noUnderline label="erf." name="fertig" width="100%" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('fertig.v')}
                            direction={'fertig.v' === sort ? !asc : false}
                            active={'fertig.v' === sort}
                        />
                    </Cell>
					<Cell>
						<Search
                            noMeta
                            width="100%"
                            label=""
                            api="Briefhandschrift"
                            name="transkription.quelle.handschrift"
							placeholder="Umfang"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('transkription.quelle.handschrift.v')}
                            direction={'transkription.quelle.handschrift.v' === sort ? !asc : false}
                            active={'transkription.quelle.handschrift.v' === sort}
                        />
					</Cell>
					<Cell>
						<Input width="100%" noMeta placeholder="Word S." label="" name="wordSeiten" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('wordSeiten.v')}
                            direction={'wordSeiten.v' === sort ? !asc : false}
                            active={'wordSeiten.v' === sort}
                        />
					</Cell>
					{/*
                    <Cell>
                        <div>&nbsp;Digitalisat</div>
                    </Cell>
					*/}
                    <Cell>
                        <CheckBox noUnderline label="Text" name="transkription.fertig" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('transkription.fertig.v')}
                            direction={'transkription.fertig.v' === sort ? !asc : false}
                            active={'transkription.fertig.v' === sort}
                        />
                    </Cell>
					<Cell>
                        <Input width="100%" noMeta placeholder="Anm. zur Transkription" label="" name="transkription.anmerkung" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('transkription.anmerkung.v')}
                            direction={'transkription.anmerkung.v' === sort ? !asc : false}
                            active={'transkription.anmerkung.v' === sort}
                        />
                    </Cell>
					<Cell>
                        <CheckBox noUnderline label="ediert" name="editionFertig" width="100%" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('editionFertig.v')}
                            direction={'editionFertig.v' === sort ? !asc : false}
                            active={'editionFertig.v' === sort}
                        />
                    </Cell>
					<Cell>
                        <Search
                            label=""
							width="100%"
							placeholder="von"
                            api="Mitarbeiter"
                            name={`ediertVon`}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('ediertVon.v')}
                            direction={'ediertVon.v' === sort ? !asc : false}
                            active={'ediertVon.v' === sort}
                        />
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                {/* vertikaler Platzhalter für Dropdownmenüs: */}
			 	{items.length < 1 && (
					 <>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
					 </>
				 )}
                <Loading loading={loading}>
                    {items.map((item, i) => (
                        <TableRow key={i}>
							<Cell>{item.aufnehmen && item.aufnehmen.v}</Cell>
							<Cell>{i + 1}</Cell>
							<Cell></Cell>
							<Cell>
	                            <Link to={`/edit/brief/${item._id}`} target={false}>
	                                {item.long && item.long.v}
	                            </Link>
							</Cell>
							<Cell></Cell>
                            <Cell>{item.fertig.v ? '✓' : ''}</Cell>
							<Cell block>
                                {item.transkription.quelle.map((e, i) => (
									<>
									{get(e, 'handschrift.v.short.v') && (
	                                    <Link to={'/edit/briefhandschrift/' + get(e, 'handschrift.v._id')} key={i}>
											{get(e, 'handschrift.v.short.v').includes(', fol. ') ? 
												get(e, 'handschrift.v.short.v').slice(get(e, 'handschrift.v.short.v').indexOf(', fol.')+1)
											: get(e, 'handschrift.v.short.v').includes(' fol.') ?
												get(e, 'handschrift.v.short.v').slice(get(e, 'handschrift.v.short.v').indexOf(' fol.')-2)
											: get(e, 'handschrift.v.short.v').includes(', S. ') ?
												get(e, 'handschrift.v.short.v').slice(get(e, 'handschrift.v.short.v').indexOf(', S.')+1)
											: get(e, 'handschrift.v.short.v').includes(' S.') ?
												get(e, 'handschrift.v.short.v').slice(get(e, 'handschrift.v.short.v').indexOf(' S.')-2)
											: ''}
	                                        <br />
	                                    </Link>
									)}
									{get(e, 'druck.v.short.v') && (
	                                    <Link to={'/edit/briefdruck/' + get(e, 'druck.v._id')} key={i}>
											{get(e, 'druck.v.short.v').includes(', Bl. ') ? 
												get(e, 'druck.v.short.v').slice(get(e, 'druck.v.short.v').indexOf(', Bl.')+1)
											: get(e, 'druck.v.short.v').includes(' Bl.') ?
												get(e, 'druck.v.short.v').slice(get(e, 'druck.v.short.v').indexOf(' Bl.')-2)
											: get(e, 'druck.v.short.v').includes(', S. ') ?
												get(e, 'druck.v.short.v').slice(get(e, 'druck.v.short.v').indexOf(', S.')+1)
											: get(e, 'druck.v.short.v').includes(' S.') ?
												get(e, 'druck.v.short.v').slice(get(e, 'druck.v.short.v').indexOf(' S.')-2)
											: ''}
	                                        <br />
	                                    </Link>
									)}
									</>
                                ))}
                            </Cell>
							<Cell>{item.wordSeiten && item.wordSeiten.v}</Cell>
							{/*
                            <Cell>
                                {item.digitalisat.quelle.handschrift.v ||
                                item.digitalisat.quelle.druck.v
                                    ? '✓'
                                    : ''}
                            </Cell>
							*/}
                            <Cell>{item.transkription.fertig.v ? '✓' : ''}</Cell>
							<Cell>{item.transkription.anmerkung && ownSlice(get(item, 'transkription.anmerkung.v'), 52)}</Cell>
							<Cell>{item.editionFertig.v ? '✓' : ''}</Cell>
							<Cell>{item.ediertVon.v && item.ediertVon.v.short.v}</Cell>
                        </TableRow>
                    ))}
                </Loading>
            </>
        </>
    );
};

export default SearchHOC(SearchStrassburgI);
