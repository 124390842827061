import React from 'react';
import NavBar from '../style/NavBar';
import NavLink from '../style/NavLink';

export default () => (
    <NavBar>
        <NavLink to="/list/amt">Amt</NavLink>
        <NavLink to="/list/beziehung">Beziehung</NavLink>
		<NavLink to="/list/band">Band</NavLink>
		<NavLink to="/list/geo">Geographische Kategorie</NavLink>        
		<NavLink to="/list/konfession">Konfession</NavLink>
		<NavLink to="/list/latein">Latein</NavLink>
		<NavLink to="/list/medium">Medium</NavLink>        
		<NavLink to="/list/mitarbeiter">Mitarbeiter</NavLink>
		<NavLink to="/list/profession">Profession</NavLink>
        <NavLink to="/list/sachgruppe">Sachgruppe</NavLink>
		<NavLink to="/list/sprache">Sprache</NavLink>
		<NavLink to="/list/textsorte">Textsorte</NavLink>
		<NavLink to="/list/uebForm">Überlieferungsform</NavLink>        
		<NavLink to="/list/verzeichnungsstufe">Verzeichnungsstufe</NavLink>
    </NavBar>
);
