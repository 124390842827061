import React from 'react';
import Anmerkung from '../../components/Anmerkungen';
import Schlagwort from '../../components/Schlagworte';
import EditHOC from '../../container/EditHOC';
import CollapseArea from '../../container/CollapseArea';
import List from '../../container/List';
import Area from '../../style/Area';
import Link from '../../style/Link';
import OpenSVG from '../../SVG/Open';
import H1 from '../../style/H1';
import { Row } from '../../style/Row';
import Title from '../../style/Title';
import Short from '../../container/Short';
import DeleteItem from '../../container/DeleteItem';
import {
    CheckBox,
    FastSearch as Search,
    Input,
    LinkedInput,
    Textarea,
    Zeit,
} from '../../inputs/InputsWithMetaLabelFocus';
import VerticalLine from '../../style/VerticalLine';
import { connect } from 'react-redux';
import SetFavorite from '../../container/SetFavorite';
import Space from '../../style/Space';

const Archiv = ({ type, shortNotGiven, item }) => (
    <>
        <Row>
            <H1>Archiv</H1>
			<Space />
                <Link to={`/ansicht/archiv/${item._id}`} target="_self">
                    <OpenSVG />
                </Link>
            <Space />
            <SetFavorite type={type} />
            <Space />
            <DeleteItem type={type} />
        </Row>
        <Short />
        <CollapseArea label="Name & Ort" show={shortNotGiven}>
            <Area>
                <Search label="Archivort" api="Ort" name="ort" extraParam="amtlich" />
            </Area>
            <Row>
                <Area>
                    <Input label="Archivkürzel" name="kuerzel" />
                </Area>
                <List label="alternative Archivkürzel" name="altkuerzel" simple>
                    {index => (
                        <Area noStyle>
                            <Input
                                key={index}
                                label="alternatives Archivkürzel"
                                name={`altkuerzel[${index}]`}
                            />
                        </Area>
                    )}
                </List>
            </Row>
            <Area>
                <Input label="Archivname" name="name" large />
            </Area>
            <List label="alte Archivnamen" name="altenamen" simple>
                {index => (
                    <Area noStyle>
                        <Input
                            key={index}
                            label="alter Archivname"
                            name={`altenamen[${index}]`}
                            large
                        />
                    </Area>
                )}
            </List>
        </CollapseArea>
        <CollapseArea label="Bedingungen" show={true}>
            <Row>
                <Area>
                    <LinkedInput
                        label="Digitaler Katalog"
                        name="katalog"
                        placeholder="http://..."
                        extraLarge
                    />
                </Area>
            </Row>
			<Area>
				<Title>weitere Findmittel</Title>
	            <Area>
	                <List label="gedruckte Findmittel/Literatur" name="findmittel.publizierte" column>
	                    {index => (
	                        <Row>
	                            <Search
	                                label="Findmittel/Literatur"
	                                api="Literatur"
	                                name={`findmittel.publizierte[${index}].literatur`}
	                            />
	                            <VerticalLine />
	                            <Input
	                                label="Seite"
	                                name={`findmittel.publizierte[${index}].seite`}
	                                placeholder="S."
	                                helperForUserInput="S. "
	                                small
	                            />
	                            <VerticalLine />
	                            <CheckBox
	                                label="ausgewertet"
	                                name={`findmittel.publizierte[${index}].ausgewertet`}
	                            />
	                        </Row>
	                    )}
	                </List>
	            </Area>
	            <Area>
	                <List label="archivalische Findmittel" name="findmittel.archivalische" column>
	                    {index => (
	                        <Row>
	                            <Input
	                                label="Findmittel"
	                                name={`findmittel.archivalische[${index}].string`}
	                                large
	                            />
	                            <VerticalLine />
	                            <CheckBox
	                                label="ausgewertet"
	                                name={`findmittel.archivalische[${index}].ausgewertet`}
	                            />
	                        </Row>
	                    )}
	                </List>
	            </Area>
	            <Area>
	                <List label="elektronische Findmittel" name="findmittel.elektronische" column>
	                    {index => (
	                        <Row>
	                            <Input
	                                label="Findmittel"
	                                name={`findmittel.elektronische[${index}].string`}
	                                placeholder="http://..."
	                                large
	                            />
	                            <VerticalLine />
	                            <CheckBox
	                                label="ausgewertet"
	                                name={`findmittel.elektronische[${index}].ausgewertet`}
	                            />
	                        </Row>
	                    )}
	                </List>
	            </Area>
			</Area>
            <Row>
                <Area>
                    <CheckBox label="W-LAN verfügbar" name={`wlan`} />
                </Area>
            </Row>
            <Row>
                <Area>
					<Title>Benutzungsantrag</Title>
					<Input label="angegebener Zweck" name="benutzungsZweck" large />
					<CheckBox label="Editionsgenehmigung (implizit/explizit/nachträglich)" name={`editionsGenehmigung`} large/>
                    <Input label="Benutzerausweis" name="ausweis" large />
                </Area>
            </Row>
            <Row>
                <Area>
                    <List label="Reproduktionen" name="reproduktionen">
                        {index => (
                            <>
                                <Input label="Art" name={`reproduktionen[${index}].art`} />
                                <Input label="Kosten" name={`reproduktionen[${index}].kosten`} />
                                <Input
                                    label="Wartezeit"
                                    name={`reproduktionen[${index}].wartezeit`}
                                />
                                <Input
                                    label="Selbstanfertigung"
                                    name={`reproduktionen[${index}].selbst`}
                                />
                                <Title>Veröffentlichungserlaubnis</Title>
                                <CheckBox
                                    label="erteilt"
                                    name={`reproduktionen[${index}].rechte.vorhanden`}
                                />
                                <Input
                                    label="Erläuterung/Bedingungen"
                                    name={`reproduktionen[${index}].rechte.erlaeuterung`}
                                />
                            </>
                        )}
                    </List>
                </Area>
            </Row>
        </CollapseArea>
        <CollapseArea label="Kontakt & Bearbeitung" show={true}>
			<Row>
	            <Area>
	                <List label="Anfragen/Schreiben" name="anfrage">
	                    {index => (
	                        <>
	                            <List label="durch" name={`anfrage[${index}].durch`} simple column>
	                                {index2 => (
	                                    <Search
	                                        label="durch"
	                                        name={`anfrage[${index}].durch[${index2}]`}
	                                        api="Mitarbeiter"
	                                    />
	                                )}
	                            </List>
	                            <Zeit label="am" name={`anfrage[${index}].am`} today />
								<Input
                                    label="Betreff"
                                    name={`anfrage[${index}].betreff`}
                                />
								<Zeit label="Antwort Datum" name={`anfrage[${index}].antwortAm`} today />
	                        </>
	                    )}
	                </List>
	            </Area>
	        </Row>
            <Row>
                <Area>
                    <List label="Besuche" name="besuch">
                        {index => (
                            <>
                                <List label="durch" name={`besuch[${index}].durch`} simple column>
                                    {index2 => (
                                        <Row key={index2} item>
                                            <Search
                                                label="durch"
                                                name={`besuch[${index}].durch[${index2}]`}
                                                api="Mitarbeiter"
                                            />
                                        </Row>
                                    )}
                                </List>
                                <Zeit label="am" name={`besuch[${index}].am`} today />
                            </>
                        )}
                    </List>
                </Area>
            </Row>
            <Row>
                <Area>
                    <Textarea label="Stand der Gesamtdurchsicht" width="800px" name="durchsicht" />
                </Area>
            </Row>
            <Area>
                <Anmerkung />
            </Area>
            <Area>
                <List label="Empfehlungen als nächstes durchzusehender Bestände" name="empfohleneBestaende">
                    {index => (
                        <Search
                            label="Signatur"
                            api="signatur"
                            name={`empfohleneBestaende[${index}]`}
                        />
                    )}
                </List>
            </Area>
            <Row>
                <Schlagwort />
            </Row>
        </CollapseArea>
    </>
);

const mapStateToProps = (state, props) => ({
    shortNotGiven: state.item.short && state.item.short.v ? false : true, // für Collapsearea)
	item: state.item,
});

export default EditHOC(connect(mapStateToProps)(Archiv));
