import React from 'react';

import ShowEye from '../container/ShowEye';
import SortArrow from '../container/SortArrow';
import {
    CheckBox,
    DropdownSimple,
    FastSearch,
    Input,
    Zeit,
} from '../inputs/InputsWithLabelFocus.js';

import Area from '../style/Area';
import Row from '../style/Row';

const AdvSearchInput = ({data}) => {
        const { path, enum : dropdown, label, type, defaultEye = false } = data;
        const name = path.slice(-2) === '.v' ? path.slice(0, -2) : path;

        let InputComponent;

        switch (type) {
            case undefined:
                InputComponent = <Input noMeta label={label} name={name}/>;
                break;
            case "Dropdown":
                InputComponent = <DropdownSimple noMeta label={label} name={name} default_value={""} items={dropdown} />;
                break;
            case "Zeit":
                InputComponent = <Zeit noMeta label={label} name={name} />;
                break;
            case "Checkbox":
                InputComponent = <CheckBox noMeta label={label} name={name} />;
                break;
            default:
                InputComponent = <FastSearch noMeta label={label} api={type} name={name}/>;
        }
        return (
            <Area>
                <Row>
                    {InputComponent}
                    <SortArrow path={path}/>
                    <ShowEye path={path} default={defaultEye}/>
                </Row>
            </Area>
        );
};

export default AdvSearchInput;
