import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeItem } from '../store/actions/item';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import styled from 'styled-components';
import Area from '../style/Area';
import Space from '../style/Space';
import { 
	DropdownSimple, 
	ChangeHookDropdownSimple, 
	Input, 
	CheckBox 
	} from '../inputs/InputsWithLabelFocus';

const RowNoWrap = styled('div')`
    display: flex;    
    flex-flow: row wrap;
    align-items: 'stretch';
`;

class OperatorElement extends Component {
    constructor(props) {
        super(props);
        const { proposalItems, index, changeItem, _key, value, binary, unary, keytype } = this.props;
        const default_key = proposalItems[index];
        // if the key was not found in store, write the default key into store
        // related to comment in mapStateToProps
        if (!_key) changeItem(`$operator.${index}.key.v`, default_key);
        if (!value) {
            if (keytype === "Checkbox") changeItem(`$operator.${index}.value.v`, false);
            else changeItem(`$operator.${index}.value.v`, '');
        }
        if (!unary) changeItem(`$operator.${index}.unary.v`, '');
        if (!binary) changeItem(`$operator.${index}.binary.v`, 'UND');
    }
    clearValue = (_, newKey) => {
        const { index, changeItem, schema } = this.props;
        const keytype = schema.find(l => l[0] === newKey)[1];
        if (keytype === "Checkbox") changeItem(`$operator.${index}.value.v`, false);
        else changeItem(`$operator.${index}.value.v`, '');
    };
    render() {
        const { proposalItems, index, keytype, _key, keyIsZeit, wording } = this.props;
        return (
			<Area>
				<RowNoWrap>
                    <ChangeHookDropdownSimple
                        label=""
                        name={`$operator.${index}.key`}
                        items={proposalItems}
                        default_value={proposalItems[index]}
                        changeHook={this.clearValue}
						noUnderline
						width={'166px'}
                    />
                    <Space/>
					{keytype !== "Checkbox" &&
	                    <DropdownSimple
	                        label=""
	                        name={`$operator.${index}.wording`}
	                        items={[
								'enthält',
								'enthält nicht',
								'ist vorhanden',
								'ist nicht vorhanden'
								]}
							noUnderline	
	                        width={'150px'}
	                    />
					}
					<Space/>
					{!(wording === 'ist vorhanden' || wording === 'ist nicht vorhanden') &&
                        <>
                            {keytype === null && 
									<Input 
										label=""
										width='250px'
										name={`$operator.${index}.value`}
										placeholder={
											keyIsZeit ? 'TT.MM.JJJJ' : 
											_key === 'Brieftiteldaten' ? 'Brief-ID, TT. Monat JJJJ, Ort, Von, An' :
											_key === 'Digitalisat' ? 'Signatur' :
											_key === 'Volltext' ? 'Wörter/Wortteil' :
											_key === 'Handschrift' ? 'Institution, Signatur, fol./S.' :
											_key === 'Schlagworte: Sachen' ? 'Sachbegriff' :
											_key === 'Schlagworte: Personen' ? 'Name' :
											_key === 'Schlagworte: Orte' ? 'Ortsname' :
											_key === 'Verfasser' ? 'Name' :
											_key === 'Adressat' ? 'Name' :
											_key === 'Absendeort' ? 'Ortsname' :
											_key === 'Zielort' ? 'Ortsname' :
											_key === 'Wirkungsort' ? 'Ortsname' :
											_key === 'Geburtsort' ? 'Ortsname' :
											_key === 'Sterbeort' ? 'Ortsname' :
											_key === 'Sprache' ? 'dt./lat./frz./engl./ital./nl./gr./hebr.' :
											_key === 'Druck' ? 'Autor, Kurztitel, Jahr (Nachweis), Bl./S.' :
											_key === 'Datenbanken' ? 'URL' :
											_key === 'Titel als Namensbestandteil' ? 'z.B. Graf' :
											_key === 'Amt' ? 'z.B. Pfarrer' :
											_key === 'Profession' ? 'Theologe/Jurist/Mediziner/Drucker...' :
											_key === 'Haupttheologe des Projekts' ? 'ja/nein' :                    
											''
										}
									/>
                            }
                            {keytype === "Checkbox" && 
								<CheckBox 
									width='100%'
									label=""
									name={`$operator.${index}.value`}
									noUnderline
								/>
                            }
                        </>
                	}
			{/*
           		{!isLastElement && (
                	<Area>
                        <DropdownSimple
                            label=""
                            name={`$operator.${index}.binary`}
                            items={['UND', 'ODER']}
                            width={'100%'}
							height={'30px'}
							noUnderline
                        />
                	</Area>
            	)}
			*/}
				</RowNoWrap>
			</Area>
        );
    }
}

OperatorElement.propTypes = {
    proposalItems: PropTypes.array.isRequired,
};

const mapStateToProps = (state, props) => {
    const isLastElement = props.index === (state.item.$operator ? state.item.$operator.length - 1 : 1);
	const moveItemInArray = (inputArray, fromPosition, toPosition) => {
	    const arr = [...inputArray];
	    arr.splice(toPosition, 0, ...arr.splice(fromPosition, 1));
	    return arr;
	}
	const path = state.router.location.pathname;
	const proposalItemsInSchemaOrder = state.search.schema.map(l => l[0]);
	let tempArray = proposalItemsInSchemaOrder;
	if (path.includes('brief')) {
		tempArray = moveItemInArray(proposalItemsInSchemaOrder, 3, 1); // move Verfasser in second pos
		tempArray = moveItemInArray(tempArray, 4, 2); // move Adressat in third pos
	};
    const proposalItems = tempArray;
    // We are not using _key = get(...) || proposalItems[0] here
    // because then _key would always be defined and thus we could not check
    // if we need to write into the state the default key which is proposalItems[0].
    // I previously used _key = get(...) || proposalItems[0] but that
    // lead to the bug described in Issue 852.
    const _key = get(state.item, `$operator.${props.index}.key.v`);
    const default_key = proposalItems[0];
    const proposalItemWithMatchingKey =
        state.search.schema.find(l => l[0] === _key) || state.search.schema.find(l => l[0] === default_key);
    const keytype = proposalItemWithMatchingKey[1];
    return {
        schema: state.search.schema,
        proposalItems,
        isLastElement,
        _key,
        keytype,
        value: get(state.item, `$operator.${props.index}.value`),
		path: state.router.location.pathname,
        unary: get(state.item, `$operator.${props.index}.unary`),
        binary: get(state.item, `$operator.${props.index}.binary`),
        wording: get(state.item, `$operator.${props.index}.wording.v`),
		keyIsZeit: (_key === 'Datum' || _key === 'Sterbedatum' || _key === 'Geburtsdatum' || _key === 'Präsentationsdatum' || _key === 'Eingangsdatum (Kanzlei)'),
    };
};
const mapDispatchToProps = { changeItem };
export default connect(mapStateToProps, mapDispatchToProps)(OperatorElement);
