import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '../style/Row';
import { get } from '../service/immutables';
import { changeItem } from '../store/actions/item';
import { connect } from 'react-redux';

export const Switch = props => {
    const { children, value } = props;
    return <Row nowrap>{children[value]}</Row>;
};

Switch.propTypes = {
    value: PropTypes.number.isRequired, // the show value
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired, // the path to the show att
};

Switch.defaultProps = {
    value: 0,
};

const mapStateToProps = (state, props) => {
    const value = get(props.name, state.item);
    const existValue =
        props.children[value] && get(props.children[value].props.name + `.v`, state.item);
    return {
        value,
        existValue,
    };
};

const mapDispatchToProps = {
    handleChange: changeItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Switch);
