import React, { Component } from 'react';
import { get } from '../../service/immutables';
import { get as getLodash } from 'lodash';
import PropTypes from 'prop-types';
import Label from '../../style/Label';
import { fastsearch, getItem } from '../../service/fetch';
import { DropDown, DropDownArea, DropDownItem } from '../../style/DropDown';
import Input from '../../style/Input';
import Link from '../../style/Link';
import { Row } from '../../style/Row';
import { changeItem } from '../../store/actions/item';
import { connect } from 'react-redux';
import Delete from '../../SVG/Delete';
import ScrollContainer from '../../style/ScrollContainer';
import Loading from '../../components/Loading';
import styled from 'styled-components';
import Underline from '../../style/Underline';

const Button = styled.div`
    border: none;
    border-radius: 2px;

    &:hover {
        margin-bottom: 1px;
        margin-top: -1px;
    }
`;

const Info = styled.div`
    font-size: 9.5px;
	color: grey;
	text-wrap: nowrap;
`;

class FastSearch extends Component {
    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element
        this.input = React.createRef();
        this.state = {
            items: [],
            possibleResults: true,
            mouseOver: false,
            loading: false,
            counter: -1, // init count issue 415
        };
    }

    onClickEdit = () => {
        const { name, handleChange, disabled } = this.props;
        !disabled && handleChange(name + '.v', null);
    };

    onChange = e => {
        const { value } = e.target;
        const { name, handleChange } = this.props;
        handleChange(name + '.v', { short: { v: value }, _id: undefined }); // if the user type -> reset the id
        this.loadProposals(value);
    };

    loadProposals = async value => {
        let {
            api,
            parentId,
            extraParam,
            valueExtraParam,
            searchParam,
            proposal,
            item,
        } = this.props;
        api = api.toLowerCase();
        let params =
            `long.v=${value ? value : ''}` +
            (extraParam !== undefined ? '&' + extraParam + '.v' : '') +
            '&' +
            searchParam;
        if (getLodash(valueExtraParam, '_id') !== undefined) {
            let sort = api === 'signatur' ? '&sort=shortNumericLength.v' : ''; // Issue 41
            params =
                `long.v=${value ? value : ''}` +
                (extraParam !== undefined
                    ? sort + '&' + extraParam + '.v=' + getLodash(valueExtraParam, '_id')
                    : '');
        }
        if (proposal) {
            let items = [];
            if (typeof proposal === 'function') {
                items = proposal(item);
            } else {
                items = getLodash(item, proposal);
            }
            if (items !== undefined) {
                if (!value) value = ''
                let possibleResults = items.count > 8;
                items = items
                    .filter(i => !!getLodash(i, 'v.long') && !!getLodash(i, 'v._id'))
                    .filter(i => i.v.long.v.toLowerCase().includes(value.toLowerCase()))
                    .map(i => i.v)

                this.setState({ items, loading: false, possibleResults });
            }
        } else {
            // TODO Error handling for async call
            this.setState({ loading: true });
            let data;
            if (getLodash(valueExtraParam, '_id') !== undefined) {
                data = await getItem(api, '', params, true);
                data.limit = 8
            } else {
                data = await fastsearch(api, params);
				if(!data) data = [];
                //clientside sort after apiside sort in fastsearch.js 
				// sort-Funktion return:
				// -1, wenn das erste Element (a) vor dem folgenden stehen soll,
				// 1, wenn das zweite Element (b) vor dem ersten rangieren soll,
				// 0, wenn die beiden Elemente gleich sind und in beliebiger Folge stehen können.
				/* 
				data.results.sort((a, b) => {
					Sortierung nach Länge:
	                    if(a.short.v < b.short.v) return -1;
	                    if(a.short.v > b.short.v) return 1;
	                    return 0;
                }); 
				*/
                data.results.sort((a, b) => {
					// b.long "Bühl = Tübingen-Bühl" includes a.short Tübingen -> a ("Tübingen") befördern
                    if(b.long && b.long.v.includes(a.short.v)) return -1;
					// Gegenprobe unnötig, daher auskommentiert:
                    // if(!b.long.v.includes(a.short.v)) return 1;
					return 0;
                });
            }
            // remove element with same ID to protect refs circles
            // TODO parentId is removed - find a way to protect refs circles
            if (parentId) data.results = data.results.filter(item => item._id !== parentId)
            this.setState({ possibleResults: data.count > data.limit, items: data.results, loading: false });
        }
    };

    choose = i => {
        const item = this.state.items[i];
        const { name, handleChange, valueExtraParam } = this.props;
        if (getLodash(valueExtraParam, '_id') !== undefined) {
            const short = getLodash(item, 'short.v');
            const long = getLodash(item, 'long.v');
            const _id = getLodash(item, '_id');
            const object = {
                short: { v: short },
                long: { v: long },
                _id: _id,
            };
            handleChange(name + '.v', object);
        } else {
            handleChange(name + '.v', item);
        }
        this.setState({ items: [], mouseOver: false });
    };

    handleBlur = () => {
        const { name, value } = this.props;
        // check if there is no _id, the input field was used and the value is not referable
        const { mouseOver } = this.state;
        // click outside / mouse is not over the overlay anymore
        if (!mouseOver) {
            // the user changed nothing - if there is a id then show the Link
            if (value && value._id) {
                this.setState({ items: [] });
            } else {
                // the user changed something (_id was removed) and doesn't choose a new one
                // TODO add VALIDATION error = inform user
                this.props.handleChange(name + '.v', null);
                this.setState({ items: [] });
            }
        } else {
            // keep focus in input field when clicking the scroll bar
            // currently not working in firefox
            this.input.current && this.input.current.focus();
        }
        //this.forceUpdate();
    };

    handleMouseEnter = () => this.setState({ mouseOver: true });

    handleMouseLeave = () => this.setState({ mouseOver: false });

    delImport = () => {
        const { name, handleChange } = this.props;
        handleChange(name + '.i', null);
    };

    myOwnMount = async () => {
        if (
            this.props.extraParam !== undefined &&
            getLodash(this.props, 'value._id') !== undefined
        ) {
            let data = await getItem(this.props.api, null, '&_id=' + this.props.value._id);
            const short =
                typeof getLodash(data[0], this.props.extraParam + '.v') !== 'string'
                    ? getLodash(data[0], 'short.v')
                    : getLodash(data[0], this.props.extraParam + '.v');
            const long = getLodash(data[0], 'long.v');
            const _id = getLodash(data[0], '_id');
            const object = {
                short: { v: short },
                long: { v: long },
                _id: _id,
            };
            this.props.handleChange(this.props.name + '.v', object, true);
        }
        if (get(this.props.name + '.v', this.props.item) === undefined) {
            this.props.handleChange(this.props.name + '.v', this.props.default, true);
        }
    };

    componentDidMount() {
        this.myOwnMount();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.focus && !prevProps.focus && !prevProps.disabled)
            if (!prevState.items.length) {
                this.loadProposals();
                this.input.current && this.input.current.focus();
            }
    }

    onKeyPressed = ({ keyCode }) => {
        const { counter } = this.state;
        // up
        if (keyCode === 38 && counter > 0) {
            this.setState({ counter: counter - 1 });
        }
        // down
        if (keyCode === 40 && counter < 7) {
            this.setState({ counter: counter + 1 });
        }
        // enter
        if (keyCode === 13) {
            this.choose(counter);
        }
        // esc
        if (keyCode === 27) {
			this.choose();
    	}
    };

    render() {
        const {
            value,
            erschlossen,
            unsicher,
            fragwuerdig,
            ca,
            api,
            placeholder,
            focus,
            disabled,
            extraParam,
			noMeta,
        } = this.props;
        let { importValue } = this.props;
        const { items, loading, counter, possibleResults } = this.state;
        let anzeigeWert = getLodash(value, 'short.v');
        if (getLodash(value, extraParam + '.v') !== undefined) {
            anzeigeWert = getLodash(value, extraParam + '.v');
        }

        return (
            <div onKeyDown={this.onKeyPressed}>
                {importValue && (
                    <Row nowrap>
                        <Label style={{ color: 'red' }}>
                        	{importValue}
                        </Label>
                        {value && value._id && (
							<Delete onClick={this.delImport}/>
						)}
                    </Row>
                )}
                <Row nowrap>
                    {!(value && value._id) ? (
                        <DropDownArea width="100%">
                            <Input
                                ref={this.input}
                                onChange={this.onChange}
                                value={(value && value.short && value.short.v) || ''}
                                onBlur={this.handleBlur}
                                placeholder={placeholder || api}
                                autoFocus={focus}
                                disabled={disabled}
								noMeta={noMeta}
                            />
							<Underline/>
                            {(items.length > 0 || loading) && (
                                <DropDown
                                    onMouseEnter={this.handleMouseEnter}
                                    onMouseLeave={this.handleMouseLeave}
                                    width="inherit"
                                >
                                    <ScrollContainer>
                                        <Loading loading={+loading}>
                                             {items && items.slice(0,8).map((item, i) => (
                                                <DropDownItem
                                                    key={i}
                                                    onClick={() => this.choose(i)}
                                                    color={i === counter || undefined}
                                                >
                                                    {api.toLowerCase() === 'archiv' ? item.short.v : item.long.v}
                                                </DropDownItem>
                                            ))}
                                            {possibleResults === true &&
                                                <DropDownItem
                                                    key="more_results"
                                                    disableHover
                                                >
                                                    ...
                                                </DropDownItem>
                                            }
                                        </Loading>
                                    </ScrollContainer>
                                </DropDown>
                            )}
                        </DropDownArea>
                    ) : (
                        <Button>
                            <Row nowrap>
                                {erschlossen && '['}
                                {ca && <>ca.&nbsp;</>}
                                    <Link to={'/edit/' + api.toLowerCase() + '/' + value._id}>
                                        {anzeigeWert !== undefined ? anzeigeWert : ''}
										<Info>&nbsp;{api.toLowerCase() === 'zitiername' && value && value.lebensjahre && value.lebensjahre.v}</Info>
                                    </Link>
                                {unsicher && '?'}
                                {erschlossen && ']'}
                                {fragwuerdig && <>&nbsp;[?]</>}
								
                                <Delete onClick={this.onClickEdit}/>
                            </Row>
                        </Button>
                    )}
                </Row>
            </div>
        );
    }
}

FastSearch.propTypes = {
    name: PropTypes.string.isRequired, // name wo im Parent object das element hingepseichert wird
    api: PropTypes.string.isRequired, // name der Api, z.B. "geo" oder "amt"
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.any, //.isRequired
    default: PropTypes.any, // default value
    parentId: PropTypes.string, // schützt vor selbstreferenzierung
    focus: PropTypes.bool, // is true when the input is focus - managed from MetaLabelFocusHoc
    noMeta: PropTypes.bool, // disable Meta tooltip
    proposal: PropTypes.oneOfType([PropTypes.string, PropTypes.func]), // pfad relativ zum item im state das als proposals geladen werden soll oder eine funktion, der das aktuelle item übergeben wird, und die proposals übernimmt
};

FastSearch.defaultProps = {
    searchParam: '',
};

const mapStateToProps = (state, props) => {
    return {
        item: state.item,
        value:
            get(props.name + '.v', state.item) !== undefined
                ? get(props.name + '.v', state.item)
                : props.default,
        valueExtraParam: get(props.extraParamName + '.v', state.item) || '',
        erschlossen: get(props.name + '.m.erschlossen', state.item),
        unsicher: get(props.name + '.m.unsicher', state.item),
        ca: get(props.name + '.m.ca', state.item),
        fragwuerdig: get(props.name + '.m.fragwuerdig', state.item),
        importValue: get(props.name + '.i', state.item),
    };
};

const mapDispatchToProps = {
    handleChange: changeItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FastSearch);
