import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { linkBlue, purple } from './colors';

const LinkStyled = styled(Link)`
    color: ${({ color }) => (color ? color : linkBlue)};
    align-self: center;
    &:visited {
        text-decoration: none;
        color: ${({ color }) => (color ? color : purple)};
    }
`;

export default props => (
    <LinkStyled {...props} target={props.target ? props.target : '_blank'}>
        {props.children}
    </LinkStyled>
);
