import React from 'react';
import { connect } from 'react-redux';
import { get } from '../../service/immutables';

import Anmerkung from '../../components/Anmerkungen';
//import IIIFViewer from '../../components/IIIFViewer/IIIFViewer_old';
import Seadragon from '../../components/IIIFViewer/IIIFViewer';
import Switch from '../../components/Switch';

import CollapseArea from '../../container/CollapseArea';
import DeleteItem from '../../container/DeleteItem';
import EditHOC from '../../container/EditHOC';
import List from '../../container/List';
import RefsLinks from '../../container/RefsLinks';
import SetFavorite from '../../container/SetFavorite';
import Short from '../../container/Short';

import Area from '../../style/Area';
import BigStampRed from '../../style/BigStampRed';
import H1 from '../../style/H1';
import Link from '../../style/Link';
import { Row } from '../../style/Row';
import RowSpace from '../../style/RowSpace';
import Space from '../../style/Space';
import VerticalLine from '../../style/VerticalLine';

import OpenSVG from '../../SVG/Open';

import {
    CheckBox,
    DropdownSimple,
    FastSearch as Search,
    Input,
    LinkedInput,
    Zeit,
} from '../../inputs/InputsWithMetaLabelFocus';

const Briefhandschrift = ({ item, type, archivDa, token, importedPath, reproPublished, noAdmin, noMitarbeiter, duplicate, irrelevant }) => (
    <>
        <Row>
            <H1>Briefhandschrift</H1>
			<Space />
            <Link to={`/ansicht/briefhandschrift/${item._id}`} target="_self">
                <OpenSVG />
            </Link>
            <Space />
            <SetFavorite type={type} />
            <Space />
            <DeleteItem type={type} />
			<Space />
            {duplicate && <><BigStampRed>[&nbsp;wird&nbsp;gelöscht&nbsp;]</BigStampRed><Space /></>}
			{irrelevant && <><BigStampRed>[&nbsp;nicht&nbsp;relevant&nbsp;]</BigStampRed><Space /></>}
        </Row>
    	<Short name="long" />
        <Area>
    		<RefsLinks label="Brief&nbsp;" api="brief" idPath="_id" query="handschrift.v=" long />
        </Area>
        <CollapseArea label="Verzeichnung" show={true}>
            <Row>
           		 <Area>
                    <Search
                        label="Überlieferungsform"
                        api="uebForm"
                        name="uebForm"
                        placeholder="Überlieferungsform"
                        noMeta
                        width="150px"
                    />
                </Area>
                <Area>
                    <Search label="Archiv" api="Archiv" name="archiv" small />
                </Area>
                <Area>
                    <Row>
                        <Search
                            label="Signatur"
                            api="Signatur"
                            name="signatur"
                            extraParam="archiv"
                            extraParamName="archiv"
                            disabled={archivDa}
                            placeholder={archivDa ? '(zuerst Archiv angeben)' : 'Signatur'}
							tooltip="der Bestelleinheit"
							width="200px"
                        />
                        <VerticalLine />
                        <Input
                            label="Nr."
                            name="nummer"
                            placeholder="Brief-Nr."
                            helperForUserInput="Nr. "
							width="80px"
							tooltip="in der Bestelleinheit"
							noMeta
                        />
                        <VerticalLine />
                        <Switch name={'show'}>
                            <Input
                                label="Blatt- /Seitenzählung"
                                name="seite"
                                placeholder="f. Umfang and. Feld"
                                helperForUserInput="fol. "
								width="130px"
								noMeta
                            />
                            <Input label="Umfang" name="umfang" width="130px" noMeta/>
                        </Switch>
                    </Row>
                </Area>
				 <Area>
					<CheckBox
	                    label="nicht verifiziert"
	                    name="nichtVerifiziert"
						width="89px"
						tooltip="fügt [nicht verifiziert] zur Handschriftangabe hinzu"
						noMeta
						noUnderline
	                />
 				</Area>
            </Row>
            <Row>
                <Area>
                    <DropdownSimple
                        label="Siegel"
                        name={`siegel`}
                        items={['', 'Siegel', 'Siegelreste', 'Siegelspuren', 'Siegelabdruck']}
                        default_value=""
                        small
                        noUnderline
                        noMeta
                        width="110px"
                    />
                </Area>
                <Area>
                    <DropdownSimple
                        label="Erhaltungszustand"
                        name={`zustand`}
                        items={[
                            '',
                            'Blattrand beschnitten',
                            'Blattteil abgeschnitten',
                            'Blattteil abgerissen',
							'Blatt fehlt',
							'Blätter fehlen',
                            'mit Flecken',
                            'mit Loch',
                            'mit Tintenfleck',
                            'mit Tintenfraß',
                            'Schrift verblasst',
                        ]}
                        default_value=""
                        noUnderline
                        noMeta
                        width="157px"
                    />
                </Area>
                <Area>
                    <Input label="Texttitel" name="titel" large noMeta />
                </Area>
            </Row>
            <Row>
                <Area>
                    <List label="Schreiber" name="schreiber">
                        {index => (
                            <>
                                <Search
                                    label="Person"
                                    api="Zitiername"
                                    name={`schreiber[${index}].person`}
                                />
                                <DropdownSimple
                                    label="Textbereich"
                                    name={`schreiber[${index}].angabe`}
                                    items={[
                                        'ganzer Text',
                                        'Unterschrift',
										'Adresse',
                                        'Teil',
                                        'Korrekturen',
										'Ergänzungen',
                                        'Randbemerkungen',
                                    ]}
                                    default_value="ganzer Text"
                                    noUnderline
                                />
                            </>
                        )}
                    </List>
                </Area>
				<Area>
					<Switch name={'show2'}>
						<Search name="abschriftAusHandschrift" label="abgeschrieben aus" api="Briefhandschrift" />
	                    <Search name="abschriftAusDruck" label="abgeschrieben aus" api="Briefdruck" />
	                </Switch>
	            </Area>
        	</Row>
        </CollapseArea>
        <CollapseArea label="Reproduktionen" show={true}>
			<Row>
			{item.reproduktion && importedPath && (
				<Area>
		            <DropdownSimple
                        label=" "
						noMeta
						extraLarge
						noUnderline
                        name={`reproduktion[0].status`}
                        items={['freigeschaltet', 'nicht öffentlich']}
                        default_value="nicht öffentlich"
						disabled={(!importedPath && !reproPublished) || (noAdmin && !reproPublished)}
                    />
					<Space/>
					<Seadragon 
						briefhandschrift_id={item._id}
						filepaths={item.reproduktion[0] ? item.reproduktion[0].datei : []}
						userToken={token} 
					/>
					
				</Area>)}
	            <Area>
	                <List label="Reproduktionen" name="reproduktion">
	                    {index => (
	                        <>
	                            <Search 
									api="Medium"
									label="Art"
									name={`reproduktion[${index}].art`}
									noMeta 
								/>
	                            <Zeit label="erstellt am" name={`reproduktion[${index}].erstelltAm`} />
	                            <Switch name={`reproduktion[${index}].urheber.show`}>
	                                <Input
	                                    label="Urheber"
	                                    placeholder="Andere"
	                                    name={`reproduktion[${index}].urheber.andere`}
	                                />
	                                <Search
	                                    label="Urheber"
	                                    api="Mitarbeiter"
	                                    name={`reproduktion[${index}].urheber.mitarbeiter`}
	                                />
	                            </Switch>
	                            {/*
								<CheckBox
	                                label="Veröffentlichungserlaubnis"
	                                name={`reproduktion[${index}].veroeffentlichungserlaubnis`}
									noMeta
									noUnderline
									width="170px"
	                            />*/}
								<RowSpace/><RowSpace/>
								<RowSpace/><RowSpace/>
								<List label="Pfade" name={`reproduktion[${index}].datei`} column>
	                                {index2 => (
	                                    <LinkedInput
	                                        label="Link"
	                                        name={`reproduktion[${index}].datei[${index2}]`}
	                                        link=""
											disabled={(importedPath && index === 0) || (reproPublished && index === 0)}
	                                        checkHttp
	                                    />
	                                )}
	                            </List>
	                        </>
	                    )}
	                </List>
	            </Area>
			</Row>
        </CollapseArea>
		{/*
		<CollapseArea label="Alter Bildbetrachter" show={false}>
            <IIIFViewer name={`reproduktion[0].datei`} />
        </CollapseArea>
		*/}
        <CollapseArea label="Hinweise" show={true}>
            <Row>
                <Area>
                    <Anmerkung />
                </Area>
            </Row>
             <Area>
                <List label="gedruckte Literatur" name="hinweis.publizierte" column>
                    {index => (
                        <Row>
                            <Search
                                label=""
                                api="Literatur"
                                name={`hinweis.publizierte[${index}].literatur`}
                            />
                            <Input
                                label="Seite"
                                name={`hinweis.publizierte[${index}].seite`}
                                placeholder="S."
                                helperForUserInput="S. "
                                small
                            />
                        </Row>
                    )}
                </List>
            </Area>
            <Area>
                <List label="archivalische Findmittel" name="hinweis.archivalische" column>
                    {index => (
                        <Input label="Findmittel" name={`hinweis.archivalische[${index}]`} large />
                    )}
                </List>
            </Area>
            <Area>
                <List label="elektronische Findmittel" name="hinweis.url" column>
                    {index => (
                        <LinkedInput label="URL" name={`hinweis.url[${index}]`} link="" large />
                    )}
                </List>
            </Area>
        </CollapseArea>
        <CollapseArea label="Zum Inhalt" show={true}>
            <Row>
                <Area>
                    <Search label="Textsorte" api="Textsorte" name="textsorte" disabled={noMitarbeiter} width="200px" />
                </Area>
                <List label="Sprache" name="sprache" simple>
                    {index => (
                        <Area noStyle>
                            <Search label="" api="Sprache" name={`sprache[${index}]`} small disabled={noMitarbeiter} />
                        </Area>
                    )}
                </List>
            </Row>
        </CollapseArea>
        {/*
        <Row>
           <Schlagwort />
        </Row>
        */}
		<CollapseArea label="Löschen" show={duplicate}>
            <Area>
				<Row>
                	<CheckBox label="HID löschen" name="handschriftLoeschen" width="85px" noMeta noUnderline />
					<Search label="da Dublette von" api="Briefhandschrift" name={`dubletteVon`} width="100px" noMeta />
					<Space/>
					<CheckBox label="Daten vereinigt & übertragen" name="datenUebertragen" width="185px" noMeta noUnderline/>
					<CheckBox label="nicht relevant" name="nichtRelevant" width="85px" noMeta noUnderline />
				</Row>	
            </Area>
       </CollapseArea>
    </>
);

const mapStateToProps = state => {
	let noAdmin = true;
	let noMitarbeiter = true;
	if (get('user.role', state.auth) === 'Admin') {
		noAdmin = false;
		noMitarbeiter = false;
		}
	if (get('user.role', state.auth) === 'Mitarbeiter') noMitarbeiter = false;
	
    return {
	    archivDa: state.item.archiv && state.item.archiv.v ? false : true,
		importedPath: state.item.reproduktion && state.item.reproduktion[0] && state.item.reproduktion[0].datei && state.item.reproduktion[0].datei[0] && state.item.reproduktion[0].datei[0].v && state.item.reproduktion[0].datei[0].v.includes('/HID') ? true : false,
		reproPublished: state.item.reproduktion && state.item.reproduktion[0] && state.item.reproduktion[0].status && state.item.reproduktion[0].status.v === 'freigeschaltet' ? true : false,
	    shortNotGiven: state.item.short && state.item.short.v ? false : true, // true = offen || false = zu (collapsearea)
	    item: state.item,
	    token: state.auth.token,
		noAdmin: noAdmin,
		noMitarbeiter: noMitarbeiter,
		duplicate: state.item.handschriftLoeschen && state.item.handschriftLoeschen.v,
		irrelevant: state.item.nichtRelevant && state.item.nichtRelevant.v
	};
};

export default EditHOC(connect(mapStateToProps)(Briefhandschrift));
