import React, { Component } from 'react';
import Area from '../style/Area';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Style = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
	position: fixed;
    top: 6%;
    right: 5%;

    //min-width: 10rem;
    padding-left: 4px;
    background-color: transparent;

    width: auto;
    //overflow-y: auto;
`;

class InfoMenu extends Component {
    render() {
        const { created, update } = this.props;
        return (
            <Style>
                <Area>erstellt am: {new Date(created).toLocaleDateString()}</Area>
                <Area>update am: {new Date(update).toLocaleDateString()}</Area>
            </Style>
        );
    }
}

InfoMenu.propTypes = {};

InfoMenu.defaultProps = {};

const mapStatetoProps = state => ({
    created: state.item.createdAt,
    update: state.item.updatedAt,
});

const mapDispatchToProps = {};

export default connect(
    mapStatetoProps,
    mapDispatchToProps
)(InfoMenu);
