import styled from 'styled-components';

export const TextblockArea = styled('div')`
    max-width: ${props => (props.width ? props.width : 'fit-content')};
    height: ${props => (props.height ? props.height : 'fit-content')};
    
    vertical-align: top;
    border: none;
    wrap: row wrap;
`;

export default TextblockArea;
