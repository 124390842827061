import React from 'react';
import List from '../container/List';
import Column from '../style/Column';
import { FastSearch as Search, Zeit } from '../inputs/InputsWithMetaLabelFocus';
import { Textarea as TextareaNoMeta } from '../inputs/InputsWithLabelFocus';
import Row from '../style/Row';
import VerticalLine from '../style/VerticalLine';

export const Anmerkungen = () => (
    <List label="Anmerkungen" name="anmerkungen" column>
        {index => (
            <Column key={index}>
                <TextareaNoMeta label="Anmerkung" name={`anmerkungen[${index}].text`} extraLarge />
                <Row>
                    <Search
                        label="durch"
                        api="Mitarbeiter"
                        name={`anmerkungen[${index}].durch`}
                        small
                    />
                    <VerticalLine />
                    <Zeit label="am" name={`anmerkungen[${index}].am`} today />
                </Row>
            </Column>
        )}
    </List>
);

export default Anmerkungen;
