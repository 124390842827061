import { get } from 'lodash';
import React from 'react';
import NewButton from '../../components/NewButton';
import AllowedForUser from '../../container/AllowedForUser';
import SearchHOC from '../../container/SearchHOC';
import Link from '../../style/Link';
import TableRow from '../../style/SearchRow';
import Cell from '../../style/SearchCell';
import DeleteSVG from '../../SVG/Delete';
import OpenSVG from '../../SVG/Open';
import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import { DropdownSimple, Input } from '../../inputs/InputsWithLabelFocus';
import Loading from '../../components/Loading';

const SearchMitarbeiter = ({ items, search, sort, asc, defaultSort, handleChangeSort, loading }) => {
    if (!sort.length) defaultSort('name.v');
    return (
        <>
            <>
                <TableRow>
                    <Cell block>{null}</Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Name:"
                            name="name"
                            value={get(search, 'name') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('name.v')}
                            direction={'name.v' === sort ? !asc : false}
                            active={'name.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Kürzel:"
                            name="kuerzel"
                            value={get(search, 'kuerzel') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('kuerzel.v')}
                            direction={'kuerzel.v' === sort ? !asc : false}
                            active={'kuerzel.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <DropdownSimple
                            noMeta
                            width="100%"
                            label="Rolle:"
                            name="role"
                            value={get(search, 'role') || ''}
                            items={['Admin', 'Mitarbeiter', 'Hiwi', 'Gast', 'ausgeschieden']}
                            disableEmpty={false}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('role.v')}
                            direction={'role.v' === sort ? !asc : false}
                            active={'role.v' === sort}
                        />
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                <Loading loading={loading}>
                    {items.map((item, i) => (
                        <TableRow key={i}>
                            <AllowedForUser rolle="Admin">
                                <Link to={`/edit/mitarbeiter/${item._id}`} target={false}>
                                    <OpenSVG />
                                </Link>
                            </AllowedForUser>
                            <Cell>{item.name && item.name.v}</Cell>
                            <Cell>{item.kuerzel && item.kuerzel.v}</Cell>
                            <Cell>{item.role && item.role.v}</Cell>
                            <AllowedForUser rolle="Admin">
                                <Link to={`/ref/mitarbeiter/${item._id}`}>
                                    <DeleteSVG />
                                </Link>
                            </AllowedForUser>
                        </TableRow>
                    ))}
                </Loading>
            </>
            <AllowedForUser rolle="Admin">
                <NewButton type="mitarbeiter" target="_blank" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchMitarbeiter);
