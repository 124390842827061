/**
 * @project:      ThBw
 * @file:         client/src/client/routes/edit/Amt.js
 * @summary:
 * @desc:
 * @created:      2019-10-23 21:21:31 by Moritz Mock
 * @lastmodified: 2019-12-06 12:54:28 by ekzyis
 */
import React from 'react';
import EditHOC from '../../container/EditHOC';
import { Input } from '../../inputs/InputsWithMetaLabelFocus';
import Area from '../../style/Area';
import H1 from '../../style/H1';
import { Row } from '../../style/Row';
import DeleteItem from '../../container/DeleteItem';
import SetFavorite from '../../container/SetFavorite';
import Space from '../../style/Space';

const Amt = ({ type }) => (
    <>
        <Row>
            <H1>Amt</H1>
            <Space />
            <SetFavorite type={type} />
            <Space />
            <DeleteItem type={type} />
        </Row>
        <Row>
            <Area>
                <Input label="Amt" name="amt" />
            </Area>
            <Area>
                <Input label="Anschluss" name="anschluss" />
            </Area>
        </Row>
    </>
);

export default EditHOC(Amt);
