import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Item from '../style/Item';
import ItemContainer from '../style/ItemContainer';
import AddSVG from '../SVG/Add';
import DeleteSVG from '../SVG/Delete';
import {
    addArrayElement,
    deleteArrayElement,
} from '../store/actions/item';
import { connect } from 'react-redux';
import { get } from '../service/immutables';
import Row from '../style/Row';

const Corner = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const Add = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-left: 22px;
`;

class List extends React.Component {
    componentDidUpdate(prevProps, state) {
        // Typical usage (don't forget to compare props):
        //console.log("updated")
        //console.log(this.state)
        //console.log(this.props.state)
    }
    onKeyPressed = ({ keyCode }) => {
        const { add, name } = this.props;
        //insert key
        if (keyCode === 45) {
            add(name);
        }
    };
    render() {
        const {
            children,
            items,
            disabled,
            add,
            del,
            name,
        } = this.props;
        // if list is empty put three items on the list
        if (!items.length) {
			items.push({});
			items.push({});
			items.push({});
		};
        return (
            <div onKeyDown={this.onKeyPressed}>
                <ItemContainer column>
                    {items.map((item, i) => (
                        <Row key={i}>
                            <Item simple style={{ backgroundColor: 'transparent' }}>
                                {children(i, item)}
								<Corner>
									{items.length > 1 && <DeleteSVG onClick={() => del(name, i)}/>}
								</Corner>
                            </Item>
							<Add>
								{i !== items.length - 1 && <>&nbsp;UND</>}
								{i === items.length - 1 && <AddSVG onClick={() => add(name)} disabled={disabled}/>}
							</Add>
						</Row>
                    ))}
                </ItemContainer>
            </div>
        );
    }
}

List.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    // KEIN items={pfad.zum.wert || []} da bei arrays der richtige Pfad immer vorhanden ist durchs backend.
    items: PropTypes.array.isRequired, 
    // TODO benötigen Listen in Listen doch die Abfrage nach dem vorahnden sein des Arrays?
    children: PropTypes.func.isRequired,
    column: PropTypes.bool, 
    disabled: PropTypes.bool,
};
List.defaultProps = {
    disabled: false,
};
const mapStateToProps = (state, props) => {
    return {
        items: get(props.name, state.item) || [],
    };
};

const mapDispatchToProps = {
    add: addArrayElement,
    del: deleteArrayElement,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
