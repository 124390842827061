import React from 'react';
import { connect } from 'react-redux';
import { get } from '../../service/immutables';

import AllowedForUser from '../../container/AllowedForUser';
import CollapseArea from '../../container/CollapseArea';
import DataCombination from '../../container/DataCombination';
import PublicViewHOC from '../../container/PublicViewHOC';
import RefsCount from '../../container/RefsCount';
import RefsLinks from '../../container/RefsLinksView';
import Short from '../../container/Short';
import List from '../../container/ViewList';

import Area from '../../style/Area';
//import Titel from '../../style/Title';
//import H1 from '../../style/H1';
import H3 from '../../style/H3';
import Link from '../../style/Link';
import OpenSVG from '../../SVG/Open';
import { Row } from '../../style/Row';
import Space from '../../style/Space';

import {
 // LabelFastSearch as Search,
    LabelLinkedInput as LinkedInput,
    LabelTextarea as Textarea,
	LabelInput as Input,
} from '../../labels/LabelWithNoFocus';

const currentDate = new Date();

const PersonPublicUI = ({ item, literaturArea }) => (
    <>
		{/*
	        <Row>
	            <H1>Person</H1> 
	        </Row>
		*/}
		<br/>
		<Row>
        <Short />
		<AllowedForUser rolle="Gast">
            <Space />
            <Link to={`/edit/person/${item._id}`} target="_self">
                <OpenSVG />
            </Link>
		</AllowedForUser>
		</Row>
		<Row> {/* Lebensdaten */}
			{(get('geburt.am.schoen.v', item) || get('geburt.ort.v.short.v', item)) && <>* </>}
			{get('geburt.am.schoen.v', item) && <>{get('geburt.am.schoen.v', item)}</>}
			{get('geburt.ort.v.short.v', item) && (<> in {get('geburt.ort.v.short.v', item)}</>)}
			<Space />
			{(get('tod.am.schoen.v', item) || get('tod.ort.v.short.v', item)) && <>† </>}
			{get('tod.am.schoen.v', item) && <>{get('tod.am.schoen.v', item)}</>}
			{get('tod.ort.v.short.v', item) && (<> in {get('tod.ort.v.short.v', item)}</>)}
		</Row>
		<br/>
		{/* 
        <List label="Profession" name="profession" simple>
            {index => <Search label="" api="Profession" name={`profession[${index}]`} small />}
        </List>
		*/}
		{get('literarisch.v', item) && <>aus: {get('literarisch.v', item)}<br/><br/></>}
		{(get('hauptfigur.hauptfigur.v', item) === "ja") && (
			get('profession[0].v.short.v', item) === "Theologe" ? (
				<>Die Korrespondenz dieses südwestdeutschen Theologen wird vollständig aufgenommen.<br/><br/></>
			) : (
				<>Die Korrespondenz dieser kirchenleitenden Persönlichkeit wird vollständig aufgenommen.<br/><br/></>
			)
		)}
		{/* 
		<List label="" name="beziehung" column>
            {index => (
                <Row>
                    <Search 
                        label="" 
                        name={`beziehung[${index}].art`} 
                        api="Beziehung"
                        width="30%"
                    />
					<>von&nbsp;&nbsp;&nbsp;</>
                    <Search
                        label=""
                        name={`beziehung[${index}].zu`}
                        api="Zitiername"
                    />
                </Row>
            )}
        </List>
		*/}
        <RefsCount
            label="Verfasser von "
            api="brief"
            idPath="namen.0._id"
            query="verfasser.person.v="
            anschlussText="Briefen"
        />
        <CollapseArea show={false}>
            <RefsLinks api="brief" idPath="namen.0._id" query="verfasser.person.v=" column long />
        </CollapseArea>
        <RefsCount
            label="Adressat von "
            api="brief"
            idPath="namen.0._id"
            query="adressat.person.v="
            anschlussText="Briefen"
        />
        <CollapseArea show={false}>
            <RefsLinks api="brief" idPath="namen.0._id" query="adressat.person.v=" column long />
        </CollapseArea>
        <RefsCount
            label="Erwähnung in "
            api="brief"
            idPath="namen.0._id"
            query="schlagworte.personen.v="
            anschlussText="Briefen"
        />
        <CollapseArea show={false}>
            <RefsLinks api="brief" idPath="namen.0._id" query="schlagworte.personen.v=" column long />
        </CollapseArea>
        {/* 
            <CheckBox label="Name bezeichnet ein Kollektiv?" name={`kollektiv.kollektiv`} small/>
            <Textarea label="Typ" name={`kollektiv.typ`} />
            <List label="Zugehörige Personen" name="kollektiv.zugehoerig" column>
                {index => (
                    <>
                        <Row>
                        <Search
                            label="Person"
                            name={`kollektiv.zugehoerig[${index}].person`}
                            api="Zitiername"
                        />
                        <Zeit label="" name={`kollektiv.zugehoerig[${index}].von`} />
                        <Zeit label="" name={`kollektiv.zugehoerig[${index}].bis`} />
                        </Row>
                    </>
                )}
            </List>   
		<DataCombination name={`literatur.literaturAngabe`}
        <List label="Konfession" name="konfession" column>
            {index => (
                <Row>
                    <Search label="" api="Konfession" name={`konfession[${index}].konfession`} />
                    <Zeit label="" name={`konfession[${index}].von`} />
                    <Zeit label="" name={`konfession[${index}].bis`} />
                </Row>
            )}
        </List>*/}
		{get('biogramm.kurz.v', item) && get('biogramm.kurzAutor[0].v', item) && (
				<>
	                <Textarea label="" name="biogramm.kurz" width="450px"/>
	                {/* 
						<List label="Autor" name="biogramm.kurzAutor" simple>
		                    {index => (
		                        <Search
		                            label="Autor"
		                            name={`biogramm.kurzAutor[${index}]`}
		                            api="Mitarbeiter"
									extraParam="name"
		                            small
		                        />
		                    )}
		                </List>
					*/}       
				</> 
		)}
		{/* 
        <Textarea label="Biogramm:" name="biogramm.lang" large />
        <List label="Autor" name="biogramm.langAutor" simple>
            {index => (
                <Search
                    label="Autor"
                    name={`biogramm.langAutor[${index}]`}
                    api="Mitarbeiter"
					extraParam="name"
                    small
                />
            )}
        </List>
        <List label="Ämter" name="aemter" column>
            {index => (
                <Row>
                    <Search label="" api="Amt" name={`aemter[${index}].amt`} small />
                    <Search
                        label=""
                        api="Ort"
                        name={`aemter[${index}].ort`}
                        extraParam="name"
                        small
                    />
                    <Zeit label="" name={`aemter[${index}].beginn`} />
                    <Zeit label="" name={`aemter[${index}].ende`} />
                </Row>
            )}
        </List>
        */}
		<br/>
		{literaturArea && (
            <Area>
                <List label="Literatur" name="sekundaerLiteratur" column>
                    {index => 
						<>
							<Row><DataCombination name={`sekundaerLiteratur[${index}].literaturAngabe`}/></Row>
							<Row><DataCombination name={`sekundaerLiteratur[${index}].druckAngabe`}/></Row>
						</>
					}
                </List>
                <List label="URLs" name={`url`} column>
                    {index => <LinkedInput label="" name={`url[${index}]`}/>}
                </List>
            </Area>
        )}
		<br/>
        <List label="Namensvarianten" name="namensvarianten">
            {index => (
                <>
                    <Input name={`namensvarianten[${index}].short`} />
                </>
            )}
        </List>
        <br/>
		<Row>
			{get('gnd.v', item) && get('gnd.v', item) === '-' ? (
	            <Input label="GND" name="gnd" />
			) : (
				<LinkedInput link="" label="GND" name="gnd" />
			)}
            <Input label="Wikidata-ID" name="wikidata" />
        </Row>
		<br/>
		{get('gnd.v', item) && get('gnd.v', item).includes('/gnd/') && (
			<>
				<br/>
		                <H3>Zitierempfehlung:</H3>
		                <>https://thbw.hadw-bw.de/person/{get('gnd.v', item).replace('http://d-nb.info/gnd/', '').replace('https://d-nb.info/gnd/', '')}. </>
		                <>Zugriff am {currentDate.getDate()}.{currentDate.getMonth() + 1}.{currentDate.getFullYear()}.</>
				<br/><br/>
			</>
		)}			
    </>
);

const mapStateToProps = (state) => {
    let literaturArea = true;
    if (
        (get('sekundaerLiteratur[0].literaturAngabe', state.item) === undefined ||
            get('sekundaerLiteratur[0].literaturAngabe', state.item) === null) &&
		(get('sekundaerLiteratur[0].druckAngabe', state.item) === undefined ||
            get('sekundaerLiteratur[0].druckAngabe', state.item) === null) &&
		(get('url[0].v', state.item) === undefined ||
            get('url[0].v', state.item) === null)
    )
        literaturArea = false;
    return {
        literaturArea: literaturArea,
        item: state.item,
    };
};

export default PublicViewHOC(connect(mapStateToProps)(PersonPublicUI));
