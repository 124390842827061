import React from 'react';
import ScrollContainer from '../style/ScrollContainer';

export default class PageNotFound extends React.Component {
    render() {
        return (
            <ScrollContainer>
                <h1>404 Page not Found</h1>
            </ScrollContainer>
        );
    }
}
