import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get as getLodash } from 'lodash';

import { changeItem } from '../../../client/store/actions/item';

import { get } from '../../service/immutables';
import { getItem } from '../../service/fetch';

import Link from '../../style/Link';

class LabelFastSearch extends Component {

    async componentDidMount() {
        const { extraParam, value, handleChange, name } = this.props;
        const api = this.props.api.toLowerCase();
        if (extraParam !== undefined && getLodash(value, '_id') !== undefined) {
            const data = await getItem(api, value._id);
            const extraParamValue = getLodash(data, extraParam);
            delete extraParamValue.m
            const newStoreValue = {
                ...value,
                [extraParam]: extraParamValue
            }
            handleChange(name + '.v', newStoreValue);
        }
    }

    render() {
        const { value, api, extraParam, erschlossen, unsicher, fragwuerdig, ca } = this.props;
        let anzeigeWert = getLodash(value, 'short.v');
        if (getLodash(value, extraParam + '.v') !== undefined) {
            anzeigeWert = getLodash(value, extraParam + '.v');
        }
        const valuesWithLink = ['brief', 'zitiername', 'sache', 'ort', 'archiv', 'briefhandschrift', 'briefdruck'];
        const showLink = valuesWithLink.includes(api.toLowerCase());

        return (
            <div>
                {!(value && value._id) ? (
                    <>&nbsp;</>
                ) : (
                    showLink ? (
						<>
							{erschlossen && '['}
	               			{ca && 'ca. '}
	                        <Link
	                            target="_self"
	                            to={'/ansicht/' + api.toLowerCase() + '/' + value._id + (this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? '/engl' : '')}
	                        >
	                            {anzeigeWert}
	                        </Link>
							{unsicher && '?'}
	                		{erschlossen && ']'}
	                		{fragwuerdig && ' [?]'}
						</>
                    )
                 	   : <>{anzeigeWert}</>
                )}
            </div>
        );
    }
}

LabelFastSearch.propTypes = {
    name: PropTypes.string.isRequired, // name wo im Parent object das element hingepseichert wird
    api: PropTypes.string.isRequired, // name der Api, z.B. "geo" oder "amt"
    value: PropTypes.any, //.isRequired
    default: PropTypes.any, // default value
};

const mapStateToProps = (state, props) => ({
        value:
            get(props.name + '.v', state.item) !== undefined
                ? get(props.name + '.v', state.item)
                : props.default,
        erschlossen: get(props.name + '.m.erschlossen', state.item),
        unsicher: get(props.name + '.m.unsicher', state.item),
        ca: get(props.name + '.m.ca', state.item),
        fragwuerdig: get(props.name + '.m.fragwuerdig', state.item),
		path: state.router.location.pathname,
});

const mapDispatchToProps = {
    handleChange: changeItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelFastSearch);
