import React, { Component } from 'react';
import { connect } from 'react-redux';

import Loading from '../components/Loading';

import { addNotification } from '../store/actions/notificationActions';
import { changeItem, loadItem, resetItem } from '../store/actions/item';
import { TOGGLE_SHOW_META_MENU } from '../store/constants/ActionTypes';

import { getItem } from '../service/fetch';

import ScrollContainer from '../style/ScrollContainer';
import PageNotFound from '../routes/PageNotFound';
import styled from 'styled-components';

const ViewPageContainer = styled('div')`
    padding-top: ${props => (
			props.noTopBottomPadding ? '0px' : props.smallTopBottomPadding ? '5px' : props.greatTopBottomPadding ? '100px' : '10px'
		)};
	padding-bottom: ${props => (
			props.noTopBottomPadding ? '0px' : props.smallTopBottomPadding ? '5px' : props.greatTopBottomPadding ? '100px' : '10px'
		)};
	padding-left: ${props => (props.noLeftRightPadding ? '0px' : props.smallLeftRightPadding ? '4px' : '15px')};
	padding-right: ${props => (props.noLeftRightPadding ? '0px' : props.smallLeftRightPadding ? '4px' : '15px')};
	height: ${props => (props.height ? props.height : '')};
	width: ${props => (props.width ? props.width : '')};
`;

export default PassedComponent => {
    class PublicViewHOC extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: 1,
                error: {},
                showInfoMenu: false,
                showLogMenu: false,
                focus: '',
                type: props.match.url.split('/')[2],
                id: props.match.params.id,
                // state which is set after backend response to indicate if brief is public or not
                isPublic: undefined,
                // state which is set if a brief was found
                found: undefined,
            };
        }

        endLoading = () => {
            this.setState(prevState => ({ loading: 0 }));
        };

        componentDidMount() {
            const { id, type } = this.state;
            const { changeItem } = this.props;
            changeItem('sendToken.v', true);
            console.log(id, type);
            if (id && type) {
                getItem(type, id)
                        .then(item => {
                            this.props.loadItem(item);
                            this.setState({ isPublic: true });
                            //this.props.addNotification(`${type} geladen`, 'success');
                            this.endLoading();
                        })
                        .catch(({ message }) => {
                            if(message.match(/nicht.*bearbeitet/i)) {
                                this.setState({ isPublic: false })
                            }
                            else {
                                this.setState({ isPublic: true })
                            }
                            if(message.match(/nicht.*gefunden/i)) {
                                this.setState({ found: false })
                            }
                            else {
                                this.setState({ found: true })
                            }
                            this.props.addNotification(
                                `Fehler beim Laden`,
                                message,
                                'error'
                            );
                            this.endLoading();
                        })
            }
        }

        componentWillUnmount() {
            this.props.resetItem();
        }

        render() {
            const { loading, id, type, isPublic, found } = this.state;
            return (
                <>
                    {found === undefined || found === true ? (
	                        <ScrollContainer grey height="95%">
	                            <ViewPageContainer width="56rem">
	                                <Loading loading={loading}>
	                                    {isPublic && <PassedComponent id={id} type={type}/>}
	                                </Loading>
	                            </ViewPageContainer>
	                        </ScrollContainer>
						) : (
                        	<PageNotFound/>
						)
                    }
                </>
            );
        }
    }

    const mapStatetoProps = state => ({
        item: state.item,
        saved: state.item.saved,
        focus: state.meta.focus,
        showMetaMenu: state.meta.show && !!state.meta.focus.length, // only show if the path is set
    });

    const mapDispatchToProps = {
        addNotification,
        resetItem,
        changeItem,
        loadItem,
        toggleMetaMenu: function() {
            return { type: TOGGLE_SHOW_META_MENU };
        },
    };

    return connect(
        mapStatetoProps,
        mapDispatchToProps
    )(PublicViewHOC);
};
