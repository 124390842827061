import React, { Component } from 'react';
import Loading from '../components/Loading';
import ScrollContainer from '../style/ScrollContainer';
import Container from '../style/Container';
import { loadItem, loadLog, resetItem } from '../store/actions/item';
import connect from 'react-redux/es/connect/connect';
import { get } from '../service/immutables';
import { getItem } from '../service/fetch';
import { addNotification } from '../store/actions/notificationActions';

export default PassedComponent => {
    class LogHOC extends Component {
        constructor(props) {
            super(props);
            this.state = {
                // 0 = daten sind geladen
                // 1 = daten werden noch geladen; fetch nicht abgeschlossen
                loading: 1,
            };
        }

        resetSearch = () => {
            this.props.resetItem();
        };

        componentDidMount() {
            const { type } = this.props.match.params;
            if (type.toLowerCase() !== 'zuletztbearbeitet') {
                this.getLogsFromApi();
            } else {
                this.getLogsFromLastChanged();
            }
        }

        componentWillUnmount() {
            this.props.resetItem();
        }
        // for /user/zuletztBearbeitet/<id>
        getLogsFromLastChanged = async () => {
            const { id } = this.props.match.params;
            const { kind, limit } = this.props;
            this.startLoading();
            await getItem('log', null, `limit=${limit}&mitarbeiter=${id}&kind=${kind.toLowerCase()}`)
                .then(item => {
                    this.props.loadLog(item, kind);
                    this.props.addNotification(`Zuletzt bearbeitete ${kind} geladen`, 'success');
                })
                .catch(e => {
                    this.props.addNotification(e.message, 'error');
                });
            this.endLoading();
        };
        // for /log/<object>/<id>
        getLogsFromApi = async () => {
            const { type, id } = this.props.match.params;
            this.startLoading();

            getItem(`${type}/${id}`)
                .then(item => {
                    this.props.loadItem(item);
                    getItem(`log/${type}/${id}`)
                        .then(logs => {
                            this.props.loadLog(logs);
                            this.props.addNotification(`Logs geladen`, 'success');
                        })
                        .catch(e => {
                            this.props.addNotification(e.message, 'error');
                        });
                })
                .catch(e => {
                    this.props.addNotification(e.message, 'error');
                });
            this.endLoading();
        };

        onKeyPressed = ({ keyCode }) => keyCode === 13 && this.getLogsFromApi();

        startLoading = () => {
            this.setState({ loading: 1 });
        };

        endLoading = () => {
            this.setState({ loading: 0 });
        };

        render() {
            let { type, id } = this.props.match.params;
            const { item, logs, kind } = this.props;
            const { loading } = this.state;
            return (
                <div style={{ width: '100%' }} onKeyDown={this.onKeyPressed}>
                    <ScrollContainer>
                        <Container>
                            <Loading loading={loading}>
                                <PassedComponent
                                    logs={logs}
                                    item={item}
                                    id={id}
                                    type={type}
                                    loading={loading}
                                    kind={kind}
                                />
                            </Loading>
                        </Container>
                    </ScrollContainer>
                </div>
            );
        }
    }

    const mapStateToProps = state => ({
        logs: get('log', state.item) || [],
        item: state.item,
    });

    const mapDispatchToProps = {
        resetItem,
        loadItem,
        loadLog,
        addNotification,
    };

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(LogHOC);
};
