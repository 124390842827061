import styled from 'styled-components';
import { lightGrey } from './colors';

export const Title = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${lightGrey};
    margin-bottom: 4px;
`;

export default Title;
