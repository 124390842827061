import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get } from '../../service/immutables';
import { Row } from '../../style/Row';
import { connect } from 'react-redux';
import styled from 'styled-components';

const LabelInputStyle = styled('div')`
    font-family: inherit;
	font-size: 13px;
	cursor: default;
	
    width: ${props => (props.width ? props.width : 'fit-content')};
	max-width: 85vw;
    height: ${props => (props.height ? props.height : 'fit-content')};
	
    vertical-align: top;
    align-items: center;
    display: flex;
    margin: 5px;

    border: none;
    border-radius: 2px;
`;

class LabelInput extends Component {
    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element
        this.input = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.focus && this.props.focus) this.input.current.focus();
    }

    render() {
        const {
            value,
            width,
            erschlossen,
            unsicher,
            fragwuerdig,
            ca,
        } = this.props;
        return (
            <Row nowrap hcenter>
                <LabelInputStyle width={width}>
                    {erschlossen ? "[" : ""} 
                    {ca ? "ca. " : ""} 
                    {value}
                    {unsicher? "?" : ""} 
                    {erschlossen ? "]" : ""}
                    {fragwuerdig ? " [?]" : ""}
                </LabelInputStyle>
            </Row>
        );
    }
}

LabelInput.defaultProps = {};

LabelInput.propTypes = {
    label: PropTypes.string, // not required - for example in Zeit
    name: PropTypes.string.isRequired, // name wo im Parent object das element hingepseichert wird
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), //.isRequired//.oneOfType([PropTypes.oneOf([null]), PropTypes.string]).isRequired
};

const mapStateToProps = (state, props) => ({
    value: get(props.name + '.v', state.item) || '',
    erschlossen: get(props.name + '.m.erschlossen', state.item),
    unsicher: get(props.name + '.m.unsicher', state.item),
    ca: get(props.name + '.m.ca', state.item),
    fragwuerdig: get(props.name + '.m.fragwuerdig', state.item),
});

const mapDispatchToProps = (dispatch, props) => ({
    //handleChange: e => dispatch(changeItem(props.name + '.v', e.target.value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LabelInput);
