import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { grey, linkBlue } from './colors';

const LinkStyled = styled(Link)`
    display: flex;
	align-items: center;
	justify-content: center; 
    height: ${props => (props.height ? props.height : '2em')};

    padding: 0 1em;
    margin-bottom: 5px;
	color: ${({ color }) => (color ? color : linkBlue)};

    &:hover {
        color: ${grey};
    }

`;

export default props => (
    <LinkStyled {...props} target={props.target ? props.target : '_self'}>
        {props.children}
    </LinkStyled>
);
