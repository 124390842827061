/**
 * @project:      ThBw
 * @file:         client/src/clientExtern/labels/LabelHoc.js
 * @summary:
 * @desc:
 * @created:      2019-10-23 21:21:31 by Moritz Mock
 * @lastmodified: 2019-12-06 12:54:35 by ekzyis
 */
import React, { Component } from 'react';
import Element from '../style/Element';
import Label from '../style/Label';
import { connect } from 'react-redux';
import { get as getLodash } from 'lodash';
import { get } from '../service/immutables';

const LabelHoc = PassedComponent => {
    class Meta extends Component {
        constructor() {
            super();
            this.state = {
                focus: false,
            };
        }

        render() {
            let show = false; // true: show, false: don't show
            const {
                width,
                height,
                label,
                noMeta,
                small,
                large,
				row,
                extraLarge,
                value,
                valueMax,
            } = this.props;
            // für alle Inputs außer Zeit
            if ( getLodash(value, "v") !== null && getLodash(value, "v") !== undefined && getLodash(value, "v") !== "" && value !== "") show = true;
            // durch die Abfrage von oben wird bei einem Zeit Input der show immer auf true gesetzt, mit den folgenden Zeilen wird dies wieder richtig gestellt
            if (typeof getLodash(valueMax, 'shortZeit.v') === 'string' || typeof getLodash(valueMax, 'shortZeit.v') === 'number')
                if (getLodash(valueMax, 'shortZeit.v') !== "0000000000000000"  && getLodash(valueMax, 'shortZeit.v') !== "10000000000000000") show = true;
                else show = false
            return (
                <>
                    {show && (
                        <Element
                            tabIndex={-1}
                            width={width}
                            height={height}
                            noMeta={noMeta}
                            small={small}
                            large={large}
                            extraLarge={extraLarge}
							row={row}
                        >
                            <Label>{label}</Label>
                            <PassedComponent {...this.props} />
                        </Element>
                    )}
                </>
            );
        }
    }

    Meta.defaultProps = {};
    Meta.propTypes = {};

    const mapStateToProps = (state, props) => ({
        value: get(props.name, state.item) || '',
        valueMax: get(props.name, state.item) || '',
    });

    return connect(mapStateToProps)(Meta);
};

export default LabelHoc;
