import React from 'react';
import Textarea from './LabelTextarea';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

const LabelZeitStyle = styled('div')`
    margin: 5px;
`;

const LabelZeit = ({
    name,
    schoen,
}) => (
	<LabelZeitStyle>
	            {schoen && (
	                    <Textarea name={name + '.schoen'} />
	            )}
	</LabelZeitStyle>
);

LabelZeit.propTypes = {
    name: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
    let schoen = get(props.name + '.schoen.v', state.item) !== null ? true : false;
    return {
        schoen: schoen,
    };
};

export default connect(
    mapStateToProps,
)(LabelZeit);
