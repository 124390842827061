import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { changePageHOC, jumpPageHOC, resetSearch } from '../store/actions/search';

import { Area } from '../style/Area';
import Button from '../style/Button';
import { blue, ultraLightGrey } from '../style/colors';
import { MakeItGray } from '../style/disableIt';
import Space from '../style/Space';
import Row from '../style/Row';
import Arrow from '../SVG/ChevronLeftRight';
import DoubleArrow from '../SVG/DoubleChevronLeftRight';
import LastFirst from '../SVG/LastFirstJump';

const op_theme = `
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    position: relative;
    bottom: 0;
    height: 5%
    width: 100%;
    background: ${ultraLightGrey}
`;

const CurrentPage = styled("div")`
    color: ${blue};
`;

const Footer = styled("div")`
  display: grid;
  grid-template-columns: ${({pos}) => pos === "top" ? "[start] 1fr [middle] 1fr [end]" : "1fr [start] 1fr [middle] 1fr [end]"};
  ${({op}) => op ? op_theme : ""}
`;

const Buttons = styled("div")`
  display: flex;
  place-self: center start;
  grid-column-start: start;
  grid-column-end: middle;
`;

const Count = styled("div")`
	place-self: center;
  	grid-column-start: middle;
  	grid-column-end: end;
`;

class SearchFooter extends Component {
    state = {
        page: 0
    }

    componentWillUnmount() {
        this.props.resetSearch();
    }

    handleClick = () => {
        this.props.jumpPage(this.state.page)
    }

    onKeyPressed = e => {
        e.stopPropagation();
        if (e.key === "Enter") this.props.jumpPage(this.state.page);
    };

    render() {
		const { changePage, page, count, limit, pos, op, loading } = this.props;
		const maxPages = Math.ceil(count / limit);
        return (
		<Footer id="SearchFooter" pos={pos} op={op}>
			{(maxPages - 1) > 0 && (
			    <>
                    <Buttons id="PageButtons">
                        {/*jump to first page*/}
                            <MakeItGray loading={page === 0}>
                                <Area>
                                    <LastFirst onClick={() => page !== 0 && changePage(-page)} />
                                </Area>
                            </MakeItGray>
                        {/* 5 pages back */}
                            <MakeItGray loading={page < 4}>
                                <Area>
                                    <DoubleArrow onClick={() => page >= 4 && changePage(-5)} />
                                </Area>
                            </MakeItGray>
                        {/*1 page back*/}
                            <MakeItGray loading={page === 0}>
                                <Area>
                                    <Arrow onClick={() => page !== 0 && changePage(-1)} />
                                </Area>
                            </MakeItGray>
                        {/*show previous page*/}
                            {page > 0 && <Area onClick={() => changePage(-1)}>{page}</Area>}
                        {/*show current page*/}
                            <Area>
                                <CurrentPage>{page + 1}</CurrentPage>
                            </Area>
                        {/*show next page*/}
                            {page < maxPages - 1 && (
                                <Area onClick={() => changePage(+1)}>{page + 2}</Area>
                            )}
                        {/*1 page ahead*/}
                            <MakeItGray loading={page === maxPages - 1}>
                                <Area>
                                    <Arrow
                                        direction={true}
                                        onClick={() => !(maxPages - 1 === page) && changePage(1)}
                                    />
                                </Area>
                            </MakeItGray>
                        {/* 5 pages ahead */}
                            <MakeItGray loading={(page >= (maxPages - 5))}>
                                <Area>
                                    <DoubleArrow
                                        direction={true}
                                        onClick={() => (page <= (maxPages - 5)) && changePage(5)} />
                                </Area>
                            </MakeItGray>
                        {/*jump to last page (care for enough RAM!) */}
                        <MakeItGray loading={page === maxPages - 1}>
                            <Area>
                                <Row nowrap>
                                    <LastFirst direction={true} onClick={() => changePage(maxPages - page - 1)} />
                                    <>&nbsp;{maxPages}</>
                                </Row>
                            </Area>
                        </MakeItGray>
                        <Space/>
                        <Button id="JumpButton" width="58px" onClick={this.handleClick}>Gehe zu:</Button>
                        <Area width="18px">
                            <input id="JumpTo" type="number" min="0" max={maxPages} style={{width: "100%"}} onKeyDown={this.onKeyPressed} onChange={v => this.setState({page: parseInt(v.target.value)})}/>
                        </Area>
                    </Buttons>
                </>
			)}
			{loading === 0 && (
				<Count id="ResultCount">{count + ' Einträge geladen'}</Count>
			)}
        </Footer>
        );
    }
}

const mapStateToProps = state => ({
    page: state.search.page,
    count: state.search.count,
 	loading: state.search.loading,
    limit: state.search.limit,
});

const mapDispatchToProps = (dispatch, props) => ({
    changePage: value => dispatch(changePageHOC(props.startSearch)(value)),
    jumpPage: value => dispatch(jumpPageHOC(props.startSearch)(value)),
    resetSearch: () => dispatch(resetSearch())
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFooter);