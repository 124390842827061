import styled from 'styled-components';
import { label } from './colors';

/* ursprüngliches Konzept:
H1 - Titel
H2 - Unterbereiche
H3 - name/short
H4 - Area Titel
*/

export default styled('div')`
    font-size: 3rem !important;
    letter-spacing: -0.8px !important;
    color: ${label} !important;
    margin-top: 10px !important;
    line-height: 3rem;
    padding: 0 !important;
`;
