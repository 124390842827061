import PropTypes from 'prop-types';
import React from 'react';
import CheckBoxStyle from '../../style/CheckBox';
import { Row } from '../../style/Row';
import { get } from '../../service/immutables';
import { changeItem } from '../../store/actions/item';
import { connect } from 'react-redux';

const CheckBox = ({
    value,
    handleChange,
    name,
    small,
    noMeta,
    erschlossen,
    unsicher,
    fragwuerdig,
    ca,
    disabled,
}) => (
    <Row nowrap>
        {erschlossen && '['}
        <CheckBoxStyle
            onClick={disabled === true ? () => '' : () => handleChange(name + '.v', !value)} // TODO find better solution for the true (() => "")
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            {value && <polyline points="20 6 9 17 4 12" />}
        </CheckBoxStyle>
        {ca && <>&nbsp;ca.</>}
        {unsicher && '?'}
        {erschlossen && ']'}
        {fragwuerdig && <>&nbsp;[?]</>}
    </Row>
);

CheckBox.defaultProps = {};

CheckBox.propTypes = {
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.bool, //.isRequired
    noMeta: PropTypes.bool, // disable Meta tooltip
};

const mapStateToProps = (state, props) => ({
    value: get(props.name + '.v', state.item),
    erschlossen: get(props.name + '.m.erschlossen', state.item),
    unsicher: get(props.name + '.m.unsicher', state.item),
    ca: get(props.name + '.m.ca', state.item),
    fragwuerdig: get(props.name + '.m.fragwuerdig', state.item),
});

// Todo write ITEM_TOGGLE_BOOL for cleaner code
const mapDispatchToProps = {
    handleChange: changeItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckBox);
