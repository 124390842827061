import React from 'react';
import styled from 'styled-components';
import { count } from '../../service/fetch';

import Container from '../../style/Container';
import H2 from '../../style/H2';
import H4 from '../../style/H4';
import Link from '../../style/Link';
import LinkExtern from '../../style/LinkExtern';
import Row from '../../style/Row';
import TextblockArea from '../../style/TextblockArea';

const Style = styled('div')`
	text-align: justify;
	justify-content: center;
	font-size: 16px;
    max-width: 100%;
    height: 100%;
	line-height: 1.5;
`;

class Hinweise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            briefCount: 3900
        };
    }

    componentDidMount() {
		count("brief", null, "sichtbar.v=true")
            .then(data => this.setState({briefCount: data}))
    }

    render() {
        return (
            <Style>
                    <Container>
						<br/><br/><br/><br/>
						<Row center>
							<H2>About the Database</H2>
							<br/><br/><br/><br/>
						</Row>
						<Row center>
							<br/><br/>
							<H4>Letters and transmission formats</H4>
                        </Row>
						<Row center>
							<br/><br/>
			                <TextblockArea width="56rem">
							Since 2017 the database has been compiling the correspondence of all leading churchmen and theologians of the German southwest (some 200 persons) 
							active in the Electoral Palatinate, the Duchy of Württemberg or the imperial city of Strasbourg between 1550 and 1620 
							&nbsp;(➔ <LinkExtern noBreak href="//www.hadw-bw.de/en/research/research-center/theologians-correspondence-southwest-empire-early-modern-period-1550-1620" target="_blank">Project description</LinkExtern>).<br/><br/>
							The database currently encompasses {this.state.briefCount} letters that have been made accessible online and by the end of the 
							project in 2031 it will comprise approximately 35,000 letters. Each letter is assigned a ID number (Brief-ID), a random five-digit number with no 
							significance for the sequence or number of letters. For the purposes of our project, a letter is defined as a document written to a specific 
							addressee – individual or collective – and in which the above mentioned theologians appear as either the sender or the recipient. The database not 
							only includes letters in the narrower sense, but also expert opinions (Gutachten) and other texts of an epistolary character. Furthermore, dedicatory 
							letters, reprints of letters in contemporary prints and prefaces to the reader also fall within its range. Letters merely mentioned but not quoted 
							are only included if a specific date is known.<br/><br/>
							Letters are often transmitted in multiple copies, most of them handwritten. The project seeks to identify all known copies as completely as possible. 
							In the case of manuscript copies, our database records the owning institution, the current shelf mark, and the format of the document (rough draft, 
							autograph original, transcription etc.). The order in which the manuscripts are listed does not necessarily reflect the order in which they originated. 
							In addition, reproductions of these letters in modern editions, references to them in research literature, and links to other letter databases are 
							also recorded. Our database is linked to the catalogues of owning institutions if these contain records on individual letters.<br/><br/>
							We are deeply indebted to Prof. Dr. Hermann Ehmer for generously entrusting us with his voluminous unpublished research on the correspondence of 
							Johannes Brenz.
							</TextblockArea>
                        </Row>
						<Row>
							<br/><br/>
						</Row>
						<Row center>
							<H4>Data and Formats</H4>
                        </Row>
						<Row center>
							<br/><br/>
			                <TextblockArea width="56rem">
							The database registers essential benchmark data (date, author, place of writing and recipient) for all letters. The following information, if 
							mentioned or inferable, is also recorded for all letters: place of destination, date of presentation, incipit, language, bearer of the letter 
							and itinerary. Extrapolated information and conjectures are always identified as such. The content of each letter is documented by means of an 
							abstract (Regest) and through keywords (Schlagworte) for the persons, places and subjects mentioned. As a matter of principle, all persons and 
							places mentioned in a letter are tagged with keywords. The tagging of subjects lies within the discretion of the respective editor and focusses 
							on the most important contents of the letter.<br/><br/> 
							In the course of time, digital copies of about one third of the letters and transcriptions of a number of letters will be incorporated into the 
							database. In addition, the fully annotated editions of the printed 
							➔ <LinkExtern noBreak href="/editions/engl" target="_self">
							project volumes</LinkExtern>&nbsp;will also be made available in the database soon after their publication. The texts are encoded in TEI-XML and 
							can be downloaded.
							</TextblockArea>
                        </Row>
						<Row>
							<br/><br/>
						</Row>
						<Row center>
							<H4>Search Functions</H4>
                        </Row>
						<Row center>
							<br/><br/>
			                <TextblockArea width="56rem">
							In the <Link to="/suche/brief/engl" target="_self">Simple mode</Link> the command Freie Suche (Free Search) searches all metadata, descriptions, and 
							transcriptions at once. The Time Search (DD.MM.YYYY) within the simple search searches not only the letter date, but also other time information 
							such as the presentation date. In the search for persons, places and subjects, Freie Suche also finds alternative denotations 
							and designations.<br/><br/> 
							In the <Link to="/erweitert/brief/engl" target="_self">Advanced search</Link>, several search criteria for letters or persons can be combined and individual 
							attributes can be looked for specifically. For example, the selection "Verfasser: Name" and "Text" can be used to search the texts of all transcribed 
							Brenz letters. The operators NICHT (not) and VORHANDEN (available) offer options for further narrowing down the search results. Because of the 
							consequent tagging of letters with keywords, it is recommended that one use the selection option "Schlagworte: …" when looking for 
							persons, places and typical subjects.<br/><br/> 
							In all searches, special characters are not interpreted as wildcards and are supported as text in most cases. Search entries in text form are also 
							searched within attributes ("includes" instead of "is equal to") and matching is not done at word level but at character level, 
							so that parts of words are also found. The advanced search is case sensitive, whereas the simple mode is not.
							</TextblockArea>
                        </Row>
						<Row>
							<br/><br/>
						</Row>
						<Row center>
							<H4>Index</H4>
                        </Row>
						<Row center>
							<br/><br/>
			                <TextblockArea width="56rem">
							Persons, places and subjects can either be looked up in the search modes on the basis of attributes or can be found listed and filtered alphabetically in 
							the&nbsp;<Link to="/filter/person" target="_self">index</Link>. As a general rule, persons and places are linked with norm data (GND, Geonames). 
							Mythological or literary figures are found under "Persons" if they represent a human being. Other beings and gods are found in the subject index. The place 
							index includes all geographical categories from continents down to buildings. By calling up a person, place or subject, one obtains a list of all letters in 
							which this person, place or subject is mentioned. Within each subject record, superordinate and subordinate terms are also listed, which together form a 
							thematically organized subject index (Sachregister). The subject index encompasses topics discussed in letters, quoted or alluded biblical passages, quotes 
							from or mentions of literary works, historical events and institutions. Institutions such as city councils or universities may additionally appear in the 
							personal index if they appear as the author or addressee of a letter.
							</TextblockArea>
                        </Row>
						<Row>
							<br/><br/>
						</Row>
						<Row center>
							<H4>Technical implementation</H4>
                        </Row>
						<Row center>
							<br/><br/>
			                <TextblockArea width="56rem">
							The database application was developed within our research project using the MERN stack (MongoDB, ExpressJS, ReactJS, NodeJS) and is constantly being expanded 
							and adapted.<br/><br/>
							Concept: Daniel Degen<br/><br/>
							Development: <LinkExtern href="https://twitter.com/linux249" target="_blank">Julian Libor</LinkExtern>, <LinkExtern href="https://mmock.net" target="_blank">Moritz Mock</LinkExtern>, Ramdip Gill, Christopher Magin, Daniel Degen<br/><br/>
							The&nbsp;<b>XSLT scripts</b>&nbsp;were created by Dario Kampkaspar.
							</TextblockArea>
                        </Row>
						<Row>
							<br/><br/>
						</Row>
						<br/><br/>
                    </Container>
            </Style>
        );
    }
}

export default Hinweise;
