import React from 'react';
import { connect } from 'react-redux';
import { get } from '../service/immutables';
import Link from '../style/Link';

class TitleMeta extends React.Component {
    datierung = () => {
        const { item } = this.props;
        if (get('datierung.schoen.v', item)) {
            return (
                <>
                    {get('datierung.schoen.v', item)}
                    {get('absendeort[0].ort.v.short.v', item) ? <>, </> : <>. </>}
                </>
            );
        } else {
            if (
                get('erlaeuterung.v', item) === 'ohne Datum' ||
                get('erlaeuterung.v', item) === 's. d.' ||
				get('ohneDatum.v', item) === true
            ) {
                if (!get('absendeort[0].ort.v.short.v', item)) {
                    return <>s.&nbsp;d. </>;
                } else if (get('absendeort[0].ort.v.short.v', item) !== 's. l.') {
                    return <>s.&nbsp;d., </>;
                }
            }
        }
    };

    absendeort = () => {
        const { item, absendeort } = this.props;
        if (
            get('absendeort[0].ort.v.short.v', item) === 's. l.' &&
            get('datierung.schoen.v', item)
        ) {
            return <>s.&nbsp;l. </>;
        } else if (
            get('absendeort[0].ort.v.short.v', item) === 's. l.' &&
            (get('erlaeuterung.v', item) === 'ohne Datum' ||
                get('erlaeuterung.v', item) === 's. d.' ||
                get('ohneDatum.v', item) === true
			)
        ) {
            return <>s.&nbsp;l.&nbsp;et&nbsp;d. </>;
        } else {
            return absendeort.map((abs, i) => {
                return (
                    <>
                        {get('ort.m.erschlossen', abs) && <>[</>}
                        {get('ort.v.short.v', abs) !== 's. l.' && (
                            <Link to={'/edit/Ort/' + get('ort.v._id', abs)}>
                                {get('ort.v.short.v', abs)}
                            </Link>
                        )}
                        {get('ort.m.unsicher', abs) && <>?</>}
                        {get('ort.m.erschlossen', abs) && <>]</>}
                        {get('ort.m.fragwuerdig', abs) && <>&nbsp;[?]</>}
                        {i < absendeort.length - 1 && get('ort.v', abs) ? <>/</> : <></>}
                        {i === absendeort.length - 1 &&
                        get('ort.v', abs) &&
                        get('ort.v.short.v', abs) !== 's. l.' ? (
                            <>. </>
                        ) : (
                            <></>
                        )}
                        {get('ort.v.short.v', abs) === 's. l.' && <>s.&nbsp;l. </>}
                    </>
                );
            });
        }
    };

    verfasser = () => {
        const { verfasser } = this.props;
        return verfasser.map((ver, i) => {
            return (
                <>
					{(verfasser.length > 1  && i === verfasser.length - 1) ? (
						<>
							{get('person.v.short.v', ver) === 'u. a.' ? <> </> : <> und </>}
							{/* Konzept für Änderung: wenn ein Amt vorangeht -> vor 'und' ein Komma
							{get('person.v.short.v', ver) === 'u. a.' ? <> </> : (
							<>   
								{(verfasser[i-1] && verfasser[i-1].amt && verfasser[i-1].amt.v) ? <>,</> : <></>  
								} 
								<> und </>
							</>
							)}
							*/}
						</> 
					) : (<></>)}
                	{get('person.m.erschlossen', ver) && <>[</>}
					{get('person.v.short.v', ver) === 'u. a.' ? ( 
                        <>u.&nbsp;a.</>
					) : (
						<Link to={'/edit/Zitiername/' + get('person.v._id', ver)}>
                            {get('person.v.short.v', ver)}
                        </Link>
					)}
					{get('person.m.unsicher', ver) && <>?</>}
                    {get('person.m.erschlossen', ver) && <>]</>}
                    {get('person.m.fragwuerdig', ver) && <>&nbsp;[?]</>}
                    {get('amt.v', ver) ? <>, </> : <></>}
                    {get('amt.v', ver)}
                    {i < verfasser.length - 2 ? <>, </> : <></>}
                </>
            );
        });
    };

    adressat = () => {
        const { item, adressat } = this.props;
		//const { verfasser } = this.props;
        return (
            <>	
				{get('0.person.v', adressat) ? (
					<>
						{(
						get('textsorte.v.short.v', item) === 'Zeugnis für Empfänger'
						|| get('textsorte.v.short.v', item) === 'Stammbucheintrag'
						|| get('textsorte.v.short.v', item) === 'Quittung'
						|| get('textsorte.v.short.v', item) === 'Instruktion'
						|| get('textsorte.v.short.v', item) === 'Urkunde'
						)
						? <> für </> : <> an </>}
					</>
				) : (<></>)}
				{/* Konzept für Änderung: voriger Verfasser mit Amt -> Komma vor "an"
                {get('0.person.v', adressat) ? (
					<>
						{
							verfasser[verfasser.length - 1] && 
							verfasser[verfasser.length - 1].amt && 
							verfasser[verfasser.length - 1].amt.v && 
								<>,</>
						}
						<> an </> 
					</>
					) : (<></>)
                }
				*/}
                {adressat.map((adr, i) => {
                    return (
                        <>
							{(adressat.length > 1  && i === adressat.length - 1) ? (
								<>
									{get('person.v.short.v', adr) === 'u. a.' ? <> </> : <> und </>}
									{/* Konzept für Änderung: wenn ein Amt vorangeht -> vor 'und' ein Komma
									{get('person.v.short.v', adr) === 'u. a.' ? <> </> : ( 
									<>
										{(adressat[i-1] && adressat[i-1].amt && adressat[i-1].amt.v) ? <>,</> : <></>  //wenn ein Amt vorangeht, muss vor 'und' ein Komma
										} 
										<> und </>
									</>
									)}
									*/}
								</> 
							) : (<></>)}
							{get('person.m.erschlossen', adr) && <>[</>}
                            {get('person.v.short.v', adr) === 'Leser' ? <>den </> : <></>}
							{get('person.v.short.v', adr) === 'u. a.' ? ( 
		                        <>u.&nbsp;a.</>
							) : (
								<Link to={'/edit/Zitiername/' + get('person.v._id', adr)}>
		                            {get('person.v.short.v', adr)}
		                        </Link>
							)}
                            {get('person.m.unsicher', adr) && <>?</>}
                            {get('person.m.erschlossen', adr) && <>]</>}
                            {get('person.m.fragwuerdig', adr) && <>&nbsp;[?]</>}
							{get('amt.v', adr) ? <>, </> : <></>}
                            {get('amt.v', adr)}
                            {i < adressat.length - 2 ? <>, </> : <></>}
                        </>
                    );
                })}
            </>
        );
    };

	zielort = () => {
        const { zielort, adressat, item } = this.props;
            return zielort.map((z, i) => {
                return (
                    <>
						{zielort.length === 1 && (
							(get('adressat.' + (adressat.length - 1) + '.person.v.short.v', item) && (
								get('adressat.' + (adressat.length - 1) + '.person.v.short.v', item).includes(get('v.short.v', z)) 
								))
							||
							(get('adressat.' + (adressat.length - 1) + '.amt.v', item) && (
								get('adressat.' + (adressat.length - 1) + '.amt.v', item).endsWith(get('v.short.v', z))
								))
						) ? (
							<></>
						) : (
							<>
								{get('v.short.v', z) && (
									<>
										{get('m.erschlossen', z) && <>[</>}
				                        {get('v.short.v', z) !== 's. l.' && (
				                            <Link to={'/edit/Ort/' + get('v._id', z)}>
				                                {get('v.short.v', z)}
				                            </Link>
				                        )}
		 								{get('m.unsicher', z) && <>?</>}
		                        		{get('m.erschlossen', z) && <>]</>}
		                        		{get('m.fragwuerdig', z) && <>&nbsp;[?]</>}
									</>
								)}
							</>
						)}
						{zielort.length === 1 && (
							(get('adressat.' + (adressat.length - 1) + '.person.v.short.v', item) && (
								get('adressat.' + (adressat.length - 1) + '.person.v.short.v', item).includes(get('v.short.v', z)) 
								))
							||
							(get('adressat.' + (adressat.length - 1) + '.amt.v', item) && (
								get('adressat.' + (adressat.length - 1) + '.amt.v', item).endsWith(get('v.short.v', z))
								))
						) ? (
							<></>
						) : (
							<>
                        		{i < zielort.length - 1 && get('v', z) ? <>/</> : <></>}
							</>
						)}
                    </>
                );
            });
    };

    render() {
        const { item } = this.props;
		const { adressat } = this.props;
		const { zielort } = this.props;
		let showInForZielorte = false;
		if (zielort.length > 1) {
				showInForZielorte = true;
			} else {
			//prüfe, ob der Text vom einzigen Zielort schon am Ende vom letzten Adressaten steht
			if (get('zielort.0.v.short.v', item)
				&& 
				get('zielort.0.v.short.v', item) !== 's. l.'
				&&
				(!get('adressat.' + (adressat.length - 1) + '.person.v.short.v', item) || 
				!get('adressat.' + (adressat.length - 1) + '.person.v.short.v', item).includes(get('zielort.0.v.short.v', item)))
				&&
				(!get('adressat.' + (adressat.length - 1) + '.amt.v', item) || 
				!get('adressat.' + (adressat.length - 1) + '.amt.v', item).endsWith(get('zielort.0.v.short.v', item)))
				) { 
					showInForZielorte = true;
			}
		}

        return (
            <div>
                {get('short.v', item)}
				{get('short.v', item) && <>:</>}
                <> </>
                {this.datierung()}
                {this.absendeort()}
                {this.verfasser()}
                {this.adressat()}
				{showInForZielorte && <> in </>}
				{this.zielort()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    item: state.item,
    verfasser: state.item.verfasser || [],
    adressat: state.item.adressat || [],
    absendeort: state.item.absendeort || [],
    zielort: state.item.zielort || [],
});

export default connect(mapStateToProps)(TitleMeta);
