import React from 'react';
import { get } from '../../service/immutables';
import { connect } from 'react-redux';
import {
    CheckBox,
    DropdownSimple,
    FastSearch as Search,
    Input,
    LinkedInput,
    Zeit,
} from '../../inputs/InputsWithMetaLabelFocus';

import AllowedForUser from '../../container/AllowedForUser';
import CollapseArea from '../../container/CollapseArea';
import CollapseTopDown from '../../container/CollapseTopDown';
import EditHOC from '../../container/EditHOC';
import DeleteItem from '../../container/DeleteItem';
import List from '../../container/List';
import RefsCount from '../../container/RefsCount';
import RefsLinks from '../../container/RefsLinks';
import SetFavorite from '../../container/SetFavorite';
import Short from '../../container/Short';

import Anmerkung from '../../components/Anmerkungen';
import Schlagwort from '../../components/Schlagworte';

import OpenSVG from '../../SVG/Open';

import Area from '../../style/Area';
import H1 from '../../style/H1';
import H4 from '../../style/H4';
import Link from '../../style/Link';
import { Row } from '../../style/Row';
import Space from '../../style/Space';
import Titel from '../../style/Title';
import VerticalLine from '../../style/VerticalLine';

const Literatur = ({
    item,
    type,
    shortNotGiven,
    ausw,
    newObject,
    mono,
    zeitschrift,
    sammel,
    sammelBtrg,
    lexNurHg,
    lexKuerz,
	lexGesamt,
    lexArt,
    editionBearb,
    editionAbk,
    editionsreihe,
    mono_hg,
    mono_plushg,
    zeitschrift_hg,
    geprueft,
	shortGiven,
}) => {
    return (
        <>
            <Row>
                <H1>Literatur</H1>
                <Space />
				<Link to={`/ansicht/literatur/${item._id}`} target="_self">
                	<OpenSVG />
            	</Link>
            	<Space />
                <SetFavorite type={type} />
                <Space />
                <DeleteItem type={type} />
            </Row>
			<Short />
            <Row>
				<H4 notBold>
					{get('gesamtaufnahme.v', item) && (<>{get('gesamtaufnahme.v', item)}</>)}
				</H4>
            </Row>
            <CollapseArea label="Exemplare" show={true}>
                <Area>
                    <List label="Exemplare" name="exemplar">
                        {index => (
                            <>
                                <Search
                                    label="Medium"
                                    api="Medium"
                                    name={`exemplar[${index}].medium`}
                                />
								<LinkedInput
                                    label="Standort/URL"
                                    name={`exemplar[${index}].standort`}
                                    checkHttp
                                />
								<CollapseTopDown trigger={[]}>
	                                <Input
	                                    label="Signatur der FS"
	                                    name={`exemplar[${index}].signatur`}
	                                />
	                                <Zeit
	                                    label="vorhanden von"
	                                    name={`exemplar[${index}].vorhanden.von`}
	                                    today
	                                />
	                                <Zeit
	                                    label="vorhanden bis"
	                                    name={`exemplar[${index}].vorhanden.bis`}
	                                    today
	                                />
	                                <List
	                                    label="geerbt von"
	                                    name={`exemplar[${index}].geerbt`}
	                                    simple
	                                    column
	                                >
	                                    {index2 => (
	                                        <Input
	                                            label="geerbt von"
	                                            name={`exemplar[${index}].geerbt[${index2}]`}
	                                        />
	                                    )}
	                                </List>
								</CollapseTopDown>
                            </>
                        )}
                    </List>
                </Area>
            </CollapseArea>
            <CollapseArea label="Bibliographie" show={shortNotGiven}>
                <Area>
                    <Titel>Zitierkürzel</Titel>
                    <Row>
                        <Area>
                            <Input
                                label="Kürzel"
                                name="kuerzel"
                                placeholder="Nachname des Autors, Kurztitel (i.d.R. ein Wort)"
                                large
                                disabled={geprueft}
                            />
                        </Area>
						<AllowedForUser rolle="Admin">
	                        <Area>
	                            <CheckBox label="geprüft" name="geprueft" />
	                        </Area>
						</AllowedForUser>
                    </Row>
                </Area>
                <Area>
                    <DropdownSimple
                        label="Publikationstyp"
                        name={`typ`}
                        items={[
                            'bitte auswählen',
                            'Monographie',
                            "Monographie mit Autor als 'Hg.'",
                            "Monographie mit zusätzl. 'hg. von'",
                            'Edition nach Abkürzung',
                            'Editionsreihe nach Abkürzung',
                            'Edition nach Bearbeiter',
                            'Zeitschrift(artikel)',
                            "Zeitschriftartikel mit 'hg. von'",
                            'Sammelbandbeitrag',
                            'Sammelband',
                            'Lexikonartikel',
                            'Lexikon nach Abkürzung (Einzelband)',
							'Lexikon nach Abkürzung (gesamt)',
                            'Lexikon nach Hg (nicht Bearbeiter)',
                        ]}
                        default_value="bitte auswählen"
                    />
                </Area>
                {!(newObject || ausw || lexKuerz || lexGesamt || sammel || lexNurHg) && (
                    <List label="Autoren/Bearbeiter" name={`autor`} simple>
                        {index => (
                            <>
                                <Area noStyle>
                                    <Search
                                        label={
                                            sammelBtrg || zeitschrift
                                                ? 'Autor (optional)'
                                                : editionBearb || editionAbk
                                                ? 'Bearbeiter'
                                                : 'Autor'
                                        }
                                        name={`autor[${index}]`}
                                        api="Zitiername"
                                        searchParam="nichtAuffuehren.literatur.v=false"
                                    />
                                </Area>
                            </>
                        )}
                    </List>
                )}
                {!(newObject || ausw) && (
                    <Area>
                        <Input
                            label={
                                zeitschrift || zeitschrift_hg
                                    ? 'Titel des Artikels (optional)'
                                    : lexArt
                                    ? 'Titel des Artikels'
                                    : sammelBtrg
                                    ? 'Titel des Beitrags'
                                    : sammel
                                    ? 'Titel des Sammelbands'
                                    : 'Titel'
                            }
                            name="titel"
                            extraLarge
                            placeholder="vollständiger Titel. Untertitel...(nicht Teilbandtitel oder Reihentitel!) ohne Punkt abschließen"
                        />
                    </Area>
                )}
                {(sammel ||
                    sammelBtrg ||
                    lexNurHg ||
                    lexKuerz ||
					lexGesamt ||
                    lexArt ||
                    editionsreihe ||
                    editionAbk ||
                    mono_plushg) && (
                    <List label="Herausgeber" name={`herausgeber`} simple>
                        {index => (
                            <>
                                <Area noStyle>
                                    <Search
                                        label={
                                            lexKuerz || lexGesamt || lexArt
                                                ? 'Herausgeber (optional)'
                                                : 'Herausgeber'
                                        }
                                        name={`herausgeber[${index}]`}
                                        api="Zitiername"
                                        searchParam="nichtAuffuehren.literatur.v=false"
                                    />
                                </Area>
                            </>
                        )}
                    </List>
                )}
                <Row>
                    {!(
                        newObject ||
                        ausw ||
                        mono ||
                        mono_hg ||
                        mono_plushg ||
                        editionBearb ||
                        editionAbk ||
                        editionsreihe ||
                        lexNurHg ||
                        lexKuerz ||
						lexGesamt ||
                        sammel
                    ) && (
                        <Area>
                            <Input
                                label={
                                    zeitschrift || zeitschrift_hg
                                        ? 'Zeitschrift'
                                        : lexArt
                                        ? 'Lexikon'
                                        : sammelBtrg
                                        ? 'Sammelband'
                                        : 'in'
                                }
                                name="in"
                                extraLarge
                                placeholder={
                                    !(mono || mono_hg || mono_plushg) && zeitschrift
                                        ? 'Titel der Zeitschrift...'
                                        : sammelBtrg
                                        ? 'Titel des Sammelbands...(nicht der Reihe!)'
                                        : lexArt
                                        ? 'Titel des Lexikons...'
                                        : ''
                                }
                            />
                        </Area>
                    )}
                    {!(newObject || ausw) && (
                        <Area>
                            <Input
                                label={
                                    zeitschrift ? 'Nr.' : editionsreihe ? 'Bände' : lexGesamt ? 'Bände' : 'Nr. (optional)'
                                }
                                name="nummer"
                                small
                                placeholder={
                                    mono || mono_hg || mono_plushg || sammel || sammelBtrg
                                        ? 'Zahl der Bandnummer'
                                        : zeitschrift
                                        ? 'Nr. der Zeitschrift'
                                        : ''
                                }
                            />
                        </Area>
                    )}
                </Row>
                {!(newObject || ausw || zeitschrift || zeitschrift_hg) && (
                    <Area>
                        <Input
                            label="Teilbandtitel (optional)"
                            placeholder="(nicht Reihentitel!)"
                            name="bandtitel"
                            extraLarge
                        />
                    </Area>
                )}
                <Row>
                    {!(newObject || ausw || zeitschrift || zeitschrift_hg) && (
                        <List label="Erscheinungsort" name={`ort`} simple>
                            {index => (
                                <Area noStyle>
                                    <Search
                                        label="Erscheinungsort"
                                        name={`ort[${index}]`}
                                        api="Ort"
                                        extraParam="amtlich"
                                        small
                                    />
                                </Area>
                            )}
                        </List>
                    )}
                    {!(newObject || ausw || zeitschrift || zeitschrift_hg) && (
                        <Area>
                            <Input label="Auflage" name="auflage" placeholder="Ziffer" small />
                        </Area>
                    )}
                    {!(newObject || ausw) && (
                        <Area>
                            <Zeit label="Jahr" name="jahr" onlyYear />
                        </Area>
                    )}
                </Row>
                {!(
                    newObject ||
                    ausw ||
                    mono ||
                    lexNurHg ||
                    lexKuerz ||
					lexGesamt ||
                    editionBearb ||
                    editionAbk ||
                    sammel
                ) && (
                    <Area>
                        <Input
                            label="Seiten"
                            name="seiten"
                            placeholder="S. "
                            helperForUserInput="S. "
                            small
                        />
                    </Area>
                )}
                {!(newObject || ausw || zeitschrift) && (
                    <Area>
                        <Titel>Reihe</Titel>
                        <Row>
                            <Input label="Titel" name="reihe.titel" large />
                            <VerticalLine />
                            <Input label="Nummer" name="reihe.nummer" small />
                        </Row>
                    </Area>
                )}
                {!(newObject || ausw) && (
                    <Area>
                        <Input label="Gesamtaufnahme" name="gesamtaufnahme" extraLarge />
						{(mono) && (
                            <>
                            {(get('titel.v', item)) && (
                                <>
                                    <>Vorschlag:</>
                                    <Area>
                                        {(get('autor.0.v.shortAlt.v', item)) && (<>{get('autor.0.v.shortAlt.v', item)}</>)}
                                        {(get('autor.1.v.shortAlt.v', item)) && (<>/{get('autor.1.v.shortAlt.v', item)}</>)}
                                        {(get('autor.2.v.shortAlt.v', item)) && (
                                            <>
                                                {(get('autor.3.v.shortAlt.v', item)) ? (
                                                        <>/u. a.</>
                                                ) : (
                                                        <>/{get('autor.2.v.shortAlt.v', item)}</>
                                                )}
                                            </>
                                        )}
                                        {(get('autor.0.v.shortAlt.v', item)) && (<>: </>)}
                                        <>{get('titel.v', item)}</>
                                        {(get('nummer.v', item)) && (<>, Band {get('nummer.v', item)}</>)}
                                        {(get('bandtitel.v', item)) && (<>, {get('bandtitel.v', item)}</>)}
                                        {(get('reihe.titel.v', item)) && (<><> (</><>{get('reihe.titel.v', item)}</><> {get('reihe.nummer.v', item)}</><>)</></>)}
                                        <>, </>
                                        {(get('ort.0.v.short.v', item)) && (<>{get('ort.0.v.short.v', item)}</>)}
                                        {(get('ort.1.v.short.v', item)) && (<>/{get('ort.1.v.short.v', item)}</>)}
                                        {(get('ort.2.v.short.v', item)) && (
                                                <>
                                                    {(get('ort.3.v.short.v', item)) ? (
                                                            <>/u. a.</>
                                                    ) : (
                                                            <>/{get('ort.2.v.short.v', item)}</>
                                                    )}
                                                </>
                                        )}
                                        {(get('ort.0.v.short.v', item)) && (<> </>)}
                                        {(get('auflage.v', item)) === '1' && (<>¹</>)}
                                        {(get('auflage.v', item)) === '2' && (<>²</>)}
                                        {(get('auflage.v', item)) === '3' && (<>³</>)}
                                        {(get('auflage.v', item)) === '4' && (<>⁴</>)}
                                        {(get('auflage.v', item)) === '5' && (<>⁵</>)}
                                        {(get('auflage.v', item)) === '6' && (<>⁶</>)}
                                        {(get('auflage.v', item)) === '7' && (<>⁷</>)}
                                        {(get('auflage.v', item)) === '8' && (<>⁸</>)}
                                        {(get('auflage.v', item)) === '9' && (<>⁹</>)}
                                        <>{get('jahr.am.j.v', item)}</>
                                        {(get('jahr.bis.j.v', item)) && (<>-{get('jahr.bis.j.v', item)}</>)}
                                        <>.</>
                                    </Area>
                                </>
                            )}
                            </>
                        )}
                        {(mono_hg) && (
                            <>
                            {(get('titel.v', item)) && (
                                <>
                                    <>Vorschlag:</>
                                    <Area>
                                        {(get('autor.0.v.shortAlt.v', item)) && (<>{get('autor.0.v.shortAlt.v', item)}</>)}
                                        {(get('autor.1.v.shortAlt.v', item)) && (<>/{get('autor.1.v.shortAlt.v', item)}</>)}
                                        {(get('autor.2.v.shortAlt.v', item)) && (
                                            <>
                                                {(get('autor.3.v.shortAlt.v', item)) ? (
                                                        <>/u. a.</>
                                                ) : (
                                                        <>/{get('autor.2.v.shortAlt.v', item)}</>
                                                )}
                                            </>
                                        )}
                                        {(get('autor.0.v.shortAlt.v', item)) && (<> (Hg.): </>)}
                                        <>{get('titel.v', item)}</>
                                        {(get('nummer.v', item)) && (<>, Band {get('nummer.v', item)}</>)}
                                        {(get('bandtitel.v', item)) && (<>, {get('bandtitel.v', item)}</>)}
                                        {(get('reihe.titel.v', item)) && (<><> (</><>{get('reihe.titel.v', item)}</><> {get('reihe.nummer.v', item)}</><>)</></>)}
                                        <>, </>
                                        {(get('ort.0.v.short.v', item)) && (<>{get('ort.0.v.short.v', item)}</>)}
                                        {(get('ort.1.v.short.v', item)) && (<>/{get('ort.1.v.short.v', item)}</>)}
                                        {(get('ort.2.v.short.v', item)) && (
                                                <>
                                                    {(get('ort.3.v.short.v', item)) ? (
                                                            <>/u. a.</>
                                                    ) : (
                                                            <>/{get('ort.2.v.short.v', item)}</>
                                                    )}
                                                </>
                                        )}
                                        {(get('ort.0.v.short.v', item)) && (<> </>)}
                                        {(get('auflage.v', item)) === '1' && (<>¹</>)}
                                        {(get('auflage.v', item)) === '2' && (<>²</>)}
                                        {(get('auflage.v', item)) === '3' && (<>³</>)}
                                        {(get('auflage.v', item)) === '4' && (<>⁴</>)}
                                        {(get('auflage.v', item)) === '5' && (<>⁵</>)}
                                        {(get('auflage.v', item)) === '6' && (<>⁶</>)}
                                        {(get('auflage.v', item)) === '7' && (<>⁷</>)}
                                        {(get('auflage.v', item)) === '8' && (<>⁸</>)}
                                        {(get('auflage.v', item)) === '9' && (<>⁹</>)}
                                        <>{get('jahr.am.j.v', item)}</>
                                        {(get('jahr.bis.j.v', item)) && (<>-{get('jahr.bis.j.v', item)}</>)}
                                        <>.</>
                                    </Area>
                                </>
                            )}
                            </>
                        )}
                        {(mono_plushg) && (
                            <>
                            {(get('titel.v', item)) && (
                                <>
                                    <>Vorschlag:</>
                                    <Area>
                                        {(get('autor.0.v.shortAlt.v', item)) && (<>{get('autor.0.v.shortAlt.v', item)}</>)}
                                        {(get('autor.1.v.shortAlt.v', item)) && (<>/{get('autor.1.v.shortAlt.v', item)}</>)}
                                        {(get('autor.2.v.shortAlt.v', item)) && (
                                            <>
                                                {(get('autor.3.v.shortAlt.v', item)) ? (
                                                        <>/u. a.</>
                                                ) : (
                                                        <>/{get('autor.2.v.shortAlt.v', item)}</>
                                                )}
                                            </>
                                        )}
                                        {(get('autor.0.v.shortAlt.v', item)) && (<>: </>)}
                                        <>{get('titel.v', item)}</>
                                        {(get('nummer.v', item)) && (<>, Band {get('nummer.v', item)}</>)}
                                        {(get('bandtitel.v', item)) && (<>, {get('bandtitel.v', item)}</>)}
                                        {(get('herausgeber.0.v.short.v', item)) && (<>, hg. von {get('herausgeber.0.v.short.v', item)}</>)}
                                        {(get('herausgeber.1.v.short.v', item)) && (<>/{get('herausgeber.1.v.short.v', item)}</>)}
                                        {(get('herausgeber.2.v.short.v', item)) && (
                                                <>
                                                    {(get('herausgeber.3.v.short.v', item)) ? (
                                                            <>/u. a.</>
                                                    ) : (
                                                            <>/{get('herausgeber.2.v.short.v', item)}</>
                                                    )}
                                                </>
                                        )}
                                        {(get('reihe.titel.v', item)) && (<><> (</><>{get('reihe.titel.v', item)}</><> {get('reihe.nummer.v', item)}</><>)</></>)}
                                        <>, </>
                                        {(get('ort.0.v.short.v', item)) && (<>{get('ort.0.v.short.v', item)}</>)}
                                        {(get('ort.1.v.short.v', item)) && (<>/{get('ort.1.v.short.v', item)}</>)}
                                        {(get('ort.2.v.short.v', item)) && (
                                                <>
                                                    {(get('ort.3.v.short.v', item)) ? (
                                                            <>/u. a.</>
                                                    ) : (
                                                            <>/{get('ort.2.v.short.v', item)}</>
                                                    )}
                                                </>
                                        )}
                                        {(get('ort.0.v.short.v', item)) && (<> </>)}
                                        {(get('auflage.v', item)) === '1' && (<>¹</>)}
                                        {(get('auflage.v', item)) === '2' && (<>²</>)}
                                        {(get('auflage.v', item)) === '3' && (<>³</>)}
                                        {(get('auflage.v', item)) === '4' && (<>⁴</>)}
                                        {(get('auflage.v', item)) === '5' && (<>⁵</>)}
                                        {(get('auflage.v', item)) === '6' && (<>⁶</>)}
                                        {(get('auflage.v', item)) === '7' && (<>⁷</>)}
                                        {(get('auflage.v', item)) === '8' && (<>⁸</>)}
                                        {(get('auflage.v', item)) === '9' && (<>⁹</>)}
                                        <>{get('jahr.am.j.v', item)}</>
                                        {(get('jahr.bis.j.v', item)) && (<>-{get('jahr.bis.j.v', item)}</>)}
                                        <>.</>
                                    </Area>
                                </>
                            )}
                            </>
                        )}
                        {(editionBearb) && (
                            <>
                            {(get('titel.v', item)) && (
                                <>
                                    <>Vorschlag:</>
                                    <Area>
                                        {(get('autor.0.v.shortAlt.v', item)) && (<>{get('autor.0.v.shortAlt.v', item)}</>)}
                                        {(get('autor.1.v.shortAlt.v', item)) && (<>/{get('autor.1.v.shortAlt.v', item)}</>)}
                                        {(get('autor.2.v.shortAlt.v', item)) && (
                                            <>
                                                {(get('autor.3.v.shortAlt.v', item)) ? (
                                                        <>/u. a.</>
                                                ) : (
                                                        <>/{get('autor.2.v.shortAlt.v', item)}</>
                                                )}
                                            </>
                                        )}
                                        {(get('autor.0.v.shortAlt.v', item)) && (<>: </>)}
                                        <>{get('titel.v', item)}</>
                                        {(get('nummer.v', item)) && (<>, Band {get('nummer.v', item)}</>)}
                                        {(get('bandtitel.v', item)) && (<>, {get('bandtitel.v', item)}</>)}                                       
                                        {(get('reihe.titel.v', item)) && (<><> (</><>{get('reihe.titel.v', item)}</><> {get('reihe.nummer.v', item)}</><>)</></>)}
                                        <>, </>
                                        {(get('ort.0.v.short.v', item)) && (<>{get('ort.0.v.short.v', item)}</>)}
                                        {(get('ort.1.v.short.v', item)) && (<>/{get('ort.1.v.short.v', item)}</>)}
                                        {(get('ort.2.v.short.v', item)) && (
                                                <>
                                                    {(get('ort.3.v.short.v', item)) ? (
                                                            <>/u. a.</>
                                                    ) : (
                                                            <>/{get('ort.2.v.short.v', item)}</>
                                                    )}
                                                </>
                                        )}
                                        {(get('ort.0.v.short.v', item)) && (<> </>)}
                                        {(get('auflage.v', item)) === '1' && (<>¹</>)}
                                        {(get('auflage.v', item)) === '2' && (<>²</>)}
                                        {(get('auflage.v', item)) === '3' && (<>³</>)}
                                        {(get('auflage.v', item)) === '4' && (<>⁴</>)}
                                        {(get('auflage.v', item)) === '5' && (<>⁵</>)}
                                        {(get('auflage.v', item)) === '6' && (<>⁶</>)}
                                        {(get('auflage.v', item)) === '7' && (<>⁷</>)}
                                        {(get('auflage.v', item)) === '8' && (<>⁸</>)}
                                        {(get('auflage.v', item)) === '9' && (<>⁹</>)}
                                        <>{get('jahr.am.j.v', item)}</>
                                        {(get('jahr.bis.j.v', item)) && (<>-{get('jahr.bis.j.v', item)}</>)}
                                        <>.</>
                                    </Area>
                                </>
                            )}
                            </>
                        )}
                        {(editionAbk) && (
                            <>
                            {(get('titel.v', item)) && (
                                <>
                                    <>Vorschlag:</>
                                    <Area>
                                        <>{get('short.v', item)}: </>
                                        <>{get('titel.v', item)}</>
                                        {(get('nummer.v', item)) && (<>, Band {get('nummer.v', item)}</>)}
                                        {(get('bandtitel.v', item)) && (<>, {get('bandtitel.v', item)}</>)}
                                        {(get('autor.0.v.short.v', item)) && (<>, bearb. von {get('autor.0.v.short.v', item)}</>)}
                                        {(get('autor.1.v.short.v', item)) && (<>/{get('autor.1.v.short.v', item)}</>)}
                                        {(get('autor.2.v.short.v', item)) && (
                                            <>
                                                {(get('autor.3.v.short.v', item)) ? (
                                                        <>/u. a.</>
                                                ) : (
                                                        <>/{get('autor.2.v.short.v', item)}</>
                                                )}
                                            </>
                                        )}
                                        {(get('herausgeber.0.v.short.v', item)) && (<>, hg. von {get('herausgeber.0.v.short.v', item)}</>)}
                                        {(get('herausgeber.1.v.short.v', item)) && (<>/{get('herausgeber.1.v.short.v', item)}</>)}
                                        {(get('herausgeber.2.v.short.v', item)) && (
                                                <>
                                                    {(get('herausgeber.3.v.short.v', item)) ? (
                                                            <>/u. a.</>
                                                    ) : (
                                                            <>/{get('herausgeber.2.v.short.v', item)}</>
                                                    )}
                                                </>
                                        )}
                                        {(get('reihe.titel.v', item)) && (<><> (</><>{get('reihe.titel.v', item)}</><> {get('reihe.nummer.v', item)}</><>)</></>)}
                                        <>, </>
                                        {(get('ort.0.v.short.v', item)) && (<>{get('ort.0.v.short.v', item)}</>)}
                                        {(get('ort.1.v.short.v', item)) && (<>/{get('ort.1.v.short.v', item)}</>)}
                                        {(get('ort.2.v.short.v', item)) && (
                                                <>
                                                    {(get('ort.3.v.short.v', item)) ? (
                                                            <>/u. a.</>
                                                    ) : (
                                                            <>/{get('ort.2.v.short.v', item)}</>
                                                    )}
                                                </>
                                        )}
                                        {(get('ort.0.v.short.v', item)) && (<> </>)}
                                        {(get('auflage.v', item)) === '1' && (<>¹</>)}
                                        {(get('auflage.v', item)) === '2' && (<>²</>)}
                                        {(get('auflage.v', item)) === '3' && (<>³</>)}
                                        {(get('auflage.v', item)) === '4' && (<>⁴</>)}
                                        {(get('auflage.v', item)) === '5' && (<>⁵</>)}
                                        {(get('auflage.v', item)) === '6' && (<>⁶</>)}
                                        {(get('auflage.v', item)) === '7' && (<>⁷</>)}
                                        {(get('auflage.v', item)) === '8' && (<>⁸</>)}
                                        {(get('auflage.v', item)) === '9' && (<>⁹</>)}
                                        <>{get('jahr.am.j.v', item)}</>
                                        {(get('jahr.bis.j.v', item)) && (<>-{get('jahr.bis.j.v', item)}</>)}
                                        <>.</>
                                    </Area>
                                </>
                            )}
                            </>
                        )}
                        {(editionsreihe) && (
                                <>  
                                {(get('titel.v', item)) && (
                                    <>
                                        <>Vorschlag:</>
                                        <Area>
                                            <>{get('short.v', item)}: </>
                                            <>{get('titel.v', item)}</>
                                            {(get('nummer.v', item)) && (<>, {get('nummer.v', item)} Bände</>)}
                                            {(get('bandtitel.v', item)) && (<>, {get('bandtitel.v', item)}</>)}
                                            {(get('herausgeber.0.v.short.v', item)) && (<>, hg. von {get('herausgeber.0.v.short.v', item)}</>)}
                                            {(get('herausgeber.1.v.short.v', item)) && (<>/{get('herausgeber.1.v.short.v', item)}</>)}
                                            {(get('herausgeber.2.v.short.v', item)) && (
                                                    <>
                                                        {(get('herausgeber.3.v.short.v', item)) ? (
                                                                <>/u. a.</>
                                                        ) : (
                                                                <>/{get('herausgeber.2.v.short.v', item)}</>
                                                        )}
                                                    </>
                                            )}
                                            {(get('reihe.titel.v', item)) && (<><> (</><>{get('reihe.titel.v', item)}</><> {get('reihe.nummer.v', item)}</><>)</></>)}
                                            <>, </>
                                            {(get('ort.0.v.short.v', item)) && (<>{get('ort.0.v.short.v', item)}</>)}
                                            {(get('ort.1.v.short.v', item)) && (<>/{get('ort.1.v.short.v', item)}</>)}
                                            {(get('ort.2.v.short.v', item)) && (
                                                    <>
                                                        {(get('ort.3.v.short.v', item)) ? (
                                                                <>/u. a.</>
                                                        ) : (
                                                                <>/{get('ort.2.v.short.v', item)}</>
                                                        )}
                                                    </>
                                            )}
                                            {(get('ort.0.v.short.v', item)) && (<> </>)}
                                            {(get('auflage.v', item)) === '1' && (<>¹</>)}
                                            {(get('auflage.v', item)) === '2' && (<>²</>)}
                                            {(get('auflage.v', item)) === '3' && (<>³</>)}
                                            {(get('auflage.v', item)) === '4' && (<>⁴</>)}
                                            {(get('auflage.v', item)) === '5' && (<>⁵</>)}
                                            {(get('auflage.v', item)) === '6' && (<>⁶</>)}
                                            {(get('auflage.v', item)) === '7' && (<>⁷</>)}
                                            {(get('auflage.v', item)) === '8' && (<>⁸</>)}
                                            {(get('auflage.v', item)) === '9' && (<>⁹</>)}
                                            <>{get('jahr.am.j.v', item)}</>
                                            {(get('jahr.bis.j.v', item)) && (<>-{get('jahr.bis.j.v', item)}</>)}
                                            <>.</>
                                        </Area>
                                    </>
                                )}
                                </>
                            )}
                        {(zeitschrift) && (
                            <>
                            {(get('in.v', item)) && (
                                <>
                                    <>Vorschlag:</>
                                    <Area>
                                        <>{get('autor.0.v.shortAlt.v', item)}</>
                                        {(get('autor.1.v.shortAlt.v', item)) && (<>/{get('autor.1.v.shortAlt.v', item)}</>)}
                                        {(get('autor.2.v.shortAlt.v', item)) && (
                                            <>
                                                {(get('autor.3.v.shortAlt.v', item)) ? (
                                                        <>/u. a.</>
                                                ) : (
                                                        <>/{get('autor.2.v.shortAlt.v', item)}</>
                                                )}
                                            </>
                                        )}
                                        {(get('autor.0.v.shortAlt.v', item)) && (<>: </>)}
                                        {(get('titel.v', item)) && (<>{get('titel.v', item)}, in: </>)}
                                        <>{get('in.v', item)}</>
                                        {(get('nummer.v', item)) && (<> {get('nummer.v', item)}</>)}
                                        {(get('jahr.am.j.v', item)) && (
                                                <>
                                                    {(get('jahr.bis.j.v', item)) ? (
                                                        <> ({get('jahr.am.j.v', item)}-{get('jahr.bis.j.v', item)})</>
                                                        ) : (
                                                        <> ({get('jahr.am.j.v', item)})</>
                                                        )
                                                    }
                                                </>
                                        )}
                                        {(get('seiten.v', item)) && (<>, {get('seiten.v', item)}</>)}
                                        <>.</>
                                    </Area>
                                </>
                            )}
                            </>
                        )}
                        {(zeitschrift_hg) && (
                            <>
                            {(get('in.v', item)) && (
                                <>
                                    <>Vorschlag:</>
                                    <Area>
                                        {(get('titel.v', item)) && (<>{get('titel.v', item)}, hg. von </>)}
                                        <>{get('autor.0.v.short.v', item)}</>
                                        {(get('autor.1.v.short.v', item)) && (<>/{get('autor.1.v.short.v', item)}</>)}
                                        {(get('autor.2.v.short.v', item)) && (
                                            <>
                                                {(get('autor.3.v.short.v', item)) ? (
                                                        <>/u. a.</>
                                                ) : (
                                                        <>/{get('autor.2.v.short.v', item)}</>
                                                )}
                                            </>
                                        )}
                                        <>, in: </>
                                        <>{get('in.v', item)}</>
                                        {(get('nummer.v', item)) && (<> {get('nummer.v', item)}</>)}
                                        {(get('jahr.am.j.v', item)) && (<>, {get('jahr.am.j.v', item)}</>)}
                                        {(get('jahr.bis.j.v', item)) && (<>-{get('jahr.bis.j.v', item)}</>)}
                                        {(get('seiten.v', item)) && (<>, {get('seiten.v', item)}</>)}
                                        <>.</>
                                    </Area>
                                </>
                            )}
                            </>
                        )}
                        {(sammelBtrg) && (
                            <>
                            {(get('in.v', item)) && (
                                <>
                                    <>Vorschlag:</>
                                    <Area>
                                        {(get('titel.v', item)) && (
                                                <>
                                                    <>{get('autor.0.v.shortAlt.v', item)}</>
                                                    {(get('autor.1.v.shortAlt.v', item)) && (<>/{get('autor.1.v.shortAlt.v', item)}</>)}
                                                    {(get('autor.2.v.shortAlt.v', item)) && (
                                                        <>
                                                            {(get('autor.3.v.shortAlt.v', item)) ? (
                                                                    <>/u. a.</>
                                                            ) : (
                                                                    <>/{get('autor.2.v.shortAlt.v', item)}</>
                                                            )}
                                                        </>
                                                    )}
                                                    {(get('autor.0.v.short.v', item)) && (<>: </>)}
                                                    <>{get('titel.v', item)}, in: </>
                                                </>
                                        )}
                                        {(get('titel.v', item)) ? (
                                            <>
                                                {(get('herausgeber.0.v.short.v', item)) && (<>{get('herausgeber.0.v.short.v', item)}</>)}
                                                {(get('herausgeber.1.v.short.v', item)) && (<>/{get('herausgeber.1.v.short.v', item)}</>)}
                                                {(get('herausgeber.2.v.short.v', item)) && (
                                                        <>
                                                            {(get('herausgeber.3.v.short.v', item)) ? (
                                                                    <>/u. a.</>
                                                            ) : (
                                                                    <>/{get('herausgeber.2.v.short.v', item)}</>
                                                            )}
                                                        </>
                                                )}
                                                {(get('herausgeber.0.v.short.v', item)) && (<> (Hg.): </>)}
                                            </>
                                        ) : (
                                            <>
                                                {(get('herausgeber.0.v.shortAlt.v', item)) && (<>{get('herausgeber.0.v.shortAlt.v', item)}</>)}
                                                {(get('herausgeber.1.v.shortAlt.v', item)) && (<>/{get('herausgeber.1.v.shortAlt.v', item)}</>)}
                                                {(get('herausgeber.2.v.shortAlt.v', item)) && (
                                                        <>
                                                            {(get('herausgeber.3.v.shortAlt.v', item)) ? (
                                                                    <>/u. a.</>
                                                            ) : (
                                                                    <>/{get('herausgeber.2.v.shortAlt.v', item)}</>
                                                            )}
                                                        </>
                                                )}
                                                {(get('herausgeber.0.v.shortAlt.v', item)) && (<> (Hg.): </>)}
                                            </>    
                                        )}
                                        <>{get('in.v', item)}</>
                                        {(get('nummer.v', item)) && (<>, Band {get('nummer.v', item)}</>)}
                                        {(get('bandtitel.v', item)) && (<>, {get('bandtitel.v', item)}</>)}
                                        {(get('reihe.titel.v', item)) && (<><> (</><>{get('reihe.titel.v', item)}</><> {get('reihe.nummer.v', item)}</><>)</></>)}
                                        <>, </>
                                        {(get('ort.0.v.short.v', item)) && (<>{get('ort.0.v.short.v', item)}</>)}
                                        {(get('ort.1.v.short.v', item)) && (<>/{get('ort.1.v.short.v', item)}</>)}
                                        {(get('ort.2.v.short.v', item)) && (
                                                <>
                                                    {(get('ort.3.v.short.v', item)) ? (
                                                            <>/u. a.</>
                                                    ) : (
                                                            <>/{get('ort.2.v.short.v', item)}</>
                                                    )}
                                                </>
                                        )}
                                        {(get('ort.0.v.short.v', item)) && (<> </>)}
                                        {(get('auflage.v', item)) === '1' && (<>¹</>)}
                                        {(get('auflage.v', item)) === '2' && (<>²</>)}
                                        {(get('auflage.v', item)) === '3' && (<>³</>)}
                                        {(get('auflage.v', item)) === '4' && (<>⁴</>)}
                                        {(get('auflage.v', item)) === '5' && (<>⁵</>)}
                                        {(get('auflage.v', item)) === '6' && (<>⁶</>)}
                                        {(get('auflage.v', item)) === '7' && (<>⁷</>)}
                                        {(get('auflage.v', item)) === '8' && (<>⁸</>)}
                                        {(get('auflage.v', item)) === '9' && (<>⁹</>)}
                                        <>{get('jahr.am.j.v', item)}</>
                                        {(get('jahr.bis.j.v', item)) && (<>-{get('jahr.bis.j.v', item)}</>)}
                                        {(get('seiten.v', item)) && (<>, {get('seiten.v', item)}</>)}
                                        <>.</>
                                    </Area>
                                </>
                            )}
                            </>
                        )}
                        {(lexArt) && (
                            <>
                            {(get('titel.v', item)) && (
                                <>
                                    <>Vorschlag:</>
                                    <Area>
                                        <>{get('autor.0.v.shortAlt.v', item)}</>
                                        {(get('autor.1.v.shortAlt.v', item)) && (<>/{get('autor.1.v.shortAlt.v', item)}</>)}
                                        {(get('autor.2.v.shortAlt.v', item)) && (
                                            <>
                                                {(get('autor.3.v.shortAlt.v', item)) ? (
                                                        <>/u. a.</>
                                                ) : (
                                                        <>/{get('autor.2.v.shortAlt.v', item)}</>
                                                )}
                                            </>
                                        )}
                                        {(get('autor.0.v.shortAlt.v', item)) && (<>: </>)}
                                        {(get('titel.v', item)) && (<>{get('titel.v', item)}, in: </>)}
                                        <>{get('in.v', item)}</>
                                        {(get('nummer.v', item)) && (<>, Band {get('nummer.v', item)}</>)}
                                        {(get('bandtitel.v', item)) && (<>, {get('bandtitel.v', item)}</>)}
                                        {(get('reihe.titel.v', item)) && (<><> (</><>{get('reihe.titel.v', item)}</><> {get('reihe.nummer.v', item)}</><>)</></>)}
                                        <>, </>
                                        {(get('ort.0.v.short.v', item)) && (<>{get('ort.0.v.short.v', item)}</>)}
                                        {(get('ort.1.v.short.v', item)) && (<>/{get('ort.1.v.short.v', item)}</>)}
                                        {(get('ort.2.v.short.v', item)) && (
                                                <>
                                                    {(get('ort.3.v.short.v', item)) ? (
                                                            <>/u. a.</>
                                                    ) : (
                                                            <>/{get('ort.2.v.short.v', item)}</>
                                                    )}
                                                </>
                                        )}
                                        {(get('ort.0.v.short.v', item)) && (<> </>)}
                                        {(get('auflage.v', item)) === '1' && (<>¹</>)}
                                        {(get('auflage.v', item)) === '2' && (<>²</>)}
                                        {(get('auflage.v', item)) === '3' && (<>³</>)}
                                        {(get('auflage.v', item)) === '4' && (<>⁴</>)}
                                        {(get('auflage.v', item)) === '5' && (<>⁵</>)}
                                        {(get('auflage.v', item)) === '6' && (<>⁶</>)}
                                        {(get('auflage.v', item)) === '7' && (<>⁷</>)}
                                        {(get('auflage.v', item)) === '8' && (<>⁸</>)}
                                        {(get('auflage.v', item)) === '9' && (<>⁹</>)}
                                        <>{get('jahr.am.j.v', item)}</>
                                        {(get('jahr.bis.j.v', item)) && (<>-{get('jahr.bis.j.v', item)}</>)}
                                        <>,</>
                                        <> {get('seiten.v', item)}</>
                                        <>.</>
                                    </Area>
                                </>
                            )}
                            </>
                        )}
                        {(lexNurHg || sammel) && (
                            <>
                            {(get('titel.v', item)) && (
                                <>
                                <>Vorschlag:</>
                                <Area>
                                    {(get('herausgeber.0.v.shortAlt.v', item)) && (<>{get('herausgeber.0.v.shortAlt.v', item)}</>)}
                                    {(get('herausgeber.1.v.shortAlt.v', item)) && (<>/{get('herausgeber.1.v.shortAlt.v', item)}</>)}
                                    {(get('herausgeber.2.v.shortAlt.v', item)) && (
                                            <>
                                                {(get('herausgeber.3.v.shortAlt.v', item)) ? (
                                                        <>/u. a.</>
                                                ) : (
                                                        <>/{get('herausgeber.2.v.shortAlt.v', item)}</>
                                                )}
                                            </>
                                    )}
                                    {(get('herausgeber.0.v.shortAlt.v', item)) && (<> (Hg.): </>)}
                                    <>{get('titel.v', item)}</>                                    
                                    {(get('nummer.v', item)) && (<>, Band {get('nummer.v', item)}</>)}
                                    {(get('bandtitel.v', item)) && (<>, {get('bandtitel.v', item)}</>)}
                                    {(get('reihe.titel.v', item)) && (<><> (</><>{get('reihe.titel.v', item)}</><> {get('reihe.nummer.v', item)}</><>)</></>)}
                                    <>, </>
                                    {(get('ort.0.v.short.v', item)) && (<>{get('ort.0.v.short.v', item)}</>)}
                                    {(get('ort.1.v.short.v', item)) && (<>/{get('ort.1.v.short.v', item)}</>)}
                                    {(get('ort.2.v.short.v', item)) && (
                                            <>
                                                {(get('ort.3.v.short.v', item)) ? (
                                                        <>/u. a.</>
                                                ) : (
                                                        <>/{get('ort.2.v.short.v', item)}</>
                                                )}
                                            </>
                                    )}
                                    {(get('ort.0.v.short.v', item)) && (<> </>)}
                                    {(get('auflage.v', item)) === '1' && (<>¹</>)}
                                    {(get('auflage.v', item)) === '2' && (<>²</>)}
                                    {(get('auflage.v', item)) === '3' && (<>³</>)}
                                    {(get('auflage.v', item)) === '4' && (<>⁴</>)}
                                    {(get('auflage.v', item)) === '5' && (<>⁵</>)}
                                    {(get('auflage.v', item)) === '6' && (<>⁶</>)}
                                    {(get('auflage.v', item)) === '7' && (<>⁷</>)}
                                    {(get('auflage.v', item)) === '8' && (<>⁸</>)}
                                    {(get('auflage.v', item)) === '9' && (<>⁹</>)}
                                    <>{get('jahr.am.j.v', item)}</>
                                    {(get('jahr.bis.j.v', item)) && (<>-{get('jahr.bis.j.v', item)}</>)}
                                    <>.</>
                                </Area>
                                </>
                            )}
                            </>
                        )}
                        {(lexKuerz) && (
                                <>
                                {(get('titel.v', item)) && (
                                    <>
                                    <>Vorschlag:</>
                                    <Area>
                                        <>{get('short.v', item)}: </>
                                        {(get('herausgeber.0.v.shortAlt.v', item)) && (<>{get('herausgeber.0.v.shortAlt.v', item)}</>)}
                                        {(get('herausgeber.1.v.shortAlt.v', item)) && (<>/{get('herausgeber.1.v.shortAlt.v', item)}</>)}
                                        {(get('herausgeber.2.v.shortAlt.v', item)) && (
                                                <>
                                                    {(get('herausgeber.3.v.shortAlt.v', item)) ? (
                                                            <>/u. a.</>
                                                    ) : (
                                                            <>/{get('herausgeber.2.v.shortAlt.v', item)}</>
                                                    )}
                                                </>
                                        )}
                                        {(get('herausgeber.0.v.shortAlt.v', item)) && (<> (Hg.): </>)}
                                        <>{get('titel.v', item)}</>                                    
                                        {(get('nummer.v', item)) && (<>, Band {get('nummer.v', item)}</>)}
                                        {(get('bandtitel.v', item)) && (<>, {get('bandtitel.v', item)}</>)}
                                        {(get('reihe.titel.v', item)) && (<><> (</><>{get('reihe.titel.v', item)}</><> {get('reihe.nummer.v', item)}</><>)</></>)}
                                        <>, </>
                                        {(get('ort.0.v.short.v', item)) && (<>{get('ort.0.v.short.v', item)}</>)}
                                        {(get('ort.1.v.short.v', item)) && (<>/{get('ort.1.v.short.v', item)}</>)}
                                        {(get('ort.2.v.short.v', item)) && (
                                                <>
                                                    {(get('ort.3.v.short.v', item)) ? (
                                                            <>/u. a.</>
                                                    ) : (
                                                            <>/{get('ort.2.v.short.v', item)}</>
                                                    )}
                                                </>
                                        )}
                                        {(get('ort.0.v.short.v', item)) && (<> </>)}
                                        {(get('auflage.v', item)) === '1' && (<>¹</>)}
                                        {(get('auflage.v', item)) === '2' && (<>²</>)}
                                        {(get('auflage.v', item)) === '3' && (<>³</>)}
                                        {(get('auflage.v', item)) === '4' && (<>⁴</>)}
                                        {(get('auflage.v', item)) === '5' && (<>⁵</>)}
                                        {(get('auflage.v', item)) === '6' && (<>⁶</>)}
                                        {(get('auflage.v', item)) === '7' && (<>⁷</>)}
                                        {(get('auflage.v', item)) === '8' && (<>⁸</>)}
                                        {(get('auflage.v', item)) === '9' && (<>⁹</>)}
                                        <>{get('jahr.am.j.v', item)}</>
                                        {(get('jahr.bis.j.v', item)) && (<>-{get('jahr.bis.j.v', item)}</>)}
                                        <>.</>
                                    </Area>
                                    </>
                                )}
                                </>
                        )}    
						{(lexGesamt) && (
                                <>
                                {(get('titel.v', item)) && (
                                    <>
                                    <>Vorschlag:</>
                                    <Area>
                                        <>{get('short.v', item)}: </>
                                        {(get('herausgeber.0.v.shortAlt.v', item)) && (<>{get('herausgeber.0.v.shortAlt.v', item)}</>)}
                                        {(get('herausgeber.1.v.shortAlt.v', item)) && (<>/{get('herausgeber.1.v.shortAlt.v', item)}</>)}
                                        {(get('herausgeber.2.v.shortAlt.v', item)) && (
                                                <>
                                                    {(get('herausgeber.3.v.shortAlt.v', item)) ? (
                                                            <>/u. a.</>
                                                    ) : (
                                                            <>/{get('herausgeber.2.v.shortAlt.v', item)}</>
                                                    )}
                                                </>
                                        )}
                                        {(get('herausgeber.0.v.shortAlt.v', item)) && (<> (Hg.): </>)}
                                        <>{get('titel.v', item)}</>                                    
                                        {(get('nummer.v', item)) && (<>, {get('nummer.v', item)} Bände</>)}
                                        {(get('bandtitel.v', item)) && (<>, {get('bandtitel.v', item)}</>)}
                                        {(get('reihe.titel.v', item)) && (<><> (</><>{get('reihe.titel.v', item)}</><> {get('reihe.nummer.v', item)}</><>)</></>)}
                                        <>, </>
                                        {(get('ort.0.v.short.v', item)) && (<>{get('ort.0.v.short.v', item)}</>)}
                                        {(get('ort.1.v.short.v', item)) && (<>/{get('ort.1.v.short.v', item)}</>)}
                                        {(get('ort.2.v.short.v', item)) && (
                                                <>
                                                    {(get('ort.3.v.short.v', item)) ? (
                                                            <>/u. a.</>
                                                    ) : (
                                                            <>/{get('ort.2.v.short.v', item)}</>
                                                    )}
                                                </>
                                        )}
                                        {(get('ort.0.v.short.v', item)) && (<> </>)}
                                        {(get('auflage.v', item)) === '1' && (<>¹</>)}
                                        {(get('auflage.v', item)) === '2' && (<>²</>)}
                                        {(get('auflage.v', item)) === '3' && (<>³</>)}
                                        {(get('auflage.v', item)) === '4' && (<>⁴</>)}
                                        {(get('auflage.v', item)) === '5' && (<>⁵</>)}
                                        {(get('auflage.v', item)) === '6' && (<>⁶</>)}
                                        {(get('auflage.v', item)) === '7' && (<>⁷</>)}
                                        {(get('auflage.v', item)) === '8' && (<>⁸</>)}
                                        {(get('auflage.v', item)) === '9' && (<>⁹</>)}
                                        <>{get('jahr.am.j.v', item)}</>
                                        {(get('jahr.bis.j.v', item)) && (<>-{get('jahr.bis.j.v', item)}</>)}
                                        <>.</>
                                    </Area>
                                    </>
                                )}
                                </>
                        )}    
                    </Area>
                )}
            </CollapseArea>
			<CollapseArea label="Briefe" show={shortGiven}>
				<br/>
	            <Area>
					<RefsCount
		            	label="Hinweise: "
			            api="brief"
			            idPath="_id"
			            query="literatur.literatur.v="
			            anschlussText="Briefe"
			        />
	            </Area>
		        <CollapseArea show={false}>
		            <RefsLinks api="brief" idPath="_id" query="literatur.literatur.v=" long column />
		        </CollapseArea>
	            <Area>
			        <RefsCount
			            label="Editionen: "
			            api="brief"
			            idPath="_id"
			            query="edition.literatur.v="
			            anschlussText="Briefe"
			        />
	            </Area>
		        <CollapseArea show={false}>
		            <RefsLinks api="brief" idPath="_id" query="edition.literatur.v=" long column />
		        </CollapseArea>
	        </CollapseArea>
            <CollapseArea label="Inhalt" show={true}>
                <Area>
                    <List label="Sichtungen" name="sichtung">
                        {index => (
                            <>
                                <Row>
                                    <Area>
                                        <Titel>Sichtung</Titel>
                                        <Input
                                            label="Gründlichkeit"
                                            name={`sichtung[${index}].gruendlich`}
                                            placeholder="gründlich?"
                                            helperForUserInput="gründlich?"
                                        />
                                        <Input
                                            label="Vollständigkeit"
                                            name={`sichtung[${index}].vollstaendig`}
                                            placeholder="vollständig?"
                                            helperForUserInput="vollständig?"
                                        />
                                        <List
                                            label="durch"
                                            name={`sichtung[${index}].durch`}
                                            simple
                                            column
                                        >
                                            {index2 => (
                                                <Search
                                                    label="durch"
                                                    name={`sichtung[${index}].durch[${index2}]`}
                                                    api="Mitarbeiter"
                                                    small
                                                />
                                            )}
                                        </List>
                                        <Zeit label="am" name={`sichtung[${index}].am`} today />
                                    </Area>
                                    <Area>
                                        <Titel>Briefe</Titel>
                                        <DropdownSimple
                                            label="enthalten?"
                                            name={`sichtung[${index}].ertrag.funde`}
                                            items={['', 'ja', 'nein', 'vielleicht']}
                                            default_value=""
                                            small
                                        />
                                        <Input
                                            label="Menge"
                                            name={`sichtung[${index}].ertrag.briefanzahl`}
                                            small
                                        />
                                        <DropdownSimple
                                            label="übernommen"
                                            name={`sichtung[${index}].ertrag.uebernommen`}
                                            items={['', 'vollständig', 'teilweise']}
                                            default_value=""
                                            small
                                        />
                                    </Area>
                                </Row>
                            </>
                        )}
                    </List>
                </Area>
                <Area>
                    <List label="Sichtungen von Teilmengen" name="teilsichtung">
                        {index => (
                            <>
                                <Row>
                                    <Area>
                                        <Titel>Abschnitt</Titel>
                                        <Row>
                                            <Input
                                                label="Beschreibung/Titel"
                                                name={`teilsichtung[${index}].titel`}
                                            />
                                            <VerticalLine />
                                            <Input
                                                label="Seiten"
                                                name={`teilsichtung[${index}].seite`}
                                                placeholder="S."
                                                helperForUserInput="S. "
                                                small
                                            />
                                        </Row>
                                    </Area>
                                </Row>
                                <Row>
                                    <Area>
                                        <Titel>Sichtung</Titel>
                                        <Input
                                            label="Gründlichkeit"
                                            name={`teilsichtung[${index}].gruendlich`}
                                            placeholder="gründlich?"
                                            helperForUserInput="gründlich?"
                                        />
                                        <Input
                                            label="Vollständigkeit"
                                            name={`teilsichtung[${index}].vollstaendig`}
                                            placeholder="vollständig?"
                                            helperForUserInput="vollständig?"
                                        />
                                        <List
                                            label="durch"
                                            name={`teilsichtung[${index}].durch`}
                                            simple
                                            column
                                        >
                                            {index2 => (
                                                <Search
                                                    label="durch"
                                                    name={`teilsichtung[${index}].durch[${index2}]`}
                                                    api="Mitarbeiter"
                                                    small
                                                />
                                            )}
                                        </List>
                                        <Zeit label="am" name={`teilsichtung[${index}].am`} today />
                                    </Area>
                                    <Area>
                                        <Titel>Briefe</Titel>
                                        <DropdownSimple
                                            label="Briefe enthalten"
                                            name={`teilsichtung[${index}].ertrag.funde`}
                                            items={['', 'ja', 'nein', 'vielleicht']}
                                            default_value=""
                                            small
                                        />
                                        <Input
                                            label="Menge"
                                            name={`teilsichtung[${index}].ertrag.briefanzahl`}
                                            small
                                        />
                                        <DropdownSimple
                                            label="Briefe übernommen"
                                            name={`teilsichtung[${index}].ertrag.uebernommen`}
                                            items={['', 'vollständig', 'teilsichtung']}
                                            default_value=""
                                            small
                                        />
                                    </Area>
                                </Row>
                            </>
                        )}
                    </List>
                </Area>
                <Area>
                    <List label="Stellenangaben aus diesem Werk" name="stellen">
                        {index => (
                            <>
                                <Input
                                    label="Seitenangabe"
                                    name={`stellen[${index}].stelle`}
                                    placeholder="S."
                                    helperForUserInput="S. "
                                />
                                <List
                                    label="Anmerkungen"
                                    name={`stellen[${index}].anmerkungen`}
                                    simple
                                    column
                                >
                                    {index2 => (
                                        <Input
                                            label="Anmerkung"
                                            name={`stellen[${index}].anmerkungen[${index2}]`}
                                        />
                                    )}
                                </List>
                            </>
                        )}
                    </List>
                </Area>
                <Area>
                    <Anmerkung />
                </Area>
            </CollapseArea>
            <Schlagwort />
        </>
    );
};

const mapStateToProps = state => ({
    item: state.item,
    ausw: state.item.typ && state.item.typ.v === 'bitte auswählen',
    newObject: state.item.typ === undefined,
    mono: state.item.typ && state.item.typ.v === 'Monographie',
    mono_hg: state.item.typ && state.item.typ.v === "Monographie mit Autor als 'Hg.'",
    mono_plushg: state.item.typ && state.item.typ.v === "Monographie mit zusätzl. 'hg. von'",
    zeitschrift: state.item.typ && state.item.typ.v === 'Zeitschrift(artikel)',
    zeitschrift_hg: state.item.typ && state.item.typ.v === "Zeitschriftartikel mit 'hg. von'",
    sammel: state.item.typ && state.item.typ.v === 'Sammelband',
    sammelBtrg: state.item.typ && state.item.typ.v === 'Sammelbandbeitrag',
    lexKuerz: state.item.typ && state.item.typ.v === 'Lexikon nach Abkürzung (Einzelband)',
	lexGesamt: state.item.typ && state.item.typ.v === 'Lexikon nach Abkürzung (gesamt)',
    lexNurHg: state.item.typ && state.item.typ.v === 'Lexikon nach Hg (nicht Bearbeiter)',
    lexArt: state.item.typ && state.item.typ.v === 'Lexikonartikel',
    editionBearb: state.item.typ && state.item.typ.v === 'Edition nach Bearbeiter',
    editionAbk: state.item.typ && state.item.typ.v === 'Edition nach Abkürzung',
    editionsreihe: state.item.typ && state.item.typ.v === 'Editionsreihe nach Abkürzung',
    shortNotGiven: state.item.short && state.item.short.v ? false : true, // für Collapsearea)
    geprueft: state.item.geprueft && state.item.geprueft.v,
	shortGiven: state.item.short && state.item.short.v ? true : false,
});
export default EditHOC(connect(mapStateToProps)(Literatur));
