import styled from 'styled-components';
import { black, lightGrey } from './colors';

export default styled('input')`
	color: ${({ color }) => (color ? color : black)};
    font-family: inherit;
	font-size: 12px;
	font-weight: bold;
    height: 20px;
    width: ${p => p.width || '100%'};
    text-align: ${({ textRight, centerText }) => (textRight ? 'right' : (centerText ? 'center' : 'left'))}
    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${lightGrey};
        opacity: 1; /* Firefox */
		font-weight: normal;
    }
`;
