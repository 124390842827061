import React from 'react';
import Icon from '../style/IconButton';
import { lightBlue, lightGrey } from '../style/colors';

export default ({ onClick, disabled }) => (
    <Icon onClick={!disabled ? onClick : undefined} disabled={disabled}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke={disabled ? lightGrey : lightBlue}
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="12" y1="6" x2="12" y2="18" />
            <line x1="6" y1="12" x2="18" y2="12" />
        </svg>
    </Icon>
);
