import React from 'react';
import { connect } from 'react-redux';
import { get } from '../service/immutables';
import {get as getLodash} from "lodash"

const domain = 'https://thbw.hadw-bw.de';
//const domain = 'http://localhost:3000';

class IFrameMap extends React.Component {
    constructor(props) {
        super();
        this.state = {
            item: {
                _id: props._id,
                short: props.short,
                lat: getLodash(props, "koordinaten.lat.v"),
                len: getLodash(props, "koordinaten.len.v"),
            },
        };
    }

    render() {
        const {enable} = this.props
        if (enable) {
            const {lat, len, _id, short} = this.state.item
            let url = domain
                .concat("/findOrt?short.v=" + short + "&lat.v=" + parseFloat(lat) + "&len.v=" + parseFloat(len) + "&_id=" + _id)
            return (
                <>
                    <br />
					<div> 					{/*<div style={{ backgroundColor: 'white' }}>*/}
                        <iframe
                            title={url}
                            src={url}
                            width="200px"	//{window.innerWidth*0.975}
                            height="200px"
                        />
                    </div>
                </>
            );
        } else {
            return <></>;
        }
    }
};

const mapStateToProps = (state, props) => ({
    koordinaten: get('koordinaten', state.item),
    _id: get('_id', state.item),
    short: get('short.v', state.item),
    enable: get('koordinaten.lat.v', state.item) ? true : false,
});

export default connect(mapStateToProps)(IFrameMap);
