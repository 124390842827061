import React, {Component} from 'react'
import { connect } from 'react-redux';

import AdvSearchInput from '../components/AdvSearchInput';

import ScrollContainer from '../style/ScrollContainer';

class AdvSearchFilter extends Component {
    render(){
        const { schema } = this.props;
        if(schema !== undefined){
            return (
                <ScrollContainer>
                    {schema.map(x => <AdvSearchInput data={x} />)}
                </ScrollContainer>
            );
        }
        return <></>
    }
}

const mapStateToProps = (state) => ({
    schema: state.search.schema
});

export default connect(mapStateToProps)(AdvSearchFilter)