import {
    ADD_ARRAY_ELEMENT,
    ADD_ARRAY_ELEMENT_HERE,
    BUILD_SHORT_SACHE,
    CHANGE_ITEM,
    CHANGE_ITEM_FAV,
    CREATE_ITEM,
    DELETE_ARRAY_ELEMENT,
    LOAD_ITEM,
    LOAD_LOG,
    MOVE_ARRAY_ELEMENT_DOWN,
    MOVE_ARRAY_ELEMENT_UP,
    RESET_ITEM,
    SAVE_ITEM,
} from '../constants/item';

export function createItem(item) {
    return {
        type: CREATE_ITEM,
        item,
    };
}

export function saveItem(item) {
    return {
        type: SAVE_ITEM,
        item,
    };
}

export function loadItem(item) {
    return {
        type: LOAD_ITEM,
        item,
    };
}

export const changeItem = (name, value, save) => {
    return {
        type: CHANGE_ITEM,
        value,
        name,
        save,
    };
};

export const changeItemFAV = (name, value) => {
    return {
        type: CHANGE_ITEM_FAV,
        value,
        name,
    };
};
export const resetItem = () => ({ type: RESET_ITEM });

export function deleteArrayElement(name, index) {
    return {
        type: DELETE_ARRAY_ELEMENT,
        name,
        index,
    };
}

export function addArrayElement(name) {
    return {
        type: ADD_ARRAY_ELEMENT,
        name,
    };
}

export function addArrayElementHere(name, index) {
    return {
        type: ADD_ARRAY_ELEMENT_HERE,
        name,
        index,
    };
}

export function moveArrayElementUp(name, index) {
    return {
        type: MOVE_ARRAY_ELEMENT_UP,
        name,
        index,
    };
}

export function moveArrayElementDown(name, index) {
    return {
        type: MOVE_ARRAY_ELEMENT_DOWN,
        name,
        index,
    };
}

export const buildSache = () => {
    return {
        type: BUILD_SHORT_SACHE,
    };
};

export function loadLog(log, kind) {
    return {
        type: LOAD_LOG,
        log,
        kind,
    };
}
