import React from 'react';
import { connect } from 'react-redux';
import { get } from '../../service/immutables';

import CollapseArea from '../../container/CollapseArea';
import CollapseLeftRight from '../../container/CollapseLeftRight';
import DeleteItem from '../../container/DeleteItem';
import EditHOC from '../../container/EditHOC';
import List from '../../container/List';
import RefsCount from '../../container/RefsCount';
import RefsLinks from '../../container/RefsLinks';
import SetFavorite from '../../container/SetFavorite';
import Short from '../../container/Short';

import Anmerkung from '../../components/Anmerkungen';
import Switch from '../../components/Switch';

import Area from '../../style/Area';
import H1 from '../../style/H1';
import Link from '../../style/Link';
import LinkExtern from '../../style/LinkExtern';
import { Row } from '../../style/Row';
import ShortStampRed from '../../style/ShortStampRed';
import Space from '../../style/Space';
import VerticalLine from '../../style/VerticalLine';

import OpenSVG from '../../SVG/Open';

//import FileUpload from '../../inputs/raw/FileUpload';
import {
    CheckBox,
    FastSearch as Search,
    Input,
    LinkedInput,
    Zeit,
} from '../../inputs/InputsWithMetaLabelFocus';

import gnd from '../../../assets/images/gnd.jpg';
import wikipedia from '../../../assets/images/wikipedia.png';

const Ort = ({ item, type, geprueft, short, shortNotGiven, gndGiven, disableCheckbox }) => (
    <>
        <Row>
            <H1>Ort</H1>
            <Space />
            <Link to={`/ansicht/ort/${item._id}`} target="_self">
                <OpenSVG />
            </Link>
            <Space />
            <SetFavorite type={type} />
			<Space />
			<LinkExtern href={
				(get('gnd.v', item) && get('gnd.v', item) !== '-') ?
				(`http://tools.wmflabs.org/persondata/redirect/gnd/de/${
				item.gnd.v.replace('https://d-nb.info/gnd/', '').replace('http://d-nb.info/gnd/', '')
				}`)
				: ('https://de.wikipedia.org/w/index.php?search=' + short)
				} target="_blank" rel="noopener noreferrer">
	            <img src={wikipedia} width="100%" alt="" />
	        </LinkExtern>
			<Space />
			<LinkExtern href={
				(get('gnd.v', item) && get('gnd.v', item) !== '-') ? get('gnd.v', item) : ('https://portal.dnb.de/opac.htm?method=checkCategory&categoryId=geographics')
				} target="_blank" rel="noopener noreferrer">
                <img src={gnd} width="100%" alt="" />
            </LinkExtern>
            <Space />
            <DeleteItem type={type} />
        </Row>
		<Row>
        	<Short />
			{!get('gnd.v', item) && (
				<a href="https://portal.dnb.de/opac.htm?method=checkCategory&categoryId=geographics" target="_blank" rel="noopener noreferrer">
					<ShortStampRed>[GND ergänzen!]</ShortStampRed>
				</a>
			)}
			{get('gnd.v', item) && (!get('gnd.v', item).includes('http://d-nb.info/gnd/') && !get('gnd.v', item).includes('https://d-nb.info/gnd/') && !(get('gnd.v', item) === '-')) && (
				<ShortStampRed>[GND formal nicht korrekt!]</ShortStampRed>
			)}
		</Row>
        <CollapseArea label="Name & Kategorie" show="true">
			<Row>
				<Area>
					<Row>
	                    <Input label="Name" name="name" noMeta width="200px" disabled={geprueft} />
	                    <VerticalLine />
						<CheckBox
	                        label="Name geprüft"
	                        name="geprueft"
	                        width="80px"
							noMeta
							noUnderline
	                        disabled={disableCheckbox}
	                    />
					</Row>
                </Area>
                <Area>
                    <Input label="Jüngster Name" name="amtlich" noMeta width="200px" disabled={geprueft} />
                </Area>
            </Row>
			<Area>
				<Row>
	                <Search label="Kategorie" api="Geo" name="geoKat" width="200px" placeholder="Kategorie" />
					<VerticalLine />
	                <CheckBox label="an den Namen anhängen?" name="geoKatAnzeigen" noMeta noUnderline width="155px" />
				</Row>
			</Area>
            <Row>
                <Area>
                    <List label="Andere Namen" name="andereNamen" column>
                        {index => (
                            <Row key={index}>
                                <Input label="Name" name={`andereNamen[${index}].form`} noMeta width="200px" />
								<VerticalLine/>
                                <CheckBox
                                    label="belegt"
                                    name={`andereNamen[${index}].belegt`}
									noMeta
									noUnderline
                                    width="50px"
                                />
                            </Row>
                        )}
                    </List>
                </Area>
            </Row>
        </CollapseArea>
		<CollapseArea label="Normdaten" show={gndGiven}>
			 <Row>
                <Area>
                    <LinkedInput
                        label="GND"
                        name="gnd"
                        placeholder="http://d-nb.info/gnd/..."
						noMeta
                    />
				</Area>
				<CollapseLeftRight
					trigger={[
	                    `geoID`,
	                    `wikidata`,
	                ]}
				>
					<Area>
	                    <Row>
		                    <LinkedInput
		                        label="GeoNames"
		                        name="geoID"
		                        placeholder="http://www.geonames.org/..."
								noMeta
		                    />
							<VerticalLine />
							<LinkedInput
		                        label="Wikidata"
		                        name="wikidata"
		                        placeholder="http://www.wikidata.org/entity/..."
								noMeta
		                    />
						</Row>
	                </Area>
				</CollapseLeftRight>
            </Row>
		</CollapseArea>
		<CollapseArea label="Verwendung im Projekt" show={true}>
            <Area>
				<RefsCount
		            label="Absendeort von "
		            api="brief"
		            idPath="_id"
		            query="absendeort.ort.v="
		            anschlussText="Briefen"
		        />
            </Area>
	        <CollapseArea show={false}>
	            <RefsLinks api="brief" idPath="_id" query="absendeort.ort.v=" column long/>
	        </CollapseArea>
            <Area>
		        <RefsCount
		            label="Zielort von "
		            api="brief"
		            idPath="_id"
		            query="zielort.v="
		            anschlussText="Briefen"
		        />
            </Area>
	        <CollapseArea show={false}>
	            <RefsLinks api="brief" idPath="_id" query="zielort.v=" column long/>
	        </CollapseArea>
            <Area>
		        <RefsCount
		            label="Erwähnung in "
		            api="brief"
		            idPath="_id"
		            query="schlagworte.orte.v="
		            anschlussText="Briefen"
		        />
            </Area>
	        <CollapseArea show={false}>
	            <RefsLinks api="brief" idPath="_id" query="schlagworte.orte.v=" column long/>
	        </CollapseArea>
		</CollapseArea>
        <CollapseArea label="Zugehörigkeit" show={true}>
            <Area>
                <List label="Territorium" name="zugehoerigkeit" simple column>
                    {index => (
                        <Row>
							<Space/>
                            <Search
                                label="Territorium"
                                api="Ort"
                                name={`zugehoerigkeit[${index}].ort`}
                            />
							<VerticalLine />
							<CollapseLeftRight
								trigger={[
	                    		`zugehoerigkeit[${index}].zeitraum.schoen`,
	                			]}
							>
	                            <Zeit label="Zeit" name={`zugehoerigkeit[${index}].zeitraum`} />
								<VerticalLine />
							</CollapseLeftRight>
                        </Row>
                    )}
                </List>
            </Area>
            <Area>
                <List label="Konfession" name="konfession" simple column>
                    {index => (
                        <Row>
							<Space/>
                            <Search
                                label="Konfession"
                                api="Konfession"
                                name={`konfession[${index}].konfession`}
                            />
                            <VerticalLine />
							<CollapseLeftRight
								trigger={[
	                    		`konfession[${index}].zeitraum.schoen`,
	                			]}
							>
                            	<Zeit label="Zeit" name={`konfession[${index}].zeitraum`} />
								<VerticalLine />
							</CollapseLeftRight>
                        </Row>
                    )}
                </List>
            </Area>
        </CollapseArea>
        <CollapseArea label="Hinweise" show={true}>
			<Row>
                <Area>
                    <Anmerkung />
                </Area>
            </Row>
            <Row>
                <Area>
                    <List label="Sekundärliteratur" name="sekundaerliteratur">
                        {index => (
                            <>
                                <Row>
                                    <Switch name={`sekundaerliteratur[${index}].show`}>
                                        <Search
                                            label=""
                                            name={`sekundaerliteratur[${index}].literatur`}
                                            api="Literatur"
                                            placeholder="Literatur"
                                        />
                                        <Search
                                            label=""
                                            name={`sekundaerliteratur[${index}].druck`}
                                            api="Druck"
                                            placeholder="Druck"
                                        />
                                    </Switch>
                                    <Input
                                        label="Seite"
                                        name={`sekundaerliteratur[${index}].seite`}
                                        placeholder="S."
                                        helperForUserInput="S. "
                                        small
                                    />
                                </Row>
                            </>
                        )}
                    </List>
                </Area>
            </Row>
            <Row>
                <Area>
                    <List label="URLs" name="url" column>
                        {index => (
                            <Row key={index}>
                                <LinkedInput label="URL" name={`url[${index}]`} large />
                            </Row>
                        )}
                    </List>
                </Area>
            </Row>
        </CollapseArea>
		{/*
        <CollapseArea label="Bildmaterial" show={false}>
            <Row>
                <Area>
                    <List label="Karten/Abbildungen" name="bilder" column>
                        {index => (
                            <React.Fragment key={index}>
                                <FileUpload label="Karten/Abbildungen" name={`bilder[${index}]`} />
                            </React.Fragment>
                        )}
                    </List>
                </Area>
            </Row>
        </CollapseArea>
		*/}
    </>
);

const mapStateToProps = (state, props) => {
    let value = true;
    if (get('user.role', state.auth) === 'Admin') value = false;
    return {
        geprueft: state.item.geprueft && state.item.geprueft.v,
        disableCheckbox: value,
        item: state.item,
		short: state.item.short ? state.item.short.v : '',
	    shortNotGiven: state.item.short && state.item.short.v ? false : true,
		gndGiven: state.item.gnd && state.item.gnd.v ? false : true,
    };
};

export default EditHOC(connect(mapStateToProps)(Ort));
