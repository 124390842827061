import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get } from '../service/immutables';
//import { api }  from '../../config'
import { white } from '../style/colors';

const IframeArea = styled('div')`
    background-color: ${white};
    border: none;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
    }
`;

const IFrame = ({ id }) => {
    let value = id;
    if (value === '') value = null;
    //value = api + '/exist/apps/edoc/view.html?id=bid' + value;
    value = 'https://thbw.hadw-bw.de/exist/apps/edoc/view.html?id=bid' + value;    
        return (
            <IframeArea>
                <iframe
                    title={value}
                    src={value}
                    width={window.innerWidth * 0.9}
                    height={window.innerHeight}
                />         
            </IframeArea>
        );
};

const mapStateToProps = (state, props) => ({
    id: get('short.v', state.item),
});

export default connect(mapStateToProps)(IFrame);
