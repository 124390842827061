import React from 'react';
import Icon from '../style/IconButton';
import { black, lightBlue } from '../style/colors';

export const ArrowTopDown = ({ direction, onClick, active }) =>
    direction ? (
        <Icon onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke={active ? lightBlue : black}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-down"
            >
                <line x1="12" y1="19" x2="12" y2="5" />
                <polyline points="5 12 12 5 19 12" />
            </svg>
        </Icon>
    ) : (
        <Icon onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke={active ? lightBlue : black}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-down"
            >
                <line x1="12" y1="5" x2="12" y2="19" />
                <polyline points="19 12 12 19 5 12" />
            </svg>
        </Icon>
    );
export default ArrowTopDown;
