import React from 'react';
import { get } from 'lodash';

import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';

import AllowedForUser from '../../container/AllowedForUser';
import SearchHOC from '../../container/SearchHOC';

import { BriefRow as TableRow } from '../../style/SearchRow';
import Cell from '../../style/SearchCell';
import Link from '../../style/Link';
import Row from '../../style/Row';

import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import DeleteSVG from '../../SVG/Delete';
import OpenSVG from '../../SVG/Open';

import formatZeit from '../../service/formatZeit';
import ownSlice from '../../service/sliceSearch';

import { FastSearch as Search, Input, Zeit } from '../../inputs/InputsWithLabelFocus';

const SearchBrief = ({ items, sort, defaultSort, handleChangeSort, asc, loading }) => {
	if (!sort.length) defaultSort('datierung.shortZeit.v');
    return (
        <>
            <>
                <TableRow>
                    <Cell block>{null}</Cell>
                    <Cell>
                        <Input width="100%" noMeta label="ID" name="short" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('short.v')}
                            direction={'short.v' === sort ? !asc : false}
                            active={'short.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Zeit noMeta width="100%" label="Datum" name="datierung" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('datierung.shortZeit.v')}
                            direction={'datierung.shortZeit.v' === sort ? !asc : false}
                            active={'datierung.shortZeit.v' === sort}
                        />
                        {/*<CheckBox label="Exakt" name="datierung.exakt" width="20%" />*/}
                    </Cell>
                    <Cell>
                        <Search
                            width="100%"
                            noMeta
                            label="Absendeort"
                            api="Ort"
                            name="absendeort.ort"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('absendeort.ort.v')}
                            direction={'absendeort.ort.v' === sort ? !asc : false}
                            active={'absendeort.ort.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Verfasser"
                            api="Zitiername"
                            name="verfasser.person"
                            searchParam="nichtAuffuehren.brief.v=false"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('verfasser.person.v')}
                            direction={'verfasser.person.v' === sort ? !asc : false}
                            active={'verfasser.person.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Adressat"
                            api="Zitiername"
                            name="adressat.person"
                            searchParam="nichtAuffuehren.brief.v=false"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('adressat.person.v')}
                            direction={'adressat.person.v' === sort ? !asc : false}
                            active={'adressat.person.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input noMeta width="100%" label="Incipit" name="incipit" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('incipit.v')}
                            direction={'incipit.v' === sort ? !asc : false}
                            active={'incipit.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Regest"
                            api="Mitarbeiter"
                            name="regest.von"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('regest.von.v')}
                            direction={'regest.von.v' === sort ? !asc : false}
                            active={'regest.von.v' === sort}
                        />
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                {/* vertikaler Platzhalter für Dropdownmenüs: */}
			 	{items.length < 1 && (
					 <>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
					 </>
				 )}
                <Loading loading={loading}>
                    {items.map((item, i) => (
                        <TableRow key={i}>
                            <Link to={`/edit/brief/${item._id}`} target={false}>
                                <OpenSVG />
                            </Link>
                            <Cell>{item.short && item.short.v}</Cell>
                            <Cell>{item.datierung && `${formatZeit(get(item, 'datierung'))}`}</Cell>
                            <Cell block>
                                {item.absendeort && item.absendeort.map((e, i) => (
                                    <Link to={'/edit/ort/' + get(e, 'ort.v._id')} key={i}>
                                        {get(e, 'ort.v.short.v')}
                                        <br />
                                    </Link>
                                ))}
                            </Cell>
                            <Cell block>
                                {item.verfasser && item.verfasser.map((verfasser, i) => (
                                    <Link
                                        to={'/edit/zitiername/' + get(verfasser, 'person.v._id')}
                                        key={i}
                                    >
                                        {get(verfasser, 'person.v.short.v')}
                                        <br />
                                    </Link>
                                ))}
                            </Cell>
                            <Cell block>
                                {item.adressat && item.adressat.map((adressat, i) => (
                                    <Link
                                        to={'/edit/zitiername/' + get(adressat, 'person.v._id')}
                                        key={i}
                                    >
                                        {get(adressat, 'person.v.short.v')}
                                        <br />
                                    </Link>
                                ))}
                            </Cell>
                            <Cell>{ownSlice(get(item, 'incipit.v'), 22)}</Cell>
                            <Cell block>
                                {item.regest && item.regest.von && item.regest.von.map((e, i) => (
                                    <Link to={'/edit/mitarbeiter/' + get(e, 'v._id')} key={i}>
                                        {get(e, 'v.short.v')}
                                        <br />
                                    </Link>
                                ))}
                            </Cell>
                            <AllowedForUser rolle="Admin">
                                <Link to={`/ref/brief/${item._id}`}>
                                    <DeleteSVG />
                                </Link>
                            </AllowedForUser>
                        </TableRow>
                    ))}
                </Loading>
            </>
            <AllowedForUser rolle="Hiwi">
                <NewButton type="brief" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchBrief);
