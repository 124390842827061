import styled from 'styled-components';
import { linkBlue, purple } from './colors';

export const LinkExtern = styled('a')`
    color: ${linkBlue};
    font-weight: bold;
    font-size: 1em;
    align-self: center;
    word-break: break-all;
    &:visited {
        text-decoration: none;
        color: ${purple};
    }
`;

export default LinkExtern;
