import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addNotification } from '../store/actions/notificationActions';

class NotificationContainer extends Component {
    componentDidMount() {
        this.notificationSystem = this.refs.notificationSystem;
    }

    componentWillReceiveProps(newProps) {
        const { title, message, level } = newProps.notification;
        this.notificationSystem.addNotification({
            title,
            message,
            level,
            position: 'br',
            autoDismiss: level === 'error' ? 0 : 5,
        });
    }

    render() {
        return <NotificationSystem style={{ whiteSpace: 'normal' }} ref="notificationSystem" />;
    }
}

function mapStateToProps(state) {
    return {
        notification: state.notification,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                addNotification,
            },
            dispatch
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationContainer);

connect();
