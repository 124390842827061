import React from 'react';
import List from '../container/List';
import Area from '../style/Area';
import { FastSearch as Search } from '../inputs/InputsWithMetaLabelFocus';
import CollapseArea from '../container/CollapseArea';

export const Schlagworte = ({ show }) => (
    <CollapseArea label="Schlagworte" show={show}>
        <Area>
            <List label="Personen" name="schlagworte.personen" newLink="person">
                {index => (
                    <Search
                        label="Person"
                        api="Zitiername"
                        name={`schlagworte.personen[${index}]`}
                        searchParam="nichtAuffuehren.brief.v=false"
                    />
                )}
            </List>
        </Area>
        <Area>
            <List label="Orte" name="schlagworte.orte" newLink="ort">
                {index => <Search label="" api="Ort" name={`schlagworte.orte[${index}]`} />}
            </List>
        </Area>
        <Area>
            <List label="Sachen" name="schlagworte.sachen" newLink="sache">
                {index => <Search label="" api="Sache" name={`schlagworte.sachen[${index}]`} />}
            </List>
        </Area>
    </CollapseArea>
);

export default Schlagworte;
