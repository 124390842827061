import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get } from '../service/immutables';
//import { api } from '../../config'
import { white } from '../style/colors';

const IframeArea = styled('div')`
    background-color: ${white};
	width: '100%';
    border: none;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
    }
`;

const IFrame = ({ id, enable }) => {
    let value = id;
    if (value === '') value = null;
    value = 'https://thbw.hadw-bw.de/exist/apps/edoc/view.html?id=bid' + value;
    if (enable) {
        return (
            <IframeArea>
                <iframe
                    title={value}
                    src={value}
                    width='100%'
					height={window.innerHeight}
                />
            </IframeArea>
        );
    } else {
        return <></>;
    }
};

const mapStateToProps = (state, props) => ({
    id: get('short.v', state.item),
    enable: get(props.enable + '.v', state.item),
});

export default connect(mapStateToProps)(IFrame);
