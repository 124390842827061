import React from 'react';
import { ArrowTopDown } from '../SVG/ArrowTopDown';
import { changeSort } from '../store/actions/search';
import { connect } from 'react-redux';

const SortArrow = ({ active, direction, path, handleChangeSort }) => (
    <ArrowTopDown onClick={() => handleChangeSort(path)} direction={direction} active={active} />
);

const mapStateToProps = (state, props) => ({
    active: state.search.sort === props.path,
    direction: props.path === state.search.sort ? !state.search.asc : false,
});

const mapDispatchToProps = {
    handleChangeSort: changeSort,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SortArrow);
