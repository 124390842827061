import React from 'react';
import LogHOC from '../../container/LogHOC';
import Cell from '../../style/SearchCell';
import { LogZB as LogRow } from '../../style/SearchRow';
import Link from '../../style/Link';
import { get } from 'lodash';
import OpenSVG from '../../SVG/Open';

const LogViewzB = ({ logs, item, kind, id }) => (
    <>
        <div>
            {logs[kind] !== undefined &&
                logs[kind].map((log, i) => {
                    if (JSON.stringify(log.changes) !== '{}') {
                        return (
                            <LogRow key={i}>
                                <Link to={`/edit/${kind}/${get(log, 'objekt._id')}`}>
                                    <OpenSVG />
                                </Link>
                                <Cell block>{get(log, 'objekt.short.v')}</Cell>
                                <Cell block>{get(log, 'objekt.long.v')}</Cell>
                                <Cell block>{get(log, 'kind')}</Cell>
                                <Cell block>{new Date(log.am).toLocaleString()}</Cell>
                            </LogRow>
                        );
                    }
                    return null;
                })}
        </div>
    </>
);

export default LogHOC(LogViewzB);
