import styled from 'styled-components';
import { grey } from './colors';

/* ursprüngliches Konzept:
H1 - Titel
H2 - Unterbereiche
H3 - name/short
H4 - Area Titel
*/

export default styled('div')`
    display: block !important;
    font-size: 1.2em !important;
    font-weight: ${({ notBold }) => (notBold ? 'normal' : 'bold')};
    letter-spacing: 0.2px !important;
    margin-bottom: 8px !important;
    padding: 0 !important;
    color: ${grey} !important;
`;
