import styled from 'styled-components';
import Area from './Area';

export const Item = styled(Area)`
    position: relative;
    padding-right: 15px;
    ${p =>
        !p.simple &&
        `    
        margin: 0 0.5em 0.5em 0; 
    `};
    ${p =>
        p.simple &&
        `    
        margin: 0 0 0 10px; 
        box-shadow: none;
        &:hover {
            box-shadow: none;
        } 
    `};
`;

export default Item;
