import React from 'react';

import Map from '../components/Map';

class Karte extends React.Component {
    render() {
        return (    
			<Map/>
        );
    }
}

export default Karte;
