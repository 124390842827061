import {
    ABORT_FETCH,
    INIT_ATTRIBUTES_SEARCH,
    SEARCH_CHANGE_ITEMS,
    SEARCH_CHANGE_PAGE,
    SEARCH_JUMP_PAGE,
    SEARCH_RESET_PAGE,
    SEARCH_CHANGE_SORT,
    SEARCH_END_LOADING,
    SEARCH_RESET,
    SEARCH_SET_COUNT,
    SEARCH_SET_LIMIT,
    SEARCH_START_LOADING,
    SEARCH_TOGGLE_SHOW_EYE,
    SET_ABORT_CONTROLLER,
} from '../constants/search';
import { SET_SCHEMA } from '../constants/item';
import { set } from '../../service/immutables';

const initState = {
    sort: '',
    asc: true,
    items: [],
    page: 0,
    limit: 50,
    loading: 0,
    count: 0,
    show: [],
    controller: null,
};

export default function search(state = initState, action) {
    switch (action.type) {
        case SEARCH_CHANGE_SORT:
            return {
                ...state,
                sort: action.name,
                asc: state.sort === action.name ? !state.asc : true,
                items: [],
            };
        case SEARCH_CHANGE_ITEMS:
            return {
                ...state,
                items: action.items,
            };
        case SEARCH_START_LOADING:
            return {
                ...state,
                loading: state.loading + 1,
            };
        case SEARCH_END_LOADING:
            return {
                ...state,
                loading: state.loading - 1,
                controller: null,
            };
        case SEARCH_CHANGE_PAGE:
            const newPage = state.page + action.value;
            return {
                ...state,
                items: [], // reset items
                page: newPage > 0 ? newPage : 0,
            };
        case SEARCH_JUMP_PAGE:
            return {
                ...state,
                items: [], // reset items
                page: action.value > 0 ? action.value - 1 : 0
            };
        case SEARCH_RESET_PAGE:
            return {
                ...state,
                page: 0
            };
        case SEARCH_SET_COUNT:
            return {
                ...state,
                count: action.count,
            };
       case SEARCH_SET_LIMIT:
            return {
                ...state,
                limit: action.limit
            };
        case SEARCH_RESET:
            // separate controller and loading from initState
            let { controller, loading, ...props } = initState;
            // so we can override all props except these two in current state
            return {
                ...state,
                ...props,
            };
        case SEARCH_TOGGLE_SHOW_EYE:
            if (state.show.includes(action.path)) {
                // remove
                return {
                    ...state,
                    show: state.show.filter(e => e !== action.path),
                };
            } else {
                //add
                return {
                    ...state,
                    show: [...state.show, action.path],
                };
            }
        case SET_ABORT_CONTROLLER:
            return {
                ...state,
                controller: action.controller,
            };
        case ABORT_FETCH:
            if (state.controller) {
                state.controller.abort();
            }
            return {
                ...state,
                loading: state.loading > 0 ? state.loading - 1 : state.loading,
                controller: null,
            };
        case SET_SCHEMA:
            return {
                ...state,
                schema: action.schema,
            };
        case INIT_ATTRIBUTES_SEARCH:
            // typeList is an array of arrays like this (modelName and its translation):
            //   [ ['Brief', 'Brief-ID'], ['Zitiername', 'Person'], ... ]
            const availableTypes = action.typeList;
            const default_type = availableTypes[0];
            const attributes = set('key.v', default_type[1], state.$attributes);
            return {
                ...state,
                $attributes: attributes,
                availableTypes: availableTypes,
            };
        default:
            return state;
    }
}
