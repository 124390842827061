import styled from 'styled-components';
import { black, lightGrey } from './colors';

export default styled('input')`
    color: ${black};
    font-family: inherit;
    height: 20px;
    width: ${p => p.width || '100%'};
    text-align: ${({ textRight }) => (textRight ? 'right' : 'left')}
    :placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${lightGrey};
        opacity: 1; /* Firefox */
    }
`;
