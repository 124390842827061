import LabelHoc from './LabelHoc';
import LabelInputRaw from './raw/LabelInput';
import LabelFastSearchRaw from './raw/LabelFastSearch';
import LabelZeitRaw from './raw/LabelZeit';
import LabelDropdownSimpleRaw from './raw/LabelDropdownSimple';
import LabelCheckBoxRaw from './raw/LabelCheckBox';
import LabelTextareaRaw from './raw/LabelTextarea';
import LabelLinkedInputRaw from './raw/LabelLinkedInput';

export const LabelInput = LabelHoc(LabelInputRaw);
export const LabelFastSearch = LabelHoc(LabelFastSearchRaw);
export const LabelZeit = LabelHoc(LabelZeitRaw);
export const LabelDropdownSimple = LabelHoc(LabelDropdownSimpleRaw);
export const LabelCheckBox = LabelHoc(LabelCheckBoxRaw);
export const LabelTextarea = LabelHoc(LabelTextareaRaw);
export const LabelLinkedInput = LabelHoc(LabelLinkedInputRaw);
