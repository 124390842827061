import styled from 'styled-components';
import { lightBlue } from './colors';

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: min-content;
    margin: auto 0.1rem;
    box-shadow: ${p => (p.active ? '0 0 15px 1px ' + lightBlue : null)};
    border-radius: 50%;

    &:hover {
        ${({ disabled, active }) =>
            !disabled && !active && 'box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.3)'};
    }
    ${({ animated, disabled }) =>
        animated &&
        disabled &&
        `
        &:hover {
            animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
        }
    
        @keyframes shake {
            10%, 90% {
                transform: translate3d(-1px, 0, 0);
            }
          
            20%, 80% {
                transform: translate3d(2px, 0, 0);
            }
        
            30%, 50%, 70% {
                transform: translate3d(-4px, 0, 0);
            }
        
            40%, 60% {
                transform: translate3d(4px, 0, 0);
            }
        }`};
`;
