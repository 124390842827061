import React from 'react';
import PublicViewHOC from '../../container/PublicViewHOC';
//import H1 from '../../style/H1';
import Link from '../../style/Link';
import Area from '../../style/Area';
import Container from '../../style/Container';
import OpenSVG from '../../SVG/Open';
import { Row } from '../../style/Row';
import Short from '../../container/Short';
import RefsLinks from '../../container/RefsLinksView';
import LinkExtern from '../../style/LinkExtern';
import WebGlobeSVG from '../../SVG/WebGlobe';
import { connect } from 'react-redux';
import Space from '../../style/Space';
import AllowedForUser from '../../container/AllowedForUser';
import { LabelFastSearch as Search } from '../../labels/LabelWithNoFocus';
import { get } from '../../service/immutables';

const BriefhandschriftPublicUI = ({ item }) => (
    <>
		{/*
        <Row>
            <H1>Briefhandschrift</H1>
        </Row>
		*/}
		<br/>
		<Row nowrap>
			<Short />
			<AllowedForUser rolle="Gast">
	            <Space />
	            <Link to={`/edit/briefhandschrift/${item._id}`} target="_self">
	                <OpenSVG />
	            </Link>
			</AllowedForUser>
		</Row>
		<Row>
			<Area>
				<RefsLinks label="Brief&nbsp;" api="brief" idPath="_id" query="handschrift.v=" long noResultMessage />
			</Area>
		</Row>
		<br/>
		<Row>
			<Container>Besitzende Institution:</Container>
			<Area>
				<Search label="" api="archiv" name="archiv" width="100%" />
	        </Area>
		</Row>
		{get('signatur.v', item) && (
			<Row>
				<Container>Signatur:</Container>
				<Area>
					<>{get('signatur.v.long.v', item)}</>
				</Area>
			</Row>		
		)}
		{(get('reproduktion[0].datei[0].v', item) && get('reproduktion[0].datei[0].v', item).includes('http')) && (
			<>
				<Row>
					<Container>Externes Digitalisat:</Container>
					<LinkExtern
			            href={get('reproduktion[0].datei[0].v', item)}
			            target="blank"
			        >
			            <WebGlobeSVG />
			        </LinkExtern>
				</Row>
			</>
		)}
		{(get('reproduktion[1].datei[0].v', item) && get('reproduktion[1].datei[0].v', item).includes('http')) && (
			<>
				<Row>
					<Container>Externes Digitalisat:</Container>
					<LinkExtern
			            href={get('reproduktion[1].datei[0].v', item)}
			            target="blank"
			        >
			            <WebGlobeSVG />
			        </LinkExtern>
				</Row>
			</>
		)}
    </>
);

const mapStateToProps = (state) => {
    return {
        item: state.item,
    };
};

export default PublicViewHOC(connect(mapStateToProps)(BriefhandschriftPublicUI));
