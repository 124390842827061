import React, { Component } from 'react';

class OnEnterPress extends Component {
    onKeyDown = ({ keyCode }) => {
        if (keyCode === 13) this.props.action();
    };

    render() {
        const { children } = this.props;
        return <span onKeyDown={this.onKeyDown}>{children}</span>;
    }
}

export default OnEnterPress;
