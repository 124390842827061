import React, { Component } from 'react';
import { connect } from 'react-redux';
import Eye from '../SVG/Eye';
import * as PropTypes from 'prop-types';
import { toggleShowEye } from '../store/actions/search';

const ShowEye = class ShowEye extends Component {
    static defaultProps = {
        default: false,
    };

    componentDidMount() {
        // lifecycle methods that are changing redux store is extremely dangerous!
        if (this.props.default && !this.props.show)
            this.props.dispatch(toggleShowEye(this.props.path));
    }
    render() {
        const { show, dispatch, path } = this.props;
        return (
            <>
                <Eye show={show} onClick={() => dispatch(toggleShowEye(path))} />
            </>
        );
    }
};

ShowEye.propTypes = {
    path: PropTypes.string.isRequired,
    default: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => ({
    show: state.search.show.includes(props.path),
});

export default connect(mapStateToProps)(ShowEye);
