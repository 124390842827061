import styled from 'styled-components';
import Area from './Area';

export const Button = styled(Area)`
    mborder-style: solid;
    border-color: #bbb #888 #666 #aaa;
    border-width: 0.8px 1.3px 1.3px 0.8px;
    align-self: ${({ align }) => (align ? 'center' : 'auto')};
	cursor: pointer;

    &:active {
        border-width: 1.3px 0.8px 0.8px 1.3px;
        color: #000;
        box-shadow: 0 1px 6px rgba(49, 126, 172, 0.28); // blue to rgb
    }
`;

export default Button;
