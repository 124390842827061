import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '../style/Row';
import DoubleRight from '../SVG/DoubleRight';
import Delete from '../SVG/Delete';
import { get } from '../service/immutables';
import { changeItem } from '../store/actions/item';
import { connect } from 'react-redux';

const handleSwitch = props => {
    const { name, handleChange, value } = props;
    let show = value + 1;
    if (show >= props.children.length) show = 0;
    handleChange(name, show);
};

export const Switch = props => {
    const { children, value, handleChange, existValue } = props;
    return (
        <Row nowrap>
            {children[value]}
            {existValue ? (
                <Delete onClick={() => handleChange(children[value].props.name + '.v', null)} />
            ) : (
                <DoubleRight onClick={() => handleSwitch(props)} />
            )}
        </Row>
    );
};

Switch.propTypes = {
    value: PropTypes.number.isRequired, // the show value
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired, // the path to the show att
};

Switch.defaultProps = {
    value: 0,
};

const mapStateToProps = (state, props) => {
    const value = get(props.name, state.item);
    const existValue =
        props.children[value] && get(props.children[value].props.name + `.v`, state.item);
    return {
        value,
        existValue,
    };
};

const mapDispatchToProps = {
    handleChange: changeItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Switch);
