export function sliceSearch(value, length = 100) {
    const result =
        value !== null
            ? value.toString().slice(0, length - 3) +
              (value.toString().split('').length > length ? '...' : '')
            : '';
    return result;
}

export default sliceSearch;
