import {
    AUTH_CHANGE_ABEARBEITET,
    AUTH_CHANGE_ASUCHE,
    AUTH_CHANGE_NAME,
    AUTH_CHANGE_PASSWORD,
    AUTH_LOGIN,
    AUTH_LOGOUT,
} from '../constants/auth';
import jwt_decode from 'jwt-decode';

export const changeAuth = (name, value) => {
    if (name === 'name.v') {
        return {
            type: AUTH_CHANGE_NAME,
            name: value,
        };
    } else if (name === 'password.v')
        return {
            type: AUTH_CHANGE_PASSWORD,
            password: value,
        };
    else if (name === 'anzahlSuche.v')
        return {
            type: AUTH_CHANGE_ASUCHE,
            anzahlSuche: value,
        };
    else if (name === 'anzahlBearbeitet.v')
        return {
            type: AUTH_CHANGE_ABEARBEITET,
            anzahlBearbeitet: value,
        };
};

export const logout = () => ({ type: AUTH_LOGOUT });

export const login = token => {
    const decoded = jwt_decode(token);

    return {
        type: AUTH_LOGIN,
        decoded,
        token,
    };
};
