import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '../../style/Row';
import { get } from '../../service/immutables';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ultraLightGrey } from '../../style/colors';

const Select = styled.select`
    border: none;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);

    padding: 2px;

    &:hover {
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
    }

    background-color: ${p => (p.disabled ? ultraLightGrey : 'inherit')};
`;

const LabelDropdownSimple = ({
    items,
    value,
    handleChange,
    disableEmpty,
    erschlossen,
    unsicher,
    fragwuerdig,
    ca,
    disabled,
    default_value,
}) => (
    <Row>
        {erschlossen && '['}
        <Select value={value || default_value} disabled={true}>
            {!disableEmpty && (
                <option key="0" value="">
                    {' '}
                </option>
            )}
            {items.map((item, i) => (
                <option key={i + 1} value={item}>
                    {item === ' ' ? 'Leerzeichen' : item}
                </option>
            ))}
        </Select>
        {ca && 'ca.'}
        {unsicher && '?'}
        {erschlossen && ']'}
        {fragwuerdig && '[?]'}
    </Row>
);

LabelDropdownSimple.defaultProps = {
    disableEmpty: true, // for (not) show a empty value
    value: null,
};

LabelDropdownSimple.propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    value: PropTypes.any, //.isRequired,
    disableEmpty: PropTypes.bool,
    default_value: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
    value: get(props.name + '.v', state.item) || 0,
    erschlossen: get(props.name + '.m.erschlossen', state.item),
    unsicher: get(props.name + '.m.unsicher', state.item),
    ca: get(props.name + '.m.ca', state.item),
    fragwuerdig: get(props.name + '.m.fragwuerdig', state.item),
});

const mapDispatchToProps = (dispatch, props) => ({
    //handleChange: e => dispatch(changeItem(props.name + '.v', e.target.value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LabelDropdownSimple);
