import React from 'react';
import Icon from '../style/IconButton';
import { lightGrey } from '../style/colors';

export default ({ direction, onClick }) =>
    direction ? (
        <Icon onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke={lightGrey}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                {/*pipe*/}
                <polyline points="20,6 20,18" />
                {/*outer arrow*/}
                <polyline points="13 17 18 12 13 7" />
                {/*inner arrow*/}
                <polyline points="6 17 11 12 6 7" />
            </svg>
        </Icon>
    ) : (
        <Icon onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke={lightGrey}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                {/*pipe*/}
                <polyline points="4,6 4,18" />
                {/*outer arrow*/}
                <polyline points="18 17 13 12 18 7" />
                {/*inner arrow*/}
                <polyline points="11 17 6 12 11 7" />
            </svg>
        </Icon>
    );
