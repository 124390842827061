import React from 'react';
import H3 from '../style/H3';
import H4 from '../style/H4';
import { connect } from 'react-redux';

import { get } from '../service/immutables';

class Short extends React.Component {
    render() {
        const { short, Header4 } = this.props;
        return <>{Header4 !== true ? <H3>{short}</H3> : <H4>{short}</H4>}</>;
    }
}

export default connect((state, props) => ({
    short: props.name
        ? get(props.name + '.v', state.item) || ''
        : state.item.short && state.item.short.v,
}))(Short);
