import React from 'react';
//import Date from '../inputs/raw/Date';
//import List from '../container/List';
//import Switch from './Switch';
import Area from '../style/Area';
import Row from '../style/Row';
//import ToggleComment from '../container/ToggleComment';
import Menu from '../style/MetaMenu';
import MetaCheckboxes from './MetaCheckboxes';
import { connect } from 'react-redux';
import Column from '../style/Column';
//import { FastSearch as Search, Textarea as Input } from '../inputs/InputsWithLabelFocus';
import ScrollContainer from '../style/ScrollContainer';
//import getCurrentUser from '../service/getCurrentUser';
import styled from 'styled-components';

const RowSpace = styled.div`
    padding-top: 2px;
`;

function MetaMenu(props) {
    const { name, zeit } = props;
    return (
        <Menu>
            <ScrollContainer>
				{/*
                {name && (
                    <Area>
						
                        <List label="Quelle" name={name + '.m.quelle'} column>
                            {index => (
                                <>
                                    <Column>
                                        <Switch name={name + `.m.quelle[${index}].show`}>
                                            <Search
                                                label=""
                                                name={name + `.m.quelle[${index}].handschrift`}
                                                api="Briefhandschrift"
                                            />
                                            <Search
                                                label=""
                                                name={name + `.m.quelle[${index}].briefdruck`}
                                                api="Briefdruck"
                                            />
                                            <Search
                                                label=""
                                                name={name + `.m.quelle[${index}].literatur`}
                                                api="Literatur"
                                            />
                                            <Input
                                                label="Sonstiges"
                                                name={name + `.m.quelle[${index}].sonstiges`}
                                                focus={true}
                                            />
                                        </Switch>
                                    </Column>
                                    <List
                                        column
                                        label="Sichtung"
                                        name={name + `.m.quelle[${index}].sichtung`}
                                    >
                                        {index2 => (
                                            <>
                                                <Search
                                                    label=""
                                                    name={
                                                        name +
                                                        `.m.quelle[${index}].sichtung[${index2}].durch`
                                                    }
                                                    api="Mitarbeiter"
                                                    default={getCurrentUser()}
                                                />
                                                <Date
                                                    label=""
                                                    name={
                                                        name +
                                                        `.m.quelle[${index}].sichtung[${index2}].am`
                                                    }
                                                />
                                                <ToggleComment
                                                    label="Anmerkung"
                                                    name={
                                                        name +
                                                        `.m.quelle[${index}].sichtung[${index2}].annmerkung`
                                                    }
                                                />
                                            </>
                                        )}
                                    </List>
                                </>
                            )}
                        </List>
                    </Area>
                )}
				*/}
				<Area>
	                {name ? <b>Metadaten zu {name}</b> : 'Attribut wählen!'}
	                {zeit ? (
	                    // Pfad ist ein Zeit Objekt und wir wollen auch die Metas der einzelnen
	                    // Komponenten ändern, also weitere MetaCheckboxes aber ohne den Metaknopf
	                    // sonst gibts ne Metainception ;)
	                    <>
							<RowSpace/>
							<Row>
								<Column>
									<>{'am.Tag'}:</><br/>
			                        <>{'am.Monat'}:</><br/>
			                        <>{'am.Jahr'}:</><br/>
									<RowSpace/><RowSpace/><RowSpace/>
			                       	<>{'bis.Tag'}:</><br/>
			                       	<>{'bis.Monat'}:</><br/>
			                        <>{'bis.Jahr'}:</><br/>
								</Column>
								<Column>
									<RowSpace/>
									<MetaCheckboxes name={name + '.am.t'} disableButton />
									<RowSpace/>
									<MetaCheckboxes name={name + '.am.m'} disableButton />
									<RowSpace/>
									<MetaCheckboxes name={name + '.am.j'} disableButton />
									<RowSpace/><RowSpace/><RowSpace/>
									<RowSpace/>
									<MetaCheckboxes name={name + '.bis.t'} disableButton />
									<RowSpace/>
									<MetaCheckboxes name={name + '.bis.m'} disableButton />
									<RowSpace/>
									<MetaCheckboxes name={name + '.bis.j'} disableButton />
								</Column>
							</Row>
	                    </>
	                ) : null}
				</Area>
            </ScrollContainer>
        </Menu>
    );
}

MetaMenu.propTypes = {};

MetaMenu.defaultProps = {};

const mapStateToProps = state => ({
    name: state.meta.focus,
    item: state.item,
    zeit: state.meta.zeit,
});

export default connect(mapStateToProps)(MetaMenu);
