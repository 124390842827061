import styled from 'styled-components';

const BigStampBlue = styled.div`
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0px;
    color: darkblue;
    margin-top: 10px;
    line-height: 3rem;
    padding: 0;
`;

export default BigStampBlue;