import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get } from '../../service/immutables';
import StyledInput from '../../style/Input';
import { Row } from '../../style/Row';
import { connect } from 'react-redux';
import { changeItem } from '../../store/actions/item';

class Input extends Component {
    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element
        this.input = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.focus && this.props.focus) this.input.current.focus();
    }

    render() {
        const {
            value,
            type,
            placeholder,
            maxLength,
            width,
            handleChange,
            erschlossen,
            unsicher,
            fragwuerdig,
            ca,
            disabled,
            textRight,
			centerText,
        } = this.props;

        return (
            <Row nowrap hcenter>
                {erschlossen && '['}
                <StyledInput
                    width={width}
                    ref={this.input}
                    type={type}
                    placeholder={placeholder}
                    onChange={handleChange}
                    value={value}
                    autoComplete="off"
                    maxLength={maxLength}
                    disabled={disabled}
                    textRight={textRight}
					centerText={centerText}
                />
                {ca && <>&nbsp;ca.</>}
                {unsicher && '?'}
                {erschlossen && ']'}
                {fragwuerdig && <>&nbsp;[?]</>}
            </Row>
        );
    }
}

Input.defaultProps = {};

Input.propTypes = {
    label: PropTypes.string, // not required - for example in Zeit
    name: PropTypes.string.isRequired, // name wo im Parent object das element hingepseichert wird
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), //.isRequired//.oneOfType([PropTypes.oneOf([null]), PropTypes.string]).isRequired
};

const mapStateToProps = (state, props) => ({
    value: get(props.name + '.v', state.item) || props.helperForUserInput || '',
    erschlossen: get(props.name + '.m.erschlossen', state.item),
    unsicher: get(props.name + '.m.unsicher', state.item),
    ca: get(props.name + '.m.ca', state.item),
    fragwuerdig: get(props.name + '.m.fragwuerdig', state.item),
});

const mapDispatchToProps = (dispatch, props) => ({
    handleChange: e => dispatch(changeItem(props.name + '.v', e.target.value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Input);
