import styled from 'styled-components';
import { background } from './clientExtern/style/colors';

export default styled('div')`
    display: flex;
	flex-direction: column;
    background-color: ${background};
	min-height: 100%;
	height: auto !important;
	height: 100%;
`;
