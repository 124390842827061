import React, { Component } from 'react';
import { get as getLodash } from 'lodash';
import { getItem } from '../../service/fetch';

class PermaLinkBrief extends Component {
    state = {
        text: 'Einen Moment bitte',
    };

    async componentWillMount() {
        const { id } = this.props.match.params;
        const brief = await getItem('Brief', null, 'short.v=' + id);
        if (getLodash(brief, '0.short.v') === id) {
            this.props.history.push(`/ansicht/brief/${getLodash(brief, '0._id')}`);
        } else {
            this.setState({
                text: `Leider nicht gefunden!`,
            });
        }
    }

    render() {
        return <div>{this.state.text}</div>;
    }
}

export default PermaLinkBrief;
