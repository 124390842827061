import styled from 'styled-components';
import { black } from './colors';

/* ursprüngliches Konzept:
H1 - Titel
H2 - Unterbereiche
H3 - name/short
H4 - Area Titel
*/

export default styled('div')`
    font-size: 2em !important;
    letter-spacing: -0.2px !important;
    padding: 0 !important;
    color: ${black} !important;
`;
