import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { changeItem } from '../store/actions/item';
import { ChangeHookDropdownSimple as DropdownSimple, FastSearch, Input } from '../inputs/InputsWithLabelFocus';
import Area from '../style/Area';
import Row from '../style/Row';
import Space from '../style/Space';

class AttributesElement extends Component {
    render() {
        const { modelType, availableTypes } = this.props;
        const englroute = this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl';
        let placeholder = undefined;
        if (modelType === 'Zeit') {
			if (englroute) {
				placeholder = 'DD.MM.YYYY';	
			} else { 
				placeholder = 'TT.MM.JJJJ';
			}
        }
		else if (modelType === 'Freie Suche') {
			if (englroute) {
				placeholder = 'in all attributes'	
			} else { 
				placeholder = 'in allen Feldern';
			}
		}
		else {
			if (englroute) {
				placeholder = 'search index'	
			}
		}
        return (
            <>
                <Area width="370px">
					<Row>
	                    <DropdownSimple
	                        label=""
	                        name={`$attributes.key`}
	                        items={availableTypes.map(obj => obj[1])}
	                        changeHook={dispatch => dispatch(changeItem(`$attributes.value.v`, null))}
							width="95px"
							height="28px"
							noUnderline
	                    />
						<Space/>
                        {modelType !== 'Freie Suche' && modelType !== 'Zeit' ? (
                            <FastSearch api={modelType} name={`$attributes.value`} placeholder={placeholder} width="250px" />
                        ) : (
                            <Input name={`$attributes.value`} placeholder={placeholder} width="250px" />
                        )}
                    </Row>
                </Area>
            </>
        );
    }
}

const mapStateToProps = (state, props) => {
    const key = get(state.item, `$attributes.key.v`) || get(state.search, `$attributes.key.v`);
    const type = props.availableTypes.find(obj => obj[1] === key);
    return { 
		modelType: type[0],
		label: type[1],
		path: state.router.location.pathname,
	};
};

export default connect(mapStateToProps)(AttributesElement);
