import PropTypes from 'prop-types';
import React, { Component } from 'react';

class AllowedForUser extends Component {
    constructor() {
        super();
        this.state = {};
    }

    allowed = rolle => {
        if (rolle === 'Gast') {
            if (
                localStorage.getItem('rolle') === 'Gast' ||
                localStorage.getItem('rolle') === 'Hiwi' ||
                localStorage.getItem('rolle') === 'Mitarbeiter' ||
                localStorage.getItem('rolle') === 'Admin'
            ) {
                return true;
            }
        }
        if (rolle === 'Hiwi') {
            if (
                localStorage.getItem('rolle') === 'Hiwi' ||
                localStorage.getItem('rolle') === 'Mitarbeiter' ||
                localStorage.getItem('rolle') === 'Admin'
            ) {
                return true;
            }
        }
        if (rolle === 'Mitarbeiter') {
            if (
                localStorage.getItem('rolle') === 'Mitarbeiter' ||
                localStorage.getItem('rolle') === 'Admin'
            ) {
                return true;
            }
        }
        return rolle === 'Admin' && localStorage.getItem('rolle') === 'Admin';
    };

    render() {
        const { children, rolle } = this.props;
        return <>{this.allowed(rolle) ? children : <div />}</>;
    }
}

AllowedForUser.propTypes = {
    rolle: PropTypes.string.isRequired,
};

AllowedForUser.defaultProps = {};

export default AllowedForUser;
