import {
    CHANGE_META_FOCUS,
    CHANGE_ZEIT_META_FOCUS,
    TOGGLE_SHOW_META_MENU,
} from '../constants/ActionTypes';

const initState = {
    focus: '', // path in the object
    show: false, // show meta menu
};

export default function meta(state = initState, action) {
    switch (action.type) {
        case CHANGE_META_FOCUS:
            return {
                ...state,
                focus: action.name,
                show: true,
                zeit: false,
            };
        case CHANGE_ZEIT_META_FOCUS:
            return {
                ...state,
                focus: action.name,
                show: true,
                zeit: true,
            };
        case TOGGLE_SHOW_META_MENU:
            return {
                ...state,
                show: !state.show,
            };
        default:
            return state;
    }
}
