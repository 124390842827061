import React, { Component } from 'react';
import Element from '../style/Element';
import Label from '../style/Label';
import Underline from '../style/Underline';
import styled from 'styled-components';

const Space = styled.div`
    width: 100%;
    height: 3px;
`;

export const LabelFocusHoc = PassedComponent => {
    class Meta extends Component {
        constructor() {
            super();
            this.state = {
                focus: false,
            };
        }

        // return null from setState means "no state changes"
        handleFocus = () => this.setState(({ focus }) => (focus ? null : { focus: true }));

        handleBlur = () => this.setState({ focus: false });

        render() {
            const { focus } = this.state;
            const { width, height, label, noMeta, small, large, extraLarge, noUnderline, placeholder } = this.props;
            return (
                <Element
                    tabIndex={-1}
                    width={width}
					height={height}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    noMeta={noMeta}
                    small={small}
                    large={large}
                    extraLarge={extraLarge}
					placeholder={placeholder}
                >
                    <Label focus={focus}>{label}</Label>
                    <Space />
                    <PassedComponent {...this.props} />
                    {!noUnderline ? (<Underline focus={focus} />) : (<></>)}
                </Element>
            );
        }
    }

    Meta.defaultProps = {};

    Meta.propTypes = {};

    return Meta;
};

export default LabelFocusHoc;
