import React, { Component } from 'react';

// Tutorial : https://medium.com/ecmastack/uploading-files-with-react-js-and-node-js-e7e6b707f4ef
class File extends Component {
    handleChange = e => {
        // sende bild an server
        // kriege url zurück
        // geb Url an parent component
    };

    render() {
        return (
            <div>
                <input type="file" onChange={this.handleChange} />
            </div>
        );
    }
}

File.defaultProps = {
    type: 'text',
    placeholder: '',
    maxLength: 524288,
    edit: false,
};

export default File;
