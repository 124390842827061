import React from 'react';
import styled from 'styled-components';
import Container from '../style/Container';
import TextblockArea from '../style/TextblockArea';
import Row from '../style/Row';
import Link from '../style/Link';
import LinkExtern from '../style/LinkExtern';
import H2 from '../style/H2';
import H4 from '../style/H4';
import { count } from '../service/fetch';

const Style = styled('div')`
	text-align: justify;
	justify-content: center;
	font-size: 16px;
    max-width: 100%;
    height: 100%;
	line-height: 1.5;
`;

class Hinweise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            briefCount: 3900
        };
    }

    componentDidMount() {
		count("brief", null, "sichtbar.v=true")
            .then(data => this.setState({briefCount: data}))
    }

    render() {
        return (
            <Style>
                    <Container>
						<br/><br/><br/><br/>
						<Row center>
							<H2>Über die Datenbank</H2>
							<br/><br/><br/><br/>
						</Row>
						<Row center>
							<br/><br/>
							<H4>Briefe und Überlieferungen</H4>
                        </Row>
						<Row center>
							<br/><br/>
			                <TextblockArea width="56rem">
							Diese Datenbank erschließt seit 2017 den Briefwechsel aller führenden&nbsp;<b>südwestdeutschen Theologen</b>&nbsp;(ca. 200 Personen), die 
							zwischen 1550 und 1620 in der Kurpfalz, Württemberg oder Straßburg gewirkt haben&nbsp;(➔ <LinkExtern noBreak href="https://www.hadw-bw.de/thbw" target="_blank">Projektbeschreibung</LinkExtern>).<br/><br/> 
							Die Datenbank umfasst derzeit&nbsp;<b>{this.state.briefCount} freigeschaltete Briefe</b>&nbsp;und wird bis zum Projektende im Jahr 2031 auf ca. 35.000 
							Briefe anwachsen. Bei den Brief-IDs handelt es sich um zufällige fünfstellige Zahlen ohne Bedeutung für 
							Reihenfolge oder Anzahl der Briefe.	Als&nbsp;<b>Brief</b>&nbsp;aufgenommen werden Schriftstücke, bei denen die Theologen (allein 
							oder zusammen mit anderen Personen) als Absender oder Adressat in Erscheinung treten und die an einen 
							bestimmten Empfänger oder Empfängerkreis gerichtet sind. Hierzu zählen neben Briefen im engeren Sinne auch 
							einige Gutachten und andere Textsorten mit brieflichem Charakter. Daneben werden aus zeitgenössischen 
							Drucken nicht nur Widmungsbriefe und Abdrucke anderer Briefe, sondern auch „an den Leser“ gerichtete Vorreden 
							der Theologenkorrespondenz zugerechnet. Bloße Brieferwähnungen ohne Wiedergabe von Inhaltsfragmenten 
							werden nur dann aufgenommen, wenn ein konkretes Datum bekannt ist.<br/><br/>
							Die oft mehrfache und überwiegend handschriftliche&nbsp;<b>Überlieferung</b>&nbsp;der Briefe wird möglichst vollständig verzeichnet. 
							Bei Handschriften wird neben der besitzenden Institution und der aktuell gültigen Signatur grundsätzlich auch die 
							Überlieferungsform (Konzept, Ausfertigung, ... ) angegeben. Die Reihenfolge, in der die Handschriften aufgelistet werden, 
							spiegelt aber nicht unbedingt die Entstehungsreihenfolge wieder. Die Liste der Fundorte wird um Angaben zu modernen Editionen, 
							Literaturhinweise und Links zu anderen Briefdatenbanken ergänzt. Kataloge besitzender Institutionen werden verlinkt, wenn sie 
							Datensätze zu einzelnen Briefen enthalten.<br/><br/>
							<b>Prof. Dr. Hermann Ehmer</b>&nbsp;ist für die Überlassung seiner unveröffentlichten und umfangreichen Rechercheergebnisse zum 
							Briefwechsel Johannes Brenz‘ zu danken.
							</TextblockArea>
                        </Row>
						<Row>
							<br/><br/>
						</Row>
						<Row center>
							<H4>Daten und Formate</H4>
                        </Row>
						<Row center>
							<br/><br/>
			                <TextblockArea width="56rem">
							Neben den notwendigsten&nbsp;<b>Eckdaten</b>&nbsp;(Datum, Verfasser, Absendeort und Adressat) werden auch folgende Daten 
							bei sämtlichen Briefen erhoben, sofern genannt oder zu erschließen: Zielort, Präsentationsdatum, Incipit, Sprache, 
							Überbringer und Transportstationen.	Erschlossene Angaben und Unsicherheiten werden grundsätzlich 
							gekennzeichnet.	Inhaltlich beschrieben werden die Briefe durch&nbsp;<b>Regesten</b>&nbsp;und durch&nbsp;<b>Schlagworte</b>&nbsp;der erwähnten 
							Personen, Orte und Sachen. Die Verschlagwortung der Personen und Orte erfolgt vollständig; die 
							Sachverschlagwortung liegt im Ermessen des jeweiligen Bearbeiters und zielt auf die jeweils relevantesten 
							Briefinhalte.<br/><br/> 
							Im Laufe der Zeit werden zu etwa einem Drittel der Briefe&nbsp;<b>Digitalisate</b>&nbsp;und zu einigen Briefen&nbsp;
							<b>Transkriptionen</b>&nbsp;ergänzt. Außerdem werden die vollständig&nbsp;<b>kommentierten Editionen</b>&nbsp;der gedruckten 
							➔ <LinkExtern noBreak href="/editionsbaende" target="_self">Projektbände</LinkExtern>&nbsp;
							bald nach deren	Erscheinen auch in der Datenbank geboten. Die Texte sind in&nbsp;
							<b>TEI-XML</b>&nbsp;codiert und können heruntergeladen werden.
							</TextblockArea>
                        </Row>
						<Row>
							<br/><br/>
						</Row>
						<Row center>
							<H4>Suchfunktionen</H4>
                        </Row>
						<Row center>
							<br/><br/>
			                <TextblockArea width="56rem">
							In der <Link to="/suche/brief" target="_self">Einfachen Briefsuche</Link> durchsucht&nbsp;<b>„Freie Suche“</b>&nbsp;sämtliche Metadaten, 
							Beschreibungen und Transkriptionen zugleich. Die&nbsp;<b>Zeitsuche</b>&nbsp;(TT.MM.JJJJ) der Einfachen Suche durchsucht neben dem Briefdatum 
							auch andere Zeitangaben wie das Präsentationsdatum. 
							In der <Link to="/suche/person" target="_self">Personen-</Link>, <Link to="/suche/ort" target="_self">Orts-</Link> 
							und <Link to="/suche/sache" target="_self">Sachensuche</Link> findet die Freitextoption auch Alternativbezeichnungen.<br/><br/> 
							In der <Link to="/erweitert" target="_self">Erweiterten Suche</Link> können mehrere Suchkriterien für Briefe oder Personen kombiniert und
							einzelne Attribute gezielt angesprochen werden. Zum Beispiel können über die Auswahl "Verfasser: Name" und "Text" die Texte aller transkribierten
							Brenzbriefe durchsucht werden. Die Operatoren NICHT und VORHANDEN bieten Optionen zur weitreichenden Eingrenzung der Suchergebnisse. Dank der 
							durchgehenden Verschlagwortung der Briefe empfiehlt sich für die Suche nach erwähnten Personen, Orten und typischen Sachbegriffen die Verwendung der 
							Auswahloption "Schlagworte: ...".<br/><br/> 
							In allen Suchen werden&nbsp;<b>Sonderzeichen</b>&nbsp;nicht als Wildcards interpretiert und in den meisten Fällen als Text unterstützt. 
							Sucheingaben in	Textform werden auch innerhalb der Attribute gesucht („enthält“ statt „ist gleich“) und der Abgleich erfolgt nicht 
							auf Wortebene, sondern auf Zeichenebene, sodass auch&nbsp;<b>Wortteile</b>&nbsp;gefunden werden. Die Erweiterte Suche unterscheidet&nbsp;
							<b>Groß- und Kleinschreibung</b>&nbsp;(Case sensitivity), die Einfache Suche nicht.
							</TextblockArea>
                        </Row>
						<Row>
							<br/><br/>
						</Row>
						<Row center>
							<H4>Register</H4>
                        </Row>
						<Row center>
							<br/><br/>
			                <TextblockArea width="56rem">
							Personen, Orte und Sachen können entweder in der Suche anhand verschiedener Attribute gefunden oder im&nbsp;<Link to="/filter/person" target="_self">Register</Link>&nbsp;alphabetisch aufgelistet 
							und gefiltert werden.&nbsp;<b>Personen</b>&nbsp;und&nbsp;<b>Orte</b>&nbsp;sind in der Regel mit Normdaten (GND, 
							Geonames) verlinkt. Mythologische oder literarische Gestalten werden den Personen zugerechnet, sofern es sich 
							um Menschen handelt. Andere Wesen und Götter sind im Sachregister zu finden. Das Ortsregister umfasst 
							sämtliche geographischen Kategorien zwischen Kontinenten und Gebäuden. Durch Aufruf einer Person, eines 
							Ortes oder einer Sache erhält man eine Liste aller zugehörigen Briefe. Innerhalb jedes Sachdatensatzes werden 
							außerdem übergeordnete und untergeordnete Begriffe aufgeführt, die zusammen ein thematisch geordnetes&nbsp;
							<b>Sachregister</b>&nbsp;ergeben. Es enthält behandelte Themen, Bibelstellen, Werke und Zitate, Ereignisse und Institutionen. 
							Institutionen wie Stadträte oder Universitäten können zusätzlich im Personenregister erscheinen, sofern sie als 
							Verfasser oder Adressat eines Briefes in Erscheinung treten.
							</TextblockArea>
                        </Row>
						{/*
						<Row>
							<br/><br/>
						</Row>
						<Row center>
							<H4>Abkürzungen</H4>
                        </Row>
						<Row center>
							<br/><br/>
			                <Area width="50%">
							<CenterItems>➔&nbsp;<Link to="/abkuerzungen" target="_blank">Liste der verwendeten Abkürzungen</Link>.</CenterItems>
							</Area>
                        </Row>
						*/}
						<Row>
							<br/><br/>
						</Row>
						<Row center>
							<H4>Technische Umsetzung</H4>
                        </Row>
						<Row center>
							<br/><br/>
			                <TextblockArea width="56rem">
							Die Datenbankanwendung wurde innerhalb der Forschungsstelle unter Verwendung des&nbsp;<b>MERN-Stacks</b>&nbsp;(MongoDB, 
							ExpressJS, ReactJS, NodeJS) entwickelt und wird stetig erweitert und angepasst.<br/><br/>
							Konzeption: Daniel Degen<br/><br/>
							Entwicklung: Daniel Degen, Moritz Mock, Ramdip Gill, Julian Libor, Christopher Magin<br/><br/>
							Die&nbsp;<b>XSLT-Skripte</b>&nbsp;wurden von Dario Kampkaspar erstellt.
							</TextblockArea>
                        </Row>
						<Row>
							<br/><br/>
						</Row>
						<br/><br/>
                    </Container>
            </Style>
        );
    }
}

export default Hinweise;
