import React from 'react';
import NavBar from '../style/NavBar';
import NavLink from '../style/NavLink';

export default () => (
    <NavBar>
        <NavLink to="/band/wuerttemberg1">Württemberg I</NavLink>
		<NavLink to="/band/kurpfalz1">Kurpfalz I</NavLink>
		<NavLink to="/band/strassburg1">Straßburg I</NavLink>
    </NavBar>
);
