import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from '../../service/immutables';
import { get as getLodash } from 'lodash';
import { getItem } from '../../service/fetch';
import { changeItem } from '../../store/actions/item';

import AllowedForUser from '../../container/AllowedForUser';
import CollapseArea from '../../container/CollapseArea';
import CollapseTopDown from '../../container/CollapseTopDown';
import DeleteItem from '../../container/DeleteItem';
import EditHOC from '../../container/EditHOC';
import H1Edit from '../../container/H1Edit';
import IFrame from '../../container/IFrame';
import List from '../../container/List';
import SetFavorite from '../../container/SetFavorite';
import TitleMeta from '../../container/TitleMeta';

import Seadragon from '../../components/IIIFViewer/IIIFViewer';
import Switch from '../../components/Switch';

import Area from '../../style/Area';
import Container from '../../style/Container';
import H1 from '../../style/H1';
import H3 from '../../style/H3';
import ItemContainer from '../../style/ItemContainer';
import Link from '../../style/Link';
import LineSpace from '../../style/LineSpace';
import BigStampRed from '../../style/BigStampRed';
import BigStampYellow from '../../style/BigStampYellow';
import BigStampBlue from '../../style/BigStampBlue';
import { Row } from '../../style/Row';
import RowSpace from '../../style/RowSpace';
import Space from '../../style/Space';
import Titel from '../../style/Title';
import { VerticalLine } from '../../style/VerticalLine';

import OpenSVG from '../../SVG/Open';

import {
    CheckBox,
    DropdownSimple,
    FastSearch as Search,
    Input,
    LinkedInput,
    Zeit,
} from '../../inputs/InputsWithMetaLabelFocus';

import { Textarea as TextareaNoMeta } from '../../inputs/InputsWithLabelFocus';

class Brief extends Component {
    constructor(props){
        super(props)

        this.state = {
            reproduktion: []
        }
    }
    componentDidMount(props) {
        if (getLodash(this.props, 'item._id') === undefined) {
            //erstes laden, angelegtVon muss gesetzt werden
            this.props.changeItem('angelegtVon.v', this.props.user);
        }
	
        const handschrift_id = getLodash(this.props.item, 'digitalisat.quelle.handschrift.v._id') || getLodash(this.props.item, 'handschrift[0].v._id');
        if(handschrift_id){
            getItem('Briefhandschrift', handschrift_id).then(briefhandschrift => this.setState({reproduktion: briefhandschrift.reproduktion, handschriftID: briefhandschrift._id}))
        }
    };
	
    render() {
    	const { 
				item,
				type, 
				shortNotGiven, 
				duplicate, 
				fertig, 
				eckdatenFertig,
				offen, 
				textsorte,
				incipitZuLang, 
				incipitZuKurz, 
				incipitFehler,
				incipitVorhanden,
				regestVorhanden,
				monatZahlImRegest, 
				monatZahlInErlaeuterung, 
				irrelevant, 
				noHandschrift, 
				//maxOneHandschrift, 
				noAdmin, 
				noMitarbeiter, 
				token,
				adressatExplained,
				verfasserExplained,
				praesentationsDatumVorhanden,
				datumExplained,
				NNAdressat,
				adressatName,
				noVerfasser, 
				NNVerfasser,
				verfasserLebensjahre,
				adressatLebensjahre,
				verfasserHauptfigur,
				adressatHauptfigur,
				adressatKollektiv,
				verfasserKollektiv,
				verfasserAmt,
				adressatAmt,
				//ohneTag,
				//ohneMonat,
				ohneJahr,
				jahr,
				zeitraum,
				xml
							} = this.props;
        const { reproduktion } = this.state;
		const { handschriftID } = this.state;
		const digitalisatBereit = (handschriftID && reproduktion && reproduktion.length > 0 && reproduktion[0].datei[0] && reproduktion[0].datei[0].v && reproduktion[0].datei[0].v.includes('/HID')) ? true : false;
		
		let duplicateRisk = false;
		if (!shortNotGiven && ohneJahr && !zeitraum) duplicateRisk = true;
		
		let nichtBrief = false;
		if (adressatName === 'N. N.' && textsorte === 'Bedenken') nichtBrief = true;
		if (adressatName === 'Leser' && textsorte !== 'Vorrede an den Leser') nichtBrief = true;
		
		let notYetToBePublished = false;
		if (
			duplicate || 
			duplicateRisk || 
			shortNotGiven || 
			(!datumExplained && zeitraum && !praesentationsDatumVorhanden) || 
			(nichtBrief && !fertig) || 
			incipitZuLang || 
			incipitZuKurz || 
			incipitFehler || 
			monatZahlImRegest || 
			monatZahlInErlaeuterung || 
			irrelevant
			) {
			notYetToBePublished = true;
		} 
		const verfasserGeburtsjahr = Number(verfasserLebensjahre.split('-')[0]);
		const verfasserSterbejahr = Number(verfasserLebensjahre.split('-')[1]);
		const adressatGeburtsjahr = Number(adressatLebensjahre.split('-')[0]);
		const adressatSterbejahr = Number(adressatLebensjahre.split('-')[1]);
		let dateImpossible = false;
		if (jahr) {
			if (verfasserGeburtsjahr + 6 > jahr) dateImpossible = true;
			if (adressatGeburtsjahr + 6 > jahr) dateImpossible = true;
			if (verfasserSterbejahr > 0 && verfasserSterbejahr < jahr) dateImpossible = true;
			if (adressatSterbejahr > 0 && adressatSterbejahr < jahr) dateImpossible = true;
		};
		
		let noActiveHauptfigur = false
		if (
			verfasserKollektiv === false && 
			verfasserHauptfigur && verfasserHauptfigur !== 'ja' && 
			adressatKollektiv === false && 
			adressatHauptfigur && adressatHauptfigur !== 'ja'
			) {
			noActiveHauptfigur = true;
		};
		
		let noRelevantPerson = false;
		if (
			noActiveHauptfigur && 
			verfasserHauptfigur !== 'passiv' && adressatHauptfigur !== 'passiv'
			) {
			noRelevantPerson = true;
		};
		
		let nichtBearbeiten = false;
		if (noActiveHauptfigur) nichtBearbeiten = true;
		
		let maengel = false;
		if (zeitraum || ohneJahr || !incipitVorhanden || incipitZuLang || incipitZuKurz || NNAdressat || NNVerfasser) {
			maengel = true;
		};
		
		//const digitalisatAngegeben = getLodash(item, 'digitalisat.quelle.handschrift.v._id');
			
        return (
            <>
                <Row>
                    <H1>Brief</H1>
                   	<H1Edit name="textsorte" />
                    <Space />
                    <Link to={`/ansicht/brief/${item._id}`} target="_self">
                        <OpenSVG />
                    </Link>
                    <Space />
                    <SetFavorite type={type} />
                    <Space />
                    <DeleteItem type={type} />
                    <Space />
					<AllowedForUser rolle="Admin" >
						<Area>
							<CheckBox label="fertig" name="fertig" width="30px" noMeta noUnderline />
						</Area>
						<Area>
							<DropdownSimple
                                label="Status"
                                name="sichtbar"
                                items={['offen', 'intern']}
                                default_value="intern"
								width="60px"
								noMeta
								noUnderline 
	                            />
						</Area>
					</AllowedForUser>
					<Space />
                    {duplicate && <><BigStampRed>[&nbsp;wird&nbsp;gelöscht&nbsp;]</BigStampRed><Space /></>}
					{irrelevant && <><BigStampRed>[&nbsp;nicht&nbsp;relevant&nbsp;]</BigStampRed><Space /></>}
					{noRelevantPerson && !irrelevant && <><BigStampYellow>[&nbsp;Keine&nbsp;Hauptfigur&nbsp;]</BigStampYellow><Space /></>}
					{nichtBearbeiten && !fertig && <><BigStampYellow>[&nbsp;Vollerfassung&nbsp;unnötig&nbsp;]</BigStampYellow><Space /></>}
					{nichtBrief && <><BigStampRed>[&nbsp;Briefcharakter&nbsp;prüfen&nbsp;]</BigStampRed><Space /></>}
					{dateImpossible && <><BigStampRed>[&nbsp;Datum&nbsp;unmöglich&nbsp;]</BigStampRed><Space /></>}
					{incipitFehler && <><BigStampRed>[&nbsp;Incipit&nbsp;fehlerhaft&nbsp;]</BigStampRed><Space /></>}
					{incipitZuLang && <><BigStampRed>[&nbsp;Incipit&nbsp;zu&nbsp;lang&nbsp;]</BigStampRed><Space /></>}
					{incipitZuKurz && <><BigStampRed>[&nbsp;Incipit&nbsp;zu&nbsp;kurz&nbsp;]</BigStampRed><Space /></>}
					{monatZahlImRegest && (
						<>
							<BigStampRed>[&nbsp;Monate&nbsp;ausschreiben&nbsp;im&nbsp;Regest!&nbsp;]</BigStampRed>
							<Space />
						</>
					)}
					{monatZahlInErlaeuterung && (
						<>
							<BigStampRed>[&nbsp;Monate&nbsp;ausschreiben&nbsp;in&nbsp;Erläuterung!&nbsp;]</BigStampRed>
							<Space />
						</>
					)}
					{duplicateRisk && (<><BigStampRed>[&nbsp;Erhöhtes&nbsp;Dublettenrisiko&nbsp;]</BigStampRed><Space /></>)}
					{!shortNotGiven && noVerfasser && (
						<>
							<BigStampRed>[&nbsp;Verfasser&nbsp;fehlt&nbsp;]</BigStampRed>
							<Space />
						</>
					)}
					{NNVerfasser && !verfasserExplained && !verfasserAmt && (
						<>
							<BigStampRed>[&nbsp;N.&nbsp;N.&nbsp;erläutern&nbsp;oder&nbsp;+&nbsp;Amt!&nbsp;]</BigStampRed>
							<Space />
						</>
					)}
					{NNAdressat && !adressatExplained && !adressatAmt && (
						<>
							<BigStampRed>[&nbsp;N.&nbsp;N.&nbsp;erläutern&nbsp;oder&nbsp;+&nbsp;Amt!&nbsp;]</BigStampRed>
							<Space />
						</>
					)}
					{zeitraum && !datumExplained && !praesentationsDatumVorhanden && (
						<>
							<BigStampRed>[&nbsp;Zeitraum&nbsp;begründen!&nbsp;]</BigStampRed>
							<Space />
						</>
					)}
					{offen && <BigStampBlue>[&nbsp;veröffentlicht&nbsp;]</BigStampBlue>}
                </Row>
                <H3>
                    <ItemContainer>
                        <TitleMeta />
                    </ItemContainer>
                </H3>
                <CollapseArea label="Eckdaten" show={shortNotGiven}>
                    <Row>
                        <Area>
                            <List label="Verfasser" name="verfasser" newLink="person" column disabled={fertig}>
                                {index => (
                                    <>
                                        <Search
                                            label="Person"
                                            name={`verfasser[${index}].person`}
                                            api="Zitiername"
                                            searchParam="nichtAuffuehren.brief.v=false"
											disabled={fertig}
                                        />
                                        <CollapseTopDown
                                            trigger={[
                                                `verfasser[${index}].pseudonym`,
                                                `verfasser[${index}].erlaeuterung`,
                                                `verfasser[${index}].amt`,
                                            ]}
                                        >
                                            <Input
                                                label="Amt (in Unterschrift)"
                                                name={`verfasser[${index}].amt`}
												disabled={fertig}
                                            />
                                            <CollapseTopDown
                                                trigger={[`verfasser[${index}].pseudonym`]}
                                            >
                                                <Input
                                                    label="Pseudonym"
                                                    name={`verfasser[${index}].pseudonym`}
													disabled={fertig}
                                                />
                                            </CollapseTopDown>
                                            <Input
                                                label="Erläuterungen"
                                                name={`verfasser[${index}].erlaeuterung`}
												disabled={fertig}
												noMeta
                                            />
                                        </CollapseTopDown>
                                    </>
                                )}
                            </List>
                        </Area>
                        <Area>
                            <List label="Adressat" name="adressat" newLink="person" column disabled={fertig}>
                                {index => (
                                    <>
                                        <Search
                                            label="Person"
                                            api="Zitiername"
                                            name={`adressat[${index}].person`}
                                            searchParam="nichtAuffuehren.brief.v=false"
											disabled={fertig}
                                        />
                                        <CollapseTopDown
                                            trigger={[
                                                `adressat[${index}].erlaeuterung`,
                                                `adressat[${index}].amt`,
                                            ]}
                                        >
                                            <Input
                                                label="Amt"
                                                name={`adressat[${index}].amt`}
												disabled={fertig}
												placeholder="in Adresse, ohne Zielort"
                                            />
                                            <Input
                                                label="Erläuterungen"
                                                name={`adressat[${index}].erlaeuterung`}
												disabled={fertig}
												noMeta
                                            />
                                        </CollapseTopDown>
                                    </>
                                )}
                            </List>
                        </Area>
                        <Area>
                            <List label="Zielort" name="zielort" newLink="ort" column disabled={fertig}>
                                {index => <Search label="" name={`zielort[${index}]`} api="Ort" disabled={fertig} />}
                            </List>
                        </Area>
                    </Row>
                    <Row>
                        <Area>
                            <Titel>Datum</Titel>
                            <Row>
                                <Area>
									<Row>
	                                    <Zeit label="Datierung" name="datierung" disabled={fertig}/>
										<VerticalLine/>
										<CheckBox label="ohne Datum" name="ohneDatum" noMeta noUnderline width="70px" disabled={fertig}/>
									</Row>
									<Input label="Datumstext mit Auflösungsbedarf" name="datumstext" placeholder="(ohne Anführungszeichen)" noMeta tooltip="ohne Anno etc." disabled={fertig}/>
                                    <Input label="Erläuterung" name="erlaeuterung" noMeta disabled={fertig}/>
                                </Area>
	                                <List label="Absendeort" name="absendeort" simple withArea disabled={fertig}>
	                                    {index => (
	                                        <>
	                                            <Search
	                                                label="Absendeort"
	                                                name={`absendeort[${index}].ort`}
	                                                api="Ort"
													disabled={fertig}
	                                            />
	                                            <CollapseTopDown
	                                                trigger={[`absendeort[${index}].erlaeuterung`]}
	                                            >
	                                                <Input
	                                                    label="Erläuterung"
	                                                    name={`absendeort[${index}].erlaeuterung`}
														disabled={fertig}
														noMeta
	                                                />
	                                            </CollapseTopDown>
	                                        </>
	                                    )}
	                                </List>
                            </Row>
                        </Area>
                    </Row>
                    <Row>
						<Area>
	                        <Search api="Textsorte" label="" name="textsorte" disabled={fertig}/>
	                    </Area>
                        <Area>
                            <Input label="Incipit" name={`incipit`} placeholder="(buchstabengetreu)" tooltip="ca. 5 Wörter" disabled={fertig}/>
                        </Area>
                        <List label="Sprache" name="sprache" simple withArea disabled={fertig}>
                            {index => (
                                <Search
                                    label=""
                                    api="Sprache"
                                    name={`sprache[${index}]`}
                                    small
									disabled={fertig}
                                />
                            )}
                        </List>
                    </Row>
                </CollapseArea>
                <CollapseArea label="Zustellung & Korrespondenz" show={shortNotGiven}>
					<Row>
                        <Area>
                            <List label="Überbringer" name="ueberbringer" column disabled={fertig}>
                                {index => (
                                    <Search
                                        label="Person"
                                        api="Zitiername"
                                        name={`ueberbringer[${index}]`}
                                        searchParam="nichtAuffuehren.brief.v=false"
										disabled={fertig}
                                    />
                                )}
                            </List>
                        </Area>
                        <Area>
                            <List label="via" name="via" column disabled={fertig}>
                                {index => <Search label="Ort" api="Ort" name={`via[${index}]`} disabled={fertig} />}
                            </List>
                        </Area>
                    </Row>
					<Row>
                        <Area>
                            <Titel>Empfang</Titel>
                            <Row>
                                <Area>
                                    <Zeit label="Präsentationsdatum" name="praesentation" disabled={fertig}/>
                                    <Zeit label="Eingang in Kanzlei" name="eingang" disabled={fertig}/>
                                </Area>
                                <List label="Ort" name="empfangsort" simple column disabled={fertig}>
                                    {index => (
                                        <Search
                                            label="Empfangsort"
                                            api="Ort"
                                            name={`empfangsort[${index}]`}
											disabled={fertig}
                                        />
                                    )}
                                </List>
                            </Row>
                        </Area>
                    </Row>
					<Row>
	                    <Area>
	                        <List label="Antwort auf" name="antwortAuf" column disabled={fertig}>
	                            {index => (
	                                <Switch name={`antwortAuf[${index}].show`}>
	                                    <Search
	                                        label="Brief-ID"
	                                        api="Brief"
	                                        name={`antwortAuf[${index}].briefID`}
											disabled={fertig}
	                                    />
	                                    <Input
	                                        label="Brief (Beschreibung)"
	                                        name={`antwortAuf[${index}].briefohneID`}
											disabled={fertig}
											noMeta
	                                    />
	                                </Switch>
	                            )}
	                        </List>
	                    </Area>
	                    <Area>
	                        <List label="außerdem erwähnte Briefe:" name="bezugAuf" column disabled={fertig}>
	                            {index => (
	                                <Switch name={`bezugAuf[${index}].show`}>
	                                    <Search
	                                        label="Brief-ID"
	                                        api="Brief"
	                                        name={`bezugAuf[${index}].briefID`}
											disabled={fertig}
	                                    />
	                                    <Input
	                                        label="Brief (Beschreibung)"
	                                        name={`bezugAuf[${index}].briefohneID`}
											disabled={fertig}
											noMeta
	                                    />
	                                </Switch>
	                            )}
	                        </List>
	                    </Area>
						<Area>
	                        <List label="beantwortet durch" name="beantwortetDurch" column disabled={fertig}>
	                            {index => (
	                                <Switch name={`beantwortetDurch[${index}].show`}>
	                                    <Search
	                                        label="Brief-ID"
	                                        api="Brief"
	                                        name={`beantwortetDurch[${index}].briefID`}
											disabled={fertig}
	                                    />
	                                    <Input
	                                        label="Brief (Beschreibung)"
	                                        name={`beantwortetDurch[${index}].briefohneID`}
											disabled={fertig}
											noMeta
	                                    />
	                                </Switch>
	                            )}
	                        </List>
	                    </Area>
                    </Row>
				</CollapseArea>
                <CollapseArea label="Regest" show={true}>
                    <Row>
                        <Area>
                            <TextareaNoMeta label="Regest" name="regest.text" extraLarge disabled={fertig} />
							<Row>
		                        <List label="Autor" name="regest.von" simple disabled={fertig}>
		                            {index => (
		                                <Search
		                                    label="Autor"
		                                    api="Mitarbeiter"
		                                    name={`regest.von[${index}]`}
		                                    small
											disabled={fertig}
		                                />
		                            )}
		                        </List>
								<Container>
                            		<Zeit label="am" name="regest.am" today />
								</Container>
							</Row> 
							<Row>
								{/*<CollapseTopDown trigger={[]} >*/}	
									<Search
			                            label="Georges"
			                            api="Latein"
										noMeta
										extraLarge
										placeholder="Latein"
			                        />
								{/*</CollapseTopDown>*/}	                            
							</Row>
                        </Area>
                    </Row>
                </CollapseArea>
                <CollapseArea label="Schlagworte" show={true}>
                	<RowSpace/>
			        <CollapseArea label="Personen" small>
			        	<Area>
				            <List label="" name="schlagworte.personen" newLink="person">
				                {index => (
				                    <Search
				                        label="Person"
				                        api="Zitiername"
				                        name={`schlagworte.personen[${index}]`}
				                        searchParam="nichtAuffuehren.brief.v=false"
				                    />
				                )}
				            </List>
			        	</Area>
			        </CollapseArea>
			        <CollapseArea label="Orte" small>
				        <Area>
				            <List label="" name="schlagworte.orte" newLink="ort">
				                {index => 
									<Search 
										label={getLodash(item, `schlagworte.orte[${index}].v.typName.v`)}
										api="Ort"
										name={`schlagworte.orte[${index}]`} 
									/>}
				            </List>
				        </Area>
			        </CollapseArea>
			        <CollapseArea label="Sachen" small>
				        <Area>
				            <List label="" name="schlagworte.sachen" newLink="sache">
				                {index =>
									<Search 
										label={getLodash(item, `schlagworte.sachen[${index}].v.sachgruppeName.v`) && getLodash(item, `schlagworte.sachen[${index}].v.sachgruppeName.v`).split(' (')[0]}
										api="Sache"
										name={`schlagworte.sachen[${index}]`}
									/>}
				            </List>
				        </Area>
			        </CollapseArea>
			    </CollapseArea>
                <CollapseArea label="Transkription" show={false}>
                    <Area>
                        <Row>
								<List label="transkribiert von" name={`transkription.von`} simple column>
	                                {index => (
	                                    <Search
	                                        label="Bearbeiter"
	                                        api="Mitarbeiter"
	                                        name={`transkription.von[${index}]`}
											small
	                                    />
	                                )}
	                            </List>
							<Area>
                                <CheckBox label="vorgemerkt" name="transkription.vorschlag" noUnderline noMeta width="70px" />
                            </Area>
                            <Area>
                                <CheckBox
                                    label="Bearbeitung abgeschlossen"
                                    name="transkription.fertig"
									disabled={noMitarbeiter}
									noUnderline
                                />
								<Space/>
                                <CollapseTopDown trigger={[]}>
                                    <Zeit
                                        label="fertiggestellt am"
                                        name={`transkription.am`}
                                        today
                                    />
                                    <List
                                        label="Korrektur"
                                        name="transkription.geprueft"
                                        simple
                                        column
                                    >
                                        {index => (
                                            <>
                                                <Search
                                                    label="korrekturgelesen durch"
                                                    api="Mitarbeiter"
                                                    name={`transkription.geprueft[${index}].durch`}
                                                />
												<Space/>
                                                <Zeit
                                                    label="korrekturgelesen am"
                                                    name={`transkription.geprueft[${index}].am`}
                                                    today
                                                />
                                            </>
                                        )}
                                    </List>
                                </CollapseTopDown>
                            </Area>
                            <Area>
	                            <CheckBox
									label="Umwandlung einwandfrei"
									name="transkription.datei"
									noUnderline
									noMeta
									width="150px"
								/>
                            </Area>
                        </Row>
                        <Row>
                            <List label="Quelle" name="transkription.quelle" simple column>
                                {index => (
                                    <Switch name={`transkription.quelle[${index}].show`}>
                                        <Search
                                            label="Textvorlage"
                                            api="Briefhandschrift"
                                            name={`transkription.quelle[${index}].handschrift`}
                                            proposal={`handschrift`}
                                            width="450px"
                                        />
                                        <Search
                                            label="Textvorlage"
                                            api="Briefdruck"
                                            name={`transkription.quelle[${index}].druck`}
                                            proposal={`druck`}
                                            large
                                        />
                                        <Row>
                                            <Search
                                                label="Textvorlage"
                                                api="Literatur"
                                                name={`transkription.quelle[${index}].literatur.literatur`}
                                                medium
                                            />
                                            <Input
                                                label="Seite"
                                                name={`transkription.quelle[${index}].literatur.seite`}
                                                placeholder="S."
                                                helperForUserInput="S. "
                                                small
                                            />
                                        </Row>
                                    </Switch>
                                )}
                            </List>
                        </Row>
						<TextareaNoMeta
                            name={`transkription.anmerkung`}
                            label="Details zur unvollendeten/fehlerhaft umgewandelten Transkription"
                            extraLarge
                        />
                        {xml && (<IFrame />)}
                    </Area>
				</CollapseArea>
				{!shortNotGiven && !noHandschrift && digitalisatBereit && (
				<CollapseArea label="Digitalisat" show={handschriftID}>
					<Area>
						{!noAdmin && (
	                        <Switch name={`digitalisat.quelle.show`} >
	                            <Search
	                                label="Quelle"
	                                api="Briefhandschrift"
									placeholder="Briefhandschrift (Auswahl wirkt erst nach dem Speichern!)"
	                                key="Briefhandschrift"
	                                name={`digitalisat.quelle.handschrift`}
	                                proposal={`handschrift`}
	                                extraLarge
									noMeta
									disabled={noAdmin}
	                            />
	                            <Search
	                                label="Quelle"
	                                api="Briefdruck"
									placeholder="Briefdruck (Auswahl wirkt erst nach dem Speichern!)"
	                                key="Druck"
	                                name={`digitalisat.quelle.druck`}
	                                proposal={`druck`}
	                               	extraLarge
									noMeta
									disabled={noAdmin}
	                            />
	                        </Switch>
	                    )}
						{digitalisatBereit && (
							<Seadragon 
								briefhandschrift_id={handschriftID}
								filepaths={reproduktion[0].datei}
								userToken={token}
							/>)}
						{noAdmin && (
	                        <Switch name={`digitalisat.quelle.show`} >
	                            <Search
	                                label="Quelle"
	                                api="Briefhandschrift"
	                                key="Briefhandschrift"
	                                name={`digitalisat.quelle.handschrift`}
	                                proposal={`handschrift`}
	                                extraLarge
									noMeta
									disabled={noAdmin}
	                            />
	                            <Search
	                                label="Quelle"
	                                api="Briefdruck"
	                                key="Druck"
	                                name={`digitalisat.quelle.druck`}
	                                proposal={`druck`}
	                               	extraLarge
									noMeta
									disabled={noAdmin}
	                            />
	                        </Switch>
	                    )}
                    </Area>
                </CollapseArea>)}
                <CollapseArea label="Überlieferung & Literatur" show={true}>
                    <Row>
                        <Area>
                            <List 
                            	label="Handschriftliche Überlieferungen (Reihenfolge nach Rang, dann nach Archiv)" 
                            	name="handschrift" 
                            	disabled={fertig}
                            	newLink="briefhandschrift"
                            >
                                {index => (
                                    <Search
                                        label=""
                                        api="Briefhandschrift"
                                        name={`handschrift[${index}]`}
										disabled={fertig}
										large
                                    />
                                )}
                            </List>
                        </Area>
                    </Row>
                    <Row>
                        <Area>
                            <List 
                            	label="Gedruckte Überlieferungen (Reihenfolge nach Jahr)"
                            	name="druck" 
                            	disabled={fertig}
                            	newLink="briefdruck"
                            >
                                {index => (
                                    <Search 
                                    	label=""
                                    	api="Briefdruck"
                                    	name={`druck[${index}]`}
                                    	disabled={fertig}
                                    	large
                                     />
                                )}
                            </List>
                        </Area>
                    </Row>
                    <Row>
                        <Area>
                            <List label="Editionen" name="edition" column disabled={fertig}>
                                {index => (
                                    <>
                                        <Row>
                                            <Search
                                                label=""
                                                api="Literatur"
                                                name={`edition[${index}].literatur`}
												disabled={fertig}
                                            />
											<VerticalLine />
                                            <Input
                                                label="Seite"
                                                name={`edition[${index}].seite`}
                                                placeholder="S. (Nr.)"
                                                helperForUserInput="S. "
                                                small
												disabled={fertig}
                                            />
                                            <VerticalLine />
                                            <List
                                                label="nach"
                                                name={`edition[${index}].nach`}
                                                simple
												disabled={fertig}
                                            >
                                                {index2 => (
                                                    <Switch
                                                        name={`edition[${index}].nach[${index2}].show`}
                                                    >
                                                        <Search
                                                            label="nach"
                                                            api="Briefdruck"
                                                            name={`edition[${index}].nach[${index2}].druck`}
                                                            proposal={`druck`}
															disabled={fertig}
                                                        />
                                                        <Search
                                                            label="nach"
                                                            api="Briefhandschrift"
                                                            name={`edition[${index}].nach[${index2}].handschrift`}
                                                            proposal={`handschrift`}
															disabled={fertig}
                                                        />
                                                        <Search
                                                            label="nach"
                                                            placeholder="Edition"
                                                            api="Literatur"
                                                            name={`edition[${index}].nach[${index2}].literatur`}
															disabled={fertig}
                                                        />
                                                    </Switch>
                                                )}
                                            </List>
                                        </Row>
                                    </>
                                )}
                            </List>
                        </Area>
                    </Row>
                    <Row>
                        <Area>
                            <List label="Literatur" name="literatur" column disabled={fertig}>
                                {index => (
                                    <Row>
                                        <Search
                                            label=""
                                            name={`literatur[${index}].literatur`}
                                            api="Literatur"
											disabled={fertig}
                                        />
										<VerticalLine />
                                        <Input
                                            label="Seite"
                                            name={`literatur[${index}].seite`}
                                            placeholder="S."
                                            helperForUserInput="S. "
                                            small
											disabled={fertig}
                                        />
                                    </Row>
                                )}
                            </List>
                        </Area>
                    </Row>
                    <Row>
                        <Area>
                            <List label="Datenbanken" name={`url`} column disabled={fertig}>
                                {index => (
                                    <LinkedInput
                                        label="Link zum Datensatz"
                                        name={`url[${index}]`}
                                        placeholder="http://..."
                                        large
										noMeta
										disabled={fertig}
                                    />
                                )}
                            </List>
                        </Area>
                    </Row>
                </CollapseArea>
                <CollapseArea label="Bearbeitung" show={true}>
					<Area>
                        <TextareaNoMeta
                            name={`bearbeitungsnotiz`}
                            label="Bearbeitungsnotiz"
                            extraLarge
                        />     
                    </Area>
                    <Row>
                        <Area>
                            <Titel>Eckdaten</Titel>
                            <Search label="durch" api="Mitarbeiter" name={`angelegtVon`} disabled={fertig} />
                            <List label="Quelle(n)" name="eckdatenQuellen" simple column disabled={fertig}>
                                {index => (
                                    <Switch name={`eckdatenQuellen[${index}].show`}>
                                        <>
                                            <Search
                                                label="Quelle"
                                                api="Briefhandschrift"
                                                name={`eckdatenQuellen[${index}].handschrift`}
                                                proposal={`handschrift`}
												disabled={fertig}
                                            />
                                        </>
                                        <>
                                            <Search
                                                label="Quelle"
                                                api="Briefdruck"
                                                name={`eckdatenQuellen[${index}].druck`}
                                                proposal={`druck`}
												disabled={fertig}
                                            />
                                        </>
                                        <>
                                            <Search
                                                label="Quelle"
                                                api="Literatur"
                                                name={`eckdatenQuellen[${index}].literatur`}
												disabled={fertig}
                                            />
                                            <Input
                                                name={`eckdatenQuellen[${index}].seite`}
                                                label="Seite"
                                                placeholder="S."
                                                helperForUserInput="S. "
                                                small
												disabled={fertig}
                                            />
                                        </>
                                    </Switch>
                                )}
                            </List>
							<CheckBox label="vollständig & endgültig" name="eckdatenFertig" noMeta noUnderline disabled={fertig || noMitarbeiter || (maengel && noAdmin && !eckdatenFertig)}/>
                            </Area>
                            <Area>
                            <Titel>Erfassung & Freischaltung</Titel>
                        	<CheckBox label="Bearbeitung abgeschlossen" noMeta noUnderline name="fertig" width="260px" disabled={noMitarbeiter || !regestVorhanden}/>
							<br/>
							<DropdownSimple
                                label="Öffentlichkeitsstatus"
                                name="sichtbar"
                                items={['offen', 'intern'/*, 'verbergen'*/]}
                                default_value="intern"
								disabled={noMitarbeiter || notYetToBePublished}
								noMeta noUnderline 
                            />
							</Area>
							<Area>
								<Titel>Edition</Titel>
								<Row>
									<Search
	                                    label="Vorschlag von"
	                                    api="Mitarbeiter"
	                                    name={`vorgeschlagenVon`}
										noMeta
										width="90px"
		                            />
									<VerticalLine/>
									<Search
	                                    label="für"
	                                    api="Band"
	                                    name={`band`}
										small
		                            />
									<VerticalLine/>
								</Row>
								<Row>
									<DropdownSimple
		                                label="Beschluss"
		                                name="aufnehmen"
		                                items={['', 'ja', 'nein', 'eher ja', 'eher nein', 'vielleicht', 'ausstehend']}
		                                default_value=""
										noUnderline
										noMeta
										width="85px"
		                            />
									<VerticalLine/>
									<Search
	                                    label="Edition durch"
	                                    api="Mitarbeiter"
	                                    name={`ediertVon`} 
										small
		                            />
								</Row>
								<Row>
									<CheckBox label="Edition fertig" noUnderline noMeta width="85px" name="editionFertig"/>
									<VerticalLine/>
									<Input
			                            name={`wordSeiten`}
			                            label="Wordseiten"
										small
			                        />
								</Row>
                        	</Area>
                    </Row>
					<Area>
                        <TextareaNoMeta
                            name={`aufnehmenAnm`}
                            label="Argumentation zur Aufnahme in Editionsband"
                            extraLarge
                        />     
                    </Area>
				</CollapseArea>
				<CollapseArea label="Löschen" show={duplicate}>
                    <Area>
                        <Row>
                            <Area>
								<Row>
                                	<CheckBox label="Brief löschen" name="briefLoeschen" disabled={fertig} width="85px" noMeta noUnderline />
                                	<CheckBox label="nicht relevant" name="nichtRelevant" disabled={fertig} width="85px" noMeta noUnderline />
								</Row>
								<Space/>
                                <Search label="da Dublette von" api="Brief" name={`dubletteVon`} disabled={fertig} width="100px" noMeta />
								<LineSpace/>
                                <CheckBox label="Daten vereinigt & übertragen" name="datenUebertragen" disabled={fertig} width="185px" noMeta noUnderline/>
                            </Area>
                            <Area>
                                <CheckBox label="hiesige Handschrift löschen" name="handschriftLoeschen" disabled={fertig} width="185px" noMeta noUnderline/>
								<Space/>
                                <CheckBox label="da ebenfalls Dublette" name="handschriftDublette" disabled={fertig} width="185px" noMeta noUnderline/>
								<Space/><Space/>
                                <CheckBox label="Hs.-Daten sind übertragen" name="handschriftDatenUebertragen" disabled={fertig} width="185px" noMeta noUnderline/>
                            </Area> 
                        </Row>
                    </Area>
                </CollapseArea>
				{/*<>{getLodash(item, 'searchTags.v')}</>*/}
            </>
        );
    }
}
const mapStateToProps = state => {
    const user = {
        short: {
            v: getLodash(state, 'auth.user.short'),
        },
        _id: getLodash(state, 'auth.user.id'),
    };
	let noAdmin = true;
	let noMitarbeiter = true;
	if (get('user.role', state.auth) === 'Admin') {
		noAdmin = false;
		noMitarbeiter = false;
		}
	if (get('user.role', state.auth) === 'Mitarbeiter') noMitarbeiter = false;
	
    return {
        item: getLodash(state, 'item'),
        user,
		//short: state.item.short && state.item.short.v,
        shortNotGiven: state.item.short && state.item.short.v ? false : true, // für Collapsearea
        duplicate: state.item.briefLoeschen && state.item.briefLoeschen.v,
		irrelevant: state.item.nichtRelevant && state.item.nichtRelevant.v,
		textsorte: state.item.textsorte && state.item.textsorte.v && state.item.textsorte.v.short.v,
		incipitFehler: state.item.incipit && state.item.incipit.v && state.item.incipit.v.includes('...'),
		incipitZuLang: state.item.incipit && state.item.incipit.v && state.item.incipit.v.length > 55,
		incipitZuKurz: state.item.incipit && state.item.incipit.v && state.item.incipit.v.length < 19,
		incipitVorhanden: state.item.incipit && state.item.incipit.v ? true : false,
		regestVorhanden: state.item.regest && state.item.regest.text && state.item.regest.text.v ? true : false,
		ohneTag: state.item.datierung && state.item.datierung.am && state.item.datierung.am.t && state.item.datierung.am.t.v ? false : true,
		ohneMonat: state.item.datierung && state.item.datierung.am && state.item.datierung.am.m && state.item.datierung.am.m.v ? false : true,
		ohneJahr: state.item.datierung && state.item.datierung.am && state.item.datierung.am.j && state.item.datierung.am.j.v ? false : true,
		jahr: state.item.datierung && state.item.datierung.am && state.item.datierung.am.j && state.item.datierung.am.j.v,
		zeitraum: state.item.datierung && state.item.datierung.between ? true : false,
		NNAdressat: state.item.adressat && state.item.adressat[0] && state.item.adressat[0].person && state.item.adressat[0].person.v && state.item.adressat[0].person.v.short.v === 'N. N.' ? true : false,
		noVerfasser: state.item.verfasser && state.item.verfasser[0] && state.item.verfasser[0].person && state.item.verfasser[0].person.v ? false : true,
		NNVerfasser: state.item.verfasser && state.item.verfasser[0] && state.item.verfasser[0].person && state.item.verfasser[0].person.v && state.item.verfasser[0].person.v.short.v === 'N. N.' ? true : false,
		verfasserExplained: state.item.verfasser && state.item.verfasser[0] && state.item.verfasser[0].erlaeuterung && state.item.verfasser[0].erlaeuterung.v ? true : false,
		adressatExplained: state.item.adressat && state.item.adressat[0] && state.item.adressat[0].erlaeuterung && state.item.adressat[0].erlaeuterung.v ? true : false,
		verfasserName: state.item.verfasser && state.item.verfasser[0] && state.item.verfasser[0].person && state.item.verfasser[0].person.v && state.item.verfasser[0].person.v.short.v,
		verfasserAmt: state.item.verfasser && state.item.verfasser[0] && state.item.verfasser[0].amt && state.item.verfasser[0].amt.v ? true : false,
		adressatName: state.item.adressat && state.item.adressat[0] && state.item.adressat[0].person && state.item.adressat[0].person.v && state.item.adressat[0].person.v.short.v,
		adressatAmt: state.item.adressat && state.item.adressat[0] && state.item.adressat[0].amt && state.item.adressat[0].amt.v ? true : false,
		adressatLebensjahre: (state.item.adressat && state.item.adressat[0] && state.item.adressat[0].person && state.item.adressat[0].person.v && state.item.adressat[0].person.v.lebensjahre && state.item.adressat[0].person.v.lebensjahre.v) || '',
		verfasserLebensjahre: (state.item.verfasser && state.item.verfasser[0] && state.item.verfasser[0].person && state.item.verfasser[0].person.v && state.item.verfasser[0].person.v.lebensjahre && state.item.verfasser[0].person.v.lebensjahre.v) || '',
		adressatHauptfigur: state.item.adressat && state.item.adressat[0] && !state.item.adressat[1] && state.item.adressat[0].person && state.item.adressat[0].person.v && state.item.adressat[0].person.v.hauptfigur && state.item.adressat[0].person.v.hauptfigur.v,
		verfasserHauptfigur: state.item.verfasser && state.item.verfasser[0] && !state.item.verfasser[1] && state.item.verfasser[0].person && state.item.verfasser[0].person.v && state.item.verfasser[0].person.v.hauptfigur && state.item.verfasser[0].person.v.hauptfigur.v,
		adressatKollektiv: state.item.adressat && state.item.adressat[0] && !state.item.adressat[1] && state.item.adressat[0].person && state.item.adressat[0].person.v && state.item.adressat[0].person.v.kollektiv && state.item.adressat[0].person.v.kollektiv.v,
		verfasserKollektiv: state.item.verfasser && state.item.verfasser[0] && !state.item.verfasser[1] && state.item.verfasser[0].person && state.item.verfasser[0].person.v && state.item.verfasser[0].person.v.kollektiv && state.item.verfasser[0].person.v.kollektiv.v,
		monatZahlImRegest: state.item.regest && state.item.regest.text && state.item.regest.text.v && (state.item.regest.text.v.includes('.1.') || state.item.regest.text.v.includes('.2.') || state.item.regest.text.v.includes('.3.') || 
		state.item.regest.text.v.includes('.4.') || state.item.regest.text.v.includes('.5.') || state.item.regest.text.v.includes('.6.') || state.item.regest.text.v.includes('.7.') || state.item.regest.text.v.includes('.8.') || 
		state.item.regest.text.v.includes('.9.') || state.item.regest.text.v.includes('.10.') || state.item.regest.text.v.includes('.11.') || state.item.regest.text.v.includes('.12.') || state.item.regest.text.v.includes('.01.') || 
		state.item.regest.text.v.includes('.02.') || state.item.regest.text.v.includes('.03.') || state.item.regest.text.v.includes('.04.') || state.item.regest.text.v.includes('.05.') || state.item.regest.text.v.includes('.06.') || 
		state.item.regest.text.v.includes('.07.') || state.item.regest.text.v.includes('.08.') || state.item.regest.text.v.includes('.09.')),
		datumExplained: state.item.erlaeuterung && state.item.erlaeuterung.v,
		praesentationsDatumVorhanden: state.item.praesentation && state.item.praesentation.am && state.item.praesentation.am.m && state.item.praesentation.am.m.v,
		monatZahlInErlaeuterung: state.item.erlaeuterung && state.item.erlaeuterung.v && (state.item.erlaeuterung.v.includes('.1.') || state.item.erlaeuterung.v.includes('.2.') || state.item.erlaeuterung.v.includes('.3.') || 
		state.item.erlaeuterung.v.includes('.4.') || state.item.erlaeuterung.v.includes('.5.') || state.item.erlaeuterung.v.includes('.6.') || state.item.erlaeuterung.v.includes('.7.') || state.item.erlaeuterung.v.includes('.8.') || 
		state.item.erlaeuterung.v.includes('.9.') || state.item.erlaeuterung.v.includes('.10.') || state.item.erlaeuterung.v.includes('.11.') || state.item.erlaeuterung.v.includes('.12.') || state.item.erlaeuterung.v.includes('.01.') || 
		state.item.erlaeuterung.v.includes('.02.') || state.item.erlaeuterung.v.includes('.03.') || state.item.erlaeuterung.v.includes('.04.') || state.item.erlaeuterung.v.includes('.05.') || state.item.erlaeuterung.v.includes('.06.') || 
		state.item.erlaeuterung.v.includes('.07.') || state.item.erlaeuterung.v.includes('.08.') || state.item.erlaeuterung.v.includes('.09.')),
		fertig: state.item.fertig && state.item.fertig.v,
		eckdatenFertig: state.item.eckdatenFertig && state.item.eckdatenFertig.v,
		xml: state.item.xml && state.item.xml.v,
		offen: state.item.sichtbar && state.item.sichtbar.v === 'offen',
		noAdmin: noAdmin,
		maxOneHandschrift: state.item.handschrift && state.item.handschrift[1] && state.item.handschrift[1].v ? false : true,
		noHandschrift: state.item.handschrift && state.item.handschrift[0] && state.item.handschrift[0].v ? false : true,
        noMitarbeiter: noMitarbeiter,
        token: state.auth.token
    };
};
const mapDispatchToProps = {
    changeItem,
};
export default EditHOC(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Brief)
);
