import React from 'react';
import EditHOC from '../../container/EditHOC';
import Area from '../../style/Area';
import H1 from '../../style/H1';
import { Row } from '../../style/Row';
import DeleteItem from '../../container/DeleteItem';
import { DropdownSimple, Input } from '../../inputs/InputsWithMetaLabelFocus';
import SetFavorite from '../../container/SetFavorite';
import Space from '../../style/Space';

const Mitarbeiter = ({ type }) => (
    <>
        <Row>
            <H1>Mitarbeiter</H1>
            <Space />
            <SetFavorite type={type} />
            <Space />
            <DeleteItem type={type} />
        </Row>
        <Row>
            <Area>
                <Input label="Name" name="name" />
            </Area>
            <Area>
                <Input label="Kürzel" name="kuerzel" />
            </Area>
            <Area>
                <DropdownSimple
                    label="Rolle"
                    name="role"
                    items={['Admin', 'Mitarbeiter', 'Hiwi', 'Gast', 'ausgeschieden']}
                    disableEmpty={true}
                    default_value={'Gast'}
                />
            </Area>
            <Area>
                <Input label="Passwort" name="password" type="password" />
            </Area>
        </Row>
    </>
);

export default EditHOC(Mitarbeiter);
