import styled, { keyframes } from 'styled-components';
import { lightBlue, lightGrey, red } from './colors';

const keyFrameExampleOne = keyframes`
  0% {
    transform: scaleX(0)
  }
  100% {
    transform: scaleX(1)
  }
`;

export const Underline = styled('div')`
    height: ${({ focus }) => (focus ? '2px' : '1px')};
    width: 100%;
    background-color: ${({ focus }) =>
        focus
            ? ({ valid }) => (!valid ? lightBlue : red)
            : ({ valid }) => (valid ? red : lightGrey)};
    margin-top: 3px;
    margin-bottom: ${({ focus }) => (focus ? '3px' : '4px')};
    animation: ${({ focus }) => focus && keyFrameExampleOne} 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

export default Underline;
