import styled from 'styled-components';

export default styled('div')`
    padding-top: ${props => (
			props.noTopBottomPadding ? '0px' : props.smallTopBottomPadding ? '5px' : props.greatTopBottomPadding ? '100px' : '10px'
			)};
	padding-bottom: ${props => (
			props.noTopBottomPadding ? '0px' : props.smallTopBottomPadding ? '5px' : props.greatTopBottomPadding ? '100px' : '10px'
			)};
	padding-left: ${props => (props.noLeftRightPadding ? '0px' : props.smallLeftRightPadding ? '4px' : '15px')};
	padding-right: ${props => (props.noLeftRightPadding ? '0px' : props.smallLeftRightPadding ? '4px' : '15px')};
	height: ${props => (props.height ? props.height : '')};
	max-width: ${props => (props.width ? props.width : '')};
`;
