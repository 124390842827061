import React from 'react';
import EditHOC from '../../../container/EditHOC';
import { Input } from '../../../inputs/InputsWithMetaLabelFocus';
import Area from '../../../style/Area';
import H1 from '../../../style/H1';
import { Row } from '../../../style/Row';
import DeleteItem from '../../../container/DeleteItem';
import SetFavorite from '../../../container/SetFavorite';
import Space from '../../../style/Space';

const Band = ({ type }) => (
    <>
        <Row>
            <H1>Band</H1>
            <Space />
            <SetFavorite type={type} />
            <Space />
            <DeleteItem type={type} />
        </Row>
        <Area>
            <Input label="Band" name="name" />
        </Area>
    </>
);

export default EditHOC(Band);
