import React from 'react';
import EditHOC from '../../container/EditHOC';
import Area from '../../style/Area';
import H1 from '../../style/H1';
import { Row } from '../../style/Row';
import Long from '../../container/Long';
import DeleteItem from '../../container/DeleteItem';
import { Input } from '../../inputs/InputsWithMetaLabelFocus';
import SetFavorite from '../../container/SetFavorite';
import Space from '../../style/Space';

const Latein = ({ type }) => (
    <>
        <Row>
            <H1>Latein</H1>
            <Space />
            <SetFavorite type={type} />
            <Space />
            <DeleteItem type={type} />
        </Row>
		<Row>
        	<Long />
		</Row>
        <Area>
            <Input label="Wort" name="wort" />
        </Area>
        <Area>
            <Input label="Übersetzung" name="uebersetzung" extraLarge/>
        </Area>
    </>
);

export default EditHOC(Latein);
