import React from 'react';
import { get } from 'lodash';

import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';
import AllowedForUser from '../../container/AllowedForUser';
import SearchHOC from '../../container/SearchHOC';

import { OrtRow as TableRow } from '../../style/SearchRow';
import Cell from '../../style/SearchCell';
import Link from '../../style/Link';
import LinkExtern from '../../style/LinkExtern';
import Row from '../../style/Row';

import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import DeleteSVG from '../../SVG/Delete';
import OpenSVG from '../../SVG/Open';

import { CheckBox, FastSearch as Search, Input } from '../../inputs/InputsWithLabelFocus';

const SearchOrt = ({ items, search, defaultSort, handleChangeSort, sort, asc, loading }) => {
    if (!sort.length) defaultSort('name.v');
    return (
        <>
            <>
                <TableRow>
                    <Cell block>{null}</Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Name"
                            name="name"
                            value={get(search, 'name') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('name.v')}
                            direction={'name.v' === sort ? !asc : false}
                            active={'name.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <CheckBox label="geprüft" name="geprueft" width="100%" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('geprueft.v')}
                            direction={'geprueft.v' === sort ? !asc : false}
                            active={'geprueft.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="jüngster Name"
                            name="amtlich"
                            value={get(search, 'amtlich') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('amtlich.v')}
                            direction={'amtlich.v' === sort ? !asc : false}
                            active={'amtlich.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input noMeta width="100%" label="Andere Namen" name="andereNamen.form" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('andereNamen.form.v')}
                            direction={'andereNamen.form.v' === sort ? !asc : false}
                            active={'andereNamen.form.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Kategorie"
                            api="Geo"
                            name="geoKat"
                            placeholder="Kategorie"
                            value={get(search, 'geoKat')}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('geoKat.v')}
                            direction={'geoKat.v' === sort ? !asc : false}
                            active={'geoKat.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="GND"
                            name="gnd"
                            value={get(search, 'gnd') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('gnd.v')}
                            direction={'gnd.v' === sort ? !asc : false}
                            active={'gnd.v' === sort}
                        />
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                {/* vertikaler Platzhalter für Dropdownmenüs: */}
			 	{items.length < 1 && (
					 <>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
					 </>
				 )}
                <Loading loading={loading}>
                    {items.map((item, i) => (
                        <TableRow key={i}>
                            <Link to={`/edit/ort/${item._id}`} target={false}>
                                <OpenSVG />
                            </Link>
                            <Cell>{item.name && item.name.v}</Cell>
                            <Cell>{item.geprueft && item.geprueft.v ? '✔' : ''}</Cell>
                            <Cell>{item.amtlich && item.amtlich.v}</Cell>
                            <Cell block>
                                {item.andereNamen && item.andereNamen.map((e, i) => (
                                    <>
                                        {get(e, 'form.v')}
                                        <br />
                                    </>
                                ))}
                            </Cell>
                            <Cell>{item.geoKat && item.geoKat.v && item.geoKat.v.short.v}</Cell>
                            <Cell>
                                {get(item, 'gnd.v') !== "-" ?
                                    <LinkExtern href={item.gnd && item.gnd.v} target="_blank">
                                        {get(item, 'gnd.v')
                                            ? get(item, 'gnd.v')
                                                  .replace('https://d-nb.info/gnd/', '')
                                                  .replace('http://d-nb.info/gnd/', '')
                                            : ''}
                                    </LinkExtern>
                                :
                                    <>
                                        -
                                    </>
                                }
                            </Cell>
                            <AllowedForUser rolle="Admin">
                                <Link to={`/ref/ort/${item._id}`}>
                                    <DeleteSVG />
                                </Link>
                            </AllowedForUser>
                        </TableRow>
                    ))}
                </Loading>
            </>
            <AllowedForUser rolle="Hiwi">
                <NewButton type="ort" target="_blank" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchOrt);
