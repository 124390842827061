import {
    CHANGE_AUTH,
    AUTH_LOGIN,
    AUTH_LOGOUT,
} from '../constants/auth';
import jwt_decode from 'jwt-decode';

export const changeAuth = (name, value) => {
    return { type: CHANGE_AUTH, name, value }
};

export const logout = () => ({ type: AUTH_LOGOUT });

export const login = token => {
    const decoded = jwt_decode(token);

    return {
        type: AUTH_LOGIN,
        decoded,
        token,
    };
};
