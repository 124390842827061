import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getItem } from '../../service/fetch';

class Zitiername extends Component {
    state = {
        text: 'Einen Moment bitte',
    };

    async componentWillMount() {
        const { id } = this.props.match.params;
        const zitiername = await getItem('Zitiername', id);
        if (zitiername.von.v) {
			if (this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl') {
				this.props.history.push(`/ansicht/person/${zitiername.von.v}/engl`);
			} else {
            	this.props.history.push(`/ansicht/person/${zitiername.von.v}`);
			}
        } else {
            this.setState({
                text: `Zitiername: ${zitiername.short.v}(${zitiername._id}) hat keine Person referenziert`,
            });
        }
    }

    render() {
        return <div>{this.state.text}</div>;
    }
}

const mapStateToProps = (state) => {
    return { 
		path: state.router.location.pathname,
	};
};

export default connect(mapStateToProps)(Zitiername);