import React from 'react';
import { connect } from 'react-redux';
import { get } from '../../service/immutables';

import AllowedForUser from '../../container/AllowedForUser';
import CollapseArea from '../../container/CollapseArea';
import IFrameMap from '../../container/IFrame_Map';
import List from '../../container/ViewList';
import PublicViewHOC from '../../container/PublicViewHOC';
import RefsCount from '../../container/RefsCount';
import BriefLinks from '../../container/BriefLinksView';
import Short from '../../container/Short';
import Link from '../../style/Link';

import { Row } from '../../style/Row';
import Space from '../../style/Space';

import OpenSVG from '../../SVG/Open';

import {
    LabelFastSearch as Search,
    LabelInput as Input,
    LabelLinkedInput as LinkedInput,
    //LabelZeit as Zeit,
} from '../../labels/LabelWithNoFocus';

const OrtPublicUI = ({ item, short }) => (
    <>
        <br/>
        <Row>
            <Short />
            <AllowedForUser rolle="Gast">
                <Space />
                <Link to={`/edit/ort/${item._id}`} target="_self">
                    <OpenSVG />
                </Link>
            </AllowedForUser>
        </Row>
        {(short.search(/\(/) === -1) && (
            <>
                <br />
                <Search label="" api="Geo" name="geoKat" />
            </>
        )}
        <br />
        <RefsCount
            label="Absendeort von "
            api="brief"
            idPath="_id"
            query="absendeort.ort.v="
            anschlussText="Briefen"
        />
        <CollapseArea show={false}>
            <BriefLinks api="brief-list/public" idPath="_id" query="absendeort=" long column />
        </CollapseArea>
        <RefsCount
            label="Zielort von "
            api="brief"
            idPath="_id"
            query="zielort.v="
            anschlussText="Briefen"
        />
        <CollapseArea show={false}>
            <BriefLinks api="brief-list/public" idPath="_id" query="zielort=" long column />
        </CollapseArea>
        <RefsCount
            label="Erwähnung in "
            api="brief"
            idPath="_id"
            query="schlagworte.orte.v="
            anschlussText="Briefen"
        />
        <CollapseArea show={false}>
            <BriefLinks api="brief-list/public" idPath="_id" query="erwaehnterOrt=" long column />
        </CollapseArea>
        <br />
        <List label="Alternativbezeichnungen:" name="andereNamen">
            {index => (
                <div key={index}>
                    <Input name={`andereNamen[${index}].form`} small />
                </div>
            )}
        </List>
        {(get('amtlich.v', item) !== get('short.v', item)) && (
            <Input label="Name heute/zuletzt:" name="amtlich"/>
        )}
        <IFrameMap/><br/>
        <Row>
            <LinkedInput label="GND" name="gnd" />
            <Input label="Wikidata-ID" name="wikidata" />
            <LinkedInput link="" label="GeoNames-ID" name="geoID" />
        </Row>
        {/*
        <br />
        <List label="Übergeordnete Orte" name="zugehoerigkeit" column>
            {index => (
                <>
                    <Search label="" api="Ort" name={`zugehoerigkeit[${index}].ort`} />
                    <Zeit label="" name={`zugehoerigkeit[${index}].zeitraum`} /> 
                </>
            )}
        </List>
        <br />
        <List label="Konfession" name="konfession" column>
            {index => (
                <Row>
                    <Search
                        label=""
                        api="Konfession"
                        name={`konfession[${index}].konfession`}
                        small
                    />
                    <Zeit label="" name={`konfession[${index}].zeitraum`} />
                </Row>
            )}
        </List>
        <br />
        <List label="Sekundärliteratur" name="sekundaerliteratur">
            {index => (
                <>
                    <Row>
                        <Switch name={`sekundaerliteratur[${index}].show`}>
                            <Search
                                label=""
                                name={`sekundaerliteratur[${index}].literatur`}
                                api="Literatur"
                            />
                            <Search
                                label=""
                                name={`sekundaerliteratur[${index}].druck`}
                                api="Druck"
                            />
                        </Switch>
                        <Input label="" name={`sekundaerliteratur[${index}].seite`} small />
                    </Row>
                </>
            )}
        </List>
        <br />
        <List label="Weblinks" name="url" column>
            {index => (
                <Row key={index}>
                    <Input label="" name={`url[${index}]`} large />
                </Row>
            )}
        </List>
        */}
    </>
);
const mapStateToProps = (state, props) => {
    let value = true;
    if (get('user.role', state.auth) === 'Admin') value = false;
    return {
        geprueft: state.item.geprueft && state.item.geprueft.v,
        disableCheckbox: value,
        item: state.item,
        short: state.item.short ? state.item.short.v : "",
    };
};

export default PublicViewHOC(connect(mapStateToProps)(OrtPublicUI));
