import React, { Component } from 'react';
import { get } from '../service/immutables';
import { connect } from 'react-redux';
import { changeItem } from '../store/actions/item';
import Loading from '../components/Loading';
import { addFavorite, getItem, removeFavorite } from '../service/fetch';
import styled from 'styled-components';
import Star from '../SVG/FavoritStar';
import AllowedForUser from './AllowedForUser';

const FavArear = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

class SetFavorite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false,
            loading: 0,
        };
    }
    componentDidMount = async () => {
        const { userID, objId, type } = this.props;
        const mitarbeiter = await getItem('mitarbeiter', userID);
        if (
            mitarbeiter.favorit.some(
                ({ kind, objekt }) =>
                    kind.v.toLowerCase() === type.toLowerCase() && objekt.v === objId
            )
        )
            this.setState({ selected: true });
    };

    selectFavorite = async () => {
        const { userID, objId, type } = this.props;
        this.startLoading();
        if (!this.state.selected === true) {
            //add fav
            await addFavorite(userID, type, objId);
        }
        if (!this.state.selected === false) {
            //removed fav
            await removeFavorite(userID, objId);
        }
        this.endLoading();
        this.setState(prevState => ({ selected: !prevState.selected }));
    };

    startLoading = () => {
        this.setState({ loading: 1 });
    };

    endLoading = () => {
        this.setState({ loading: 0 });
    };

    render() {
        const { objId } = this.props;
        const { selected, loading } = this.state;
        return (
            <FavArear>
                <AllowedForUser rolle="Hiwi">
                    <Loading loading={loading} marginTop="25px" marginLeft="25px">
                        {objId === undefined ? (
                            <></>
                        ) : selected === false ? (
                            <Star onClick={this.selectFavorite} direction={false} />
                        ) : (
                            <Star onClick={this.selectFavorite} direction={true} />
                        )}
                    </Loading>
                </AllowedForUser>
            </FavArear>
        );
    }
}

const mapStateToProps = (state, props) => ({
    objId: get('_id', state.item),
    userID: get('user.id', state.auth),
});

const mapDispatchToProps = (dispatch, props) => ({
    handleChange: e => dispatch(changeItem(props.name + '.v', e.target.value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SetFavorite);
