import React from 'react';
import { connect } from 'react-redux';
import { get as getLodash } from 'lodash';
import { get } from '../../service/immutables';
import Anmerkung from '../../components/Anmerkungen';
import Schlagwort from '../../components/Schlagworte';

import AllowedForUser from '../../container/AllowedForUser';
import CollapseArea from '../../container/CollapseArea';
import CollapseTopDown from '../../container/CollapseTopDown';
import DeleteItem from '../../container/DeleteItem';
import EditHOC from '../../container/EditHOC';
import List from '../../container/List';
import RefsCount from '../../container/RefsCount';
import RefsLinks from '../../container/RefsLinks';
import SetFavorite from '../../container/SetFavorite';
import Short from '../../container/Short';

import Area from '../../style/Area';
import H1 from '../../style/H1';
import H4 from '../../style/H4';
import LineSpace from '../../style/LineSpace';
import { Row } from '../../style/Row';
import Space from '../../style/Space';
import Title from '../../style/Title';
import VerticalLine from '../../style/VerticalLine';

import {
    CheckBox,
    DropdownSimple,
    FastSearch as Search,
    Input,
    LinkedInput,
    Zeit,
} from '../../inputs/InputsWithMetaLabelFocus';

const Druck = ({ type, archivDa, shortNotGiven, vd1617, geprueft, item }) => (
    <>
        <Row>
            <H1>Druck</H1>
            <Space />
            <SetFavorite type={type} />
            <Space />
            <DeleteItem type={type} />
        </Row>
        <Short />
		<Row>
			<H4 notBold>
				{(get('long.v', item) && get('long.v', item) !== get('short.v', item)) && (<>{get('long.v', item)}</>)}
			</H4>
        </Row>
		<Area>
            <List label="Digitalisate" name="exemplar" column>
                {index => (
                    <>
                        <LinkedInput
                            label="Pfad"
                            name={`exemplar[${index}].repro[0].url`}
                            checkHttp
							width="550px"
							noMeta
                        />
						<CollapseTopDown trigger={[`exemplar[${index}].archiv`]}>
							<Row>
	                            <Search
	                                label="Archiv"
	                                name={`exemplar[${index}].archiv`}
	                                api="Archiv"
	                            />
	                            <Search
	                                label="Signatur"
	                                name={`exemplar[${index}].signatur`}
	                                api="Signatur"
	                                disabled={archivDa[index]}
	                                extraParam="archiv"
	                                extraParamName={`exemplar[${index}].archiv`}
	                            />
							</Row>
						</CollapseTopDown>
                    </>
                )}
            </List>
        </Area>
		<Row>
            <Area>
                <RefsCount
                    label=""
                    api="briefdruck"
                    idPath="_id"
                    query={'druck.v='}
                    anschlussText=" Briefdrucke:"
					column
                />
				<LineSpace />
    			<CollapseArea show={false}>
            		<RefsLinks
                        api="briefdruck"
                        idPath="_id"
                        query={'druck.v='}
						column
                    />	
    			</CollapseArea>
				<RefsLinks
	                label="Sache: "
	                api="sache"
	                idPath="_id"
	                query={'zusammensetzung.werk.druck.v='}
					column
	            />
            </Area>
        </Row>
        <CollapseArea label="Verzeichnung" show={true}>
			<Area>
                <Row>
                    <Area>
                        <Input
                            label="Kürzel"
                            name="kuerzel"
                            placeholder="Nachname des Autors, Kurztitel, Jahr (Bibl. Nachweis)"
                            width="494px"
                            disabled={geprueft}
							noMeta
                        />
                    </Area>
					<AllowedForUser rolle="Admin">
	                    <Area>
	                        <CheckBox label="geprüft" name="geprueft" noMeta noUnderline width="38px"/>
	                    </Area>
					</AllowedForUser>
                </Row>
            </Area>
            <Area>
                <Title>Bibliographischer Nachweis</Title>
                <Row>
                    <Area>
                        <LinkedInput
                            label="VD16/17-Nummer"
                            name="nachweis.vd1617"
                            link={vd1617}
                            removeFromValueForLink="VD17 "
							noMeta
                        />
                    </Area>
                    <List
                        label="sonstige bibliographische Nachweise"
                        name="nachweis.sonstige"
                        simple
                    >
                        {index => (
                            <Area noStyle>
                                <Input
                                    label="sonstiger Nachweis"
                                    name={`nachweis.sonstige[${index}]`}
									noMeta
									small
                                />
                            </Area>
                        )}
                    </List>
                </Row>
            </Area>
            <Area>
                <Title>Eckdaten</Title>
                <Row>
					<div>
	                    <List label="Autor" name="autor" column simple>
	                        {index => (
	                            <Area noStyle>
	                                <Search
	                                    label="Autor"
	                                    api="Zitiername"
	                                    name={`autor[${index}]`}
	                                    small
	                                />
	                            </Area>
	                        )}
	                    </List>
					</div>
                	<Area>
                    	<Input label="Titel (Angabe optional)" name="titel" extraLarge />
                	</Area>
                </Row>
                <Area>
                    <Zeit label="Jahr" name="amDruck" onlyYear width="170px" />
                </Area>
                <Row>
                    <List label="Druckort" name="druckOrt" simple column>
                        {index => (
                            <Area noStyle>
                                <Search 
									label="Druckort" 
									api="Ort" 
									name={`druckOrt[${index}]`} 
									width="190px" 
								/>
                            </Area>
                        )}
                    </List>
                    <List label="Drucker" name="drucker" simple column>
                        {index => (
                            <Area noStyle>
                                <Search
                                    label="Drucker"
                                    api="Zitiername"
                                    name={`drucker[${index}]`}
                                />
                            </Area>
                        )}
                    </List>
                </Row>
                <Row>
                    <List label="Verlegerort" name="verlegerOrt" simple column>
                        {index => (
                            <Area noStyle>
                                <Search
                                    label="Verlegerort"
                                    name={`verlegerOrt[${index}]`}
                                    api="Ort"
									width="190px"
                                />
                            </Area>
                        )}
                    </List>
                    <List label="Verleger" name="verleger" simple column>
                        {index => (
                            <Area noStyle>
                                <Search
                                    label="Verleger"
                                    name={`verleger[${index}]`}
                                    api="Zitiername"
                                />
                            </Area>
                        )}
                    </List>
                </Row>
			<Area>
                <Search label="abhängig von Druck" api="Druck" name="abhaengigVon" />
            </Area>
            </Area>
			{/*
            <Area>
                <Input label="Gesamtaufnahme" name="gesamtaufnahme" extraLarge noMeta />
            </Area>
			*/}
            
        </CollapseArea>
		<CollapseArea label="Sichtungen" show={true}>
			<Row>
				<Area>
	                <List label="Sichtungen" name="sichtung">
	                    {index => (
	                        <>
	                            <Row>
	                                <Area>
	                                    <Title>Sichtung</Title>
	                                    <Input
	                                        label="Gründlichkeit"
	                                        name={`sichtung[${index}].gruendlich`}
	                                        placeholder="gründlich?"
	                                        helperForUserInput="gründlich?"
	                                        noMeta
	                                    />
	                                    <Input
	                                        label="Vollständigkeit"
	                                        name={`sichtung[${index}].vollstaendig`}
	                                        placeholder="vollständig?"
	                                        helperForUserInput="vollständig?"
	                                        noMeta
	                                    />
	                                    <List
	                                        label="durch"
	                                        name={`sichtung[${index}].durch`}
	                                        simple
	                                        column
	                                        width="100px"
	                                    >
	                                        {index2 => (
	                                            <Search
	                                                label="durch"
	                                                name={`sichtung[${index}].durch[${index2}]`}
	                                                api="Mitarbeiter"
	                                                small
	                                            />
	                                        )}
	                                    </List>
	                                    <Zeit label="am" name={`sichtung[${index}].am`} today />
	                                </Area>
	                                <Area>
	                                    <Title>Briefe</Title>
	                                    <DropdownSimple
	                                        label="enthalten?"
	                                        name={`sichtung[${index}].ertrag.funde`}
	                                        items={['', 'ja', 'nein', 'vielleicht']}
	                                        default_value=""
	                                        width="100px"
											noMeta
	                                    />
										<Input
	                                        label="Menge"
	                                        name={`sichtung[${index}].ertrag.briefanzahl`}
	                                        width="100px"
											noMeta
	                                    />
										<DropdownSimple
	                                        label="übernommen"
	                                        name={`sichtung[${index}].ertrag.uebernommen`}
	                                        items={['', 'vollständig', 'teilweise']}
	                                        default_value=""
	                                        width="100px"
											noMeta
											noUnderline
	                                    />
	                                    
	                                </Area>
	                            </Row>
	                        </>
	                    )}
	                </List>
	            </Area>
	            <Area>
	                <List label="Sichtungen von Teilmengen" name="teilsichtung">
	                    {index => (
	                        <>
	                            <Row>
                                    <Row>
                                        <Input
                                            label="Beschreibung/Titel des Abschnitts"
                                            name={`teilsichtung[${index}].titel`}
											noMeta
                                            width="261px"
                                        />
                                        <VerticalLine />
                                        <Input
                                            label="Bl./S."
                                            name={`teilsichtung[${index}].seite`}
                                            placeholder="Bl."
                                            helperForUserInput="Bl. "
                                            width="87px"
											noMeta
                                        />
                                    </Row>
	                            </Row>
	                            <Row>
	                                <Area>
	                                    <Title>Sichtung</Title>
	                                    <Input
	                                        label="Gründlichkeit"
	                                        name={`teilsichtung[${index}].gruendlich`}
	                                        placeholder="gründlich?"
	                                        helperForUserInput="gründlich?"
											noMeta
	                                    />
	                                    <Input
	                                        label="Vollständigkeit"
	                                        name={`teilsichtung[${index}].vollstaendig`}
	                                        placeholder="vollständig?"
	                                        helperForUserInput="vollständig?"
											noMeta
	                                    />
	                                    <List
	                                        label="durch"
	                                        name={`teilsichtung[${index}].durch`}
	                                        simple
	                                        column
	                                    >
	                                        {index2 => (
	                                            <Search
	                                                label="durch"
	                                                name={`teilsichtung[${index}].durch[${index2}]`}
	                                                api="Mitarbeiter"
	                                                small
	                                            />
	                                        )}
	                                    </List>
	                                    <Zeit label="am" name={`teilsichtung[${index}].am`} today />
	                                </Area>
	                                <Area>
	                                    <Title>Briefe</Title>
	                                    <DropdownSimple
	                                        label="enthalten?"
	                                        name={`teilsichtung[${index}].ertrag.funde`}
	                                        items={['', 'ja', 'nein', 'vielleicht']}
	                                        default_value=""
	                                        width="100px"
											noMeta
	                                    />
	                                    <Input
	                                        label="Menge"
	                                        name={`teilsichtung[${index}].ertrag.briefanzahl`}
	                                        width="100px"
											noMeta
										/>
	                                    <DropdownSimple
	                                        label="übernommen"
	                                        name={`teilsichtung[${index}].ertrag.uebernommen`}
	                                        items={['', 'vollständig', 'teilsichtung']}
	                                        default_value=""
	                                        width="100px"
											noMeta
											noUnderline
	                                    />
	                                </Area>
	                            </Row>
	                        </>
	                    )}
	                </List>
	            </Area>
			</Row>
        </CollapseArea>
        <CollapseArea label="Anmerkungen" show={true}>
            <Row>
                <Area>
                    <Anmerkung />
                </Area>
            </Row>
        </CollapseArea>
        <Row>
            <Schlagwort />
        </Row>
    </>
);

const mapStateToProps = (state, props) => {
    const exemplar = state.item.exemplar;
    let array = [];
    /**
     *  vd16 = http://gateway-bayern.de/
     *  vd17 = https://gso.gbv.de/DB=1.28/CMD?ACT=SRCHA&IKT=8002&TRM=
     * */
    let vd1617 = null;
    let valuevd1617 = getLodash(state, 'item.nachweis.vd1617.v');
    if (valuevd1617 !== undefined && valuevd1617 !== null && valuevd1617.includes('VD16')) {
        vd1617 = 'http://gateway-bayern.de/';
    } else if (valuevd1617 !== undefined && valuevd1617 !== null && valuevd1617.includes('VD17')) {
        vd1617 = 'https://kxp.k10plus.de/DB=1.28/CMD?ACT=SRCHA&IKT=8079&TRM=%27';
    }

    if (exemplar !== undefined) {
        for (let i = 0; i < exemplar.length; ++i) {
            array[i] = getLodash(exemplar[i], 'archiv.v._id') ? false : true;
        }
    }
    return {
		item: state.item,
        archivDa: array,
        shortNotGiven: state.item.short && state.item.short.v ? false : true, // für Collapsearea)
        vd1617,
        geprueft: state.item.geprueft && state.item.geprueft.v,
    };
};

export default EditHOC(connect(mapStateToProps)(Druck));
