import React from 'react';
import { connect } from 'react-redux';
import { addNotification } from '../store/actions/notificationActions';
import { Row } from '../style/Row';
import { history } from '../../index';

/**
 * löscht localStorage
 * git das warning aus
 * redirect to login
 *
 * */
class ForcedLogout extends React.Component {
    reload = path => {
        history.push(path);
    };
    destroyToken = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('id');
        localStorage.removeItem('rolle');
        localStorage.removeItem('name');
        localStorage.removeItem('exp_maxTime');
        localStorage.removeItem('exp_currentTime');
    };

    constructor() {
        super();
        this.state = {
            token: null,
            item: {
                name: { v: '' },
                password: { v: '' },
            },
            login: false,
        };
    }

    componentWillMount() {
        this.destroyToken();
        this.props.dispatch(
            addNotification(
                'Bitte melden Sie sich erneut an',
                'Ihre Anmeldezeit wurde überschritten',
                'warning'
            )
        );
        this.reload('/login');
        /*
        if(localStorage.getItem('token')) {
            // user hatte ein token, dieses muss aber erneuert werden
        }else{
            // user hatte kein token
            this.destroyToken();
            this.reload('/login');
        }
        */
    }

    // TODO check if a bedder notification/message is usefully here
    render() {
        return <Row center />;
    }
}

export default connect()(ForcedLogout);
