import React from 'react';
import { get, set } from 'lodash';
import { connect } from 'react-redux';
import { api } from '../../config';
import { addNotification } from '../store/actions/notificationActions';
import Area from '../style/Area';
import Button from '../style/Button';
import styled from 'styled-components';
import jwt_decode from 'jwt-decode';
import { changeAuth, login, logout } from '../store/actions/auth';
import StyledInput from '../style/Input';
import { history } from '../../index';

const Middle = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
`;

class Login extends React.Component {
    login = async () => {
        const { name, password } = this.props;
        if (name === '' || password === '') {
            this.props.addNotification(
                `Bitte geben Sie einen Benutzernamen und/oder Passwort ein`,
                'error'
            );
        } else {
            const body = JSON.stringify({ name: { v: name }, password: { v: password } });
            try {
                const response = await fetch(api + '/api/v1/auth/login', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body,
                });
                const data = await response.json();
                //console.log('Login post - antowrt von api:');
                //console.log({ data });
                if (response.ok) {
                    localStorage.setItem('token', data.token);
                    var decoded = jwt_decode(data.token);
                    //console.log({ decoded });
                    this.props.login(data.token);
                    // parse token into Object, so that "role" or other informations can be used
                    localStorage.setItem('name', get(decoded, 'name.v'));
                    localStorage.setItem('rolle', get(decoded, 'role.v'));
                    localStorage.setItem('short', get(decoded, 'short.v'));
                    localStorage.setItem('id', get(decoded, '_id'));
                    localStorage.setItem('anzahlSuche', get(decoded, 'anzahlSuche.v'));
                    localStorage.setItem('anzahlBearbeitet', get(decoded, 'anzahlBearbeitet.v'));
                    var test = get(decoded, 'exp');
                    var time = new Date().getTime();
                    var time2 = time.toString().match(/.{1,10}/g)[0];
                    localStorage.setItem('exp_maxTime', test.toString());
                    localStorage.setItem('exp_currentTime', time2);
                    if (
                        parseInt(localStorage.getItem('exp_maxTime')) >
                        parseInt(localStorage.getItem('exp_currentTime'))
                    ) {
                        //console.log('man hat noch zeit');
                    } else {
                        //console.log('jwt token ');
                    }
                    this.setState({ login: true, token: data.token });
                    this.props.addNotification('Login erfolgreich', 'success');

                    this.reload('/');
                } else {
                    this.props.addNotification(data.message || 'unbehandelter Fehler!', 'error');
                }
            } catch (e) {
                this.props.addNotification(
                    e.message || 'UNBEKANNTER FEHLER! DOKUMENTIEREN',
                    'error'
                );
            }
        }
    };
    handleChange = (name, value) => {
        const item = JSON.parse(JSON.stringify(this.state.item));
        set(item, name, value);
        this.setState({ item });
    };
    logout = () => {
        this.props.logout();
        this.destroyToken();
        //token needs to be destroyed on the server
        this.setState({ token: null, login: false });
        this.reload('/');
    };
    reload = path => {
        history.push(path);
    };
    destroyToken = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('id');
        localStorage.removeItem('rolle');
        localStorage.removeItem('name');
        localStorage.removeItem('short');
        localStorage.removeItem('exp_maxTime');
        localStorage.removeItem('exp_currentTime');
    };
    onKeyPressed = e => {
        if (e.keyCode === 13) {
            this.login();
        }
    };
    constructor() {
        super();
        this.state = {
            token: null,
            item: {
                name: { v: '' },
                password: { v: '' },
            },
            login: false,
        };
    }

    componentWillMount() {
        //const { destroy } = this.props.match.params;
        //console.log(destroy);
        const token = localStorage.getItem('token');
        if (token && token.length > 0) this.setState({ token });
    }

    render() {
        const { token } = this.state;
        const { name, password, changeAuth } = this.props;
        return (
            <Middle onKeyDown={this.onKeyPressed}>
                {token === null && (
                    <Area>
                        <StyledInput
                            placeholder="Benutzername"
                            name="name"
                            onChange={e => changeAuth('name', e.target.value)}
                            onFocus={() => this.setState({ readyToWrite: true })}
                            onBlur={() => this.setState({ readyToWrite: false })}
                            value={name}
                            autoComplete="off"
                        />
                        <StyledInput
                            placeholder="Password"
                            type="password"
                            name="password"
                            onChange={e => changeAuth('password', e.target.value)}
                            onFocus={() => this.setState({ readyToWrite: true })}
                            onBlur={() => this.setState({ readyToWrite: false })}
                            value={password}
                            autoComplete="off"
                        />

                        <Button onClick={this.login}>Anmelden</Button>
                    </Area>
                )}

                {token !== null && <Button onClick={this.logout}>Logout</Button> // Todo usernamen Anzeigen
                }
            </Middle>
        );
    }
}

const mapStateToProps = state => ({
    name: state.auth.name,
    password: state.auth.password,
});
export default connect(
    mapStateToProps,
    { addNotification, changeAuth, login, logout }
)(Login);
