import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { changeSort, getDataFromApi, resetSearch, setSchema } from '../../../store/actions/search';
import { loadAdvSearchSchema } from '../../../service/fetch';

import AllowedForUser from '../../../container/AllowedForUser';
import SearchFooter from '../../../components/SearchFooter';
import AdvSearchFilter from '../../../container/AdvSearchFilter'
//import AdvSearchItems from '../../../container/AdvSearchItems'
import NewButton from '../../../components/NewButton';
import Loading from '../../../components/Loading';

import Container from '../../../style/Container';
import ScrollContainer from '../../../style/ScrollContainer';
import { SearchBody, SearchContainer, SearchInputArea } from '../../../style/Search';
import { Button } from '../../../style/Button';
import Row from '../../../style/Row';
import H3 from '../../../style/H3';

class AdvSearch extends Component {
        constructor(props) {
            super(props);

            const { setSchema } = this.props;
            loadAdvSearchSchema(this.props.type).then(setSchema);
        }

        componentDidMount() {
            this.props.handleChangeSort('short.v'); // set default sort
        }

        componentWillUnmount() {
            this.props.resetSearch();
        }

        onKeyPressed = ({ keyCode }) => {
            const { startSearch } = this.props;
            if (keyCode === 13) {
                this.setState({ page: 0 }, startSearch);
            }
        };

        render() {
            const { loading, startSearch, type } = this.props;
            return (
                <>
                    <SearchContainer onKeyDown={this.onKeyPressed}>
                        <SearchBody>
                            <ScrollContainer>
                                <Container>
                                    <Loading loading={loading}>
                                        <div>Test</div> {/*<AdvSearchItemsItems />*/}
                                    </Loading>
                                </Container>
                            </ScrollContainer>
                            <SearchInputArea>
                                <Row>
                                    <H3 margin="10px 10px 10px 10px">
                                        {type[0].toUpperCase() + type.slice(1)}
                                    </H3>
                                    <Button onClick={startSearch}>Suche</Button>
                                </Row>
                                <AdvSearchFilter />
                            </SearchInputArea>
                        </SearchBody>
                        <AllowedForUser rolle="Mitarbeiter">
                            <NewButton type={type} />
                        </AllowedForUser>
                    </SearchContainer>
                    <SearchFooter startSearch={getDataFromApi}/>
                </>
            );
        }
    }

AdvSearch.propTypes = {
    loading: PropTypes.number,
};

const mapStateToProps = (state, props) => ({
    loading: state.search.loading,
    type: props.match.params.type,
});

const mapDispatchToProps = {
    setSchema,
    resetSearch,
    handleChangeSort: changeSort,
    startSearch: getDataFromApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvSearch);