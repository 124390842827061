import React, { Component } from 'react';
import { getItem } from '../../service/fetch';

class Zitiername extends Component {
    state = {
        text: 'Einen Moment bitte',
    };

    async componentWillMount() {
        const { id } = this.props.match.params;
        const zitiername = await getItem('Zitiername', id);
        if (zitiername.von.v) {
            this.props.history.push(`/edit/person/${zitiername.von.v}`);
        } else {
            this.setState({
                text: `Zitiername: ${zitiername.short.v}(${zitiername._id}) hat keine Person referenziert`,
            });
        }
    }

    render() {
        return <div>{this.state.text}</div>;
    }
}

export default Zitiername;
