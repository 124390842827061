import styled from 'styled-components';
import { grey } from './colors';

export default styled('div')`
    display: block !important;
    font-size: 1.2em !important;
    font-weight: bold;
    letter-spacing: 0.2px !important;
    margin-bottom: 8px !important;
    padding: 0 !important;
    color: ${grey} !important;
`;
