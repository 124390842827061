import styled from 'styled-components';
import Area from './Area';
import { button } from './colors';

export const Button = styled(Area)`
    mborder-style: solid;
    border-color: #bbb #888 #666 #aaa;
    border-width: 0.8px 1.3px 1.3px 0.8px;
	cursor: pointer;
	display: flex;
    flex-flow: wrap;
    flex-grow: 0;
    // allow custom direction
    flex-direction: ${({ column }) => (column ? 'column' : 'row')};
    margin: 0.5em;
	background-color: ${button};

    &:active {
        border-width: 1.3px 0.8px 0.8px 1.3px;
        color: #000;
        box-shadow: 0 1px 6px rgba(49, 126, 172, 0.28); // blue to rgb
    }
`;

export default Button;
