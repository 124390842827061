import styled from 'styled-components';

export const MetaMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
	position: fixed;
    top: 16%;
    right: 5%;

    //min-width: 10rem;
    padding-left: 4px;
    background-color: transparent;

    width: auto;
    //overflow-y: auto;
`;

export default MetaMenu;
