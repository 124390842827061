import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get as getLodash } from "lodash";
import { changeItem, resetItem } from '../../store/actions/item';
import { changeSort, changeSortAndLoad, resetSearch, getDataFromApi, resetPage, setLimit } from '../../store/actions/search';
import { addNotification } from '../../store/actions/notificationActions';

import SearchFooter from '../../components/SearchFooter';

import Area from '../../style/Area';
import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import { defaultTextColor } from '../../style/colors';
import Button from '../../style/Button';
import Cell from '../../style/SearchCell';
import Container from '../../style/Container';
import Link from '../../style/Link';
import LinkExtern from '../../style/LinkExtern';
import Space from '../../style/Space';
import Loading from '../../components/Loading';
import OpenSVG from '../../SVG/Open';
import WebGlobeSVG from '../../SVG/WebGlobe';
import Row from '../../style/Row';

import { FastSearch, Input } from '../../inputs/InputsWithLabelFocus';

const SearchBody = styled('div')`	
 	display: flex;
 	align-items: center;
  	justify-content: center;
`;

const SearchTable = styled('div')`	
	display: table;
  	align-items: center;
  	justify-content: center;
	width: 900px;
`;

const SearchRowArea = styled(Area)`
    margin-top: 5px;
	margin-bottom: 6px;
	padding: 6px 8px;
	max-width: 100%;
    height: ${props => (props.height ? props.height : 'fit-content')};
`;

const TableRow = styled(SearchRowArea)`
    grid-template-columns: 2em 1fr 2fr 1fr 1fr 2em;
    //                        short|alt|jue|typ
    display: grid;
`;

class SearchOrt extends React.Component {
        state = {
	            suche: this.props.match.url.split('/')[2].toLowerCase()
	        }
	
        onKeyPressed = ({ key }) => {
            if (key === "Enter") this.props.resetPage()
        };

        reset = () => {
            this.props.resetItem();
            this.props.resetSearch();
        };

		componentWillUnmount() {
           this.reset();
        };
        
        componentDidMount() {
            this.props.changeSort('short.v');
        }

        render() {
            const { items, loading, count, sort, asc } = this.props;
            const englroute = this.props.match.url.split('/')[this.props.match.url.split('/').length-1] === 'engl';
     		return (
                <SearchBody onKeyDown={this.onKeyPressed}>
                    <SearchTable>
                        <Container>
	                        <Row>
			                	<TableRow>
				                    <Cell block>{null}</Cell>
				                    <Cell>
		                   				<Input
				                            noMeta
				                            width="100%"
				                            label="Ortsname"
				                            name="long"
				                        />
				                        {count !== 0 && (
					                        <ArrowTopDown
					                            onClick={() => this.props.changeSortAndLoad('short.v')}
					                            direction={'short.v' === sort ? !asc : false}
					                            active={'short.v' === sort}
					                        />)}
				                    </Cell>
				                    {/*
				                    <Cell>
				                        <Input
				                            noMeta
				                            width="100%"
				                            label="GND"
				                            name="gnd"
				                            handleChange={handleChange}
				                            value={get(search, 'gnd') || ''}
				                        />
										{countElem && (
					                        <ArrowTopDown
					                            onClick={() => handleChangeSort('gnd.v')}
					                            direction={'gnd.v' === sort ? !asc : false}
					                            active={'gnd.v' === sort}
					                        />
										)}
				                    </Cell>
				                    */}
	                    			 <Cell>
				                    	{loading === 0 && count === 0 && (
											!englroute ? (
												<Button id="SearchButton" onClick={this.props.resetPage} >
						                        	Suchen
						                        </Button>
					                        ) : (
												<Button id="SearchButton" onClick={this.props.resetPage} >
						                        	Search
						                        </Button>
											)
										)}
										{loading === 0 && count !== 0 && (
											!englroute ? (
												<Button id="SearchButton" onClick={this.props.resetPage} >
						                        	aktualisieren
						                        </Button>
					                        ) : (
												<Button id="SearchButton" onClick={this.props.resetPage} >
						                        	Search
						                        </Button>
											)
										)}
										{loading === 0 && count !== 0 && (	
											<>
											{!englroute ? (
												<Link to={`/filter/ort`} target="_self" color={defaultTextColor}>
													<Button>
						                                zurücksetzen
						                            </Button>
												</Link>
											) : (
												<Link to={`/filter/ort/engl`} target="_self" color={defaultTextColor}>
													<Button>
						                                Reset
						                            </Button>
												</Link>
											)}
											</>
										)}
									</Cell>
									<Cell>
										{!englroute && (
											<Link to={`/karte`} target="_self" color={defaultTextColor}>
												<Button>
			                                        Zur Karte
			                                    </Button>
											</Link>
										)}
										{englroute && (
											<Link to={`/karte/engl`} target="_self" color={defaultTextColor}>
												<Button>
			                                        Go to Map
			                                    </Button>
											</Link>
										)}
									</Cell>
									<Cell>
				                        <FastSearch
				                            noMeta
				                            width="100%"
				                            label="Kategorie filtern"
				                            api="Geo"
				                            name="typName"
											placeholder="z.B. Stadt"
											dontWantID
				                        />
				                        {count !== 0 && (
					                        <ArrowTopDown
					                            onClick={() => this.props.changeSortAndLoad('geoKat.v')}
					                            direction={'geoKat.v' === sort ? !asc : false}
					                            active={'geoKat.v' === sort}
					                        />
										)}
				                    </Cell>
									<Cell block>{null}</Cell>
	                			</TableRow>
							</Row>
			                <Loading loading={loading}>
			                    {items.map((item, i) => (
			                        <TableRow key={i}>
			                            <Cell>
				                            <Link to={`/ansicht/ort/${item._id}`} target="_blank">
				                                <OpenSVG />
				                            </Link>
				                        </Cell>
			                            <Cell>{item.short && item.short.v}</Cell>
										<Cell block>
			                                {item.andereNamen && item.andereNamen.map((object) => (
			                                   <div>{getLodash(object, 'form.v')}</div>
			                                ))}
			                            </Cell>
			                            <Cell>
			                            	{item.koordinaten && item.koordinaten.lat && item.koordinaten.lat.v && item.koordinaten.len && item.koordinaten.len.v && (
					                            <>
						                            <LinkExtern href={`http://maps.google.de/maps?q=${item.koordinaten.lat.v + ',' + item.koordinaten.len.v}`} target="_blank">
						                                <WebGlobeSVG />
						                            </LinkExtern>
						                            <Space/>
					                            </>
				                            )}
				                            {item.amtlich && item.short && (item.amtlich.v !== item.short.v) && item.amtlich.v}
			                            </Cell>
			                            <Cell>{item.geoKat && item.geoKat.v && item.geoKat.v.short.v}</Cell>			                            
			                            {/*<Cell>{item.koordinaten && item.koordinaten.lat && item.koordinaten.lat.v}</Cell>
			                            <Cell>{item.koordinaten && item.koordinaten.len && item.koordinaten.len.v}</Cell>
			                            
			                                <Cell>
			                                    <LinkExtern href={item.gnd.v}>
			                                        {get(item, 'gnd.v')
			                                            ? get(item, 'gnd.v')
			                                                  .replace('https://d-nb.info/gnd/', '')
			                                                  .replace('http://d-nb.info/gnd/', '')
			                                            : ''}
			                                    </LinkExtern>
			                                </Cell>
			                                */}
			                            <Cell>{null}</Cell>
			                        </TableRow>
			                    ))}
			                </Loading>
							<SearchFooter startSearch={getDataFromApi}/> 
                        </Container>
                    </SearchTable>
                </SearchBody>
            );
        };
	}
	
   const mapStateToProps = (state) => ({
        items: state.search.items,
        sort: state.search.sort,
        asc: state.search.asc,
        loading: state.search.loading,
        count: state.search.count
    });

    const mapDispatchToProps = {
        changeSort,
        changeSortAndLoad,
        resetSearch,
        getDataFromApi,
        resetPage,
        setLimit,
        changeItem,
        resetItem,
        addNotification
    };
  
export default connect(mapStateToProps, mapDispatchToProps)(SearchOrt);
