import React from 'react';
import { get } from 'lodash';
import NewButton from '../../../components/NewButton';
import { Input } from '../../../inputs/InputsWithLabelFocus';
import SearchHOC from '../../../container/SearchHOC';
import Link from '../../../style/Link';
import TableRow from '../../../style/SearchRow';
import Cell from '../../../style/SearchCell';
import DeleteSVG from '../../../SVG/Delete';
import OpenSVG from '../../../SVG/Open';
import AllowedForUser from '../../../container/AllowedForUser';
import { ArrowTopDown } from '../../../SVG/ArrowTopDown';
import Loading from '../../../components/Loading';

const SearchUebForm = ({ items, search, defaultSort, handleChangeSort, sort, asc, loading }) => {
    if (!sort.length) defaultSort('short.v');
    return (
        <>
            <div>
                <TableRow>
                    <div>{null}</div>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Überlieferungsform"
                            name="short"
                            value={get(search, 'short') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('short.v')}
                            direction={'short.v' === sort ? !asc : false}
                            active={'short.v' === sort}
                        />
                    </Cell>
                    <div>{null}</div>
                </TableRow>
                <Loading loading={loading}>
                    {items.map((item, i) => (
                        <TableRow key={i}>
                            <Link to={`/edit/uebForm/${item._id}`} target={false}>
                                <OpenSVG />
                            </Link>
                            <Cell>{item.short && item.short.v}</Cell>
                            <AllowedForUser rolle="Admin">
                                <Link to={`/ref/uebForm/${item._id}`}>
                                    <DeleteSVG />
                                </Link>
                            </AllowedForUser>
                        </TableRow>
                    ))}
                </Loading>
            </div>
            <AllowedForUser rolle="Admin">
                <NewButton type="uebForm" target="_blank" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchUebForm);
