import React from 'react';
import { connect } from 'react-redux';
import { count } from '../service/fetch';
import { get } from '../service/immutables';
import PropTypes from 'prop-types';
import CollapseArea from '../container/CollapseArea';
import RefsLinks from '../container/RefsLinks';

class RefsContainer extends React.Component {
    searchCount = async () => {
        this.setState({ toggle: false });
        let { api, query, idPath } = this.props;
        if (idPath === '') idPath = undefined;
        if (idPath !== undefined) {
			const countResult = await count(api, null, query + '' + idPath);
            this.setState({ count: countResult });
        } else this.setState({ count: 0 });
		this.setState({ countGeladen: true });
    };

    constructor() {
        super();
        this.state = {
			count: 0,
            countGeladen: false,
        };
    }

	//this does nothing?:
	componentDidUpdate(prevProps) {
        if (this.props.idPath !== prevProps.idPath) {
			this.searchCount();
		}
    }

    componentDidMount() {
		if (this.props.idPath !== '') {
			this.searchCount();
		}
    }

    render() {
        const { count, countGeladen } = this.state;
        const { api, query, idPath, label, anschlussText, closed, column, long } = this.props;
        return (
            <>
				{label && <>{label}</>}
				{countGeladen && (
				<>
					{(count + ' ' + anschlussText)}<br/>
					{count > 0 && count < 400 && (
					<CollapseArea show={closed ? false : (count < 10 ? true : false)}>
						<RefsLinks
							api={api}
							idPath={idPath}
							query={query}
							column={column}
							long={long}
						/>
					</CollapseArea>
					)}
				</>
				)}
            </>
        );
    }
}

RefsContainer.defaultProps = {};
RefsContainer.propTypes = {
    api: PropTypes.string.isRequired,
    idPath: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
};
const mapStateToProps = (state, props) => ({
    idPath: get(props.idPath, state.item) || props.helperForUserInput || '',
});

export default connect(mapStateToProps)(RefsContainer);
