import React from 'react';
import { get as getLodash } from 'lodash';
import Link from '../style/Link';
import { connect } from 'react-redux';

class DataCombination extends React.Component {
    calcLinks = (value, array) => {
        let position = 0;
        let result = [];
        for (let i = 0; i < array.length; ++i) {
            //nicht link stück
            result.push(<div key={i + 'a' + this.props.name}>{value.slice(position, array[i].start)}</div>);
            position = array[i].start;
            //link
            let to = `../${array[i].api.toLowerCase()}/${array[i]._id}`+ (this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? '/engl' : '');
            if (array[i].api === 'Zitiername') {
                // get the parent path so we can look into the "person" path to get the person ID
                // via person.v.von.v
                const toppath = this.props.name.replace(/\.[A-Za-z]+$/, '');
                const personId = getLodash(this.props.fullItem, toppath + '.person.v.von.v');
                to = `../Person/${personId}`;
            }
            result.push(
                <Link target="_self" key={i + 'b' + this.props.name} to={to}>
                    {value.slice(array[i].start, array[i].ende)}
                </Link>
            );
            position = array[i].ende;
        }
        //letztes nicht link stück
        if (value.charAt(position) === ',') {
            result.push(<div>,</div>);
            result.push(<>&nbsp;</>);
            position += 1;
        }
		else if (value.charAt(position) === ' ') {
			result.push(<>&nbsp;</>);
			position += 1;
		}
        result.push(<div key={'last'}>{value.slice(position, value.length)}</div>);
        return result;
    };

    render() {
        const { item, noLink } = this.props;
        if (getLodash(item, 'combi') && getLodash(item, 'info')) {
            return <>{noLink ? item.combi : this.calcLinks(item.combi, item.info)}</>;
        } else return <></>;
    }
}

const mapStateToProps = (state, props) => ({
    fullItem: state.item,
    item: getLodash(state.item, props.name),
	path: state.router.location.pathname,
});

export default connect(mapStateToProps)(DataCombination);
