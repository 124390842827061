import React from 'react';
import { get } from 'lodash';
import ownSlice from '../../service/sliceSearch';

import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';
import AllowedForUser from '../../container/AllowedForUser';
import SearchHOC from '../../container/SearchHOC';

import { SignaturRow as TableRow } from '../../style/SearchRow';
import Cell from '../../style/SearchCell';
import Link from '../../style/Link';
import LinkExtern from '../../style/LinkExtern';
import Row from '../../style/Row';

import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import DeleteSVG from '../../SVG/Delete';
import OpenSVG from '../../SVG/Open';
import WebGlobeSVG from '../../SVG/WebGlobe';

import { CheckBox, FastSearch as Search, Input } from '../../inputs/InputsWithLabelFocus';

const SearchSignatur = ({ items, search, defaultSort, handleChangeSort, sort, asc, loading }) => {
    if (!sort.length) defaultSort('archiv.v');
    return (
        <>
            <>
                <TableRow>
                    <Cell block>{null}</Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Archiv"
                            api="Archiv"
                            value={get(search, 'archiv') || ''}
                            name="archiv"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('archiv.v')}
                            direction={'archiv.v' === sort ? !asc : false}
                            active={'archiv.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Signatur"
                            name="short"
                            value={get(search, 'short') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('short.v')}
                            direction={'short.v' === sort ? !asc : false}
                            active={'short.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <CheckBox label="gültig" name="istGueltig" width="100%" noUnderline />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('istGueltig.v')}
                            direction={'istGueltig.v' === sort ? !asc : false}
                            active={'istGueltig.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Verzeichnungsstufe"
                            api="Verzeichnungsstufe"
                            name="stufe"
                            value={get(search, 'stufe')}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('stufe.v')}
                            direction={'stufe.v' === sort ? !asc : false}
                            active={'stufe.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Titel"
                            name="titel"
                            value={get(search, 'titel') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('titel.v')}
                            direction={'titel.v' === sort ? !asc : false}
                            active={'titel.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="URL"
                            name="findmittel.url.string"
                            value={get(search, 'findmittel.url.string') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('findmittel.url.string.v')}
                            direction={'findmittel.url.string.v' === sort ? !asc : false}
                            active={'findmittel.url.string.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Sichtungen"
                            api="Mitarbeiter"
                            name="sichtung.durch"
                            value={get(search, 'sichtung.durch') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('sichtung.durch.v')}
                            direction={'sichtung.durch.v' === sort ? !asc : false}
                            active={'sichtung.durch.v' === sort}
                        />
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                {/* vertikaler Platzhalter für Dropdownmenüs: */}
			 	{items.length < 1 && (
					 <>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
					 </>
				 )}
                <Loading loading={loading}>
                    {items.map((item, i) => (
                        <TableRow key={i}>
                            <Link to={`/edit/signatur/${item._id}`} target={false}>
                                <OpenSVG />
                            </Link>
                            <Cell>
                                <Link to={'/edit/archiv/' + get(item, 'archiv.v._id')}>
                                    {get(item, 'archiv.v.short.v')}
                                </Link>
                            </Cell>
                            <Cell>{get(item, 'short.v')}</Cell>
                            <Cell>{item.istGueltig && item.istGueltig.v ? '✔' : ''}</Cell>
                            <Cell>{get(item, 'stufe.v.short.v')}</Cell>
                            <Cell>{ownSlice(get(item, 'titel.v'), 100)}</Cell>
                            <Cell>
                                {item.findmittel && item.findmittel.url && item.findmittel.url.map(e => {
                                    return (
                                        <>
                                            {get(e, 'string.v') !== null &&
                                            get(e, 'string.v').includes('http') ? (
                                                <LinkExtern
                                                    href={get(e, 'string.v')}
                                                    target="blank"
                                                >
                                                    <WebGlobeSVG />
                                                </LinkExtern>
                                            ) : (
                                                get(e, 'string.v')
                                            )}
                                            <br />
                                        </>
                                    );
                                })}
                            </Cell>
                            <Cell>
                                {item.sichtung && item.sichtung.map((e, index) => (
                                    <Link
                                        to={'/edit/mitarbeiter/' + get(e, 'durch.0.v._id')}
                                        key={i}
                                    >
                                        {get(e, 'durch.0.v.short.v')}
                                        &nbsp;
                                    </Link>
                                ))}
                            </Cell>
                            <AllowedForUser rolle="Admin">
                                <Link to={`/ref/signatur/${item._id}`}>
                                    <DeleteSVG />
                                </Link>
                            </AllowedForUser>
                        </TableRow>
                    ))}
                </Loading>
            </>
            <AllowedForUser rolle="Hiwi">
                <NewButton type="Signatur" target="_blank" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchSignatur);
