import styled from 'styled-components';
import Area from './Area';

export const StyledGrid = styled(Area)`
    //für grid braucht man alles darunter
    max-width: ${props => (props.width ? props.width : '95%')}; //dont reduce for register, width needed for suche/brief
    display: grid;
`;

export default styled(StyledGrid)`
    grid-template-columns: 2em repeat(${props => props.children.length - 2}, 1fr) 2em;
`;

export const OpSearchRow = styled(StyledGrid)`
    grid-template-columns: 2em 2fr 1fr;
`;

export const AttributesSearchRow = styled(StyledGrid)`
    grid-template-columns: 2em 2fr;
	max-width: 54rem;
`;

export const FavoriteRow = styled(StyledGrid)`
    grid-template-columns: 2em 0.5fr 2fr 2fr 2em;
`;

export const LogRow = styled(StyledGrid)`
    grid-template-columns: 10em 10em 1fr;
`;

export const LogZB = styled(StyledGrid)`
    grid-template-columns: 2em 1fr 4fr 1fr 1fr 1fr;
`;

export const BriefRow = styled(StyledGrid)`
    grid-template-columns: 2em 0.35fr 1fr 0.45fr 1fr 1fr 0.7fr 0.45fr 2em;
    //                          ID  Datum Ort  Verf.Empf.Inc. Regest
`;

export const PublicBriefRow = styled(StyledGrid)`
    grid-template-columns: 2em 0.35fr 1fr 0.45fr 1fr 1fr 0.7fr 0.45fr 0.45fr 0.45fr 2em;
    //                          ID  Datum Ort  Verf.Empf.Ziel. Digit Trans  Regest
`;

export const PersonRow = styled(StyledGrid)`
    grid-template-columns: 2em 1fr 1fr 1fr 1fr 0.25fr 2em;
`;

export const ZitiernameRow = styled(StyledGrid)`
    grid-template-columns: 2em 1fr 1fr 1fr 1fr 0.25fr 2em;
    //                         N. Vorn.Bein.Tit.gepr
`;

export const OrtRow = styled(StyledGrid)`
    grid-template-columns: 2em 0.8fr 0.25fr 0.8fr 0.8fr 0.6fr 0.6fr 2em;
    //                         N.     gepr. jüN.  and    kat.  gnd
`;

export const SacheRow = styled(StyledGrid)`
    grid-template-columns: 2em 1fr 1fr 1fr 1fr 2em;
    //                       short|alt|alt|typ
`;

export const ArchivRow = styled(StyledGrid)`
    grid-template-columns: 2em 0.25fr 1fr 0.1fr 2em;
    //                         Arch. Name kat.
`;

export const SignaturRow = styled(StyledGrid)`
    grid-template-columns: 2em 0.45fr 1fr 0.25fr 0.5fr 1fr 0.35fr 0.35fr 2em;
    //                         Arch. Sign.gueltig.Stufe Titel URL
`;

export const BriefhandschriftRow = styled(StyledGrid)`
    grid-template-columns: 2em 0.4fr 0.6fr 1fr 0.55fr 0.55fr 0.75fr 0.55fr 0.55fr 2em;
    //                         HsID  Archiv Sign. fol.   Nr.  uebForm Repr  Brief
`;

export const BriefdruckRow = styled(StyledGrid)`
    grid-template-columns: 2em 0.2fr 1fr 0.45fr 0.45fr 0.3fr 0.3fr 2em;
    //                         BdID Druck  S.   Nr.   Repr   Brief
`;

export const DruckRow = styled(StyledGrid)`
    grid-template-columns: 2em 0.6fr 1fr 0.2fr 0.55fr 0.55fr 2em;
    //                          Verf. Kurz    J. | Ort. | vd16
`;

export const LiteraturRow = styled(StyledGrid)`
    grid-template-columns: 2em 0.4fr 0.3fr 1fr 0.16fr 0.35fr 0.3fr 2em;
    //                        kürz.|Aut.|Tit.|j|url
`;

// Achtung!: feste Pixelangaben verhindern gleichmäßiges Stauchen.
// Achtung!: Damit derselbe Suchfilter auf verschiedenen Seiten gleichgroß ist, müssen unterschiedliche Prozentangaben verwendet werden.
