import styled from 'styled-components';
import { grey } from './colors';

/* ursprüngliches Konzept:
H1 - Titel
H2 - Unterbereiche
H3 - name/short
H4 - Area Titel
*/

export default styled('div')`
    font-size: 1.5em !important;
    font-weight: ${({ notBold }) => (notBold ? 'normal' : 'bold')};
    letter-spacing: -0.2px !important;
    padding: 0 !important;
    margin: ${({ margin }) => margin ? margin : '0px 0px 10px 0px !important'}
    color: ${grey} !important;
`;
