import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from '../service/immutables';
import styled from 'styled-components';
import Arrow from '../SVG/ChevronLeftRight';

const Cente = styled.div`
    margin-top: 30px;
`;

class CollapseLeftRight extends Component {
    constructor(props) {
        super();
        this.state = {
            show: props.show,
        };
    }

    render() {
        const { children } = this.props;
        const { show } = this.state;
        return (
            <>
                {show && children}
                <Cente>
                    <Arrow
                        direction={!show}
                        onClick={() => this.setState(({ show }) => ({ show: !show }))}
                    />
                </Cente>
            </>
        );
    }
}

CollapseLeftRight.propTypes = {
    show: PropTypes.bool,
    trigger: PropTypes.arrayOf(PropTypes.string),
    helperForUserInput: PropTypes.string,
};

CollapseLeftRight.defaultProps = {
    show: false,
};

const mapStateToProps = (state, props) => {
    let show = false;
    for (let i = 0; i < props.trigger.length; ++i) {
        let tmp = get(props.trigger[i] + '.v', state.item);
        if (tmp !== '' && tmp !== null && tmp !== undefined) {
            show = true;
            break; // ein relevanter wert wurde gefunden
        }
    }
    return { show: !!show || !!props.helperForUserInput };
};

export default connect(mapStateToProps)(CollapseLeftRight);
