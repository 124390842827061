import styled from 'styled-components';
import { lightBlue } from './colors';

export const DropDownArea = styled('div')`
    width: ${props => (props.width ? props.width : 'auto')};
    position: relative;
`;

export const DropDown = styled('div')`
    display: block;
    position: absolute;
    width: ${props => (props.width ? props.width : 'auto')};
    max-height: 20rem;

    overflow: auto;
	scrollbar-width: thin; //needed for Firefox
    background-color: #fff;
    border: 1px solid #c6c6c6;
    border-radius: 3px;

    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    top: 100%;
    z-index: 1;
`;

export const DropDownItem = styled('div')`
    width: ${props => (props.width ? props.width : 'auto')};
    padding: 3px;
    background-color: ${props => (props.color ? lightBlue : 'none')};
    &:hover {
        background-color: ${lightBlue};
    }
`;
