import styled from 'styled-components';
import { Area } from './Area';

export const TableRow = styled(Area)`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
export const Items = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, 24%);
    width: 95%;
`;
export const SearchInputArea = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 5px;
`;
export const SearchBody = styled.div`
    max-width: 1264px;
    width: 100%;
    height: 94%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 0;
`;
export const SearchContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;
