/**
 * @project:      ThBw
 * @file:         client/src/client/routes/search/items/SearchItems.js
 * @summary:
 * @desc:
 * @created:      2019-11-29 16:02:01 by ekzyis
 * @lastmodified: 2019-12-06 12:54:34 by ekzyis
 */
import React from 'react';
import Loading from '../../../../components/Loading';

// A general component to show items
const SearchItems = ({ loading, items, children }) => {
    return (
        <>
            <Loading loading={loading}>{items.map((item, i) => children(item, i))}</Loading>
        </>
    );
};

export default SearchItems;
