import React from 'react';
import { connect } from 'react-redux';
import { get } from '../../service/immutables';

import CollapseArea from '../../container/CollapseArea';
import CollapseTopDown from '../../container/CollapseTopDown';
import DeleteItem from '../../container/DeleteItem';
import EditHOC from '../../container/EditHOC';
import List from '../../container/List';
import Namen from '../../container/Zitiernamen';
import RefsContainer from '../../container/RefsContainer';
import SetFavorite from '../../container/SetFavorite';
import Short from '../../container/Short';

import Switch from '../../components/Switch';

import Area from '../../style/Area';
import H1 from '../../style/H1';
import H3 from '../../style/H3';
import H4 from '../../style/H4';
import Link from '../../style/Link';
import LinkExtern from '../../style/LinkExtern';
import { Row } from '../../style/Row';
import ShortStampRed from '../../style/ShortStampRed';
import Space from '../../style/Space';
import Title from '../../style/Title';
import VerticalLine from '../../style/VerticalLine';

import OpenSVG from '../../SVG/Open';

import {
    CheckBox,
    DropdownSimple,
    FastSearch as Search,
    Input,
    LinkedInput,
    Textarea,
    Zeit,
} from '../../inputs/InputsWithMetaLabelFocus';

import wikipedia from '../../../assets/images/wikipedia.png';
import gnd from '../../../assets/images/gnd.jpg';

const Person = ({ item, type, short, shortGiven, gndGiven, kollektiv, mitarbeiter, lebensdatenGiven, nichtAuffuehrenBrief, nichtAuffuehrenLiteratur, blockFuerBrief, blockFuerLiteratur }) => (
    <>
        <Row>
            <H1>Person</H1>
            <Space />
            <Link to={`/ansicht/person/${item._id}`} target="_self">
                <OpenSVG />
            </Link>
            <Space />
            <SetFavorite type={type} />
			<Space />
			<LinkExtern href={
				(get('gnd.v', item) && get('gnd.v', item) !== '-') ?
				(`http://tools.wmflabs.org/persondata/redirect/gnd/de/${
				item.gnd.v.replace('https://d-nb.info/gnd/', '').replace('http://d-nb.info/gnd/', '')
				}`)
				: ('https://de.wikipedia.org/w/index.php?search=' + short)
				} target="_blank" rel="noopener noreferrer">
                <img src={wikipedia} width="100%" alt="" />
            </LinkExtern>
			<Space />
			<LinkExtern href={
				(get('gnd.v', item) && get('gnd.v', item) !== '-') ? get('gnd.v', item) : ('https://portal.dnb.de/opac.htm?method=checkCategory&categoryId=persons')
				} target="_blank" rel="noopener noreferrer">
                <img src={gnd} width="100%" alt="" />
            </LinkExtern>
            <Space />
            <DeleteItem type={type} />
        </Row>
		<Row>
        	<Short />
			<Space />
			<H3 notBold>
				{(get('geburt.am.schoen.v', item) || get('geburt.ort.v.short.v', item)) && <>* </>}
				{get('geburt.am.schoen.v', item) && <>{get('geburt.am.schoen.v', item)}</>}
				{get('geburt.ort.v.short.v', item) && (<> in {get('geburt.ort.v.short.v', item)}</>)}
				<Space />
				{(get('tod.am.schoen.v', item) || get('tod.ort.v.short.v', item)) && <>† </>}
				{get('tod.am.schoen.v', item) && <>{get('tod.am.schoen.v', item)}</>}
				{get('tod.ort.v.short.v', item) && (<> in {get('tod.ort.v.short.v', item)}</>)}
			</H3>
			{!get('gnd.v', item) && (
				<a href="https://portal.dnb.de/opac.htm?method=checkCategory&categoryId=persons" target="_blank" rel="noopener noreferrer">
					<ShortStampRed>[GND ergänzen!]</ShortStampRed>
				</a>
			)}
			{get('gnd.v', item) && (!get('gnd.v', item).includes('http://d-nb.info/gnd/') && !get('gnd.v', item).includes('https://d-nb.info/gnd/') && !(get('gnd.v', item) === '-')) && (
				<ShortStampRed>[GND formal nicht korrekt!]</ShortStampRed>
			)}
		</Row>
		{kollektiv && (
			<H4 notBold>
				{get('namen.0.long.v', item) && (
					<>
						{get('namen.0.long.v', item)}
					</>
				)}
			</H4>
		)}
		<>
			{/* nicht verwendbar: commons.wikimedia.org/w/index.php?title=Special:Redirect/file/ wegen unberechenbaren Fotos, z.B. Aaron = Popgruppe */}
			{/* zu geringe Ausbeute, manchmal Gruppenbilder: 
			<img src={`https://www.heiligenlexikon.de/Fotos/${short.replace(' ', '_')}.jpg`} height="150px" alt="" />*/}
        </>
        <CollapseArea label="Namen" show={!shortGiven}>
            <Namen />
            <Row>
                <Area>
                    <List label="Namensvarianten" name="namensvarianten" column>
                        {index => (
                            <>
                                <Short name={`namensvarianten[${index}].short`} Header4 />
                                <Row>
                                    <Input
                                        label="Name"
                                        name={`namensvarianten[${index}].name`}
										noMeta
                                        small
                                    />
                                    <VerticalLine />
                                    <Input
                                        label="Vorname"
                                        name={`namensvarianten[${index}].vorname`}
										noMeta
                                        small
                                    />
                                    <VerticalLine />
                                    <Input
                                        label="Beiname"
                                        name={`namensvarianten[${index}].beiname`}
										noMeta
                                        small
                                    />
                                    <VerticalLine />
                                    <Input 
										label="Titel" 
										name={`namensvarianten[${index}].titel`}
										noMeta 
									/>
                                    <VerticalLine />
                                    <CheckBox
                                        label="belegt"
                                        name={`namensvarianten[${index}].belegt`}
                                        width="50px"
										noMeta
                                    />
                                </Row>
                            </>
                        )}
                    </List>
                </Area>
            </Row>
        </CollapseArea>
		<CollapseArea label="Lebensdaten" show={!lebensdatenGiven}>
			<Row>
                <Area>
                    <Title>Geburt</Title>
					<Row>
	                    <Zeit label="am" name="geburt.am" />
						<VerticalLine/>
	                    <Search label="Ort" api="Ort" name="geburt.ort" small />
					</Row>
                </Area>
                <Area>
                    <Title>Tod</Title>
					<Row>
	                    <Zeit label="am" name="tod.am" />
						<VerticalLine/>
	                    <Search label="Ort" api="Ort" name="tod.ort" small />
					</Row>
                </Area>
            </Row>
		</CollapseArea>
		<CollapseArea label="Normdaten" show={!gndGiven}>
			<Row>
                <Area>
                    <LinkedInput label="GND" name="gnd" placeholder="http://d-nb.info/gnd/..." noMeta/>
                </Area>
                <Area>
                	<LinkedInput
                          label="Wikidata"
                          name="wikidata"
                          placeholder="http://www.wikidata.org/entity/..."
						  noMeta
                	/>
                </Area>
            </Row>
		</CollapseArea>
		
		<CollapseArea label="Kategorisierung" show={!shortGiven}>
			<Row>
				<Area>
					<Row>
		                <CheckBox
		                    label="nur für Briefe..."
		                    name={`nichtAuffuehren.literatur`}
		                    default={true}
							disabled={blockFuerBrief}
							width="90px"
							noMeta
							noUnderline
		                />
						<CheckBox
		                    label="nur für Literatur"
		                    name={`nichtAuffuehren.brief`}
							disabled={blockFuerLiteratur}
							width="90px"
							noMeta
							noUnderline
		                />
					</Row>
	            </Area>
				{!nichtAuffuehrenBrief && (
					<>
		                <Area>
		                    <DropdownSimple
		                        label="Hauptfigur bei uns"
		                        name="hauptfigur.hauptfigur"
		                        items={['ja', 'nein', 'passiv', 'vorübergehend', 'angedacht']}
								noMeta
								noUnderline
								width="120px"
		                        default_value="nein"
		                    />
							<CollapseTopDown trigger={[`hauptfigur.von`, `hauptfigur.bis`]}>
			                    <Zeit label="von" name="hauptfigur.von" />
			                    <Zeit label="bis" name="hauptfigur.bis" />
							</CollapseTopDown>
		                </Area>
		                <Area>
		                    <Input label="literarische Figur aus" name="literarisch" width="120px" noMeta/>
		                </Area>
						<Area>
		                    <CheckBox label="weiblich" name="weiblich" width="50px" noMeta noUnderline />
		                </Area>
		                <List label="Profession" name="profession" simple>
			                {index => (
			                    <Area noStyle>
			                        <Search label="" api="Profession" name={`profession[${index}]`} small />
			                    </Area>
			                )}
			            </List>
	                </>
                )}
            </Row>
			{!nichtAuffuehrenBrief && (
				<>
					<Row>
		                <Area>
		                    <List label="Konfession" name="konfession" column>
		                        {index => (
		                            <Row>
		                                <Search
		                                    label="Konfession"
		                                    api="Konfession"
		                                    name={`konfession[${index}].konfession`}
											small
		                                />
		                                <VerticalLine />
		                                <Zeit label="von" name={`konfession[${index}].von`} />
		                                <VerticalLine />
		                                <Zeit label="bis" name={`konfession[${index}].bis`} />
		                            </Row>
		                        )}
		                    </List>
		                </Area>
		            </Row>
		            <Area>
		                <Title>Kollektiv</Title>
		                <Row>
		                    <CheckBox label="ja" name={`kollektiv.kollektiv`} width="25px" noMeta noUnderline />
		                    <VerticalLine />
		                    <DropdownSimple
		                        label="Typ"
		                        name="kollektiv.typ"
		                        items={[
		                            '',
		                            'Rat',
		                            'Senat',
		                            'Professoren',
		                            'Doktoren',
		                            'Theologen',
		                            'Prediger',
		                            'Pfarrer',
		                            'Schulherren',
		                        ]}
								noMeta
								noUnderline
		                        width="100px"
		                    />
		                </Row>
						<br/>
		                <List label="Zugehörige Personen" name="kollektiv.zugehoerig" column>
		                    {index => (
		                        <>
		                            <Row>
		                                <Search
		                                    label="Person"
		                                    name={`kollektiv.zugehoerig[${index}].person`}
		                                    api="Zitiername"
											small
		                                />
		                                <VerticalLine />
		                                <Zeit label="von" name={`kollektiv.zugehoerig[${index}].von`} />
		                                <VerticalLine />
		                                <Zeit label="bis" name={`kollektiv.zugehoerig[${index}].bis`} />
		                            </Row>
		                        </>
		                    )}
		                </List>
		            </Area>
				</>
			)}
        </CollapseArea>
		{!nichtAuffuehrenLiteratur && (
			<CollapseArea label="Vorkommen in Literatur" show={shortGiven}>
				<Area>
			        <RefsContainer
			            label="Autor von "
			            api="literatur"
			            idPath="namen.0._id"
			            query="autor.v="
			            anschlussText="Literaturtiteln"
						column
						long
			        />
	            </Area>
				<Area>
			        <RefsContainer
			            label="Herausgeber von "
			            api="literatur"
			            idPath="namen.0._id"
			            query="herausgeber.v="
			            anschlussText="Literaturtiteln"
						column
						long
			        />
	            </Area>
			</CollapseArea>
		)}
		{!nichtAuffuehrenBrief && (
		<>
			<CollapseArea label="Vorkommen in Briefen" show={shortGiven}>
				<br/>
	            <Area>
					<RefsContainer
			            label="Verfasser von "
			            api="brief"
			            idPath="namen.0._id"
			            query="verfasser.person.v="
			            anschlussText="Briefen"
						column
						long
			        />
	            </Area>
	            <Area>
			        <RefsContainer
			            label="Adressat von "
			            api="brief"
			            idPath="namen.0._id"
			            query="adressat.person.v="
			            anschlussText="Briefen"
						column
						long
			        />
	            </Area>
	            <Area>
			        <RefsContainer
			            label="Erwähnung in "
			            api="brief"
			            idPath="namen.0._id"
			            query="schlagworte.personen.v="
			            anschlussText="Briefen"
						column
						long
			        />
	            </Area>
				<Area>
			        <RefsContainer
			            label="Überbringer von "
			            api="brief"
			            idPath="namen.0._id"
			            query="ueberbringer.v="
			            anschlussText="Briefen"
						column
						long
			        />
	            </Area>
				<Area>
			        <RefsContainer
			            label="Schreiber von "
			            api="briefhandschrift"
			            idPath="namen.0._id"
			            query="schreiber.person.v="
			            anschlussText="Briefhandschriften"
						column
						long
						closed
			        />
	            </Area>
			</CollapseArea>
			<CollapseArea label="Beteiligung an Drucken" show={false}>
				<br/>
				<Area>
			        <RefsContainer
			            label="Autor von "
			            api="druck"
			            idPath="namen.0._id"
			            query="autor.v="
			            anschlussText="Drucken"
						column
						long
			        />
	            </Area>
				<Area>
			        <RefsContainer
			            label="Drucker von "
			            api="druck"
			            idPath="namen.0._id"
			            query="drucker.v="
			            anschlussText="Drucken"
						column
						long
			        />
	            </Area>
				<Area>
			        <RefsContainer
			            label="Verleger von "
			            api="druck"
			            idPath="namen.0._id"
			            query="verleger.v="
			            anschlussText="Drucken"
						column
						long
			        />
	            </Area>
	        </CollapseArea>
		</>
		)}
		{!nichtAuffuehrenBrief && (
	        <CollapseArea label="Lebenslauf" show={true}>
				<Row>
	                <Area>
	                    <List label="Beziehung zu Person" name="beziehung" column>
	                        {index => (
	                            <Row>
	                                <Search
	                                    label="Art"
	                                    name={`beziehung[${index}].art`}
	                                    api="Beziehung"
	                                    small
	                                />
	                                <VerticalLine />
	                                <>
	                                    <br />
	                                    von
	                                </>
	                                <VerticalLine />
	                                <Search
	                                    label="Person"
	                                    name={`beziehung[${index}].zu`}
	                                    api="Zitiername"
	                                />
	                            </Row>
	                        )}
	                    </List>
	                </Area>
	            </Row>
	            <Row>
	                <Area>
	                    <List label="Lebenslauf" name="aemter" column>
	                        {index => (
	                            <Row>
	                                <Search label="Amt" api="Amt" name={`aemter[${index}].amt`} small />
	                                <VerticalLine />
	                                <Search
	                                    label="Ort"
	                                    api="Ort"
	                                    name={`aemter[${index}].ort`}
	                                    extraParam="name"
	                                    small
	                                />
	                                <VerticalLine />
	                                <Zeit label="Beginn" name={`aemter[${index}].beginn`} />
	                                <VerticalLine />
	                                <Zeit label="Ende" name={`aemter[${index}].ende`} />
	                            </Row>
	                        )}
	                    </List>
	                </Area>
	            </Row>
	            <Row>
	                <Area>
	                    <List label="Aufenthalte (Itinerar)" name="aufenthalt" column>
	                        {index => (
	                            <Row>
	                                <Search
	                                    label="Ort"
	                                    api="Ort"
	                                    name={`aufenthalt[${index}].ort`}
	                                    small
	                                />
	                                <VerticalLine />
	                                <Zeit label="von" name={`aufenthalt[${index}].beginn`} />
	                                <VerticalLine />
	                                <Zeit label="bis" name={`aufenthalt[${index}].ende`} />
	                            </Row>
	                        )}
	                    </List>
	                </Area>
	            </Row>
	            <Area>
	                <Title>Biogramm</Title>
	                <Area>
						<Input 
							label="Kurzfassung (sofort öffentlich sichtbar!)" 
							name="biogramm.kurz"
							disabled={!mitarbeiter} 
							large 
						/>
	                    <List label="Autor" name="biogramm.kurzAutor" simple>
	                        {index => (
	                            <Search
	                                label="Autor"
	                                name={`biogramm.kurzAutor[${index}]`}
	                                api="Mitarbeiter"
	                                small
	                            />
	                        )}
	                    </List>
	                </Area>
	                <Area>
	                    <Textarea label="Längere Fassung / interne Notizen" name="biogramm.lang" large />
	                    <List label="Autor" name="biogramm.langAutor" simple>
	                        {index => (
	                            <Search
	                                label="Autor"
	                                name={`biogramm.langAutor[${index}]`}
	                                api="Mitarbeiter"
	                                small
	                            />
	                        )}
	                    </List>
	                </Area>
	            </Area>
	            <Row>
	                <Area>
	                    <Title>Biographische Primärquellen</Title>
	                    <Row>
	                        <Area>
	                            <List label="Handschriften" name="bioQuelle.handschriften" column>
	                                {index => (
	                                    <Search
	                                        label="Quelle"
	                                        api="Briefhandschrift"
	                                        name={`bioQuelle.handschriften[${index}]`}
											placeholder="Handschrift/Abschnitt"
	                                    />
	                                )}
	                            </List>
	                        </Area>
	                        <Area>
	                            <List label="Drucke" name="bioQuelle.drucke" column>
	                                {index => (
	                                    <Search
	                                        label="Quelle"
	                                        api="Briefdruck"
	                                        name={`bioQuelle.drucke[${index}]`}
											placeholder="Druck (Abschnitt)"
	                                    />
	                                )}
	                            </List>
	                        </Area>
	                    </Row>
	                    <Row>
	                        <Area>
	                            <List label="Editionen" name="bioQuelle.editionen" column>
	                                {index => (
	                                    <Row>
	                                        <Search
	                                            label=""
	                                            api="Literatur"
	                                            name={`bioQuelle.editionen[${index}].literatur`}
	                                        />
	                                        <VerticalLine />
	                                        <Input
	                                            label="Seite"
	                                            name={`bioQuelle.editionen[${index}].seite`}
	                                            placeholder="S."
	                                            helperForUserInput="S. "
	                                            small
	                                        />
	                                    </Row>
	                                )}
	                            </List>
	                        </Area>
	                    </Row>
	                </Area>
	            </Row>
	        </CollapseArea>
		)}
		{!nichtAuffuehrenBrief && (
	        <CollapseArea label="Werk" show={false}>
	            <Row>
	                <Area>
	                    <List label="Wichtige Handschriften" name="werke.handschriften" column>
	                        {index => (
	                            <Search
	                                label=""
	                                api="Briefhandschrift"
	                                name={`werke.handschriften[${index}]`}
	                            />
	                        )}
	                    </List>
	                </Area>
	                <Area>
	                    <List label="Wichtige Drucke" name="werke.drucke" column>
	                        {index => <Search label="" api="Druck" name={`werke.drucke[${index}]`} />}
	                    </List>
	                </Area>
	                <Area>
	                    <List label="Werkeditionen" name="werke.editionen" column>
	                        {index => (
	                            <Search label="" api="Literatur" name={`werke.editionen[${index}]`} />
	                        )}
	                    </List>
	                </Area>
	            </Row>
	        </CollapseArea>
		)}
        <CollapseArea label="Sekundärliteratur" show={true}>
            <Row>
                <Area>
                    <List label="Sekundärliteratur" name="sekundaerLiteratur" column>
                        {index => (
                            <Row>
                                <Switch name={`sekundaerLiteratur[${index}].show`}>
                                    <Search
                                        label=""
                                        api="Literatur"
                                        name={`sekundaerLiteratur[${index}].literatur`}
                                    />
                                    <Search
                                        label=""
                                        api="Druck"
                                        name={`sekundaerLiteratur[${index}].druck`}
                                    />
                                </Switch>
                                <Input
                                    label="Seite"
                                    name={`sekundaerLiteratur[${index}].seite`}
                                    placeholder="S."
                                    helperForUserInput="S. "
                                    small
                                />
                            </Row>
                        )}
                    </List>
                </Area>
            </Row>
            <Area>
                <List label="URL`s" name="url" column>
                    {index => (
                        <LinkedInput
                            label="URL"
                            name={`url[${index}]`}
                            placeholder="http://..."
                            large
                        />
                    )}
                </List>
            </Area>
        </CollapseArea>
		{/*
        <CollapseArea label="Bildmaterial" show={false}>
            <Row>
                <Area>
                    <List label="Portraitbilder" name="portraits">
                        {index => <File name={`portraits[${index}]`} />}
                    </List>
                </Area>
            </Row>
            <Row>
                <Area>
                    <List label="Handschriftenproben" name="proben">
                        {index => (
                            <>
                                <File label="Bild" name={`proben[${index}].bild`} />
                                <Zeit label="Datum" name={`proben[${index}].entstehung`} />
                            </>
                        )}
                    </List>
                </Area>
            </Row>
        </CollapseArea>
		*/}
    </>
);

const mapStateToProps = state => {
	let mitarbeiter = false;
	if (get('user.role', state.auth) === 'Mitarbeiter') {
		mitarbeiter = true;
		};
	if (get('user.role', state.auth) === 'Admin') {
		mitarbeiter = true;
		};
		
    return {
		nichtAuffuehrenBrief: state.item.nichtAuffuehren && state.item.nichtAuffuehren.brief && state.item.nichtAuffuehren.brief.v === true ? true : false,
		nichtAuffuehrenLiteratur: state.item.nichtAuffuehren && state.item.nichtAuffuehren.literatur && state.item.nichtAuffuehren.literatur.v === true ? true : false,
	    blockFuerBrief: state.item.nichtAuffuehren && state.item.nichtAuffuehren.brief && state.item.nichtAuffuehren.brief.v === true && state.item.nichtAuffuehren.literatur && state.item.nichtAuffuehren.literatur.v === false ? true : false,
		blockFuerLiteratur: state.item.nichtAuffuehren === undefined || (state.item.nichtAuffuehren.literatur && state.item.nichtAuffuehren.literatur.v === true && state.item.nichtAuffuehren.brief && state.item.nichtAuffuehren.brief.v === false) ? true : false,
		short: state.item.short ? state.item.short.v : '',
		shortGiven: state.item.short && state.item.short.v ? true : false,
		kollektiv: state.item.kollektiv && state.item.kollektiv.kollektiv && state.item.kollektiv.kollektiv.v ? true : false,
		gndGiven: state.item.gnd && state.item.gnd.v ? true : false,
		lebensdatenGiven: (state.item.geburt && state.item.geburt.am && state.item.geburt.am.schoen && state.item.geburt.am.schoen.v) || (state.item.tod && state.item.tod.am && state.item.tod.am.schoen && state.item.tod.am.schoen.v) ? true : false,
	    item: state.item,
		mitarbeiter: mitarbeiter
	};
};

export default EditHOC(connect(mapStateToProps)(Person));
