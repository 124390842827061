import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, admin, ...rest }) => {
    const token = localStorage.getItem('token');
    const expTime = parseInt(localStorage.getItem('exp_maxTime'));
    const currentTime = parseInt(localStorage.getItem('exp_currentTime'));
    return (
        <Route
            {...rest}
            render={props =>
                //2. Abfrage ist dazuda um ein evtl exp token zu ermitteln
                token !== null && expTime > currentTime ? (
                    <Component {...props} />
                ) : (
                    <>
                        <Redirect to={'/login/forceLogout'} />
                    </>
                )
            }
        />
    );
};
export default PrivateRoute;
