import styled from 'styled-components';

export const Row = styled('div')`
    display: flex;    
    flex-flow: ${({ nowrap }) => (nowrap ? 'nowrap' : 'row wrap')};
    
    ${({ center }) => center && 'justify-content: center;'}
    ${({ right }) => right && 'justify-content: flex-end;'}
    ${({ between }) => between && 'justify-content: space-between;'}
    
    align-items: ${({ hcenter }) => (hcenter ? 'center' : 'stretch')};
`;

export default Row;
