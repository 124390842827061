export const loadLocalStorage = (name = null) => {
    try {
        const state = localStorage.getItem(name);
        return state ? JSON.parse(state) : undefined;
    } catch (e) {
        return undefined;
    }
};

export const saveLocalStorage = (name, state) => {
    try {
        localStorage.setItem(name, JSON.stringify(state));
    } catch (e) {
        // TODO check error possibles and write suitable handling
    }
};

export const deleteLocalStorage = name => {
    try {
        localStorage.removeItem(name);
    } catch (e) {
        // TODO check error possibles and write suitable handling
    }
};
