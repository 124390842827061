/**
 * @project:      ThBw
 * @file:         client/src/client/container/DeleteItem.js
 * @summary:
 * @desc:
 * @created:      2019-10-23 21:21:31 by Moritz Mock
 * @lastmodified: 2019-12-20 14:31:23 by ekzyis
 */
import React from 'react';
import { connect } from 'react-redux';
import Link from '../style/Link';
import { red } from '../style/colors';
import AllowedForUser from './AllowedForUser';
import Icon from '../style/IconButton';

export const DeleteItem = ({ id, type }) => (
    <AllowedForUser rolle={'Admin'}>
        <Link to={`/ref/${type}/${id}`} target="_self">
            <Icon>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke={red}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
            </Icon>
        </Link>
    </AllowedForUser>
);

export default connect(state => ({ id: state.item._id }))(DeleteItem);
