import { css } from 'styled-components';

export const lightGrey = '#a7a7a7';

export const ultraLightGrey = '#e6e6e6';

export const lightBlue = '#4285f4'; //'#5cb9ff';

export const blue = '#3E7BA7';

export const bodyGrey = '#eceae8';

export const linkBlue = '#365899';

export const purple = '#690095'; //link purple from google

export const black = '#484848';

export const defaultTextColor = '#555555';

export const lineGrey = '#ebebeb';

export const grey = '#767676';

export const label = '#757575';

export const white = '#fff';

export const red = '#cc3300';

export const background = '#F6F5F3';

export const backgroundOld = '#e9ebee';

const color_GET = '#20c020';

const color_POST = '#1e8484';

const color_DELETE = '#c02020';

const color_PUT = '#949035';

export const yellow = '#f7e234';

export const RESTColorMap = new Map();
RESTColorMap.set('GET', color_GET);
RESTColorMap.set('POST', color_POST);
RESTColorMap.set('DELETE', color_DELETE);
RESTColorMap.set('PUT', color_PUT);

export const boxShadow = css`
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    /*    &:hover {
        box-shadow: 0 1px 6px rgba(49, 126, 172, 0.28); // blue to rgb
    }*/
`;

export const border = css`
    border: 1px solid rgba(32, 33, 36, 0.28);
`;
