import React, { Component } from 'react';
import { deleteItem, getItem, getRef, getToken } from '../../service/fetch';
import Button from '../../style/Button';
import Loading from '../../components/Loading';
import Grid from '../../style/SearchRow';
import Cell from '../../style/SearchCell';
import Link from '../../style/Link';
import OpenSVG from '../../SVG/Open';
import { history } from '../../../index';
import Row from '../../style/Row';
import Container from '../../style/Container';
import H3 from '../../style/H3';

class Ref extends Component {
    state = {
        loading: 0,
        error: false,
        items: [],
        short: '',
    };

    startLoading = () => {
        this.setState({ loading: 1 });
    };

    endLoading = () => {
        this.setState({ loading: 0 });
    };

    handleRefs = async () => {
        const { id, model } = this.props.match.params;
        this.startLoading();
        getItem(model, id)
            .then(async items => {
                this.setState({ short: items.short.v });
            })
            .catch(console.error);
        getRef(model, id)
            .then(async items => {
                if (model.toLowerCase() === 'person') {
                    return getItem('person', id).then(async person => {
                        //console.log(person.namen);
                        for (let i = 0; i < person.namen.length; ++i) {
                            await getRef('zitiername', person.namen[i]._id).then(nameRefs => {
                                this.setState(prevState => ({
                                    items: prevState.items.concat([
                                        { model: 'Zitiername: ' + person.namen[i].short.v },
                                        ...nameRefs,
                                    ]),
                                }));
                            });
                        }
                    });
                } else this.setState({ items });
            })
            .catch(console.error)
            .finally(() => {
                console.log('endLoading');
                this.endLoading();
            });
    };

    async componentDidMount() {
        if (getToken() !== '') {
            await this.handleRefs();
        } else {
            history.push('/login/forceLogout');
        }
    }

    handleDelete = async () => {
        const { id, model } = this.props.match.params;
        if (window.confirm('Wirklich löschen?')) {
            deleteItem(model, id)
                .then(status => {
                    if (status === 200) {
                        window.alert('Erfolgreich gelöscht!');
                        window.top.close();
                    } else throw new Error(status);
                })
                .catch(console.error);
        }
    };

    render() {
        const { /*id,*/ model } = this.props.match.params;
        const { items, loading, short } = this.state;
        return (
            <Container>
				<>
					{/*{`ID: ${id}`}*/}
					{(!loading && short) && (
						<>
							<Row>
				                <H3>
									{`${model}`.substring(0, 1).toUpperCase() + `${model}`.substring(1)}
									<>&nbsp;</>
				                    {`${short}`}
					                <>&nbsp;</>
								</H3>
							</Row>
							<br/>
							{items.length > 0 ? (
								<div>Referenzierungen gefunden:</div>
			                ) : (
								<>
									<div>keine Referenzierungen</div>
									<br/>
								    <Button onClick={this.handleDelete}>Löschen</Button>
								</>
							)}
						</>
					)}
					{!loading && !short && (<H3>Objekt nicht gefunden!</H3>)}
					<br />
				</>
                <Loading loading={loading}>
                    {items.map((item, i) => (
                        <Grid key={i}>
                            {item.short ? (
                                <Link to={`/edit/${item.model}/${item.id}`}>
                                    <OpenSVG />
                                </Link>
                            ) : (
                                <div>{null}</div>
                            )}
                            <Cell>{item.model}</Cell>
                            <Cell>{item.short}</Cell>
                            <Cell>{item.long}</Cell>
                            <Cell>
                                {(() => {
                                    if (item.path) {
                                        switch (item.path.slice(0, item.path.indexOf('.'))) {
                                            case 'bezugAuf':
                                                return 'Bezug auf';
                                            case 'beantwortetDurch':
                                                return 'Beantwortet durch';
                                            case 'antwortAuf':
                                                return 'Antwort auf';
                                            default:
                                                return '';
                                        }
                                    }
                                })()}
                            </Cell>
                            <div>{null}</div>
                        </Grid>
                    ))}
                </Loading>
            </Container>
        );
    }
}

export default Ref;

/*
const handleDelete = async (i) => {
    if (localStorage.getItem("rolle") === "Admin") {
        const { type } = this.state;
        const id = this.state.items[i]._id;
        if (window.confirm("Element wirklich löschen?")) {
            await deleteItem(type, id);
            this.getDataFromApi(this.state.search)
        }
    }
    else {
        window.confirm("keine ausreichenden rechte")
    }

};*/
