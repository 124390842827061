import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    initAttributesSearch,
    startAllAttributesSearch,
    resetSearch,
} from '../../store/actions/search';
import { loadTypeList } from '../../service/fetch';
import Row from '../../style/Row';
import Button from '../../style/Button';
import Container from '../../style/Container';
import { resetItem } from '../../store/actions/item';
import Cell from '../../style/SearchCell';
import { AttributesSearchRow as TableRow } from '../../style/SearchRow';
import OpenSVG from '../../SVG/Open';
import { defaultTextColor } from '../../style/colors';
import Link from '../../style/Link';
import AttributesElement from '../../components/AttributesElement';
import SearchItems from './adv/items/SearchItems';
import Loading from '../../components/Loading';
import SearchFooter from '../../components/SearchFooter';
import { SearchBody, SearchContainer } from '../../style/Search';
import TokenCheckBox from '../../components/TokenCheckBox';
import AllowedForUser from '../../container/AllowedForUser';
import ZeitraumInput from '../../components/ZeitraumInput';

class ExternAttributesSearch extends Component {
    constructor(props) {
        super(props);
        let type = props.match.url.split('/')[2];
        this.state = {
            type,
            loadingItems: false,
            showItems: false,
        };
        // get the items which can be used as proposals in fast search / dropdown
        loadTypeList(type).then(props.initAttributesSearch);
    }

    async startSearch() {
        this.setState({ showItems: false });
        await this.props.startAllAttributesSearch();
        this.setState({ showItems: true });
    }

    onKeyPressed = ({ keyCode }) => {
        if (keyCode === 13 && this.props.query.$attributes && 
			this.props.query.$attributes.value.v &&
		 		(this.props.query.$attributes.key === undefined ||
				this.props.query.$attributes.key === 'Freie Suche' || 
				this.props.query.$attributes.key === 'Zeit')
			) {
            return this.startSearch();
        }
    };

    componentWillUnmount() {
        this.props.resetItem();
        this.props.resetSearch();
    }

    backToSearch = () => {
        this.props.resetSearch();
        this.setState({ showItems: false })
    };

    render() {
        const { loadingItems, items, count, availableTypes } = this.props;
        const { showItems, type } = this.state;
        const englroute = this.props.match.url.split('/')[this.props.match.url.split('/').length-1] === 'engl';
        let loadingTypeList = !!availableTypes ? 0 : 1;
        let searching = !loadingItems && !showItems;
        return (
            <>
                <SearchContainer onKeyDown={this.onKeyPressed}>
                    <SearchBody>
                            <Container>
                                {showItems && (
									<>
										{!englroute && (
											<Row center>
			                                    <Container>{count + ' Treffer'}</Container>
			                                    <Button onClick={this.backToSearch}>
	                                        	Neue Suche
		                                    	</Button>
		                                	</Row>
										)}
		                                {englroute && (
											<Row center>
			                                    <Container>{count + ' results'}</Container>
			                                    <Button onClick={this.backToSearch}>
	                                        		New Search
		                                    	</Button>
		                                	</Row>
										)}
									{count === 0 && (
										<>
											<Row center>
												<br/><br/><br/>
											 </Row>
											<Row center>
												{!englroute && (
                                                	<Container>Mögliche Ursache für erfolglose Suchen:</Container>
												)}
												{englroute && (
                                                	<Container></Container>
												)}
											</Row>
											<Row center>
												{!englroute && (
                                                	<Container>
														<Row>– Sie haben eine Indexsuche (z.B. Brief-ID) verwendet, aber keinen Eintrag aus der Vorschlagliste angeklickt.</Row>
													</Container>
												)}	
												{englroute && (
                                                	<Container>
														<Row></Row>
													</Container>
												)}
											</Row>
										</>
									)}
									{count > 1 && (
										<>
											<Row center>
												<br/><br/><br/>
											 </Row>
											<Row center>
												{!englroute && (
	                                                <Container>Bitte beachten!: In diesem Suchmodus wird der Suchbegriff ohne Berücksichtigung der Groß- und Kleinschreibung in sämtlichen mit diesem Objekt verbundenen Daten (inklusive Forschungsdaten) gesucht.</Container>
												)}
												{englroute  && (
	                                                <Container></Container>
												)}
											</Row>
											<Row center>
												{!englroute && (
                                                	<Container>
														<Row>Dies kann zu mehr Ergebnissen als erwartet führen. Für eine präzisere Suche verwenden Sie bitte die&nbsp;<Link to={`/erweitert/${type}`} target="_self"> Erweitere Suche</Link></Row>
													</Container>
												)}	
												{englroute && (
                                                	<Container>
														<Row></Row>
													</Container>
												)}
											</Row>
										</>
									)}
									</>
                                )}
                                <Row>
                                    <Loading loading={loadingTypeList}>
                                        {searching ? (
											<>
	                                            <Row>
	                                                <AttributesElement availableTypes={availableTypes} />
													{!englroute && (
														<Button onClick={() => this.startSearch()}>
			                                            	Suchen
			                                        	</Button>
													)}
													{englroute && (
														<Button onClick={() => this.startSearch()}>
			                                            	Search
			                                        	</Button>
													)}
													{(type === 'brief' || type === 'person') && (
														<>
															{!englroute && (
																<Link to={`/erweitert/${type}`} target="_self" color={defaultTextColor}>
																	<Button>
					                                            		➔ Zur Erweiterte Suche
					                                                </Button>
																</Link>
															)}
															{englroute  && (
																<Link to={`/erweitert/${type}/engl`} target="_self" color={defaultTextColor}>
																	<Button>
					                                                   	 ➔ Go to Advanced Search
					                                                </Button>
																</Link>
															)}
														</>
													)}
													{(type === 'person' || type === 'ort' || type === 'sache') && (
														<>
															{!englroute && (
																<Link to={`/filter/${type}`} target="_self" color={defaultTextColor}>
																	<Button>
					                                                   	 ➔ Liste aufrufen
					                                                </Button>
																</Link>
															)}
															{englroute && (
																<Link to={`/filter/${type}/engl`} target="_self" color={defaultTextColor}>
																	<Button>
					                                                   	 ➔ Show List
					                                                </Button>
																</Link>
															)}
														</>
													)}
													{(type === 'sache') && (
														<>
															{!englroute && (
																<Link to={`/ansicht/sache/640859dd459afb2f0481f2a3`} target="_self" color={defaultTextColor}>
																	<Button>
					                                                    	➔ Register anzeigen
					                                                </Button>
																</Link>
															)}
															{englroute && (
																<Link to={`/ansicht/sache/640859dd459afb2f0481f2a3/`} target="_self" color={defaultTextColor}>
																	<Button>
					                                                   	 ➔ Show Register
					                                                </Button>
																</Link>
															)}
														</>
													)}
													{(type === 'ort') && (
														<>
														{!englroute && (
															<Link to={`/karte`} target="_self" color={defaultTextColor}>
																<Button>
				                                                    	➔ Karte anzeigen
				                                                </Button>
															</Link>
														)}
														{englroute && (
															<Link to={`/karte/engl`} target="_self" color={defaultTextColor}>
																<Button>
				                                                    	➔ Show Map
				                                                </Button>
															</Link>
														)}
														</>
													)}
												</Row>
												{type === 'brief' && (
                                                	<ZeitraumInput name="$period" />
												)}
												{type === 'brief' && (
		                                            <AllowedForUser rolle={'Gast'}>
													   	<TokenCheckBox />
		                                            </AllowedForUser>
												)}
												<Row>
													<AllowedForUser rolle={'Gast'}>
														<Link to={`/attributes/${type}`} target="_self" color={defaultTextColor}>
															<Button>
			                                                    Interne Suche
			                                                </Button>
														</Link>
													</AllowedForUser>
													<AllowedForUser rolle={'Gast'}>
														<Link to={`/operator/${type}`} target="_self" color={defaultTextColor}>
															<Button>
			                                                    Interne Erweiterte Suche
			                                                </Button>
														</Link>
													</AllowedForUser>
												</Row>
											</>
                                        ) : (
                                            <SearchItems items={items} loading={loadingItems}>
                                                {(item, i) => (
                                                    <TableRow key={i}>
                                                        <Link
                                                            to={englroute ? `/ansicht/${type}/${item._id}/engl` : `/ansicht/${type}/${item._id}`}
                                                            target="_blank"
                                                        >
                                                            <OpenSVG />
                                                        </Link>
                                                        <Cell>{type === 'brief' ? item.long.v : (
																	(type === 'person' && item.shortAlt && item.shortAlt.v) ? item.short.v : item.short.v
																)}</Cell>
                                                    </TableRow>
                                                )}
                                            </SearchItems>
                                        )}
                                    </Loading>
                                </Row>
                            </Container>
                    </SearchBody>
                </SearchContainer>
				{showItems && (<SearchFooter startSearch={startAllAttributesSearch} />)}
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    availableTypes: state.search.availableTypes,
    items: state.search.items,
    type: props.match.params.type,
    count: state.search.count,
    loadingItems: state.search.loading,
    query: state.item
});

const mapDispatchToProps = {
    resetItem,
    resetSearch,
    startAllAttributesSearch,
    initAttributesSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternAttributesSearch);
