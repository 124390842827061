import React, { Component } from 'react';
import { get } from 'lodash';
import Element from '../style/Element';
import Label from '../style/Label';
import MetaCheckboxes from '../components/MetaCheckboxes';
import { Row } from '../style/Row';
import RowSpace from '../style/RowSpace';
import Underline from '../style/Underline';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';

const Space = styled.div`
    width: 100%;
    height: 3px;
`;

// TODO scan all props - collect them maybe in a store and check all is fine

export const MetaLabelFocusHoc = (PassedComponent, passedProps) => {
    class Meta extends Component {
        constructor() {
            super();
            this.state = {
                focus: false,
            };
        }

        // return null from setState means "no state changes"
        handleFocus = () =>
            !this.props.disabled && this.setState(({ focus }) => (focus ? null : { focus: true }));

        handleBlur = () => this.setState({ focus: false });

        render() {
            const { focus } = this.state;
            const {
                value,
                width,
                disabled,
                name,
                label,
                api,
                small,
                large,
                extraLarge,
                tooltip,
				noMeta,
				noUnderline,
            } = this.props;
            let vis = Boolean(tooltip);
            return (
                <Element
                    tabIndex={-1}
                    width={width}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    disabled={disabled}
                    small={small}
                    large={large}
                    extraLarge={extraLarge}
                >
                    <Row between>
                        {vis ? (
                            <Tooltip placement="top" title={tooltip}>
                                <Label focus={focus}>{label || api || 'no label'}</Label>
                            </Tooltip>
                        ) : (
                            <Label focus={focus}>{label || api || 'no label'}</Label>
                            
                        )}
						{!noMeta && (
                        	<MetaCheckboxes name={name} {...passedProps} />
						)}
                    </Row>
                    <Space />
                    <PassedComponent {...this.props} importValue={get(value, 'i')} focus={focus} />
                    {(!noUnderline && !api) ? <Underline focus={focus} /> : <><RowSpace/><RowSpace/><RowSpace/><RowSpace/><RowSpace/></>}
                    {!api && <Space />}
                </Element>
            );
        }
    }

    Meta.defaultProps = {};

    Meta.propTypes = {};

    return Meta;
};

export default MetaLabelFocusHoc;
