import React from 'react';
import '../style/Loading.css';
import { MakeItGray } from '../style/disableIt';

export default ({ loading, children, marginTop, marginLeft, id }) => (
    <MakeItGray loading={loading} id={id} >
        {loading !== 0 && loading !== false ? (
            <div
                className="sk-circle"
                style={{
                    marginTop: marginTop ? marginTop : '100px',
                    marginBottom: '0px',
                    marginLeft: marginLeft ? marginLeft : 'auto',
                }}
            >
                <div className="sk-circle1 sk-child" />
                <div className="sk-circle2 sk-child" />
                <div className="sk-circle3 sk-child" />
                <div className="sk-circle4 sk-child" />
                <div className="sk-circle5 sk-child" />
                <div className="sk-circle6 sk-child" />
                <div className="sk-circle7 sk-child" />
                <div className="sk-circle8 sk-child" />
                <div className="sk-circle9 sk-child" />
                <div className="sk-circle10 sk-child" />
                <div className="sk-circle11 sk-child" />
                <div className="sk-circle12 sk-child" />
            </div>
        ) : (
            children
        )}
    </MakeItGray>
);
