import { MetaLabelFocusHoc } from './MetaLabelFocusHoc';
import CheckBoxRaw from './raw/CheckBox';
import DropdownSimpleRaw, { DropDownSimpleAuth as DropdownSimpleAuthRaw } from './raw/DropdownSimple';
import FastSearchRaw from './raw/FastSearch';
//import InputRaw from './raw/Input';
import LinkedInputRaw from './raw/LinkedInput';
import TextareaRaw from './raw/Textarea';
import ZeitRaw from './raw/Zeit';

export const CheckBox = MetaLabelFocusHoc(CheckBoxRaw);
export const DropdownSimple = MetaLabelFocusHoc(DropdownSimpleRaw);
export const DropdownSimpleAuth = MetaLabelFocusHoc(DropdownSimpleAuthRaw);
export const FastSearch = MetaLabelFocusHoc(FastSearchRaw);
export const Input = MetaLabelFocusHoc(TextareaRaw);
export const LinkedInput = MetaLabelFocusHoc(LinkedInputRaw);
export const Textarea = MetaLabelFocusHoc(TextareaRaw);
export const Zeit = MetaLabelFocusHoc(ZeitRaw, { handledComponentIsZeit: true });
