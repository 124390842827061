import React from 'react';
//import CollapseArea from '../../container/CollapseArea';
import PublicViewHOC from '../../container/PublicViewHOC';
import H3 from '../../style/H3';
import Link from '../../style/Link';
import LinkExtern from '../../style/LinkExtern';
import Area from '../../style/Area';
import Container from '../../style/Container';
import OpenSVG from '../../SVG/Open';
import { Row } from '../../style/Row';
import Short from '../../container/Short';
import List from '../../container/ViewList';
//import RefsCount from '../../container/RefsCount';
//import RefsLinks from '../../container/RefsLinksView';
import { connect } from 'react-redux';
import Space from '../../style/Space';
import AllowedForUser from '../../container/AllowedForUser';
import { LabelLinkedInput as LinkedInput } from '../../labels/LabelWithNoFocus';
import { get } from '../../service/immutables';

const ArchivPublicUI = ({ item }) => (
    <>
        <Row>
            <H3>Institution:&nbsp;</H3>
			<Short />
			<AllowedForUser rolle="Gast">
	            <Space />
	            <Link to={`/edit/archiv/${item._id}`} target="_self">
	                <OpenSVG />
	            </Link>
			</AllowedForUser>
		</Row>
        <br />
		<Row>
			<Container>Name:</Container>
			<Area>
	            <>{get('name.v', item)}</>
	        </Area>
		</Row>
		{(get('katalog.v', item) && get('katalog.v', item).match('http')) && (
			<Row>
				<Container>Katalog:</Container>
				<Area>	
					<LinkExtern 
						href={get('katalog.v', item)} 
						target="_blank"
						width="100%"
					>
						{get('katalog.v', item)}
					</LinkExtern>
		        </Area>
			</Row>
		)}
		{get('findmittel.elektronische[0].string', item) && (
			<Row>
				<Container>Onlinefindmittel:</Container>
				<Area>
		            <List label="" name="findmittel.elektronische" column>
		                    {index => (
		                        <Row>
									{(get(`findmittel.elektronische[${index}].string.v`, item) && get(`findmittel.elektronische[${index}].string.v`, item).match('http')) && (
		                            <LinkedInput
										width="100%"
		                                label=""
		                                name={`findmittel.elektronische[${index}].string`}
		                            />
									)}
		                        </Row>
		                    )}
		                </List>
		        </Area>
			</Row>
		)}
		<br />
		{/* geht nicht, weil die Briefhandschriften nicht wissen, ob die Briefe öffentlich sind: 
        <RefsCount
            label="Handschriften aus dieser Institution: "
            api="briefhandschrift"
            idPath="_id"
            query="archiv.v="
            anschlussText="Briefe"
        />
        <CollapseArea show={false}>
            <RefsLinks api="briefhandschrift" idPath="_id" query="archiv.v=" column />
        </CollapseArea>
        <br />
		*/}
    </>
);

const mapStateToProps = (state) => {
    return {
        item: state.item,
    };
};

export default PublicViewHOC(connect(mapStateToProps)(ArchivPublicUI));
