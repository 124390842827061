import React from 'react';
import ScrollContainer from '../../style/ScrollContainer';
import Container from '../../style/Container';
import LogViewzB from '../log/LogViewzB';
import connect from 'react-redux/es/connect/connect';
import CollapseArea from '../../container/CollapseArea';

class zuletztBearbeitet extends React.Component {
    render() {
        return (
            <ScrollContainer>
                <Container>
					<br/>
                    <CollapseArea label={'Brief'} show={false}>
                        <LogViewzB kind="Brief" match={this.props.match} limit={this.props.limit} />
                    </CollapseArea>
                    <CollapseArea label={'Person'} show={false}>
                        <LogViewzB
                            kind="Person"
                            match={this.props.match}
                            limit={this.props.limit}
                        />
                    </CollapseArea>
                    <CollapseArea label={'Ort'} show={false}>
                        <LogViewzB kind="Ort" match={this.props.match} limit={this.props.limit} />
                    </CollapseArea>
                    <CollapseArea label={'Sache'} show={false}>
                        <LogViewzB kind="Sache" match={this.props.match} limit={this.props.limit} />
                    </CollapseArea>
                    <CollapseArea label={'Archiv'} show={false}>
                        <LogViewzB
                            kind="Archiv"
                            match={this.props.match}
                            limit={this.props.limit}
                        />
                    </CollapseArea>
                    <CollapseArea label={'Signatur'} show={false}>
                        <LogViewzB
                            kind="Signatur"
                            match={this.props.match}
                            limit={this.props.limit}
                        />
                    </CollapseArea>
                    <CollapseArea label={'Briefhandschrift'} show={false}>
                        <LogViewzB
                            kind="Briefhandschrift"
                            match={this.props.match}
                            limit={this.props.limit}
                        />
                    </CollapseArea>
                    <CollapseArea label={'Briefdruck'} show={false}>
                        <LogViewzB
                            kind="Briefdruck"
                            match={this.props.match}
                            limit={this.props.limit}
                        />
                    </CollapseArea>
                    <CollapseArea label={'Druck'} show={false}>
                        <LogViewzB kind="Druck" match={this.props.match} limit={this.props.limit} />
                    </CollapseArea>
                    <CollapseArea label={'Literatur'} show={false}>
                        <LogViewzB
                            kind="Literatur"
                            match={this.props.match}
                            limit={this.props.limit}
                        />
                    </CollapseArea>
                </Container>
            </ScrollContainer>
        );
    }
}
const mapStateToProps = state => {
    return {
        limit: state.auth.anzahlBearbeitet || 25,
    };
};

export default connect(mapStateToProps)(zuletztBearbeitet);
