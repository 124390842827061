import styled from 'styled-components';
import { white } from './colors';

const SideMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    border-left: 1px solid #f1f3f4;

    width: 3rem;
    background-color: ${white};

    > * {
        margin: 0.5rem 0;
    }
`;

export default SideMenu;
