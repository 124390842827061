import React from 'react';
import ScrollContainer from '../style/ScrollContainer';
import Container from '../style/Container';

export default class rightsNotSufficient extends React.Component {
    render() {
        return (
            <ScrollContainer>
                <Container>
                    <h1>Sie haben nicht ausreichende Rechte</h1>
                </Container>
            </ScrollContainer>
        );
    }
}
