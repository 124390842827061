import styled from 'styled-components';
import { label } from './colors';

export const Label = styled('div')`
    color: ${label};
    font-size: 0.8rem;
    margin-top: 0.5em;
	margin-left: -0.4em;
`;
export default Label;
