import React from 'react';
import NavBar from '../style/NavBar';
import NavLink from '../style/NavLink';
import { background } from '../style/colors';
import styled from 'styled-components';

const SubmenuStyle = styled('div')`
    background-color: ${background};
`;

export default () => (
	<SubmenuStyle>
	    <NavBar>
	        <NavLink to="/attributes/brief">Briefe</NavLink>
			<NavLink to="/attributes/briefhandschrift">Briefhandschriften</NavLink>
	        <NavLink to="/attributes/person">Personen</NavLink>
	        <NavLink to="/attributes/ort">Orte</NavLink>
	        <NavLink to="/attributes/sache">Sachen</NavLink>
	    </NavBar>
	</SubmenuStyle>
);
