import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    initAttributesSearch,
    startAllAttributesSearch,
    resetSearch,
} from '../../store/actions/search';
import { loadTypeList } from '../../service/fetch';
import Row from '../../style/Row';
import Button from '../../style/Button';
import Container from '../../style/Container';
import ItemContainer from '../../style/ItemContainer';
import { changeItem, resetItem } from '../../store/actions/item';
import Cell from '../../style/SearchCell';
import { OpSearchRow as TableRow } from '../../style/SearchRow';
import OpenSVG from '../../SVG/Open';
import { defaultTextColor } from '../../style/colors';
import Link from '../../style/Link';
import AttributesElement from '../../components/AttributesElement';
import SearchItems from './adv/items/SearchItems';
import Loading from '../../components/Loading';
import SearchFooter from '../../components/SearchFooter';
import { SearchBody, SearchContainer } from '../../style/Search';

class InternAttributesSearch extends Component {
    constructor(props) {
        super(props);
        let type = props.match.url.split('/')[2];
        this.state = {
            type,
            loadingItems: false,
            showItems: false,
        };
        // get the items which can be used as proposals in fast search / dropdown
        loadTypeList(type).then(props.initAttributesSearch);
    }

    async startSearch() {
        this.setState({ showItems: false });
        await this.props.startAllAttributesSearch();
        this.setState({ showItems: true });
    }

    onKeyPressed = ({ keyCode }) => {
        if (keyCode === 13) {
            return this.startSearch();
        }
    };

    componentWillUnmount() {
        this.props.resetItem();
        this.props.resetSearch();
    }

    backToSearch = () => {
        this.props.resetSearch();
        this.setState({ showItems: false })
    };

    render() {
        const { loadingItems, items, count, availableTypes } = this.props;
        const { showItems, type } = this.state;
        let loadingTypeList = !!availableTypes ? 0 : 1;
        let searching = !loadingItems && !showItems;
        return (
            <>
                <SearchContainer onKeyDown={this.onKeyPressed} id="SearchContainer">
                    <SearchBody id="SearchBody">
                            <Container id="Container">
                                {showItems && (
                                    <Row>
                                        <Container>{count + ' Treffer'}</Container>
                                        <Button onClick={this.backToSearch}>
                                            Neue Suche
                                        </Button>
                                    </Row>
                                )}
                                <Row id="OuterRow">
                                    <Loading loading={loadingTypeList} id="LoadingComponent">
                                        {searching ? (
                                            <Row id="SearchField">
                                                <AttributesElement availableTypes={availableTypes} />
												<ItemContainer id="ButtonContainer">
	                                                <Button onClick={() => this.startSearch()}>
	                                                    Suchen
	                                                </Button>
												</ItemContainer>
												{(type === 'brief' || type === 'person' || type === 'briefhandschrift') && (
												<Link to={`/operator/${type}`} target="_self" color={defaultTextColor}>
													<Button>
	                                                    Erweiterte Suche
	                                                </Button>
												</Link>
												)}
                                            </Row>
                                        ) : (
                                            <SearchItems items={items} loading={loadingItems}>
                                                {(item, i) => (
                                                    <TableRow key={i}>
                                                        <Link
                                                            to={`/edit/${type}/${item._id}`}
                                                            target={false}
                                                        >
                                                            <OpenSVG />
                                                        </Link>
                                                        <Cell>{type === 'brief' ? item.long.v : (type === 'person' ? item.short.v : item.short.v)}</Cell>
                                                    </TableRow>
                                                )}
                                            </SearchItems>
                                        )}
                                    </Loading>
                                </Row>
                            </Container>
                    </SearchBody>
                </SearchContainer>
				{showItems && (
                	<SearchFooter startSearch={startAllAttributesSearch} />
				)}
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    availableTypes: state.search.availableTypes,
    items: state.search.items,
    count: state.search.count,
    type: props.match.params.type,
    loadingItems: state.search.loading,
});

const mapDispatchToProps = {
    changeItem,
    resetItem,
    resetSearch,
    startAllAttributesSearch,
    initAttributesSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(InternAttributesSearch);
