import { css } from 'styled-components';

export const lightGrey = '#a7a7a7';

export const ultraLightGrey = '#e6e6e6';

export const button = '#f6f5f3';

export const defaultTextColor = '#555555';

export const lightBlue = '#4285f4'; //'#5cb9ff';

export const selectionGreen = '#009a00';

export const blue = '#0063AF'; // Design DD/intern: '#3E7BA7'

export const bodyGrey = '#eceae8'; //Hintergrundbraun der Akademie Webseite, für Header verwendet

export const bodyGrey2 = '#F6F5F3'; // intern und in Viewkomponenten verwendet ab dem 1.7.2020

export const bodyGrey3 = '#F7F7F7'; // entspricht grau der Malalasseite aber dort nicht als body

export const linkBlue = '#0063AF'; // Design DD/intern: '#365899'

export const purple = '#690095'; //link purple from google

export const black = '#484848';

export const lineGrey = '#ebebeb';

export const grey = '#767676';

export const label = '#757575';

export const white = '#fff';

export const red = '#ff0a00';

export const background2 = '#e8e8e8';

export const background = '#FFFFFF';

const color_GET = '#20c020';

const color_POST = '#1e8484';

const color_DELETE = '#c02020';

const color_PUT = '#949035';

export const yellow = '#f7e234';

export const RESTColorMap = new Map();
RESTColorMap.set('GET', color_GET);
RESTColorMap.set('POST', color_POST);
RESTColorMap.set('DELETE', color_DELETE);
RESTColorMap.set('PUT', color_PUT);

export const boxShadow = css`
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    /*    &:hover {
        box-shadow: 0 1px 6px rgba(49, 126, 172, 0.28); // blue to rgb
    }*/
`;

export const border = css`
    border: 1px solid rgba(32, 33, 36, 0.28);
`;
