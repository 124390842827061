import styled from 'styled-components';
import { lightGrey } from './colors';
import TextareaAutosize from 'react-textarea-autosize';

export default styled(TextareaAutosize)`
    font-family: inherit;
    display: flex;
    
    overflow: auto // needed for ISSUE 474
    
    width: 100%;
    resize: none;
    //justify-content: center;
    align-items: center;
    margin: 5px 0 0;
    overflow: hidden !important
    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${lightGrey};
        opacity: 1; /* Firefox */
    }
`;
