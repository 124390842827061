import PropTypes from 'prop-types';
import React from 'react';
import Row from '../../style/Row';
import TextAreaStyle from '../../style/Textarea';
import { get } from '../../service/immutables';
import { changeItem } from '../../store/actions/item';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Warning = styled('div')`
    //font-weight: bold;
    color: red;
`;

// Textarea is build with https://react-components.buildo.io/#textareaautosize
class Textarea extends React.Component {
    render() {
        const {
            handleChange,
            value,
            placeholder,
            erschlossen,
            unsicher,
            fragwuerdig,
            ca,
            disabled,
            helperForUserInput,
            focus,
        } = this.props;
        return (
            <Row nowrap>
                {erschlossen && '['}
                <TextAreaStyle
                    onChange={handleChange}
					//sets helperForUserInput in Input if it is focus and empty or adds last missing char for helperForUserInput (z.B. "Nr." -> "Nr. ")
                    value={focus === true ? (value === '' ? helperForUserInput : value) : value}
					placeholder={placeholder}
					//(helperForUserInput && value === helperForUserInput.slice(0,helperForUserInput.length-1))
                    // rows={1}     // setting the min height - default: 1
                    maxRows={50} // setting max height
                    disabled={disabled}
                />
                {focus !== true && value && (value.substr(-1) === ' ' || value.substr(0,1) === ' ') && <Warning>⚠</Warning>}
                {ca && <>&nbsp;ca.</>}
                {unsicher && '?'}
                {erschlossen && ']'}
                {fragwuerdig && <>&nbsp;[?]</>}
            </Row>
        );
    }
}

Textarea.defaultProps = {};

Textarea.propTypes = {
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.any, //.isRequired
};

const mapStateToProps = (state, props) => ({
    value: get(props.name + '.v', state.item) || '',
    erschlossen: get(props.name + '.m.erschlossen', state.item),
    unsicher: get(props.name + '.m.unsicher', state.item),
    ca: get(props.name + '.m.ca', state.item),
    fragwuerdig: get(props.name + '.m.fragwuerdig', state.item),
});

const mapDispatchToProps = (dispatch, props) => ({
    handleChange: e => dispatch(changeItem(props.name + '.v', e.target.value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Textarea);
