import React, { Component } from 'react';
import { get as getLodash } from 'lodash';
import { getItem } from '../../service/fetch';

class PermaLinkPerson extends Component {
    state = {
        text: 'Einen Moment bitte',
    };

    async componentWillMount() {
        const { id } = this.props.match.params;
        const person = await getItem('Person', null, 'gnd.v=' + id);
        if (getLodash(person, '0.gnd.v').replace('http://d-nb.info/gnd/', '').replace('https://d-nb.info/gnd/', '') === id) {
            this.props.history.push(`/ansicht/person/${getLodash(person, '0._id')}`);
        } else {
            this.setState({
                text: `Leider nicht gefunden!`,
            });
        }
    }

    render() {
        return <div>{this.state.text}</div>;
    }
}

export default PermaLinkPerson;
