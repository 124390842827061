import React from 'react';
import Icon from '../style/IconButton';
import { grey, lightBlue } from '../style/colors';
import { CHANGE_META_FOCUS, CHANGE_ZEIT_META_FOCUS } from '../store/constants/ActionTypes';

export default ({ active, dispatch, name, handledComponentIsZeit }) => (
    <Icon
        onClick={() =>
            dispatch({
                type: handledComponentIsZeit ? CHANGE_ZEIT_META_FOCUS : CHANGE_META_FOCUS,
                name,
            })
        }
    >
        <svg
            height="12px"
            viewBox="0 0 24 24"
            width="12px"
            fill="black"
            strokeWidth="1"
            stroke={active ? lightBlue : grey}
        >
            <g>
                <g>
                    <path d="M19.6,23.5H4.2c-2.1,0-3.8-1.7-3.8-3.8V4.3c0-2.1,1.7-3.8,3.8-3.8h15.3c2.1,0,3.8,1.7,3.8,3.8v15.3    C23.4,21.8,21.7,23.5,19.6,23.5z M4.2,1.5c-1.6,0-2.8,1.3-2.8,2.8v15.3c0,1.6,1.3,2.8,2.8,2.8h15.3c1.6,0,2.8-1.3,2.8-2.8V4.3    c0-1.6-1.3-2.8-2.8-2.8H4.2z" />
                </g>
                <g>
                    <g>
                        <g>
                            <path d="M7.8,17.9c-0.3,0-0.5-0.2-0.5-0.5V6.6c0-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4,0,0.6,0.2l4.1,6.9c0.1,0.2,0.1,0.5-0.2,0.7      c-0.2,0.1-0.5,0.1-0.7-0.2L8.3,8.4v8.9C8.3,17.7,8,17.9,7.8,17.9z" />
                        </g>
                        <g>
                            <path d="M9.3,17.9h-3c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h3c0.3,0,0.5,0.2,0.5,0.5S9.6,17.9,9.3,17.9z" />
                        </g>
                        <g>
                            <path d="M7.8,7.1H6.3C6,7.1,5.8,6.9,5.8,6.6S6,6.1,6.3,6.1h1.5c0.3,0,0.5,0.2,0.5,0.5S8,7.1,7.8,7.1z" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M16,17.9c-0.3,0-0.5-0.2-0.5-0.5V8.4l-3.2,5.3c-0.1,0.2-0.4,0.3-0.7,0.2c-0.2-0.1-0.3-0.4-0.2-0.7l4.1-6.9      c0.1-0.2,0.3-0.3,0.6-0.2c0.2,0.1,0.4,0.3,0.4,0.5v10.8C16.5,17.7,16.3,17.9,16,17.9z" />
                        </g>
                        <g>
                            <path d="M17.5,17.9h-3c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h3c0.3,0,0.5,0.2,0.5,0.5S17.8,17.9,17.5,17.9z" />
                        </g>
                        <g>
                            <path d="M17.5,7.1H16c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h1.5c0.3,0,0.5,0.2,0.5,0.5S17.8,7.1,17.5,7.1z" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </Icon>
);
