import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckBox } from '../inputs/InputsWithLabelFocus';
import { connect } from 'react-redux';
import Area from '../style/Area';
import Row from '../style/Row';
import CheckboxLabel from '../style/CheckboxLabel';

class AllowedForUser extends Component {
    allowed = rolle => {
        const order = ['Gast', 'Hiwi', 'Mitarbeiter', 'Admin'];
        const userRolle = localStorage.getItem('rolle');
        return order.indexOf(userRolle) >= order.indexOf(rolle);
    };
    render() {
        const { children, rolle } = this.props;
        return <>{this.allowed(rolle) ? children : <div />}</>;
    }
}

AllowedForUser.propTypes = {
    rolle: PropTypes.string.isRequired,
};

class TokenCheckBox extends CheckBox {
    render() {
        const { hasToken } = this.props;
        return (
            <AllowedForUser rolle={'Gast'}>
                {hasToken ? (
					<Area>
						<Row nowrap>
							<CheckBox label='' name={'sendToken'} width='30px' noUnderline/>
							<CheckboxLabel>inklusive unveröffentlichte</CheckboxLabel>
						</Row>
					</Area>
                ) : null}
            </AllowedForUser>
        );
    }
}

const mapStateToProps = state => {
    return {
        hasToken: !!state.auth.token,
    };
};

export default connect(mapStateToProps)(TokenCheckBox);
