import { ADD_NOTIFICATION } from '../constants/ActionTypes';

export function addNotification(title, message, level) {
    // TODO refactor usage to 3 args every time
    // old ussage with 2 arguments (message, level)
    if (!level)
        return {
            type: ADD_NOTIFICATION,
            message: title,
            level: message,
        };
    else
        return {
            type: ADD_NOTIFICATION,
            title,
            message,
            level,
        };
}
