import React from 'react';
import Area from '../../style/Area';
import PublicViewHOC from '../../container/PublicViewHOC';
//import List from '../../container/ViewList';
//import H3 from '../../style/H3';
import H4 from '../../style/H3';
import Link from '../../style/Link';
import OpenSVG from '../../SVG/Open';
import { Row } from '../../style/Row';
import Title from '../../style/Title';
import { buildSache } from '../../store/actions/item';
//import Switch from '../../components/SwitchView';
import { connect } from 'react-redux';
import Space from '../../style/Space';
import styled from 'styled-components';
//import { lightGrey } from '../../style/colors';
import { get } from '../../service/immutables';
//import RefsCount from '../../container/RefsCount';
import RefsLinks from '../../container/RefsLinksView';
import { LabelFastSearch as Search } from '../../labels/LabelWithNoFocus';
import AllowedForUser from '../../container/AllowedForUser';

/*
const Ellipsis = styled(Row)`
    font-size: 1.5em !important;
    font-weight: bold;
    letter-spacing: -0.2px !important;
    padding: 0 !important;
    margin-bottom: 10px !important;
    color: ${lightGrey} !important;
`;
*/

const Indentation = styled('div')`
	margin-left: 15px !important;
`;

const VerticalContainer = styled('div')`
    vertical-align: top;
`;

const SachePublicUI = ({
    item,
    disableZusammenSetzung,
}) => (
    <>
		<Row>
			{/* <H3>Sachregister:</H3> */}
			{/* <Search api="Sachgruppe" label="Typ:" name="typ" small /> */}
			{/* TODO: Anstelle der Überschrift "Sachregister"
				{(get('typ.v.short.v', item) === 'Bibelstelle') && <H3>{get('typ.v.short.v', item)}:&nbsp;</H3>}
				{(!get('typ.v.short.v', item) || get('typ.v.short.v', item) === 'Theol. Locus') && <H3>Sache:&nbsp;</H3>}
			*/}
		</Row>
		<Row>
			<Area width="300px">
				<H4>
					{get('uebergeordnet[0].v.short.v', item) === undefined && get('short.v', item) !== 'Sachregister' && (
						<Row>
							<Link to={`/ansicht/sache/640859dd459afb2f0481f2a3`} target="_self">
								▲&nbsp;Sachregister
							</Link>
						</Row>	
					)}
					{get('short.v', item) === 'Sachregister' && (
						<>&nbsp;&nbsp;Sachregister</>	
					)}
					{/*
						<Link to={`/ansicht/sache/${get('uebergeordnet[0].v._id', item)}`} target="_self">
							<Ellipsis>...</Ellipsis>
						</Link>
					*/}
					{get('uebergeordnet[0].v._id', item) && (
						<Row>
							<Link to={`/ansicht/sache/${get('uebergeordnet[0].v._id', item)}`} target="_self">
								▲&nbsp;{get('uebergeordnet[0].v.short.v', item)}
							</Link>
						</Row>	
						)}
					{get('uebergeordnet[1].v._id', item) && (
						<Row>
							<Link to={`/ansicht/sache/${get('uebergeordnet[1].v._id', item)}`} target="_self">
								▲&nbsp;{get('uebergeordnet[1].v.short.v', item)}
							</Link>
						</Row>	
						)}
					{get('uebergeordnet[2].v._id', item) && (
						<Row>
							<Link to={`/ansicht/sache/${get('uebergeordnet[2].v._id', item)}`} target="_self">
								▲&nbsp;{get('uebergeordnet[2].v.short.v', item)}
							</Link>
						</Row>	
						)}
					{get('uebergeordnet[3].v._id', item) && (
						<Row>
							<Link to={`/ansicht/sache/${get('uebergeordnet[3].v._id', item)}`} target="_self">
								▲&nbsp;{get('uebergeordnet[3].v.short.v', item)}
							</Link>
						</Row>	
						)}
					{get('uebergeordnet[4].v._id', item) && (
						<Row>
							<Link to={`/ansicht/sache/${get('uebergeordnet[4].v._id', item)}`} target="_self">
								▲&nbsp;{get('uebergeordnet[4].v.short.v', item)}
							</Link>
						</Row>	
						)}
					{get('uebergeordnet[5].v._id', item) && (
						<Row>
							<Link to={`/ansicht/sache/${get('uebergeordnet[5].v._id', item)}`} target="_self">
								▲&nbsp;{get('uebergeordnet[5].v.short.v', item)}
							</Link>
						</Row>	
						)}
					{get('uebergeordnet[6].v._id', item) && (
						<Row>
							<Link to={`/ansicht/sache/${get('uebergeordnet[6].v._id', item)}`} target="_self">
								▲&nbsp;{get('uebergeordnet[6].v.short.v', item)}
							</Link>
						</Row>	
						)}
					{get('uebergeordnet[7].v._id', item) && (
						<Row>
							<Link to={`/ansicht/sache/${get('uebergeordnet[7].v._id', item)}`} target="_self">
								▲&nbsp;{get('uebergeordnet[7].v.short.v', item)}
							</Link>
						</Row>	
						)}
					{get('uebergeordnet[8].v._id', item) && (
						<Row>
							<Link to={`/ansicht/sache/${get('uebergeordnet[8].v._id', item)}`} target="_self">
								▲&nbsp;{get('uebergeordnet[8].v.short.v', item)}
							</Link>
						</Row>	
						)}
				</H4>
			</Area>
		</Row>
		<Row>	
			<VerticalContainer>	
				<Area width="300px">
					{get('short.v', item) !== 'Sachregister' && (
						<Row>
							<H4><>&nbsp;–&nbsp;</>{get('short.v', item)}</H4>
							<AllowedForUser rolle="Gast">
								<Space/>
								<Link to={`/edit/sache/${item._id}`} target="_self">
						        	<OpenSVG />
						    	</Link>
							</AllowedForUser>
						</Row>
					)}
					<Indentation>
						<RefsLinks api="sache" idPath="_id" query="uebergeordnet.v=" column arrowDown /> {/* untergeordnete Sachen */}
					</Indentation>
				</Area>
				{/*
					{get('uebergeordnet[0].v', item) && (
						<Area width="300px">
							<Link to={`/ansicht/sache/${get('uebergeordnet[0].v._id', item)}`} target="_self">
								<Ellipsis>...</Ellipsis>
							</Link>
						</Area>
					)}
				*/}
			</VerticalContainer>			
			<Area width="546px">
				<RefsLinks api="brief" idPath="_id" query="schlagworte.sachen.v=" column long noResultMessage /> {/* Briefe */}
				{/* Zählung wohl nicht benötigt
	    		<RefsCount label="in " api="brief" idPath="_id" query="schlagworte.sachen.v=" anschlussText="Briefen" />
		 		*/}
			</Area>
		</Row>
		<Row>
			{get('alternativen[0].sache.v.short.v', item) && (
				<Area width="300px">
					{/* TODO: mit map iterieren */}
					<>siehe auch:</><br />
					{get('alternativen[0].sache.v.short.v', item) && <Link to={`/ansicht/sache/${get('alternativen[0].sache.v._id', item)}`}>{get('alternativen[0].sache.v.short.v', item)}<br /></Link>}
					{get('alternativen[1].sache.v.short.v', item) && <Link to={`/ansicht/sache/${get('alternativen[1].sache.v._id', item)}`}>{get('alternativen[1].sache.v.short.v', item)}<br /></Link>}
					{get('alternativen[2].sache.v.short.v', item) && <Link to={`/ansicht/sache/${get('alternativen[2].sache.v._id', item)}`}>{get('alternativen[2].sache.v.short.v', item)}<br /></Link>}
					{get('alternativen[3].sache.v.short.v', item) && <Link to={`/ansicht/sache/${get('alternativen[3].sache.v._id', item)}`}>{get('alternativen[3].sache.v.short.v', item)}<br /></Link>}
					{get('alternativen[4].sache.v.short.v', item) && <Link to={`/ansicht/sache/${get('alternativen[4].sache.v._id', item)}`}>{get('alternativen[4].sache.v.short.v', item)}<br /></Link>}
					{get('alternativen[5].sache.v.short.v', item) && <Link to={`/ansicht/sache/${get('alternativen[5].sache.v._id', item)}`}>{get('alternativen[5].sache.v.short.v', item)}<br /></Link>}
					{get('alternativen[6].sache.v.short.v', item) && <Link to={`/ansicht/sache/${get('alternativen[6].sache.v._id', item)}`}>{get('alternativen[6].sache.v.short.v', item)}<br /></Link>}
					{get('alternativen[7].sache.v.short.v', item) && <Link to={`/ansicht/sache/${get('alternativen[7].sache.v._id', item)}`}>{get('alternativen[7].sache.v.short.v', item)}<br /></Link>}
					{get('alternativen[8].sache.v.short.v', item) && <Link to={`/ansicht/sache/${get('alternativen[8].sache.v._id', item)}`}>{get('alternativen[8].sache.v.short.v', item)}<br /></Link>}
					{get('alternativen[9].sache.v.short.v', item) && <Link to={`/ansicht/sache/${get('alternativen[9].sache.v._id', item)}`}>{get('alternativen[9].sache.v.short.v', item)}<br /></Link>}
				</Area>
			)}
		</Row>
		{/* Per Suche finden, aber hier nicht anzeigen, denn das sind suboptimale Bezeichnungen: 
        <List label="Alternativbezeichnungen" name="alternativen">
            {index => (
				<>
                    <Textarea label="" name={`alternativen[${index}].text`} />
				</>
            )}
        </List>
		 */}
		<br />
        {disableZusammenSetzung && (
            <>
                <Title>Verknüpfungen</Title>
                <Row>
                    <Search label="" api="Zitiername" name="zusammensetzung.person" />
                    <br />
                    <Search label="" api="Ort" name="zusammensetzung.ort" />
                    <br />
                    <Search label="" api="Druck" name="zusammensetzung.werk.druck" />
                </Row>
                {/*<Row>
                    <List label="Zeit" name="zusammensetzung.zeit" simple>
                        {index => <Zeit label="" name={`zusammensetzung.zeit[${index}]`} />}
                    </List>
                    </Row>*/}
            </>
        )}
		<br/>
		<>Hinweis: Die Zusammenordnung der Sachschlagworte zu einem Sachregister ist 'work in progress' und keiner abschließenden Revision unterzogen. Die Verweise auf mögliche Überbegriffe und untergeordnete Sachen erheben zum jetzigen Zeitpunkt keinen Anspruch auf Vollständigkeit.</>
		<br/>
		<br/>
             <>https://thbw.hadw-bw.de/sache/{get('short.v', item).replaceAll(' ','%20')}</>
		<br/><br/>
    </>
);
const mapStateToProps = (state, props) => {
    let disableZusammenSetzung = true;
    if (
        get('zusammensetzung.person.v', state.item) === null &&
        get('zusammensetzung.werk.druck.v', state.item) === null &&
        get('zusammensetzung.ort.v', state.item) === null
    )
        disableZusammenSetzung = false;
    return {
        disableZusammenSetzung: disableZusammenSetzung,
        item: state.item,
    };
};

const mapDispatchToProps = {
    buildShort: buildSache,
};

export default PublicViewHOC(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SachePublicUI)
);
