import React, { Component } from 'react';
import OpenSeadragon from 'openseadragon';
import { iiif_api as api } from '../../../config';
import Space from '../../style/Space'
import styled from 'styled-components';

// import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearchPlus,
    faSearchMinus,
    faExpand,
    faArrowCircleRight,
    faArrowCircleLeft,
    faRedo,
    faUndo,
} from '@fortawesome/free-solid-svg-icons';
import './IIIFViewer.css'; //import styles

const RowNoWrap = styled('div')`
    display: flex;    
    flex-flow: nowrap;
    ${({ center }) => center && 'justify-content: center;'}
    ${({ right }) => right && 'justify-content: flex-end;'}
    ${({ between }) => between && 'justify-content: space-between;'}
    align-items: ${({ hcenter }) => (hcenter ? 'center' : 'stretch')};
`;

const Block = styled(RowNoWrap)`
    align-items: center;
	display: flex;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;
	max-width: 850px;
`;

const RightSubBlock = styled('div')`
    align-items: center;
    margin-right: 0px;
`;

const LeftSubBlock = styled('div')`
    align-items: center;
`;

// Umlaute parsen, damit openseadragon die URL in UTF-8 und nicht ISO-8859 parsed.
const preParseFilename = filename => {
	if (filename !== null) {
	    return filename
	        .replace('/', '!')
	        .replace('ü', '%C3%BC')
	        .replace('ö', '%C3%B6')
	        .replace('ä', '%C3%A4');
	} else {
		return filename;
	}
};

const getInfoJson = (urls, token) => {
    return Promise.all(
        urls.map(url =>
            fetch(url, { method: 'GET', headers: { 'x-access-token': token } }).then(res =>
                res.json()
            )
        )
    );
};

class Seadragon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoObjects: [],
        };
    }

    componentDidMount() {
        const { briefhandschrift_id, filepaths, userToken } = this.props;
        if (filepaths) {
            const urls = filepaths.map(path => {
                const filename = preParseFilename(path.v);
                return `${api}/api/v1/iiif/${briefhandschrift_id}/${filename}/info.json`;
            });
            getInfoJson(urls, userToken).then(data => {
                const infoJsons = data.map((obj, index) => {
                    const filename = preParseFilename(filepaths[index].v);
                    return {
                        ...obj,
                        '@id': `${api}/api/v1/iiif/${briefhandschrift_id}/${filename}`,
                    };
                });
                this.setState({ infoObjects: infoJsons });
                this.viewer = OpenSeadragon({
                    id: 'seadragon',
                    zoomInButton: 'zoom-in',
                    zoomOutButton: 'zoom-out',
					zoomPerClick: '1.6',
                    homeButton: 'home',
                    fullPageButton: 'full-page',                    
                    previousButton: 'previous',
					nextButton: 'next',
					//showNavigator: true,
					//navigatorPosition: 'TOP_RIGHT',
                    rotateLeftButton: 'rotate-left',
                    rotateRightButton: 'rotate-right',
                    showRotationControl: true,
                    tileSources: this.state.infoObjects,
                    sequenceMode: true,
                    showReferenceStrip: true,
                    referenceStripScroll: 'vertical',
                    referenceStripSizeRatio: 0.1,
                    ajaxWithCredentials: true,
                    loadTilesWithAjax: true,
                    ajaxHeaders: {
                        'x-access-token': userToken,
                    },
                });
            });
        }
    }

    render() {
        return (
			<>
		            <Block>
						<LeftSubBlock>
							<FontAwesomeIcon id="previous" className="toolbarIcon" icon={faArrowCircleLeft} />
							<FontAwesomeIcon id="next" className="toolbarIcon" icon={faArrowCircleRight} />
						</LeftSubBlock>
						<RightSubBlock>
							<FontAwesomeIcon id="rotate-left" className="toolbarIcon" icon={faUndo} />
			                <FontAwesomeIcon id="rotate-right" className="toolbarIcon" icon={faRedo} />
							<Space/>
				            <FontAwesomeIcon id="full-page" className="toolbarIcon" icon={faExpand} />
							<Space/>
			                <FontAwesomeIcon id="zoom-out" className="toolbarIcon" icon={faSearchMinus} />
							<FontAwesomeIcon id="zoom-in" className="toolbarIcon" icon={faSearchPlus} />
						</RightSubBlock>
					</Block>
                <div id="seadragon" />
            </>
        );
    }
}

export default Seadragon;
