import styled from 'styled-components';

export default styled('div')`
    display: flex;
	justify-content: center;
    flex: none;
    align-items: center;
    flex-wrap: wrap;

    padding-left: 1em;

    border-bottom: 1px solid rgba(32, 33, 36, 0.28);
`;
