import React from 'react';
import ScrollContainer from '../style/ScrollContainer';
import Container from '../style/Container';
import styled from 'styled-components';
import { defaultTextColor } from '../style/colors';
import Button from '../style/Button';
import Link from '../style/Link';
//import LinkExtern from '../style/LinkExtern';
import Row from '../style/Row';
import AllowedForUser from '../container/AllowedForUser';

const BigFontSize = styled('div')`
    font-size: 1.5em !important;
    letter-spacing: -0.2px !important;
    padding: 0 !important;
`;

class Start extends React.Component {
    render() {
        return (
			<AllowedForUser rolle="Mitarbeiter">
                <ScrollContainer>
					<br/>
					<Row center>
						<Container>
	                        <Link to={'/edit/brief'} target="_self" color={defaultTextColor}>
								<Button>
									<BigFontSize>
	                                Brief
									</BigFontSize>
									anlegen
	                            </Button>
							</Link>
						</Container>
						<Container>
	                        <Link to={'/edit/person'} target="_self" color={defaultTextColor}>
								<Button>
									<BigFontSize>
	                                Person
									</BigFontSize>
									anlegen
	                            </Button>
							</Link>
						</Container>
						<Container>
	                        <Link to={'/edit/ort'} target="_self" color={defaultTextColor}>
								<Button>
									<BigFontSize>
	                                Ort
									</BigFontSize>
									anlegen
	                            </Button>
								</Link>
						</Container>
						<Container>
	                        <Link to={'/edit/sache'} target="_self" color={defaultTextColor}>
								<Button>
									<BigFontSize>
	                                Sache
									</BigFontSize>
									anlegen
	                            </Button>
							</Link>
						</Container>
						<Container>
								<Link to={'/edit/druck'} target="_self" color={defaultTextColor}>
									<Button>
										<BigFontSize>
		                                Druck
										</BigFontSize>
										anlegen
	            	                </Button>
								</Link>
						</Container>
						<Container>
								<Link to={'/edit/literatur'} target="_self" color={defaultTextColor}>
									<Button>
										<BigFontSize>
	                            	    Literatur
										</BigFontSize>
										anlegen
	                	            </Button>
								</Link>
						</Container>
					</Row>
				</ScrollContainer>
			</AllowedForUser>
        );
    }
}
export default Start;
