import styled from 'styled-components';

export const IconSmall = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 14px;
    height: 14px;
    margin: 2px;
    border: 0 solid #387ef5;
    border-radius: 50%;

    &:hover {
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.3);
    }
`;
