/**
 * @project:      ThBw
 * @file:         client/src/store/reducers/auth.js
 * @summary:      
 * @desc:         
 * @created:      2018-11-07 11:13:25 by julian libor
 * @lastmodified: 2019-09-23 15:45:44 by ekzyis
 */
import { AUTH_LOGIN, AUTH_LOGOUT, CHANGE_AUTH } from '../constants/auth';
import { deleteLocalStorage, loadLocalStorage, saveLocalStorage } from '../../service/storage';
import { set } from '../../service/immutables';

const store = loadLocalStorage('auth') || {};

const initState = {
    name: '',
    password: '',
    token: store.token || '',
    exp: store.exp || 0, //
    iat: store.iat || 0, // (issued at) claim identifies the time at which the JWT was issued.
    user: store.user || {
        name: '',
        rolle: '',
        short: '',
        id: '',
    },
    anzahlSuche: store.anzahlSuche || 25,
    anzahlBearbeitet: store.anzahlBearbeitet || 25,
};

// https://github.com/heidelberger-akademie-d-wissenschaften/ThBw/issues/664
const changeAuthInLocalStorage = (key, value) => {
    const localAuth = loadLocalStorage('auth') || {};
    localAuth[key] = value;
    saveLocalStorage('auth', localAuth);
};

export default function auth(state = initState, action) {
    switch (action.type) {
        case CHANGE_AUTH:
            changeAuthInLocalStorage(action.name, action.value);
            return set(action.name, action.value, state);
        case AUTH_LOGIN:
            let anzahlSuche = action.decoded.anzahlSuche
                ? action.decoded.anzahlSuche.v
                : initState.anzahlSuche;
            let anzahlBearbeitet = action.decoded.anzahlBearbeitet
                ? action.decoded.anzahlBearbeitet.v
                : initState.anzahlBearbeitet;
            const newState = {
                ...state,
                token: action.token,
                exp: action.decoded.exp,
                iat: action.decoded.iat,
                user: {
                    name: action.decoded.name.v,
                    short: action.decoded.short.v,
                    role: action.decoded.role.v,
                    id: action.decoded._id,
                },
                anzahlSuche,
                anzahlBearbeitet,
            };
            saveLocalStorage('auth', newState);
            return newState;
        case AUTH_LOGOUT:
            deleteLocalStorage('auth');
            return {
                ...state,
                token: '',
                exp: 0,
                iat: 0,
                user: {
                    name: '',
                    rolle: '',
                    short: '',
                    id: '',
                },
            };
        default:
            return state;
    }
}
