import PropTypes from 'prop-types';
import React, { Component } from 'react';

class AllowedForUser extends Component {

    allowed = rolle => {
        const order = ['Gast', 'Hiwi', 'Mitarbeiter', 'Admin'];
        const userRolle = localStorage.getItem('rolle');
        return order.indexOf(userRolle) >= order.indexOf(rolle);
    };

    render() {
        const { children, rolle } = this.props;
        return <>{this.allowed(rolle) ? children : <div />}</>;
    }
}

AllowedForUser.propTypes = {
    rolle: PropTypes.string.isRequired,
};

export default AllowedForUser;