import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            localStorage.getItem('token') !== null && localStorage.getItem('rolle') === 'Admin' ? (
                <Component {...props} />
            ) : (
                <Redirect to="/rightsNotSufficient" />
            )
        }
    />
);
export default AdminRoute;
