import React from 'react';
import { lightGrey } from '../style/colors';
import { IconSmall } from '../style/IconSmall';

export default ({ onClick }) => (
    <IconSmall onClick={onClick}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 24 24"
            fill="none"
            stroke={lightGrey}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
    </IconSmall>
);
