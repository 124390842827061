import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

//import Input from '../inputs/raw/Input';
import { Input } from '../inputs/InputsWithLabelFocus';
import Area from '../style/Area';
import Row from '../style/Row';
//import Label from '../style/Label';
import Element from '../style/Element';
//import Underline from '../style/Underline';

const Space = styled.div`
    width: 100%;
    height: 3px;
`;

class ZeitraumInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focus: false,
        };
    }

    handleFocus = () => this.setState(({ focus }) => (focus ? null : { focus: true }));
    handleBlur = () => this.setState({ focus: false });

    render() {
        //const { focus } = this.state;
        const { name } = this.props;
        return (
			<Area>
	            <Element tabIndex={-1} onFocus={this.handleFocus} onBlur={this.handleBlur}>
	                {/*<Label focus={focus}>{'Zeitraum'}</Label>*/}
	                <Space />
	                <Row nowrap hcenter>
	                	{/*
	                    <Input
	                        name={name + '.von.t'}
	                        placeholder="T"
	                        maxLength={2}
	                        width="1.5em"
	                        textRight
	                    />
	                    .
	                    <Input
	                        name={name + '.von.m'}
	                        placeholder="M"
	                        maxLength={2}
	                        width="1.5em"
	                        textRight
	                    />
	                    .
	                    */}
	                    <Input
	                        name={name + '.von.j'}
	                        placeholder={this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? "Year" : "Jahr"}
	                        maxLength={4}
	                        width="2.3em"
	                    />
	                    <span style={{ margin: '0 0px' }}> － </span>
	                    {/*
	                    <Input
	                        name={name + '.bis.t'}
	                        placeholder="T"
	                        maxLength={2}
	                        width="1.5em"
	                        textRight
	                    />
	                    .
	                    <Input
	                        name={name + '.bis.m'}
	                        placeholder="M"
	                        maxLength={2}
	                        width="1.5em"
	                        textRight
	                    />
	                    .
	                    */}
	                    <Input
	                        name={name + '.bis.j'}
	                        placeholder={this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? "Year" : "Jahr"}
	                        maxLength={4}
	                        width="2.3em"
	                    />
	                </Row>
	                {/*<Underline focus={focus} />*/}
	            </Element>
			</Area>
        );
    }
}

const mapStateToProps = state => ({
    path: state.router.location.pathname,
});

export default connect(mapStateToProps)(ZeitraumInput);
