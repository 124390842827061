import styled from 'styled-components';

export const ItemContainer = styled('div')`
    display: flex;
    flex-flow: wrap;
    flex-grow: 0;
    // allow custom direction
    flex-direction: ${({ column }) => (column ? 'column' : 'row')};
    margin: 0.5em;
	margin-left: ${({ listSimple }) => (listSimple ? '-0.6em' : '0.5em')};
`;

export default ItemContainer;
