import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { blue, grey } from './colors';

const activeClassName = 'activeNavItem';

const LinkStyled = styled(NavLink).attrs({
    activeClassName,
})`
    display: flex;
    align-items: center;
    height: ${props => (props.height ? props.height : '2em')};

    font-weight: ${props => (props.fontSize ? '350' : '500')};
    font-size: ${props => (props.fontSize ? props.fontSize : '1rem')};
    text-transform: uppercase;

    padding: 0 1em;
    margin-bottom: 5px;
    color: ${grey};

    &:hover {
        color: ${blue};
    }

    &.activeNavItem {
        border-bottom: 5px solid rgba(0, 0, 0, 0.25);
        margin-bottom: 0;
    }
`;

export default props => <LinkStyled {...props} />;
