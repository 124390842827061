import React from 'react';
import Icon from '../style/IconButton';
import { lightGrey } from '../style/colors';

export default ({ onClick, disabled }) => (
    <Icon onClick={!disabled && onClick} disabled={disabled}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke={lightGrey}
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <polygon points="14 2 18 6 7 17 3 17 3 13 14 2" />
        </svg>
    </Icon>
);
