import React from 'react';
import { count } from '../service/fetch';
import { get } from '../service/immutables';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class RefsCount extends React.Component {
    search = async () => {
        this.setState({ toggle: false });
        let { api, query, idPath } = this.props;
        if (idPath === '') idPath = undefined;
        if (idPath !== undefined) {
            const result = await count(api, null, query + '' + idPath);
            this.setState({ count: result });
        } else {
            this.setState({ count: 0 });
        }
    };
    constructor() {
        super();
        this.state = {
            count: 0,
        };
    }

    componentDidMount() {
        this.search();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.idPath !== prevProps.idPath) {
            this.search();
        }
    }
    render() {
        const { count } = this.state;
        const { label, anschlussText } = this.props;
        return (
            <>
                {label}
                {count + ' ' + anschlussText}
            </>
        );
    }
}
RefsCount.defaultProps = {};
RefsCount.propTypes = {
    api: PropTypes.string.isRequired,
    idPath: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
};
const mapStateToProps = (state, props) => ({
    idPath: get(props.idPath, state.item) || props.helperForUserInput || '',
});

export default connect(mapStateToProps)(RefsCount);
