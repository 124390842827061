import React from 'react';
import EditHOC from '../../container/EditHOC';
import { DropdownSimpleAuth as DropdownSimple, Input } from '../../inputs/InputsWithMetaLabelFocus';
import Area from '../../style/Area';
import H2 from '../../style/H2';

const User = ({ type }) => (
    <>
        <H2>Password ändern:</H2>
        <Area>
            <Input
                label="Passwort"
                name="password"
                //type="password"
            />
        </Area>
        <br />
        <H2>Anzahl der Such-Resultate:</H2>
        <Area>
            <DropdownSimple
                label="Anzahl"
                name="anzahlSuche"
                items={[10, 25, 50, 75, 100, 200, 250]}
                default_value={25}
            />
        </Area>
        <br />
        <H2>Anzahl der zuletzt Bearbeitet-Resultate:</H2>
        <Area>
            <DropdownSimple
                label="Anzahl"
                name="anzahlBearbeitet"
                items={[25, 50, 100]}
                default_value={25}
            />
        </Area>
    </>
);

export default EditHOC(User);
