/**
 * @project:      ThBw
 * @file:         client/src/client/components/AttributesElement.js
 * @summary:
 * @desc:
 * @created:      2019-11-29 16:02:01 by ekzyis
 * @lastmodified: 2019-12-20 14:31:23 by ekzyis
 */
import React, { Component } from 'react';
import Area from '../style/Area';
import { ChangeHookDropdownSimple as DropdownSimple, FastSearch, Input } from '../inputs/InputsWithLabelFocus';
import { connect } from 'react-redux';
import Row from '../style/Row';
import Space from '../style/Space';
import { get } from 'lodash';
import { changeItem } from '../store/actions/item';

class AttributesElement extends Component {
    render() {
        const { modelType, availableTypes } = this.props;
        let placeholder = undefined;
        if (modelType === 'Zeit') {
            placeholder = 'TT.MM.JJJJ';
        }
        return (
            <>
                <Area>
                    <Row>
                        <DropdownSimple
                            label=""
                            name={`$attributes.key`}
                            items={availableTypes.map(obj => obj[1])}
                            changeHook={dispatch => dispatch(changeItem(`$attributes.value.v`, null))}
                            height="28px"
							width="fit-content"
                            noUnderline
                        />
						<Space />
                        {modelType !== 'Freie Suche' && modelType !== 'Zeit' ? (
                            <FastSearch api={modelType} name={`$attributes.value`} height="28px" placeholder=" " />
                        ) : (
                            <Input name={`$attributes.value`} height="28px" placeholder={placeholder} />
                        )}
                    </Row>
                </Area>
            </>
        );
    }
}

const mapStateToProps = (state, props) => {
    const key = get(state.item, `$attributes.key.v`) || get(state.search, `$attributes.key.v`);
    const type = props.availableTypes.find(obj => obj[1] === key);
    return { modelType: type[0], label: type[1]  };
};

export default connect(mapStateToProps)(AttributesElement);
