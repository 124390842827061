import React from 'react';
import styled from 'styled-components';
import { lineGrey } from './colors';

const Line = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;

    width: 1px;
    height: 90%;
    margin-top: 10%; // doesn't work TODO
    background-color: ${lineGrey};
`;

const LineContainer = styled.div`
    display: flex;
    padding: 0 8px;
    position: relative;
`;

export const VerticalLine = () => (
    <LineContainer>
        <Line />
    </LineContainer>
);

export default VerticalLine;
