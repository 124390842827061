import styled from 'styled-components';

const ShortStampRed = styled('div')`
    color: red;
    font-size: 1.5em;
    font-weight: bold;
    letter-spacing: 0px;
    margin-left: 10px;
    padding: 0;
`;

export default ShortStampRed;
