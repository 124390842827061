import React from 'react';
import Icon from '../style/IconButton';
import { linkBlue } from '../style/colors';

/**
 * This SVG was created with the help of the `Tryit Editor v3.5` from W3Schools
 * https://www.w3schools.com/html/tryit.asp?filename=tryhtml_default
 * https://www.w3schools.com/code/tryit.asp?filename=FUL5RCE8X813 (downscaled to 20x20 for usage in application)
 */
export default ({ onClick }) => (
    <Icon onClick={onClick}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            stroke={linkBlue}
            strokeWidth="1"
            fill="none"
        >
            {/* The circle */}
            <circle cx="10" cy="10" r="9.5" />
            {/* Lines which meet in the center of circle */}
            <line x1="10" y1="0.5" x2="10" y2="19.5" />
            <line x1="0.5" y1="10" x2="19.5" y2="10" />
            {/* Ellipse which goes from mid,top to mid,bottom */}
            <ellipse cx="10" cy="10" rx="5" ry="9.5" />
            {/* Bottom arc */}
            <path d="M 3.25 16.75 A 9.5 5 0 0 1 16.75 16.75" />
            {/* Top arc */}
            <path d="M 3.25 3.25 A 9.5 5 0 0 0 16.75 3.25" />
        </svg>
    </Icon>
);
