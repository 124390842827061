import styled from 'styled-components';

// TODO check if this is correct
// Element should be the only place where 'width' is used and everywhere else it should be auto
export const Element = styled('div')`
    display: flex;
    flex-direction: column;
    height: ${props => {
        const { height } = props;
        return height;
    }};
    width: ${props => {
        const { width, small, large, extraLarge, row } = props;
        let value = '100%'; // default

        if (width) value = width;
		if (row) value = '250px';
        else if (small) value = '165px';
        else if (large) value = '500px';
        else if (extraLarge) value = '750px';
        return value;
    }};

`;

export default Element;
