import React, { Component } from 'react';
import { get } from 'lodash';
import { get as getIM } from '../service/immutables';
import { deleteItem } from '../service/fetch';
import Area from '../style/Area';
import Item from '../style/Item';
import ItemContainer from '../style/ItemContainer';
import { Row } from '../style/Row';
import H3 from '../style/H3';
import Title from '../style/Title';
import AddSVG from '../SVG/Add';
import AllowedForUser from '../container/AllowedForUser';
import List from './List';
import { connect } from 'react-redux';
import { changeItem } from '../store/actions/item';
import { CheckBox, Input, Zeit } from '../inputs/InputsWithMetaLabelFocus';
import VerticalLine from '../style/VerticalLine';
import { DeleteItem } from './DeleteItem';

class Namen extends Component {
    componentDidUpdate() {
        const { items, handleChange } = this.props;
        // if new Person is created add deafult (empty) Zitiername
        if (items.length === 0) {
            this.addName(true);
            handleChange('short.v', 'Bitte einen Zitiernamen als vorrangige Namensform angeben!');
        }
    }
    addName = async (register = false) => {
        const { items, handleChange, personId } = this.props;
        // create minimal Zitiernamen
        const body = {
            von: {
                v: personId || null, //same code as: personId === undefined ? null : personId
            },
            short: {
                v: '',
            },
            register: {
                v: register,
            },
        };
        items.push(body);
        handleChange('namen', [...items]);
    };

    removeName = async i => {
        // remove item n parent state
        const { items, handleChange } = this.props;
        const id = items[i]._id;
        items.splice(i, 1);
        await deleteItem('zitiername', id); // delete item db
        handleChange('namen', [...items]);
    };

    render() {
        const { items, disableCheckbox } = this.props;

        return (
            <Area>
                <React.Fragment>
                    <Title>
                        Zitiernamen
                        <AllowedForUser rolle="Hiwi">
                            <AddSVG onClick={() => this.addName()} />
                        </AllowedForUser>
                    </Title>
                    <ItemContainer column>
                        {items.map((item, index) => {
                            return (
                                <Item key={index}>
                                    <H3>{item.short.v || 'bitte speichern'}</H3>
                                    <Row>
                                        <Input
                                            label="Name"
                                            name={`namen[${index}].name`}
                                            disabled={get(item, `geprueft.v`)}
                                            placeholder="(darf nie leer sein)"
											noMeta
                                            small
                                        />
                                        <VerticalLine />
                                        <Input
                                            label="Vorname"
                                            name={`namen[${index}].vorname`}
                                            disabled={get(item, `geprueft.v`)}
                                            placeholder="(bei Fürsten meist leer)"
											noMeta
                                            small
                                        />
                                        <VerticalLine />
                                        <Input
                                            label="Beiname"
                                            name={`namen[${index}].beiname`}
                                            disabled={get(item, `geprueft.v`)}
											noMeta
                                            small
                                        />
                                        <VerticalLine />
                                        <Input
                                            label="Titel"
                                            name={`namen[${index}].titel`}
                                            disabled={get(item, `geprueft.v`)}
                                            placeholder="(inklusive Territorium falls vorhanden)"
											noMeta
                                        />
                                    </Row>
                                    <Row>
                                        <CheckBox
                                            label="Name geprüft"
                                            name={`namen[${index}].geprueft`}
                                            small
											noMeta
                                            disabled={disableCheckbox}
                                        />
                                        <VerticalLine />
                                        <CheckBox
                                            label="Hauptname"
                                            name={`namen[${index}].register`}
                                            small
											noMeta
                                        />
                                    </Row>
                                    <List
                                        label="Namenszeitraum"
                                        name={`namen[${index}].namenszeitraum`}
                                        simple
                                    >
                                        {index2 => (
                                            <Zeit
                                                label="verwendet von - bis"
                                                name={`namen[${index}].namenszeitraum[${index2}]`}
                                            />
                                        )}
                                    </List>
                                    <DeleteItem type={'zitiername'} id={item._id} />
                                </Item>
                            );
                        })}
                    </ItemContainer>
                </React.Fragment>
            </Area>
        );
    }
}

const mapStateToProps = state => {
    let value = true;
    if (getIM('user.role', state.auth) === 'Admin') value = false;
    return {
        personId: state.item._id,
        items: state.item.namen || [],
        disableCheckbox: value,
    };
};
export default connect(
    mapStateToProps,
    { handleChange: changeItem }
)(Namen);
