import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Container from '../style/Container';
import Area from '../style/Area';
import Row from '../style/Row';
import H2 from '../style/H2';
import Link from '../style/Link';
import Space from '../style/Space';
//import thbw1verzeichnisse from '../../assets/thbw1verzeichnisse.pdf';
import thbw1 from '../../assets/thbw1.pdf';
import thbw2verzeichnisse from '../../assets/thbw2verzeichnisse.pdf';
//import thbw2 from '../../assets/thbw2.pdf';

const Style = styled('div')`
    display: flex;
	text-align: justify;
	justify-content: center;
    width: 100%;
    height: 100%;
	line-height: 1.5;
`;

class Editionsbaende extends React.Component {
    render() {
        return (    
            <Style>
                    <Container>
						<br/><br/><br/><br/>
						<Row center>
							<H2>
							{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? (
								<>Printed editions</>
							) : (
								<>Editionsbände</>	
							)}
							</H2>
							<br/><br/><br/><br/>
						</Row>
						<Row center>
							<br/><br/>
			                <Area width="50rem">
							<b>Band 1:</b>&nbsp;Theologenbriefwechsel im Südwesten des Reichs in der Frühen Neuzeit (1550-1620). Kritische Auswahledition, Band 1:&nbsp;
							<b>Württemberg I (1548-1570)</b>, hg. von Christoph Strohm, bearb. von Sabine Arend, Stephen E. Buckwalter, Daniel Degen, Gerald Dörner, Max Graff, 
							Theresa Möke, Paul A. Neuendorf	und Thomas Wilhelmi (Quellen und Forschungen zur Reformationsgeschichte 96), Gütersloh 2020.<br/>
							{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? (
								<><Space/><Space/><Space/><Link to={thbw1} target="_blank">Download PDF</Link></>
							) : (
								<><Space/><Space/><Space/><Link to={thbw1} target="_blank">PDF herunterladen</Link></>
							)}
							{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? (
								<><Space/><Space/><Space/><Link to={'//thbw.hadw-bw.de/ansicht/literatur/5fb51a871613bc188ab12362'}>Letters in the Database</Link></>
							) : (
								<><Space/><Space/><Space/><Link to={'//thbw.hadw-bw.de/ansicht/literatur/5fb51a871613bc188ab12362'}>Briefe in der Datenbank</Link></>
							)}
							</Area>
                        </Row>
						<br/>
						<Row center>
							<br/><br/>
			                <Area width="50rem">
							<b>Band 2:</b>&nbsp;Theologenbriefwechsel im Südwesten des Reichs in der Frühen Neuzeit (1550-1620). Kritische Auswahledition, Band 2:&nbsp;
							<b>Kurpfalz I (1556-1583)</b>, hg. von Christoph Strohm, bearb. von Stefan Aderhold, Sabine Arend, Marcel Böhme, Stephen E. Buckwalter, Daniel Degen, 
							Gerald Dörner, Max Graff, Judith Steiniger und Thomas Wilhelmi (Quellen und Forschungen zur Reformationsgeschichte 99), Gütersloh 2022.<br/>
							{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? (
								<><Space/><Space/><Space/><Link to={thbw2verzeichnisse} target="_blank">Table of Content and Index</Link></>
							) : (
								<><Space/><Space/><Space/><Link to={thbw2verzeichnisse} target="_blank">Inhaltsverzeichnis und Register</Link></>
							)}
							{this.props.path.split('/')[this.props.path.split('/').length-1] === 'engl' ? (
								<><Space/><Space/><Space/><Link to={'//thbw.hadw-bw.de/ansicht/literatur/62bb06b4bd67bd052cb5df21'}>Letters in the Database</Link></>
							) : (
								<><Space/><Space/><Space/><Link to={'//thbw.hadw-bw.de/ansicht/literatur/62bb06b4bd67bd052cb5df21'}>Briefe in der Datenbank</Link></>
							)}
							</Area>
                        </Row>
						<Row>
							<br/><br/>
						</Row>
                    </Container>
            </Style>
        );
    }
}

const mapStateToProps = (state) => ({
	path: state.router.location.pathname
});

export default connect(mapStateToProps)(Editionsbaende);
