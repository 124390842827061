import styled from 'styled-components';

export const ItemContainer = styled('div')`
    display: flex;
    flex-flow: wrap;
    flex-grow: 0;
	max-width: 90vw;
	row-gap: 4px;
    // allow custom direction
    flex-direction: ${({ row }) => (row ? 'row' : 'column')};
	margin: ${({ margin }) => (margin ? margin : '0.5em')};
`;

export default ItemContainer;
