import { get } from 'lodash';
import React from 'react';
import NewButton from '../../components/NewButton';
import SearchHOC from '../../container/SearchHOC';
import Link from '../../style/Link';
import TableRow from '../../style/SearchRow';
import Cell from '../../style/SearchCell';
import DeleteSVG from '../../SVG/Delete';
import OpenSVG from '../../SVG/Open';
import AllowedForUser from '../../container/AllowedForUser';
import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import { Input } from '../../inputs/InputsWithLabelFocus';

import Loading from '../../components/Loading';

const SearchAmt = ({ items, search, defaultSort, handleChangeSort, sort, asc, loading }) => {
    if (!sort.length) defaultSort('amt.v');
    return (
        <>
            <div>
                <TableRow>
                    <Cell block>{null}</Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Amt"
                            name="amt"
                            value={get(search, 'amt') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('amt.v')}
                            direction={'amt.v' === sort ? !asc : false}
                            active={'amt.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Anschluss"
                            name="anschluss"
                            value={get(search, 'anschluss') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('anschluss.v')}
                            direction={'anschluss.v' === sort ? !asc : false}
                            active={'anschluss.v' === sort}
                        />
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                <Loading loading={loading}>
                    {items.map((item, i) => (
                        <TableRow key={i}>
                            <Link to={`/edit/amt/${item._id}`} target={false}>
                                <OpenSVG />
                            </Link>
                            <Cell>{item.short && item.short.v}</Cell>
                            <Cell>{item.anschluss && item.anschluss.v}</Cell>
                            <AllowedForUser rolle="Admin">
                                <Link to={`/ref/amt/${item._id}`}>
                                    <DeleteSVG />
                                </Link>
                            </AllowedForUser>
                        </TableRow>
                    ))}
                </Loading>
            </div>
            <AllowedForUser rolle="Hiwi">
                <NewButton type="amt" target="_blank" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchAmt);
