import { get as getLodash } from 'lodash';

export const buildSacheShort = item => {
    const z = item.zusammensetzung;
    //const person = getLodash(z, 'person.v.short.v');
    //const anschlussPerson = getLodash(z, 'anschlussPerson.v');
    let begriff = '';
	/*
    if (person) {
        switch (anschlussPerson) {
            case 'Gen auf s':
                begriff += person + 's ';
                break;
            case 'Leerzeichen':
                begriff += person + ' ';
                break;
            case 'Komma':
                begriff += person + ', ';
                break;
            default:
                begriff += person + ', ';
                break;
        }
    }


    const zaehlung = getLodash(z, 'zaehlung.v');
    if (zaehlung) begriff += zaehlung + ' ';
	*/
    const werk =
        getLodash(z, 'werk.freitext.v') ||
        (getLodash(z, 'werk.druck.v') && getLodash(z, 'werk.druck.v.short.v')) ||
        null;
	
    //const kategorie = getLodash(z, 'kategorie.v');
    //const ort = getLodash(z, 'ort.v.short.v');
    //const anschlussOrt = getLodash(z, 'anschlussOrt.v');

    if (werk) {
        begriff += werk;
    } else {
		/*
        if (kategorie && !ort) {
            begriff += kategorie;
        } else if (ort && !kategorie) {
            begriff += ort;
        } else if (ort && kategorie) {
            switch (anschlussOrt) {
                case 'zu':
                    begriff += kategorie + ' zu ' + ort;
                    break;
                case 'von':
                    begriff += kategorie + ' von ' + ort;
                    break;
                case 'Gen auf s':
                    begriff += kategorie + ' ' + ort + 's';
                    break;
                case 'Gen auf er':
                    begriff += ort + 'er ' + kategorie;
                    break;
                case 'aus':
                    begriff += kategorie + ' aus ' + ort;
                    break;
                case 'in':
                    begriff += kategorie + ' in ' + ort;
                    break;
                case 'nach':
                    begriff += kategorie + ' nach ' + ort;
                    break;
                case 'für':
                    begriff += kategorie + ' für ' + ort;
                    break;
                case 'Leerzeichen':
                    begriff += kategorie + ' ' + ort;
                    break;
                case 'nur Ort':
                    begriff += ort;
                    break;
                case 'nur Kat.':
                    begriff += kategorie;
                    break;
                default:
                    break;
            }
        }
    */}
	/*
    const anzeigenZeit = getLodash(z, 'anzeigen.v');
    if (anzeigenZeit === true) {
        const zeit = getLodash(z, 'zeit');
        begriff += ' (';
        zeit.forEach((datum, i) => {
            if (getLodash(datum, 'am.t.v')) begriff += getLodash(datum, 'am.t.v') + '.';
            if (getLodash(datum, 'am.m.v')) begriff += getLodash(datum, 'am.m.v') + '.';
            if (getLodash(datum, 'am.j.v')) begriff += getLodash(datum, 'am.j.v');
            if (getLodash(datum, 'between') === true) {
                begriff += '-';
                if (getLodash(datum, 'bis.t.v')) begriff += getLodash(datum, 'bis.t.v') + '.';
                if (getLodash(datum, 'bis.m.v')) begriff += getLodash(datum, 'bis.m.v') + '.';
                if (getLodash(datum, 'bis.j.v')) begriff += getLodash(datum, 'bis.j.v');
            }
            if (i < zeit.length - 1) begriff += '/';
        });
        begriff += ')';
    }
	*/
    return begriff;
};
