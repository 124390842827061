import React from 'react';
import { get, get as getLodash } from 'lodash';

import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';
import AllowedForUser from '../../container/AllowedForUser';
import SearchHOC from '../../container/SearchHOC';

import { DruckRow as TableRow } from '../../style/SearchRow';
import Cell from '../../style/SearchCell';
import Link from '../../style/Link';
import LinkExtern from '../../style/LinkExtern';
import Row from '../../style/Row';

import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import DeleteSVG from '../../SVG/Delete';
import OpenSVG from '../../SVG/Open';

import formatZeit from '../../service/formatZeit';

import { CheckBox, FastSearch as Search, Input } from '../../inputs/InputsWithLabelFocus';

const SearchDruck = ({ items, search, defaultSort, handleChangeSort, sort, asc, loading }) => {
    if (!sort.length) defaultSort('autor.v');
    return (
        <>
            <>
                <TableRow>
                    <Cell block>{null}</Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Autor"
                            api="Zitiername"
                            value={get(search, 'autor') || ''}
                            name="autor"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('autor.v')}
                            direction={'autor.v' === sort ? !asc : false}
                            active={'autor.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Kürzel"
                            name="kuerzel"
                            value={get(search, 'kuerzel') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('kuerzel.v')}
                            direction={'kuerzel.v' === sort ? !asc : false}
                            active={'kuerzel.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <CheckBox label="geprüft" name="geprueft" width="100%" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('geprueft.v')}
                            direction={'geprueft.v' === sort ? !asc : false}
                            active={'geprueft.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Jahr"
                            name="amDruck.am.j"
                            value={get(search, 'amDruck.am.j')}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('amDruck.am.j.v')}
                            direction={'amDruck.am.j.v' === sort ? !asc : false}
                            active={'amDruck.am.j.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            noMeta
                            width="100%"
                            label="Druckort"
                            api="Ort"
                            value={get(search, 'druckOrt') || ''}
                            name="druckOrt"
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('druckOrt.v')}
                            direction={'druckOrt.v' === sort ? !asc : false}
                            active={'druckOrt.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="VD16/17"
                            name="nachweis.vd1617"
                            value={get(search, 'nachweis.vd1617') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('nachweis.vd1617.v')}
                            direction={'nachweis.vd1617.v' === sort ? !asc : false}
                            active={'nachweis.vd1617.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Sonstiger Nachweis"
                            name="nachweis.sonstige"
                            value={get(search, 'nachweis.sonstige') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('nachweis.sonstige.v')}
                            direction={'nachweis.sonstige.v' === sort ? !asc : false}
                            active={'nachweis.sonstige.v' === sort}
                        />
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                {/* vertikaler Platzhalter für Dropdownmenüs: */}
			 	{items.length < 1 && (
					 <>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
					 </>
				 )}
                <Loading loading={loading}>
                    {items && items.map((item, i) => {
                        let vd1617 = null;
                        let valuevd1617 = getLodash(item, 'nachweis.vd1617.v');
                        if (valuevd1617) {
                            if (valuevd1617.includes('VD16')) {
                                vd1617 = 'http://gateway-bayern.de/' + valuevd1617.replace(/ /g, '+');
                            } else if (valuevd1617.includes('VD17')) {
                                vd1617 =
                                    'https://kxp.k10plus.de/DB=1.28/CMD?ACT=SRCHA&IKT=8079&TRM=%27' +
                                    valuevd1617.replace('VD17 ', '');
                            }
                        }
                        return (
                            <TableRow key={i}>
                                <Link to={`/edit/druck/${item._id}`} target={false}>
                                    <OpenSVG />
                                </Link>
                                <Cell block>
                                    {item.autor && item.autor.map((verfasser, i) => (
                                        <Link
                                            key={i}
                                            to={'/edit/Zitiername/' + get(verfasser, 'v._id')}
                                        >
                                            {get(verfasser, 'v.short.v')}
                                            <br />
                                        </Link>
                                    ))}
                                </Cell>
                                <Cell>{item.kuerzel && item.kuerzel.v}</Cell>
                                <Cell>{item.geprueft && item.geprueft.v ? '✔' : ''}</Cell>
                                <Cell>{item.amDruck && `${formatZeit(get(item, 'amDruck'))}`}</Cell>
                                <Cell block>
                                    {item.druckOrt && item.druckOrt.map((e, i) => (
                                        <Link key={i} to={'/edit/ort/' + get(e, 'v._id')}>
                                            {get(e, 'v.short.v')}
                                            <br />
                                        </Link>
                                    ))}
                                </Cell>
                                <Cell>
                                    <LinkExtern href={vd1617} target="blank">
                                        {item.nachweis && item.nachweis.vd1617 && item.nachweis.vd1617.v}
                                    </LinkExtern>
                                </Cell>
                                <Cell block>
                                    {item.nachweis && item.nachweis.sonstige && item.nachweis.sonstige.map((e, i) => (
                                        <>
                                            {get(e, 'v')}
                                            <br />
                                        </>
                                    ))}
                                </Cell>
                                <AllowedForUser rolle="Admin">
                                    <Link to={`/ref/druck/${item._id}`}>
                                        <DeleteSVG />
                                    </Link>
                                </AllowedForUser>
                            </TableRow>
                        );
                    })}
                </Loading>
            </>
            <AllowedForUser rolle="Hiwi">
                <NewButton type="druck" target="_blank" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchDruck);
