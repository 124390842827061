import React from 'react';
import { connect } from 'react-redux';
import { get } from '../../service/immutables';

import Anmerkung from '../../components/Anmerkungen';

import CollapseArea from '../../container/CollapseArea';
import DeleteItem from '../../container/DeleteItem';
import EditHOC from '../../container/EditHOC';
import List from '../../container/List';
import RefsLinks from '../../container/RefsLinks';
import SetFavorite from '../../container/SetFavorite';
import Short from '../../container/Short';

import Area from '../../style/Area';
import H1 from '../../style/H1';
import Link from '../../style/Link';
import { Row } from '../../style/Row';
import Space from '../../style/Space';
import VerticalLine from '../../style/VerticalLine';

import OpenSVG from '../../SVG/Open';

import {
    CheckBox,
    FastSearch as Search,
    Input,
    LinkedInput,
} from '../../inputs/InputsWithMetaLabelFocus';

const Briefdruck = ({ type, shortNotGiven, item, noMitarbeiter }) => (
    <>
        <Row>
            <H1>Briefdruck</H1>
            <Space />
			<Link to={`/ansicht/briefdruck/${item._id}`} target="_self">
	            <OpenSVG />
	        </Link>
			<Space />
            <SetFavorite type={type} />
            <Space />
            <DeleteItem type={type} />
			<Space />			
        </Row>
        <Short name="long" />
		<Area>
            <RefsLinks label="Brief:&nbsp;" api="brief" idPath="_id" query="druck.v=" long />
        </Area>
        <CollapseArea label="Verzeichnung" show={true}>
            <Row>
				<div>
					<Area>
	                    <CheckBox 
							label="Auszug" 
							name="auszug" 
							width="44px"
							noMeta
							noUnderline
						/>
	                </Area>
					<Area>
	                    <CheckBox 
							label="Erwähnung" 
							name="briefErwaehnt" 
							width="65px"
							noMeta 
							noUnderline
						/>
	                </Area>
				</div>
					<Row>
		                <Area>
		                    <Search 
								label="Druck" 
								api="Druck" 
								name="druck" 
								width="390px"
								noMeta
							/>
		                    <Input 
								label="Benutztes Exemplar" 
								name="exemplar"
								noMeta
							/>
		                </Area>
		                <Area>
							<Row>
		                        <Input
		                            label="Blatt/Seite"
		                            name="seite"
		                            placeholder="Bl."
		                            helperForUserInput="Bl. "
									noMeta
									width="80px"
		                        />
	                        	<VerticalLine />
		                        <Input
		                            label="Nr."
		                            name="nummer"
		                            placeholder="Nr."
		                            helperForUserInput="Nr. "
		                           	width="80px"
									noMeta
		                        />
	                		</Row>
	                	</Area>
					</Row>
					<Area>
						<CheckBox
		                    label="nicht verifiziert"
		                    name="nichtVerifiziert"
							width="82px"
							tooltip="fügt [nicht verifiziert] zur Handschriftangabe hinzu"
							noMeta
							noUnderline
		                />
	 				</Area>
            </Row>
			<Row>
				<Area>
                    <CheckBox 
						label="Übersetzung"
						name="uebersetzung"
						width="72px"
						noMeta
						noUnderline
					/>
                </Area>
                <Area>
                    <Search label="Übersetzer" name="uebersetzer" api="Zitiername" />
                </Area>
			</Row>	
            <Area>
                <List label="Digitalisate" name="reproduktion" column>
                    {index => (
                        <>
                            <LinkedInput
                                label="Link"
                                name={`reproduktion[${index}].datei[0]`}
                                link=""
                                checkHttp
								large
								noMeta
                            />
                        </>
                    )}
                </List>
            </Area>
        </CollapseArea>
        <CollapseArea label="Zusatzinformationen" show={true}>
            <Row>
                <Area>
                    <Anmerkung />
                </Area>
            </Row>
			<Area>
                <List label="Literaturhinweise" name="literatur" column>
                    {index => (
                        <Row>
                            <Search
                                label="Literatur"
                                api="Literatur"
                                name={`literatur[${index}].literatur`}
								noMeta
                            />
                            <VerticalLine />
                            <Input
                                label="Seite"
                                name={`literatur[${index}].seite`}
                                placeholder="S."
                                helperForUserInput="S. "
                                small
								noMeta
                            />
                        </Row>
                    )}
                </List>
            </Area>
            <Area>
                <Input label="Texttitel" name="titel" width="390px" noMeta />
            </Area>
            <Area>
                <Search api="Briefdruck" label="abhängig von" name="abhaengigVon" width="390px"/>
            </Area>
            <Area>
                <Search 
					label="Textsorte" 
					api="Textsorte" 
					name="textsorte" 
					disabled={noMitarbeiter} 
					width="200px" 
				/>
            </Area>
			<List label="Sprache" name="sprache" simple>
                {index => (
                    <Area noStyle>
                        <Search 
							label="" 
							api="Sprache" 
							name={`sprache[${index}]`} 
							small 
							disabled={noMitarbeiter}
						/>
                    </Area>
                )}
            </List>
		 	 {/*
	        <Row>
	           <Schlagwort />
	        </Row>
	        */}
        </CollapseArea>
    </>
);

const mapStateToProps = state => {
	//let noAdmin = true;
	let noMitarbeiter = true;
	if (get('user.role', state.auth) === 'Admin') {
		//noAdmin = false;
		noMitarbeiter = false;
		}
	if (get('user.role', state.auth) === 'Mitarbeiter') noMitarbeiter = false;
	return {
		item: state.item,
	    shortNotGiven: state.item.short && state.item.short.v ? false : true, // für Collapsearea)
		noMitarbeiter: noMitarbeiter,
	};
};

export default EditHOC(connect(mapStateToProps)(Briefdruck));
