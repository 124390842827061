import { get } from 'lodash';
import React from 'react';
import formatZeit from '../../service/formatZeit';

import Loading from '../../components/Loading';
import NewButton from '../../components/NewButton';

import AllowedForUser from '../../container/AllowedForUser';
import SearchHOC from '../../container/SearchHOC';

import Cell from '../../style/SearchCell';
import Link from '../../style/Link';
import LinkExtern from '../../style/LinkExtern';
import { PersonRow as TableRow } from '../../style/SearchRow';
import Row from '../../style/Row';
import Space from '../../style/Space';

import { ArrowTopDown } from '../../SVG/ArrowTopDown';
import DeleteSVG from '../../SVG/Delete';
import OpenSVG from '../../SVG/Open';

import { Input, FastSearch as Search, Zeit } from '../../inputs/InputsWithLabelFocus';

const SearchPerson = ({ items, search, defaultSort, handleChangeSort, sort, asc, loading }) => {
    if (!sort.length) defaultSort('short.v');
    return (
        <>
            <>
                <TableRow>
                    <Cell block>{null}</Cell>
                    <Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="Name"
                            name="short"
                            value={get(search, 'short') || ''}
                        />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('short.v')}
                            direction={'short.v' === sort ? !asc : false}
                            active={'short.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Zeit noMeta width="100%" label="geboren" name="geburt.am" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('geburt.am.shortZeit.v')}
                            direction={'geburt.am.shortZeit.v' === sort ? !asc : false}
                            active={'geburt.am.shortZeit.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Zeit noMeta width="100%" label="gestorben" name="tod.am" />
                        <ArrowTopDown
                            onClick={() => handleChangeSort('tod.am.shortZeit.v')}
                            direction={'tod.am.shortZeit.v' === sort ? !asc : false}
                            active={'tod.am.shortZeit.v' === sort}
                        />
                    </Cell>
                    <Cell>
                        <Search
                            width="100%"
                            noMeta
                            label="Amt"
                            api="Amt"
                            name="aemter.amt"
                        />
                        <Space/>
                    </Cell>
                    <Cell>
                        <Search
                            width="100%"
                            noMeta
                            label="Wirkungsort"
                            api="Ort"
                            name="aemter.ort"
                        />
                        <Space/>
                    </Cell>
					<Cell>
                        <Input
                            noMeta
                            width="100%"
                            label="GND"
                            name="gnd"
                            value={get(search, 'gnd') || ''}
                        />
                    </Cell>
                    <Cell block>{null}</Cell>
                </TableRow>
                {/* vertikaler Platzhalter für Dropdownmenüs: */}
			 	{items.length < 1 && (
					 <>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
						 <Row>&nbsp;</Row>
					 </>
				 )}
                <Loading loading={loading}>
                    {items.map((item, i) => {
                        const aemter = item.aemter.map((e, i) => (
                            <React.Fragment key={i}>
                                {get(e, 'amt.v.short.v')}
                                <br />
                            </React.Fragment>
                        ));
                        const amtOrt = item.aemter.map((e, i) => (
                            <React.Fragment key={i}>
                                {get(e, 'ort.v.short.v')}
                                <br />
                            </React.Fragment>
                        ));
                        return (
                            <TableRow key={i}>
                                <Link to={`/edit/person/${item._id}`} target={false}>
                                    <OpenSVG />
                                </Link>
                                <Cell>{item.short.v}</Cell>                                
                                <Cell>
                                    {item.geburt.am && `${formatZeit(get(item, 'geburt.am'))}`}
                                </Cell>
                                <Cell>{item.tod.am && `${formatZeit(get(item, 'tod.am'))}`}</Cell>
                                <Cell>{aemter}</Cell>
                                <Cell>{amtOrt}</Cell>
								<Cell>
	                                {get(item, 'gnd.v') !== "-" ?
	                                    <LinkExtern href={item.gnd.v} target="_blank">
	                                        {get(item, 'gnd.v')
	                                            ? get(item, 'gnd.v')
	                                                  .replace('https://d-nb.info/gnd/', '')
	                                                  .replace('http://d-nb.info/gnd/', '')
	                                            : ''}
	                                    </LinkExtern>
	                                :
	                                    <>
	                                        -
	                                    </>
	                                }
	                            </Cell>
                                <AllowedForUser rolle="Admin">
                                    <Link to={`/ref/person/${item._id}`}>
                                        <DeleteSVG />
                                    </Link>
                                </AllowedForUser>
                            </TableRow>
                        );
                    })}
                </Loading>
            </>
            <AllowedForUser rolle="Hiwi">
                <NewButton type="person" target="_blank" />
            </AllowedForUser>
        </>
    );
};

export default SearchHOC(SearchPerson);
