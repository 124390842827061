import styled from 'styled-components';
import { bodyGrey2 } from './colors';

export const ScrollContainer = styled.div`
    flex: auto;
    overflow-y: auto;
	background-color: ${props => (props.grey ? bodyGrey2 : '')};
	height: ${props => (props.height ? props.height : '')};
	justify-content: center;
	display: flex;
`;

export default ScrollContainer;
