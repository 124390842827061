import Leaflet from 'leaflet'

/*
* iconAnchor muss immer genau die hälfe von iconSize sein
*
*
* */

export const image = new Leaflet.Icon({
    iconUrl: require('../SVG/marker.svg'),
    //shadowUrl: require('../public/marker-shadow.png'),
    iconSize:     [28, 40], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [14, 20], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0],  // the same for the shadow
    popupAnchor:  [0, -10]// point from which the popup should open relative to the iconAnchor
})