import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import LinkExtern from '../style/LinkExtern';
import Link from '../style/Link';
//import { getOperatorItem } from '../service/fetch';

const FooterStyle = styled('div')`
	position: relative;
	bottom: 0;
	width: 100%;
	height: 39px;
    display: flex;
	align-items: center;
	justify-content: center;
	border-top: 1px solid #ccc;
	border-radius: 0 !important;
    background-color: #0063AF;
	color: #FFFFFF;
	a:link {
		color: #FFFFFF;
	}
	a:visited {
		color: #FFFFFF;
	}
`;

class Footer extends Component {
	/* removed by cmagin in SearchFooter Changes
    constructor(props) {
        super(props);
        this.state = {
            count: 0
        };
    }

    componentDidMount() {
        getOperatorItem("Brief", {"$and":[{"sichtbar":"offen","options":{}}],"$or":[], "$time":[]})
            .then(data => this.setState({count: data.count}))
    }
	*/
    render() {
		let welcomePage = false;
		let editHOC = false;
		let searchHOC = false;
		let operator = false;
		let attributes = false;
		let karte = false;
		
		const path = this.props.path.split('/')[1];
		if (path.toLowerCase() === '') welcomePage = true;
		if (path.toLowerCase() === 'edit') editHOC = true;
		if (path.toLowerCase() === 'search') searchHOC = true;
		if (path.toLowerCase() === 'operator') operator = true;
		if (path.toLowerCase() === 'attributes') attributes = true;
		if (path.toLowerCase() === 'karte') karte = true;
        return (
			<>
				{(editHOC || searchHOC || operator || attributes || karte) ? (
					<></>
				) : (
					<FooterStyle>
						<LinkExtern href="https://www.hadw-bw.de/forschung/forschungsstelle/theologenbrief-wechsel-im-suedwesten-des-reichs-der-fruehen-neuzeit-1550-1620/kontakt" target="_blank">Kontakt</LinkExtern>&nbsp;|&nbsp;
						<LinkExtern href="https://www.hadw-bw.de/impressum" target="_blank">Impressum</LinkExtern>&nbsp;|&nbsp;
						{welcomePage && (<>
						<Link to="/bildnachweise" target="_self">Bildnachweise</Link>&nbsp;|&nbsp;</>)}
						<LinkExtern href="https://www.hadw-bw.de/datenschutzerklaerung" target="_blank">Datenschutzerklärung</LinkExtern>
		                {/*&nbsp; | {this.state.count} Briefe online*/}
		            </FooterStyle>
				)}
			</>
        );
    }
}

const mapStateToProps = state => ({
	path: state.router.location.pathname,
    short: state.item.short && state.item.short.v,
});

export default connect(mapStateToProps)(Footer);
