/**
 * Summary: Starting point for our react app.
 * Desc: ------------------------------------------------------------------------------
 *      In here, every middleware for our react app is set up.                        |
 *      We are using a redux store from 'react-redux' for state management and        |
 *      'connected-react-router' for routing.                                         |
 * ------------------------------------------------------------------------------------
 * @see (for further reference): -------------------------------------------------------
 *      * Connected-React-Router: https://github.com/supasate/connected-react-router  |
 *      * Redux:                  https://redux.js.org/introduction/getting-started   |
 *      * React-Redux:            https://react-redux.js.org/introduction/quick-start |
 *      * Redux-Thunk:            https://github.com/reduxjs/redux-thunk              |
 * ------------------------------------------------------------------------------------
 */

import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
/**
 * 'redux-thunk' is used in ./store/actions/search.js since actions in there return functions and
 * not objects. Actions which return functions are called "thunks". Redux itself does expect plain
 * objects with a type property as actions, so that's where the thunk middleware comes into play.
 * All that the thunk middleware does is call these functions with the dispatcher. If a action is
 * not a function, it just passes it on to redux.
 * In fact, the whole code of the redux-thunk middleware is only 14 lines long:
 * @see https://github.com/reduxjs/redux-thunk/blob/master/src/index.js
 * @see https://daveceddia.com/what-is-a-thunk/ for further explanation of what thunks are.
 */
import thunk from 'redux-thunk';
// our react app
import App from './App';
/** @see https://redux.js.org/basics/reducers */
import rootReducer from './client/store/reducers';
import './index.css';
/**
 * -- 'Connected-react-router'?
 * A react app is a __single-page__ application (SPA). And what is a SPA you may ask?
 * -------------------------------------------------------------------------------------------------
 * | A single-page application (SPA) is a web application or web site that interacts with the user |
 * | by dynamically rewriting the current page rather than loading entire new pages from a server. |
 * | This approach avoids interruption of the user experience between successive pages, making the |
 * | application behave more like a desktop application. [...]                                     |
 * | The page does not reload at any point in the process, [...]                                   |
 * -------------------------------------------------------------------------------------------------
 * - Wikipedia, Single-page application, https://en.wikipedia.org/wiki/Single-page_application
 * This means, that while navigating through our application, we are not making page requests
 * to our server and therefore not changing our URL / location.
 * This behaviour breaks the history buttons of the browser since they rely on navigating between
 * different locations. To fix this and still keep the advantages of a SPA, we insert a router which
 * handles location changes even though the document itself does not change, it only
 * dynamically renders other content.
 * The library is called 'connected-react-router' because it is meant for usage with redux
 * - "connecting" with the redux store. It adds a router reducer to the store.
 * @see store/reducers/index.js for explanation about router reducer / reducers in general
 * @see src/components/App.js for definition of routes for dynamic rendering
 */
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router';


let store;
export const history = createBrowserHistory();

/**
 * The redux logger which can be seen in action in the browser web console (press F12)
 * while dispatching redux actions.
 * [[Daniel: zu diesem comment von Ramdip gehörte eigentlich statt der folgenden diese Codezeile:
 * import { createLogger } from 'redux-logger';
 * ]] 
 */
const { createLogger } = require(`redux-logger`);
const logger = createLogger({
    collapsed: true,
    diff: true,
});

/**
 * [[hier hatte Ramdip folgende Codeergänzung in einer branch:
 * proper way to fix #368
 * @see https://github.com/heidelberger-akademie-d-wissenschaften/ThBw/issues/368
 * @see https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
 * const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
 * ]]
 */

// the redux store
store = createStore(
	// add router reducer to our root reducer
    // @see store/reducers/index.js
    connectRouter(history)(rootReducer),
    compose(
        applyMiddleware(thunk, routerMiddleware(history), logger),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);
export { store };

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('app')
);
