import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '../../style/Row';
import { get } from '../../service/immutables';
import styled from 'styled-components';
import { changeItem } from '../../store/actions/item';
import { connect } from 'react-redux';
import { ultraLightGrey } from '../../style/colors';
import { changeAuth } from '../../store/actions/auth';

const Select = styled.select`
    border: none;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);

    padding: 2px;

    &:hover {
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
    }

    background-color: ${p => (p.disabled ? ultraLightGrey : 'inherit')};
`;

const DropdownSimple = ({
    items,
    value,
    handleChange,
    disableEmpty,
    erschlossen,
    unsicher,
    fragwuerdig,
    ca,
    disabled,
    default_value,
    default_labels
}) => (
    <Row nowrap>
        {erschlossen && '['}
        <Select onChange={handleChange} value={value || default_value} disabled={disabled}>
            {!disableEmpty && (
                <option key="0" value="">
                    {' '}
                </option>
            )}
            {items.map((item, i) => (
                <option key={i + 1} value={item}>
                    {getOptionLabel(item, i, default_labels)}
                </option>
            ))}
        </Select>
        {ca && <>&nbsp;ca.</>}
        {unsicher && '?'}
        {erschlossen && ']'}
        {fragwuerdig && <>&nbsp;[?]</>}
    </Row>
);

DropdownSimple.defaultProps = {
    disableEmpty: true, // for (not) show a empty value
    value: null,
    default_labels: ''
};

DropdownSimple.propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.any, //.isRequired,
    disableEmpty: PropTypes.bool,
    default_value: PropTypes.string,
    default_labels: PropTypes.string
}

const mapStateToProps = (state, props) => ({
    value: get(props.name + '.v', state.item) || 0,
    erschlossen: get(props.name + '.m.erschlossen', state.item),
    unsicher: get(props.name + '.m.unsicher', state.item),
    ca: get(props.name + '.m.ca', state.item),
    fragwuerdig: get(props.name + '.m.fragwuerdig', state.item),
});

const mapItemDispatchToProps = (dispatch, props) => {
    return {
        handleChange: e => {
            dispatch(changeItem(props.name + '.v', e.target.value));
        },
    };
};

const mapItemAndAuthDispatchToProps = (dispatch, props) => {
    return {
        handleChange: e => {
            dispatch(changeItem(props.name + '.v', e.target.value));
            // auth store has no '.v' at end - see default auth store
            dispatch(changeAuth(props.name , e.target.value));
        },
    };
};

const changeHookMapItemDispatchToProps = (dispatch, props) => {
    return {
        handleChange: e => {
            dispatch(changeItem(props.name + '.v', e.target.value));
            props.changeHook(dispatch, e.target.value);
        }
    }
};

function getOptionLabel(item, i, default_labels){
    if(default_labels === ''){
        return  item === '' ? '       ' : item
    }
    else if (default_labels !== ''){
        return item === '' ? '        ' : default_labels + ' ' + i  
    }
}

export const DropDownSimpleItem = connect(
    mapStateToProps,
    mapItemDispatchToProps
)(DropdownSimple);

export const DropDownSimpleAuth = connect(
    mapStateToProps,
    mapItemAndAuthDispatchToProps
)(DropdownSimple);

export const ChangeHookDropDownSimple = connect(
    mapStateToProps,
    changeHookMapItemDispatchToProps
)(DropdownSimple);

export default DropDownSimpleItem;