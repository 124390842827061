/**
 * @project:      ThBw
 * @file:         client/src/client/routes/edit/Listen/Konfession.js
 * @summary:
 * @desc:
 * @created:      2019-10-23 21:21:31 by Moritz Mock
 * @lastmodified: 2019-12-06 12:54:29 by ekzyis
 */
import React from 'react';
import EditHOC from '../../../container/EditHOC';
import { Input } from '../../../inputs/InputsWithMetaLabelFocus';
import Area from '../../../style/Area';
import H1 from '../../../style/H1';
import { Row } from '../../../style/Row';
import DeleteItem from '../../../container/DeleteItem';
import SetFavorite from '../../../container/SetFavorite';
import Space from '../../../style/Space';

const Konfession = ({ type }) => (
    <>
        <Row>
            <H1>Konfession</H1>
            <Space />
            <SetFavorite type={type} />
            <Space />
            <DeleteItem type={type} />
        </Row>
        <Area>
            <Input label="Konfession" name="name" />
        </Area>
    </>
);

export default EditHOC(Konfession);
