import React from 'react';
import Container from '../style/Container';
import Row from '../style/Row';
import H3 from '../style/H3';
import styled from 'styled-components';
import LinkExtern from '../style/LinkExtern';
import Area from '../style/Area';
 
const Style = styled('div')`
    display: flex;
	text-align: justify;
	justify-content: center;
    width: 100%;
    height: 100%;
	line-height: 1.5;
`;

class Welcome extends React.Component {
    render() {
        return (
            <Style>
                    <Container>
						<Row center>
							<H3>Bildnachweise</H3>
						</Row>
						<Row center>
							<br/><br/>
			                <Area width="50rem">
				                <>Bild "Briefe": </>
								<>Ausschnitt aus: Hans Holbein d. J., Bildnis des Kaufmanns Georg Gisze in London, 1532 (Quelle: <LinkExtern noBreak target="_blank" href="https://commons.wikimedia.org/wiki/File:Der_Kaufmann_Georg_Gisze_(Hans_Holbein_the_Younger).jpg">Wikimedia Commons</LinkExtern>)</>
							</Area>
                        </Row>
						<Row center>
							<br/><br/>
							<Area width="50rem">
				                <>Bild "Personen": </>
								<>Johannes Brenz, in: Boissard, Bibliotheca chalcographica, Tl. 1-5 (Heidelberg 1669), P3 (Quelle: <LinkExtern noBreak target="_blank" href="http://mateo.uni-mannheim.de/desbillons/aport/seite66.html">Universität Mannheim, MARABU</LinkExtern>)</>
							</Area>
                        </Row>
						<Row center>
							<br/><br/>
							<Area width="50rem">
				                <>Bild "Orte": </>
								<>Südwesten des Heiligen Römischen Reichs deutscher Nation. Ausschnitt aus: Putzgers historischer Schul-Atlas, Deutschland zur Zeit der Reformation, 1547 (Quelle: <LinkExtern noBreak target="_blank" href="http://nbn-resolving.de/urn:nbn:de:0220-gd-5464445">GEI-Digital</LinkExtern>)</>
							</Area>
                        </Row>
						<Row center>
							<br/><br/>
							<Area width="50rem">
			                	<>Bild "Sachen": </>
								<>Ausschnitt aus: Matthias Grünewald, Isenheimer Altar, Kreuzigung (Quelle: <LinkExtern noBreak target="_blank" href="https://de.wikipedia.org/wiki/Datei:Mathis_Gothart_Gr%C3%BCnewald_022.jpg">Wikimedia Commons</LinkExtern>)</>
							</Area>				
                        </Row>
                    </Container>
            </Style>
        );
    }
}

export default Welcome;
