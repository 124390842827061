import PropTypes from 'prop-types';
import React from 'react';
import Row from '../../style/Row';
import { get } from '../../service/immutables';
import { changeItem } from '../../store/actions/item';
import { connect } from 'react-redux';
import LinkExtern from '../../style/LinkExtern';

class LinkedInput extends React.Component {
    constructor() {
        super();
        this.state = { edit: false };
    }
    render() {
        let { value, erschlossen, unsicher, fragwuerdig, ca, link } = this.props;
        const { edit } = this.state;

        if (link === undefined) link = '';
        return (
            <Row nowrap>
                {erschlossen && '['}
                {edit || !value.length ? (
                    <>&nbsp;</>
                ) : (
                    <>
                        <LinkExtern target="_blank" href={link + value}>
                            {value}
                        </LinkExtern>
                    </>
                )}
                {ca && 'ca.'}
                {unsicher && '?'}
                {erschlossen && ']'}
                {fragwuerdig && '[?]'}
            </Row>
        );
    }
}

LinkedInput.defaultProps = {};

LinkedInput.propTypes = {
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.any, //.isRequired
};

const mapStateToProps = (state, props) => ({
    value: get(props.name + '.v', state.item) || '',
    erschlossen: get(props.name + '.m.erschlossen', state.item),
    unsicher: get(props.name + '.m.unsicher', state.item),
    ca: get(props.name + '.m.ca', state.item),
    fragwuerdig: get(props.name + '.m.fragwuerdig', state.item),
});

const mapDispatchToProps = (dispatch, props) => ({
    handleChange: e => dispatch(changeItem(props.name + '.v', e.target.value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LinkedInput);
