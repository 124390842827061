export const SEARCH_CHANGE_SORT = 'SEARCH_CHANGE_SORT';
export const SEARCH_START_LOADING = 'SEARCH_START_LOADING';
export const SEARCH_END_LOADING = 'SEARCH_END_LOADING';
export const SEARCH_CHANGE_ITEMS = 'SEARCH_CHANGE_ITEMS';
export const SEARCH_CHANGE_PAGE = 'SEARCH_CHANGE_PAGE';
export const SEARCH_JUMP_PAGE = 'SEARCH_JUMP_PAGE';
export const SEARCH_RESET_PAGE = 'SEARCH_RESET_PAGE'
export const SEARCH_SET_COUNT = 'SEARCH_SET_COUNT';
export const SEARCH_SET_LIMIT = 'SEARCH_SET_LIMIT';
export const SEARCH_RESET = 'SEARCH_RESET';
export const SEARCH_TOGGLE_SHOW_EYE = 'SEARCH_TOGGLE_SHOW_EYE';
export const SET_ABORT_CONTROLLER = 'SET_ABORT_CONTROLLER';
export const ABORT_FETCH = 'ABORT_FETCH';
export const INIT_ATTRIBUTES_SEARCH = 'INIT_ATTRIBUTES_SEARCH';
