import React, { Component } from 'react';
import { get as getLodash } from 'lodash';
import { getItem } from '../../service/fetch';

class ShortRedirectPerson extends Component {
    state = {
        text: 'Einen Moment bitte',
    };

    async componentWillMount() {
        const { short } = this.props.match.params;
        const person = await getItem('Person', null, 'short.v=' + short);
        if (getLodash(person, '0.short.v') === short) {
            this.props.history.push(`/ansicht/person/${getLodash(person, '0._id')}`);
        } else {
            this.setState({
                text: `Leider nicht gefunden!`,
            });
        }
    }

    render() {
        return <div>{this.state.text}</div>;
    }
}

export default ShortRedirectPerson;
