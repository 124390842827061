import styled from 'styled-components';
import { boxShadow, ultraLightGrey } from './colors';

export const Element = styled('div')`
    display: flex;
    flex-direction: column;
    height: ${props => (props.height ? props.height : 'fit-content')};
    width: ${props => {
        const { width, small, large, extraLarge, noMeta } = props;
        let value = '250px'; // default
        if (width) value = width;
        else if (small) value = '165px';
        // Minimum für funktionierende Toolbox bei maximaler Labellänge von ca. 7 Zeichen, z.B. "Sprache"
        else if (large) value = '500px';
        else if (extraLarge) value = '750px';

        if (noMeta) value = '100%';
        return value;
    }};
	max-width: 75vw;
    ${p =>
        p.disabled &&
        `
        background-color: ${ultraLightGrey};
        ${boxShadow}
    `};
`;

export default Element;
