import React from 'react';
import Loading from '../../../../components/Loading';

// A general component to show items
const SearchItems = ({ loading, items, children }) => {
    return (
        <>
            <Loading loading={loading}>{items.map((item, i) => children(item, i))}</Loading>
        </>
    );
};

export default SearchItems;
