import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { getItem, getToken, saveItem } from '../service/fetch';
import { addNotification } from '../store/actions/notificationActions';
import { loadItem, resetItem } from '../store/actions/item';
import Loading from '../components/Loading';
import MetaMenu from '../components/MetaMenu';
import InfoMenu from '../container/InfoMenu';
import SaveButton from '../components/SaveButton';
import SideMenu from '../style/SideMenu';
import ScrollContainer from '../style/ScrollContainer';
import Icon from '../style/IconButton';
import styled from 'styled-components';
import { grey, lightBlue } from '../style/colors';
import { TOGGLE_SHOW_META_MENU } from '../store/constants/ActionTypes';
import Container from '../style/Container';
import { history } from '../../index';
import AllowedForUser from './AllowedForUser';
import Link from '../style/Link';

const IconText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 1.5rem;
    height: 1.5rem;

    font-size: 1.1rem;
    font-weight: 600;
    
    color: ${({ active }) => (active ? lightBlue : grey)}
    
    border: 3px solid;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row !important;
`;

let justSaved = false;

export default PassedComponent => {
    class EditHOC extends Component {
        constructor(props) {
            super();
            this.state = {
                loading: 1,
                error: {},
                showInfoMenu: false,
                focus: '',
                type:
                    props.match.url.split('/')[2].toLowerCase() === 'favoriten'
                        ? 'mitarbeiter'
                        : props.match.url.split('/')[2],
            };
        }

        handleLeavePage = e => {
            const { saved } = this.props;
            if (saved === false || saved === undefined) {
                const confirmationMessage = '';
                e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
                return confirmationMessage; // Gecko, WebKit, Chrome <34
            }
        };

        startLoading = () => {
            this.setState(prevState => ({ loading: 1 }));
        };

        endLoading = () => {
            this.setState(prevState => ({ loading: 0 }));
        };

        handleSave = async () => {
            const { id } = this.props.match.params;
            const { type } = this.state;
            this.startLoading();
            saveItem(type, id, this.props.item)
                .then(item => {
                    //this.props.addNotification(`${type} gespeichert`, 'success');
                    console.info(item);
                    const id = item._id; // use id of saved item now - maybe we just created a new item by saving
                    getItem(type, id)
                        .then(item => {
                            this.props.loadItem(item);
                            this.props.addNotification(`geladen`, 'success');
                        })
                        .catch(({ message }) => {
                            console.error(message);
                            this.props.addNotification(
                                `Fehler beim Laden`,
                                message,
                                'error'
                            );
                        });
                })
                .catch(({ message }) => {
                    console.error(message);
                    this.props.addNotification(
                        `Fehler beim Speichern`,
                        message,
                        'error'
                    );
                })
                .finally(() => {
					this.endLoading();
					justSaved = true;
					if(type === 'brief' && id) history.push(`/ansicht/${type}/${id}`);
				});
        };

        async componentDidMount() {
            const token = getToken();
            //console.log({ token });
            if (token !== '') {
                //für chrome
                window.addEventListener('beforeunload', this.handleLeavePage);
                //für firefox
                window.onbeforeunload = this.handleLeavePage;
                const { id } = this.props.match.params;
                const { type } = this.state;

                if (id && type) {
                    // we are editing an existing object
                    this.startLoading();
                    getItem(type, id)
                        .then(item => {
                            this.props.loadItem(item);
                            this.props.addNotification(`geladen`, 'success');
                        })
                        .catch(({ message }) => {
                            console.error(message);
                            this.props.addNotification(
                                `Fehler beim Laden`,
                                message,
                                'error'
                            );
                        })
                        .finally(() => this.endLoading());
                } else if (!id) {
                    // we are editing an object which is not saved (yet)
                    if (!type) {
                        // we have a serious problem
                        this.props.addNotification(
                            'Fehler beim Laden',
                            'Keine ID und Typ',
                            'error'
                        );
                    }
                    this.endLoading();
                }
            } else {
                history.push('/login/forceLogout');
            }
        }

        componentWillUnmount() {
            window.removeEventListener('beforeunload', this.handleLeavePage);
            window.onbeforeunload = null;
            this.props.resetItem();
        }

        toggleInfo = () => {
            this.setState(({ showInfoMenu }) => ({ showInfoMenu: !showInfoMenu }));
        };

        render() {
            const { loading, type, showInfoMenu } = this.state;
            const { showMetaMenu, toggleMetaMenu, focus } = this.props;
            const { id } = this.props.match.params;
            let rolle = 'Hiwi';
            /*
            if(type.toLowerCase() === "person"){
                rolle = "Admin"
            }
            */
            return (
                <>
                    <Prompt
                        when={!this.props.saved && !justSaved}
                        message={location =>
                            location.pathname.match(/^\/edit\/[a-zA-z]+\/[0-9a-z]+$/)
                                ? true
                                : 'Es gibt ungespeicherte Änderungen. Wirklich verlassen? ' +
                                  location.pathname
                        }
                    />
					<FlexRow>
	                    <ScrollContainer>
	                        <Container>
	                            <Loading loading={loading}>
	                                <PassedComponent type={type} loading={loading} />
	                            </Loading>
	                        </Container>
	                    </ScrollContainer>
	                    {showMetaMenu && <MetaMenu />}
	                    {showInfoMenu && <InfoMenu id={id} type={type} />}
	                    <SideMenu>
	                        <Icon onClick={toggleMetaMenu} animated disabled={!focus}>
	                            <IconText active={showMetaMenu}>M</IconText>
	                        </Icon>
	                        <Icon onClick={this.toggleInfo}>
	                            <IconText active={showInfoMenu}>I</IconText>
	                        </Icon>
	                        <AllowedForUser rolle="Hiwi">
	                            <Icon>
	                                <Link to={`/log/${type}/${id}`}>
	                                    <IconText>L</IconText>
	                                </Link>
	                            </Icon>
	                        </AllowedForUser>
	                    </SideMenu>
					</FlexRow>
					{loading === 0 &&
                            <AllowedForUser rolle={rolle}>
                                <SaveButton clickHandler={this.handleSave} saved={this.props.saved}/>
                            </AllowedForUser>
                        }
                </>
            );
        }
    }

    const mapStatetoProps = state => ({
        item: state.item,
        saved: state.item.saved,
        focus: state.meta.focus,
        showMetaMenu: state.meta.show && !!state.meta.focus.length, // only show if the path is set
    });

    const mapDispatchToProps = {
        addNotification,
        resetItem,
        loadItem,
        toggleMetaMenu: function() {
            return { type: TOGGLE_SHOW_META_MENU };
        },
    };

    return connect(
        mapStatetoProps,
        mapDispatchToProps
    )(EditHOC);
};
